import { validateStyleObj } from '../../common/functions/_utilFunctions';
/**
 * Updates the style of a component.
 * @param {Object} comp - The component to update the style for.
 */
export default function updateStyle(comp) {
	comp.setStyle(
		validateStyleObj({
			...comp.getStyle(),
			'background-color': comp.get('backgroundColor'),
			'border-width': comp.get('borderWidth'),
			'border-style': comp.get('borderStyle'),
			'border-color': comp.get('borderColor'),
			'border-radius': comp.get('useBorderRadius')
				? Object.values(comp.get('borderRadius')).join(' ')
				: null
		})
	);
}
