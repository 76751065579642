import { capitalize } from 'lodash-es';
import { toHex } from 'color2k';

import {
	getUnit,
	setContent,
	addClassesString,
	webSafeFonts,
	webFonts,
	cssFontWeights,
	borderStyles,
	merge,
	isValidDimension,
	isValidUnit,
	getValidUnit
} from 'datatalks-utils';
import {
	ColorPickerInput,
	InputNumber,
	Accordion,
	SectionItem,
	section,
	Dropdown,
	ToggleableSection
} from 'datatalks-ui';
import buttonBorderRadius from './common/_borderRadius';
import buttonAlignment from './common/_alignment';
import _lineHeightDropdown from '../../../traits/commonAccordions/style/common/_lineHeight';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		accordion: {
			title: 'Button',
			color: 'grey',
			type: 'extend',
			extendedClasses: ''
		},
		traitsVisibility: {
			width: true,
			align: true,
			height: true,
			lineHeight: true,
			font: true,
			weight: true,
			color: true,
			backgroundColor: true,
			borderWidth: true,
			borderStyle: true,
			borderColor: true,
			borderRadius: true
		},
		buttonBorderRadiusOptions: {},
		buttonAlignmentOptions: {},
		showBorderRadius: true,
		onBorderRadiusToggle: null,
		useWebFonts: true,
		background: {
			label: 'Background color',
			color: obj.component.get('buttonBackgroundColor'),
			emptyColor: () =>
				obj.component
					.getEditor()
					?.getStyleRules('button', 'background-color'),
			emptyColorLabel: 'Buttons color',
			onChange: (cpi, ev) => {
				obj.component.setAndRerender(
					'buttonBackgroundColor',
					toHex(cpi.color) === toHex(cpi.emptyColor)
						? null
						: cpi.getColor()
				);
			}
		},
		width: {
			label: 'Width',
			value: obj.component.get('buttonWidth'),
			unit: getUnit(obj.component.get('buttonWidth')),
			changeableUnit: true,
			onChange: (value, unit, inputValue) => {
				obj.component.setAndRerender('buttonWidth', inputValue);
			}
		},
		align: {
			label: 'Alignment',
			value: obj.component.get('buttonAlignment'),
			onChange: (allSelected, changedItem) => {
				obj.component.setAndRerender(
					'buttonAlignment',
					changedItem.getValue()
				);
			}
		},
		height: {
			label: 'Height',
			value:
				obj.component.get('buttonHeight') ||
				obj.component.getEditor()?.getStyleRules('button', 'height'),
			changeableUnit: true,
			onChange: value => {
				obj.component.setAndRerender('buttonHeight', value);
			}
		},
		lineHeight: {
			label: 'Line height',
			resetLabel: 'Same as general style',
			value: obj.component.get('buttonLineHeight'),
			onChange: value => {
				let lineHeight = value;
				if (
					lineHeight ===
					emailBuilder.getLineHeightTypes().sameAsHeight
				) {
					buttonHeightInput.on('change', setLineHeightToHeight);
					lineHeight = obj.component.get('buttonHeight');
				} else {
					buttonHeightInput.off('change', setLineHeightToHeight);
				}
				obj.component.setAndRerender('buttonLineHeight', lineHeight);
			},
			onReset: () => {
				obj.component.setAndRerender('buttonLineHeight', null);
			}
		},
		font: {
			label: 'Font',
			value: obj.component.get('buttonFontFamily'),
			onChange: value => {
				obj.component.setAndRerender('buttonFontFamily', value);
			}
		},
		weight: {
			label: 'Text weight',
			value: obj.component.get('buttonFontWeight'),
			onChange: value => {
				obj.component.setAndRerender('buttonFontWeight', value);
			}
		},
		color: {
			label: 'Text color',
			value: obj.component.get('buttonColor'),
			emptyColor: () =>
				obj.component.getEditor()?.getStyleRules('button', 'color'),
			emptyColorLabel: "General button's text color",
			onChange: value => {
				obj.component.setAndRerender('buttonColor', value);
			}
		},
		borderWidth: {
			label: 'Width',
			value: obj.component.get('buttonBorderWidth') || 0,
			unit: getUnit(obj.component.get('buttonBorderWidth')) || 'px',
			onChange: value => {
				obj.component.setAndRerender('buttonBorderWidth', value);
			}
		},
		borderStyle: {
			label: 'Style',
			value: obj.component.get('buttonBorderStyle'),
			onChange: value => {
				obj.component.setAndRerender('buttonBorderStyle', value);
			}
		},
		borderColor: {
			label: 'Color',
			value: obj.component.get('buttonBorderColor'),
			onChange: value => {
				obj.component.setAndRerender('buttonBorderColor', value);
			}
		},
		borderRadius: {
			label: 'Border radius',
			corners: {
				topLeft: {
					value: obj.component.get('buttonBorderRadius')?.topLeft,
					onChange: value => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topLeft: value
						});
					},
					unit: getUnit(
						obj.component.get('buttonBorderRadius')?.topLeft
					)
				},
				topRight: {
					value: obj.component.get('buttonBorderRadius')?.topRight,
					onChange: value => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topRight: value
						});
					},
					unit: getUnit(
						obj.component.get('buttonBorderRadius')?.topRight
					)
				},
				bottomRight: {
					value: obj.component.get('buttonBorderRadius')?.bottomRight,
					onChange: value => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomRight: value
						});
					},
					unit: getUnit(
						obj.component.get('buttonBorderRadius')?.bottomRight
					)
				},
				bottomLeft: {
					value: obj.component.get('buttonBorderRadius')?.bottomLeft,
					onChange: value => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomLeft: value
						});
					},
					unit: getUnit(
						obj.component.get('buttonBorderRadius')?.bottomLeft
					)
				}
			}
		},
		forceOutlookBorderRadius: {
			label: 'Force Outlook border-radius',
			description:
				'Outlook does not support buttons with auto width and border-radius simultaneously. If you enable this option, the button in Outlook will have a border-raclius and will be full width by default. However, you can still manually adjust the width by specifying a percentage in the input below:',
			active: obj.component.get('buttonHasOutlookBorderRadius'),
			onActiveChange: value => {
				obj.component.setAndRerender(
					'buttonHasOutlookBorderRadius',
					value
				);
			},
			outlookWidth: {
				label: 'Outlook button width',
				value: obj.component.get('buttonOutlookWidth'),
				unit: getUnit(obj.component.get('buttonOutlookWidth')),
				changeableUnit: false,
				onChange: value => {
					obj.component.setAndRerender('buttonOutlookWidth', value);
				}
			}
		},
		textColorPickerProp: 'buttonCp',
		backgroundColorPickerProp: 'buttonBackgroundCp',
		borderColorPickerProp: 'buttonBorderCp'
	};

	options = merge(defaults, options);

	const editor = obj.component.getEditor();
	const emailBuilder = editor?.getEmailBuilder();

	let fonts = webSafeFonts;

	if (options.useWebFonts) {
		fonts = fonts.concat(webFonts);
	}

	if (
		options.traitsVisibility.color &&
		!customTrait[options.textColorPickerProp]
	)
		customTrait[options.textColorPickerProp] = new ColorPickerInput({
			emptyColor: options.color.emptyColor,
			emptyColorLabel: options.color.emptyColorLabel,
			color: options.color.value,
			onChange: (cpi, ev) => {
				if (typeof options.color.onChange === 'function')
					options.color.onChange(cpi.getColor());
			}
		});

	if (
		options.traitsVisibility.backgroundColor &&
		!customTrait[options.backgroundColorPickerProp]
	) {
		const cp = new ColorPickerInput({
			color: options.background.value,
			emptyColor: options.background.emptyColor,
			emptyColorLabel: options.background.emptyColorLabel,
			onChange: options.background.onChange
		});

		obj.component
			.getEditor()
			.on('run:change-general-style-prop', (edt, sender) => {
				if (
					sender.changedProp === 'buttonBackgroundColor' &&
					cp.emptyColorIsSet()
				) {
					cp.changeSwatchColor(sender.newValue);
				}
			});

		customTrait[options.backgroundColorPickerProp] = cp;
	}

	if (
		options.traitsVisibility.borderColor &&
		!customTrait[options.borderColorPickerProp]
	)
		customTrait[options.borderColorPickerProp] = new ColorPickerInput({
			color: options.borderColor.value,
			onChange: (cpi, ev) => {
				if (typeof options.borderColor.onChange === 'function')
					options.borderColor.onChange(cpi.getColor());
			}
		});

	if (
		options.traitsVisibility.width &&
		options.width.value !== 'auto' &&
		(!isValidDimension(options.width.value) ||
			!isValidUnit(options.width.unit))
	) {
		options.width.unit = getValidUnit(options.width.value, {
			defaultUnit: '%'
		});
		options.width.value = isNaN(parseFloat(options.width.value))
			? '100%'
			: parseFloat(options.width.value) + options.width.unit;
		options.width.onChange(
			parseFloat(options.width.value),
			options.width.unit,
			options.width.value
		);
	}

	let outlookBorderRadiusSection;
	/**
	 * Sets the visibility of the Outlook border radius section.
	 * @param {boolean} show - Whether to show the section.
	 */
	function setVisibilityOutookBorderRadiusSection(show) {
		if (!outlookBorderRadiusSection) return;

		if (show) outlookBorderRadiusSection.classList.remove('eb-hidden');
		else outlookBorderRadiusSection.classList.add('eb-hidden');
	}

	if (options.traitsVisibility.borderRadius) {
		outlookBorderRadiusSection = new ToggleableSection({
			startOpen: options.forceOutlookBorderRadius.active,
			label: options.forceOutlookBorderRadius.label,
			content: setContent(document.createElement('div'), [
				addClassesString(
					setContent(document.createElement('p'), [
						options.forceOutlookBorderRadius.description
					]),
					// TODO: Add classes
					''
				),
				new SectionItem({
					label: options.forceOutlookBorderRadius.outlookWidth.label,
					content: new InputNumber({
						defaultValue:
							options.forceOutlookBorderRadius.outlookWidth
								.value || '0px',
						unit: options.forceOutlookBorderRadius.outlookWidth
							.unit,
						changeableUnit:
							options.forceOutlookBorderRadius.outlookWidth
								.changeableUnit,
						onChange: (value, unit, inputValue) => {
							if (
								typeof options.forceOutlookBorderRadius
									.outlookWidth.onChange === 'function'
							)
								options.forceOutlookBorderRadius.outlookWidth.onChange(
									inputValue
								);
						}
					}).getEl()
				}).getEl()
			]),
			toggleableContent: true,
			onToggle: options.forceOutlookBorderRadius.onActiveChange
		}).getEl();

		setVisibilityOutookBorderRadiusSection(
			obj.component.get('buttonWidth') == 'auto'
		);
	}
	/**
	 * Sets the line height of the button to match its height.
	 */
	function setLineHeightToHeight() {
		obj.component.setAndRerender(
			'buttonLineHeight',
			obj.component.get('buttonHeight') ||
				editor?.getStyleRules('buttons', 'height')
		);
	}

	const lineHeightDropdown = _lineHeightDropdown({
		availableLineHeights: [
			...Object.values(emailBuilder.getLineHeightTypes()),
			...emailBuilder.getAvailableLineHeights()
		],
		resetLabel: options.lineHeight.resetLabel,
		value: options.lineHeight.value,
		onChange: options.lineHeight.onChange,
		onReset: options.lineHeight.onReset
	});

	const buttonHeightInput = new InputNumber({
		defaultValue:
			options.height.value === 'auto' ? '40px' : options.height.value,
		unit: options.height.unit,
		changeableUnit: options.height.changeableUnit,
		onChange: (value, unit, inputValue) => {
			if (typeof options.height.onChange === 'function')
				options.height.onChange(inputValue);
		}
	});

	const buttonWidthInput = new InputNumber({
		defaultValue:
			options.width.value === 'auto' ? '100%' : options.width.value,
		unit: options.width.value === 'auto' ? '%' : options.width.unit,
		changeableUnit: options.width.changeableUnit,
		onChange: options.width.onChange
	});

	const buttonAccordionContent = setContent(document.createElement('div'), [
		options.traitsVisibility.width &&
			new ToggleableSection({
				startOpen: options.width.value == 'auto',
				label: options.width.label,
				content: buttonWidthInput.getEl(),
				toggleableContent: true,
				toggleLabel: 'Auto',
				toggleOnShowsContent: false,
				onToggle: isActive => {
					if (isActive) {
						options.width.onChange('', '', 'auto');
					} else {
						options.width.onChange(
							buttonWidthInput.getValue(),
							buttonWidthInput.getUnit(),
							buttonWidthInput.getInputValue()
						);
					}
					setVisibilityOutookBorderRadiusSection(isActive);
				}
			}).getEl(),
		options.traitsVisibility.align &&
			new SectionItem({
				label: options.align.label,
				content: buttonAlignment(options.align).getEl()
			}).getEl(),
		options.traitsVisibility.height &&
			new ToggleableSection({
				startOpen: options.height.value == 'auto',
				label: 'Height',
				content: buttonHeightInput.getEl(),
				toggleableContent: true,
				toggleLabel: 'Auto',
				toggleOnShowsContent: false,
				onToggle: isActive => {
					if (isActive) {
						options.height.onChange('auto');
					} else {
						options.height.onChange(
							buttonHeightInput.getInputValue()
						);
					}
				}
			}).getEl(),
		options.traitsVisibility.lineHeight &&
			new SectionItem({
				label: options.lineHeight.label,
				content: lineHeightDropdown.getEl()
			}).getEl(),
		section({
			label: 'Text',
			content: [
				options.traitsVisibility.font &&
					new SectionItem({
						label: 'Font',
						content: new Dropdown({
							items: fonts.sort().map(font => {
								return {
									content: webFonts.includes(font)
										? `${capitalize(font)} *`
										: capitalize(font),
									value: font,
									active: options.font.value === font
								};
							}),
							onChange: (dropdown, activeItem) => {
								if (typeof options.font.onChange === 'function')
									options.font.onChange(
										activeItem.getValue()
									);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.weight &&
					new SectionItem({
						label: options.weight.label,
						content: new Dropdown({
							items: cssFontWeights.map(weight => {
								return {
									content:
										typeof weight === 'string'
											? capitalize(weight)
											: weight,
									value: weight,
									active: options.weight.value === weight
								};
							}),
							onChange: (dropdown, activeItem) => {
								if (
									typeof options.weight.onChange ===
									'function'
								)
									options.weight.onChange(
										activeItem.getValue()
									);
							}
						}).getEl()
					}).getEl(),
				// letterSpacingContainer(obj, options.letterSpacingOptions),
				options.traitsVisibility.color &&
					new SectionItem({
						label: options.color.label,
						content:
							customTrait[options.textColorPickerProp].getEl()
					}).getEl()
			]
		}),
		options.traitsVisibility.backgroundColor &&
			section({
				label: 'Background',
				content: new SectionItem({
					label: options.background.label,
					content:
						customTrait[options.backgroundColorPickerProp].getEl()
				}).getEl()
			}),
		section({
			label: 'Border',
			content: [
				options.traitsVisibility.borderWidth &&
					new SectionItem({
						label: options.borderWidth.label,
						content: new InputNumber({
							defaultValue: options.borderWidth.value,
							unit: options.borderWidth.unit,
							onChange: (value, unit, inputValue) => {
								if (
									typeof options.borderWidth.onChange ===
									'function'
								)
									options.borderWidth.onChange(inputValue);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.borderStyle &&
					new SectionItem({
						label: options.borderStyle.label,
						content: new Dropdown({
							items: borderStyles.map(style => {
								return {
									content: capitalize(style),
									value: style,
									active: options.borderStyle.value === style
								};
							}),
							onChange: (dropdown, activeItem) => {
								if (
									typeof options.borderStyle.onChange ===
									'function'
								)
									options.borderStyle.onChange(
										activeItem.getValue()
									);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.borderColor &&
					new SectionItem({
						label: options.borderColor.label,
						content:
							customTrait[options.borderColorPickerProp].getEl()
					}).getEl(),
				options.traitsVisibility.borderRadius &&
					new ToggleableSection({
						toggleableContent: true,
						label: 'Border radius',
						startOpen: options.showBorderRadius,
						onToggle: options.onBorderRadiusToggle,
						content: setContent(document.createElement('div'), [
							buttonBorderRadius(options.borderRadius),
							outlookBorderRadiusSection
						])
					}).getEl()
			]
		})
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: buttonAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		extendedClasses: options.accordion.extendedClasses
	});

	return buttonAccordion;
};
