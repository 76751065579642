import {
	merge,
	cssStyleObjectToString,
	isValidDimension
} from 'datatalks-utils';

import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';

export default (comp, options) => {
	const defaults = {
		debug: false,
		parentWidth: null,
		style: getComputedStyle(comp),
		space: comp.get('spacingBetweenElements'),
		buttonRatio: comp.get('buttonContentRatio') || 0.3
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isMobileOptimized = comp
		.getEditor()
		.getEmailBuilder()
		.getAdvancedOption('isMobileOptimized');

	const debugStrStart = options.debug ? 'data-eb-name="cta-first"' : '';
	const debugStrEnd = options.debug ? 'data-eb-name="cta-last"' : '';

	const marginsWidth = comp.get('hasMargins')
		? parseFloat(comp.get('leftMargin')) +
		  parseFloat(comp.get('rightMargin')) +
		  'px'
		: 0;

	let innerHtml;

	const bgAttr = options.style['background-color']
		? `bgcolor="${options.style['background-color']}"`
		: '';

	/**
	 * Calculates the width of the list item based on the parent width, provided options and number of columns.
	 * @param {string} element - The element to calculate the width for.
	 * @param {number} nElements - The number of elements to calculate the width for.
	 * @return {number} The width of the parent element.
	 */
	function getParentWidth(element, nElements = 2) {
		let ratio =
			element === 'text' ? 1 - options.buttonRatio : options.buttonRatio;
		if (nElements === 1) ratio = 1;
		return `${
			(parseFloat(options.parentWidth) -
				parseFloat(marginsWidth) -
				parseFloat(options.space)) *
			ratio
		}px`;
	}
	const columnPadding =
		isMobileOptimized && isValidDimension(options.space)
			? `padding:${parseFloat(options.space) / 2}px;`
			: '';

	if (comp.get('displayText') && comp.get('displayButton')) {
		innerHtml = `
			<div ${debugStrStart} class="ebr-side-col" style="font-size:0;${cssStyleObjectToString(
			options.style
		)}">
				<!--[if mso]>
					<table role="presentation" width="100%" style="${cssStyleObjectToString(
						options.style
					)}" ${bgAttr}>
						<tr>
							<td style="width:${getParentWidth('text')};" valign="middle">
							<![endif]-->
								<div class="ebr-column ${
									isMobileOptimized
										? `ebr-max-width-cover-${
												100 -
												Math.floor(
													options.buttonRatio * 10
												) *
													10
										  }`
										: ''
								}" style="width:100%;max-width:${getParentWidth(
			'text'
		)};display:inline-block;vertical-align:middle;${columnPadding}">

									<div ${debugStrEnd}>
										${comp.findType('text')[0].toHTML({ parentWidth: getParentWidth('text') })}
									</div>

								</div>
							<!--[if mso]>
							</td>
							${
								isMobileOptimized
									? ''
									: '<td width="${options.space}" style="width:${options.space};"></td>'
							}
							<td style="width:${getParentWidth('button')};" valign="middle">
							<![endif]-->
								<div class="ebr-column ${
									isMobileOptimized
										? `ebr-max-width-cover-${
												Math.floor(
													options.buttonRatio * 10
												) * 10
										  }`
										: ''
								}" style="width:100%;max-width:${getParentWidth(
			'button'
		)};display:inline-block;vertical-align:middle;${columnPadding}margin-left:${
			isMobileOptimized ? '0px' : options.space
		};">

									<div ${debugStrEnd}>
										${comp.findType('button')[0].toHTML({ parentWidth: getParentWidth('button') })}
									</div>

								</div>
							<!--[if mso]>
							</td>
						</tr>
					</table>
				<![endif]-->
			</div>
		`;
	} else if (!comp.get('displayText') && !comp.get('displayButton')) {
		innerHtml = '';
	} else {
		const element = comp.get('displayText') ? 'text' : 'button';
		innerHtml = `
			<table>
				<tr>
					<td ${bgAttr}>
						${comp.findType(element)[0].toHTML({ parentWidth: getParentWidth(element, 1) })}
					</td>
				</tr>
			</table>
		`;
	}

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log(
			'Call to action HTML: ',
			comp.get('hasMargins') ? html : innerHtml
		);

	return comp.get('hasMargins') ? html : innerHtml;
};
