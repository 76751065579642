import { setContent } from 'datatalks-utils';
import { getIcon } from '../../../../../../../icons/_icons';

export default (obj, item, options) => {
	const accordionDeleteBtn = getIcon('delete-bin-line');
	accordionDeleteBtn.addEventListener('click', () => {
		obj.component.deleteItem.call(obj.component, item);
		obj.trait.removeItem(item);
	});
	accordionDeleteBtn.setAttribute('eb-data-action', 'delete');

	return setContent(document.createElement('div'), [
		`
			<i eb-data-action="drag" class="eb-i eb-i-draggable"></i>
			<span>${item.name}</span>
		`,
		accordionDeleteBtn
	]);
};
