import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, opts = {}) => {
			editor.UndoManager.undo();
		}
	};
};
