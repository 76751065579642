import { addSpace as _addSpace } from '../_componentsUtils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';
import updateStyle from '../common/_updateCommonStyle';

export default comp => {
	const innerComponents = [];
	const addSpace = _addSpace.bind(null, comp, false, innerComponents);

	/**
	 * Get the width of an element based on the component's properties.
	 * @param {string} element - The element type ('text' or 'button').
	 * @return {string} The calculated width of the element.
	 */
	function getWidth(element) {
		if (!comp.get('displayText') || !comp.get('displayButton')) {
			return '100%';
		} else {
			const percentage =
				element === 'text'
					? `${(1 - comp.get('buttonContentRatio')) * 100}%`
					: `${comp.get('buttonContentRatio') * 100}%`;

			// return `calc(${percentage} - ${comp.get(
			// 	'spacingBetweenElements'
			// )})`;
			return percentage;
		}
	}

	if (comp.get('displayText')) {
		innerComponents.push({
			type: 'cell',
			style: {
				'text-align': comp.attributes.textAlignment,
				width: getWidth('text')
			},
			components: {
				type: 'text',
				style: validateStyleObj(comp.get('textStyle')),
				text: comp.attributes.textContent
			}
		});
	}

	if (comp.get('displayButton')) {
		if (innerComponents.length) addSpace();

		innerComponents.push({
			type: 'cell',
			style: {
				width: getWidth('button')
			},
			attributes: {
				align: comp.get('buttonAlignment')
			},
			components: {
				type: 'button',
				backgroundColor: comp.get('buttonBackgroundColor'),
				height: comp.get('buttonHeight'),
				width: comp.get('buttonWidth'),
				fontFamily: comp.get('buttonFontFamily'),
				fontSize: comp.get('buttonFontSize'),
				fontStyle: comp.get('buttonFontStyle'),
				color: comp.get('buttonColor'),
				fontWeight: comp.get('buttonFontWeight'),
				borderWidth: comp.get('buttonBorderWidth'),
				borderStyle: comp.get('buttonBorderStyle'),
				borderColor: comp.get('buttonBorderColor'),
				borderRadius: comp.get('buttonBorderRadius'),
				useBorderRadius: comp.get('buttonUseBorderRadius'),
				lineHeight: comp.get('buttonLineHeight'),
				href: comp.get('buttonHref'),
				hrefState: comp.get('buttonHrefState'),
				style: {
					'letter-spacing': comp.get('buttonLetterSpacing')
				},
				text: comp.attributes.buttonText,
				textAlignment: comp.get('buttonTextAlignment'),
				textDecorationLine: comp.get('buttonTextDecorationLine')
			}
		});
	}

	updateStyle(comp);

	return [
		{
			type: 'margins',
			innerComponents: [
				{
					type: 'row',
					components: innerComponents
				}
			],
			hasMargins: comp.get('hasMargins'),
			margins: {
				top: comp.get('topMargin'),
				right: comp.get('rightMargin'),
				bottom: comp.get('bottomMargin'),
				left: comp.get('leftMargin')
			},
			marginsColor: {
				top: comp.get('topMarginColor'),
				right: comp.get('rightMarginColor'),
				bottom: comp.get('bottomMarginColor'),
				left: comp.get('leftMarginColor')
			}
		}
	];
};
