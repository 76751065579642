import icons from './icons/_icons';
import { translationFramework } from '../../_editor';

export default (editor, opts = {}) => {
	const bm = editor.BlockManager;
	const names = translationFramework.getTranslation('components.names');
	const categories = translationFramework.getTranslation(
		'components.categories'
	);
	// Build custom components
	editor.componentBuilder({ name: 'custom-button', components: ['button'] });

	editor.componentBuilder({ name: 'custom-text', components: ['text'] });

	editor.componentBuilder({
		name: 'custom-space',
		components: [
			{
				type: 'space',
				options: {
					spaceTagName: 'table',
					leftMargin: '0px',
					rightMargin: '0px',
					topMargin: '0px',
					bottomMargin: '0px'
				}
			}
		],
		baseTraits: {
			background: true,
			margins: false,
			spacing: false
		}
	});

	editor.componentBuilder({
		name: 'separator',
		components: [
			{
				type: 'space',
				options: {
					spaceTagName: 'table',
					spaceDimension: '1px',
					spaceColor: '#909090'
				}
			}
		],
		baseTraits: {
			background: true,
			margins: true,
			spacing: false
		}
	});

	editor.componentBuilder({
		name: 'custom-image',
		components: ['image-wrapper']
	});

	// Categories
	bm.categories.add({
		id: 'navigation',
		label: categories.navigation || 'Navigation',
		open: true
	});
	bm.categories.add({
		id: 'content',
		label: categories.content || 'Content',
		open: true
	});
	bm.categories.add({
		id: 'base',
		label: categories.base || 'Base',
		open: true
	});

	// Base components
	bm.add('image', {
		label: names.image || 'Image',
		content: {
			type: 'custom-image',
			hasMargins: false
		},
		category: 'base',
		media: icons.image
	});

	bm.add('text', {
		label: names.text || 'Text',
		content: {
			type: 'custom-text',
			traitOptions: {
				styleTabOptions: {
					textAccordionOptions: {
						traitsVisibility: {
							color: false,
							align: false,
							weight: false
						}
					}
				}
			}
		},
		category: 'base',
		media: icons.text
	});

	bm.add('button', {
		label: names.button || 'Button',
		content: {
			type: 'custom-button',
			traitOptions: {
				styleTabOptions: {
					buttonAccordionOptions: {
						traitsVisibility: {
							color: false,
							weight: false
						}
					}
				}
			}
		},
		category: 'base',
		media: icons.button
	});

	bm.add('spaces', {
		label: names.space || 'Space',
		content: {
			type: 'custom-space'
		},
		category: 'base',
		media: icons.space
	});

	bm.add('separator', {
		label: names.separator || 'Separator',
		content: {
			type: 'separator',
			traitOptions: {
				styleTabOptions: {
					colorAccordionOptions: {
						backgroundColor: {
							label: 'Separator Color'
						},
						accordion: {
							title: 'Color'
						}
					},
					spaceAccordionOptions: {
						space: {
							label: 'Separator Thickness'
						},
						accordion: {
							title: 'Size'
						}
					}
				}
			}
		},
		category: 'base',
		media: icons.separator
	});

	// Navigation components
	bm.add('header', {
		label: names.header || 'Header',
		content: {
			type: 'header',
			isTextList: true,
			traitOptions: {
				styleTabOptions: {
					linksAccordionOptions: {
						traitsVisibility: {
							color: false,
							weight: false
						}
					}
				},
				accordionContentOptions: {
					traitsVisibility: {
						url: false,
						target: false
					}
				}
			}
		},
		category: 'navigation',
		media: icons.header
	});

	bm.add('footer', {
		label: names.footer || 'Footer',
		content: {
			type: 'footer',
			traitOptions: {
				styleTabOptions: {
					accordionsVisibility: {
						title: false,
						description: false
					},
					listAccordionOptions: {
						traitsVisibility: {
							horizontal: false
						}
					}
				}
			}
		},
		category: 'navigation',
		media: icons.footer
	});

	// Content components
	bm.add('hero', {
		label: names.hero || 'Hero',
		content: {
			type: 'hero',
			traitOptions: {
				styleTabOptions: {
					accordionsVisibility: {
						text: false
					},
					buttonAccordionOptions: {
						traitsVisibility: {
							color: false,
							weight: false
						}
					}
				}
			}
		},
		category: 'content',
		media: icons.hero
	});

	bm.add('horizontal-list', {
		label: names.horizontalList || 'Horizontal List',
		content: {
			type: 'list',
			isVertical: false,
			itemOptions: {
				imagePlacement: 'top'
			},
			hasMargins: false,
			traitOptions: {
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						hasImageUploader: true,
						hasTitleEditor: true,
						hasDescriptionEditor: true,
						hasButtonEditor: true,
						onImageChange: null,
						buttonProp: 'buttonText',
						imageHasLinkProp: 'imageHasLink',
						imageHrefProp: 'imageHref',
						titleProp: 'title'
					}
				},
				styleTabOptions: {
					buttonAccordionOptions: {
						traitsVisibility: {
							weight: false
						}
					}
				}
			}
		},
		category: 'content',
		media: icons.horizontalList
	});

	bm.add('vertical-list', {
		label: names.verticalList || 'Vertical List',
		content: {
			type: 'list',
			isVertical: true,
			itemOptions: {
				imagePlacement: 'left'
			},
			traitOptions: {
				styleTabOptions: {
					alignmentAccordionOptions: {
						vAlignment: {
							active: false
						}
					},
					buttonAccordionOptions: {
						traitsVisibility: {
							weight: false
						}
					}
				},
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						hasImageUploader: true,
						hasTitleEditor: true,
						hasDescriptionEditor: true,
						hasButtonEditor: true,
						onImageChange: null,
						buttonProp: 'buttonText',
						imageHasLinkProp: 'imageHasLink',
						imageHrefProp: 'imageHref',
						titleProp: 'title'
					}
				}
			}
		},
		category: 'content',
		media: icons.verticalList
	});

	bm.add('call-to-action', {
		label: names.callToAction || 'Call to Action',
		content: {
			type: 'call-to-action',
			traitOptions: {
				styleTabOptions: {
					accordionsVisibility: {
						text: false
					},
					buttonAccordionOptions: {
						traitsVisibility: {
							color: false,
							weight: false
						}
					},
					spacingAccordionOptions: {
						traitsVisibility: {
							ratio: true
						}
					}
				}
			}
		},
		category: 'content',
		media: icons.callToAction
	});

	const imagePropsMap = {
		imageBorderRadius: 'borderRadius',
		imageUseBorderRadius: 'useBorderRadius'
	};

	bm.add('image-horizontal-list', {
		label: names.imagesHorizontal || 'Images Horizontal',
		content: {
			type: 'list',
			isVertical: false,
			hasMargins: false,
			traitOptions: {
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						hasImageUploader: true,
						hasTitleEditor: false,
						hasDescriptionEditor: false,
						hasButtonEditor: false,
						imageHasLinkProp: 'hasLink',
						imageHrefProp: 'href',
						onImageChange: (comp, item, fileInput) => {
							comp.changeItemProp(
								item,
								'imgName',
								fileInput?.getName()
									? fileInput?.getName()
									: 'Untitled image'
							);
						},
						onImageDelete: (comp, item) => {
							comp.changeItemProp(
								item,
								'imgName',
								'Image placeholder'
							);
						},
						titleProp: 'imgName'
					}
				},
				styleTabOptions: {
					accordionsVisibility: {
						button: false
					},
					spacingAccordionOptions: {
						horizontal: {
							visible: false
						},
						vertical: {
							visible: false
						},
						size: {
							visible: false
						}
					},
					imageAccordionOptions: {
						innerTraitsVisibility: {
							alignment: false
						}
					},
					marginsAccordionOptions: {
						traitsVisibility: {
							list: true,
							innerElements: {
								title: false,
								description: false,
								button: false,
								image: false
							}
						}
					}
				}
			},
			itemType: 'image-wrapper',
			itemOptions: {
				imgWidth: '150px',
				imgName: 'Image placeholder'
			},
			itemPropsMap: imagePropsMap
		},
		category: 'content',
		media: icons.imageHorizontalList
	});

	bm.add('image-vertical-list', {
		label: names.imagesVertical || 'Images Vertical',
		content: {
			type: 'list',
			isVertical: true,
			hasMargins: true,
			traitOptions: {
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						hasImageUploader: true,
						hasTitleEditor: false,
						hasDescriptionEditor: false,
						hasButtonEditor: false,
						imageHasLinkProp: 'hasLink',
						imageHrefProp: 'href',
						onImageChange: (comp, item, fileInput) => {
							comp.changeItemProp(
								item,
								'imgName',
								fileInput?.getName()
									? fileInput?.getName()
									: 'Untitled image'
							);
						},
						onImageDelete: (comp, item) => {
							comp.changeItemProp(
								item,
								'imgName',
								'Image placeholder'
							);
						},
						titleProp: 'imgName'
					}
				},
				styleTabOptions: {
					accordionsVisibility: {
						button: false
					},
					alignmentAccordionOptions: {
						vAlignment: {
							active: false
						}
					},
					spacingAccordionOptions: {
						horizontal: {
							visible: false
						},
						vertical: {
							visible: false
						},
						size: {
							visible: false
						}
					},
					imageAccordionOptions: {
						innerTraitsVisibility: {
							alignment: false
						}
					},
					marginsAccordionOptions: {
						traitsVisibility: {
							list: true,
							innerElements: {
								title: false,
								description: false,
								button: false,
								image: false
							}
						}
					}
				}
			},
			itemType: 'image-wrapper',
			itemOptions: {
				imgWidth: '300px',
				imgName: 'Image placeholder'
			},
			itemPropsMap: imagePropsMap
		},
		category: 'content',
		media: icons.imageVerticalList
	});

	const buttonPropsMap = {
		buttonWidth: 'width',
		buttonHeight: 'height',
		buttonFontFamily: 'fontFamily',
		buttonFontSize: 'fontSize',
		buttonFontStyle: 'fontStyle',
		buttonColor: 'color',
		buttonBackgroundColor: 'backgroundColor',
		buttonFontWeight: 'fontWeight',
		buttonBorderWidth: 'borderWidth',
		buttonBorderStyle: 'borderStyle',
		buttonBorderColor: 'borderColor',
		buttonTextAlignment: 'textAlignment',
		buttonBorderRadius: 'borderRadius',
		buttonUseBorderRadius: 'useBorderRadius',
		buttonTextDecorationLine: 'textDecorationLine'
	};

	bm.add('button-horizontal-list', {
		label: names.buttonsHorizontal || 'Buttons Horizontal',
		content: {
			type: 'list',
			isVertical: false,
			hasMargins: false,
			numberOfStartingItems: 3,
			maxItems: 4,
			traitOptions: {
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						hasImageUploader: false,
						hasTitleEditor: false,
						hasDescriptionEditor: false,
						hasButtonEditor: true,
						buttonProp: 'text',
						buttonHrefProp: 'href',
						buttonHrefStateProp: 'hrefState',
						titleProp: 'text'
					}
				},
				styleTabOptions: {
					accordionsVisibility: {
						image: false
					},
					spacingAccordionOptions: {
						horizontal: {
							visible: false
						},
						vertical: {
							visible: false
						}
					},
					marginsAccordionOptions: {
						traitsVisibility: {
							list: true,
							innerElements: {
								title: false,
								description: false,
								button: false,
								image: false
							}
						}
					},
					buttonAccordionOptions: {
						traitsVisibility: {
							align: false,
							weight: false
						}
					}
				}
			},
			itemType: 'button',
			itemOptions: {
				text: 'Button'
			},
			itemPropsMap: buttonPropsMap
		},
		category: 'content',
		media: icons.buttonHorizontalList
	});
};
