/* eslint-disable require-jsdoc */
import { TabItem } from 'datatalks-ui';

export default class Tabs {
	constructor(options = {}) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'tabs',
			extendedClasses: '',
			appendTo: null,
			items: []
		};
		this.options = {
			...defaults,
			...options
		};

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		if (this.options.items) {
			this.items = this.options.items;
			this.convertItems();
		}

		if (this.items) {
			this.init();
		} else {
			throw new Error(
				'You need to pass an array of items in order to create an instance of Tabs (through options.items)'
			);
		}
	}

	init() {
		this.element = document.createElement('div');
		this.element.className = `${this.className}${
			this.options.extendedClasses
				? ' ' + this.options.extendedClasses
				: ''
		}`;

		this.tabs = document.createElement('div');
		this.tabs.className = `${this.className}__tabs`;

		this.panes = document.createElement('div');
		this.panes.className = `${this.className}__content-wrapper`;

		this.createItems();
		this.setOnlyOneActive();

		this.element.append(this.tabs, this.panes);
	}

	getEl() {
		return this.element;
	}

	createItems() {
		const self = this;
		self.items.forEach(item => {
			self.addTab(item);
			self.addPane(item);
			self.addEventListeners(item);
		});
	}

	addTab(tabItem) {
		const tab = document.createElement('div');
		tab.className = `${this.className}__tab-item`;
		tab.append(tabItem.tabContent);
		this.tabs.append(tab);
		tabItem.setTabElement(tab);
	}

	addPane(tabItem) {
		const pane = document.createElement('div');
		pane.className = `${this.className}__tab-pane`;
		pane.append(tabItem.paneContent);
		this.panes.append(pane);
		tabItem.setPaneElement(pane);
	}

	addEventListeners(tabItem) {
		const self = this;
		if (tabItem.showPaneOnTabClick) {
			tabItem.getTab().addEventListener('click', e => {
				self.onItemClick.call(self, tabItem);
			});
		}
	}

	onItemClick(item) {
		const self = this;
		self.activateItem(item);
		if (typeof item.onSelect === 'function') {
			item.onSelect.call(item, item, self);
		}
	}

	activateItem(item) {
		this.deselectAllItems();
		this.selectItem(item);
	}

	selectItem(item) {
		item.setActive(true);
		item.getTab().classList.add(`${this.className}__tab-item--active`);
		item.getPane().classList.add(`${this.className}__tab-pane--active`);
	}

	convertItems() {
		this.items = this.items.map(item => {
			return item instanceof TabItem ? item : new TabItem(item);
		});
	}

	deselectItem(item) {
		item.setActive(false);
		item.getTab().classList.remove(`${this.className}__tab-item--active`);
		item.getPane().classList.remove(`${this.className}__tab-pane--active`);
	}

	deselectAllItems() {
		const self = this;
		self.items.forEach(item => {
			self.deselectItem(item);
		});
	}

	setOnlyOneActive() {
		const activeItems = this.items.filter(item => {
			return item.startActive;
		}).length;
		if (!activeItems.length) {
			this.selectItem(this.items[0]);
		} else if (activeItems.length > 1) {
			let activeItems = 0;
			this.items.forEach(item => {
				item.isActive.call(item) && !activeItems
					? (activeItems++, this.selectItem(item))
					: (item.active = false);
			});
		} else {
			this.selectItem(activeItems[0]);
		}
	}
}
