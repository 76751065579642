/* eslint-disable require-jsdoc */
import { addClassesString, setContent } from 'datatalks-utils';

export default options => {
	const defaults = {
		cssClass: 'section',
		classPrefix: 'eb-',
		extendedClasses: '',
		tagName: 'div',
		label: null,
		content: null
	};

	options = {
		...defaults,
		...options
	};

	const baseEl = addClassesString(
		document.createElement(options.tagName),
		`${options.classPrefix}${options.cssClass}${
			options.extendedClasses ? ` ${options.extendedClasses}` : ''
		}`
	);

	if (!options.content) {
		return baseEl;
	} else {
		const content = setContent(
			addClassesString(
				document.createElement('div'),
				`${options.classPrefix}${options.cssClass}__content`
			),
			options.content
		);
		const cnt = [content];
		if (options.label)
			cnt.unshift(
				setContent(
					addClassesString(
						document.createElement('label'),
						`eb-label ${options.classPrefix}${options.cssClass}__label`
					),
					options.label
				)
			);
		const section = setContent(baseEl, cnt);
		section.addContent = el => setContent(content, el, true);
		section.removeContent = el => {
			return content.removeChild(el);
		};
		section.hasContent = el => {
			return content.contains(el);
		};
		section.toggleContent = el => {
			if (section.hasContent(el)) {
				section.removeContent(el);
			} else {
				section.addContent(el);
			}
		};
		return section;
	}
};
