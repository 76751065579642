import { addClassesString, setContent } from 'datatalks-utils';

/**
 * The options to configure the Dropdown Item for the Rich Text Editor's Dropdown
 * @typedef { Object } DropdownItemOptions
 * @property { boolean= } active - Whether or not the item should start active. @default false
 * @property { DropdownItemCallback= } onSelect - A callback function to call when the item is selected. @default null
 * @property { DropdownItemCallback= } onDeselect - A callback function to call when the item is deselected. @default null
 * @property { DropdownItemCallback= } onClick - A callback function to call when the item is clicked. @default null
 * @property { HTMLString | string= } content - The HTML content to render on the item. @default ''
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string= } cssClass - The base CSS class to add to the item. @default 'dropdown-item'
 * @property { string= } extendedClasses - The CSS classes to extend the item's style. @default ''
 */

/**
 * The callbacks functions called on the onSelect, onDeselect and OnClick events of the Dropdown Item.
 * @callback DropdownItemCallback
 * @param {DropdownItem} item - The Item itself
 * @param {any} value - The value stored by the Item
 */

/**
 * Class representing a Dropdown Item to be used as an option inside the Dropdown.
 * @class
 * @property { DropdownItemOptions }  options - The passed options merged with the default
 * values to configure the Dropdown Item.
 * @property { HTMLElement }  el - The element that wraps the Dropdown Item.
 * @property { boolean }  isActive - Whether or not the item is in the active state.
 * @property { any }  value - A value stored by the dropdown item.
 */
export default class DropdownItem {
	/**
	 * Creates the needed properties to build the Dropdown Item and calls init method.
	 * @constructor
	 * @param { DropdownItemOptions } options - The options to configure the Dropdown Item.
	 */
	constructor(options = {}) {
		const defaults = {
			active: false,
			classPrefix: 'eb-',
			cssClass: 'dropdown-item',
			extendedClasses: '',
			content: '',
			onSelect: null,
			onDeselect: null,
			onClick: null,
			value: null,
			isDefault: false
		};

		this.options = {
			...defaults,
			...options
		};

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.el = null;
		this.isActive = this.options.active;
		this.value = this.options.value;
		this.content = this.options.content || this.options.value;
		this.isDefault = this.options.isDefault;
		this.init();
	}

	/**
	 * Creates the wrapper element, assigns it to the 'el' property and adds the attributes.
	 * Adds a click event listener to call onItemClick method. Sets the inner HTML of the element
	 * provided through the content option
	 * @method
	 */
	init() {
		const item = this;
		item.el = document.createElement('div');
		item.el.classList.add(item.className);
		this.setContent(this.options.content);
		if (item.options.extendedClasses)
			addClassesString(item.el, item.options.extendedClasses);
		item.el.addEventListener('click', e => {
			item.onItemClick.call(item);
		});
	}

	/**
	 * Sets the content of the Item by replacing it's inner HTML with the given content
	 * @param { (HTMLString | string) } content - The new HTML content of the item
	 * @method
	 */
	setContent(content) {
		setContent(this.el, content);
	}

	/**
	 * Sets the default value for the dropdown item.
	 * @param {boolean} isDefault - The value indicating whether the item is default or not.
	 */
	setDefault(isDefault = true) {
		this.isDefault = isDefault;
	}

	/**
	 * Checks if the dropdown item is the default item.
	 * @return {boolean} True if the dropdown item is the default item, false otherwise.
	 */
	isDefaultItem() {
		return this.isDefault;
	}

	/**
	 * Calls onClick function passed through options and calls the select method
	 * @method
	 */
	onItemClick() {
		const item = this;
		if (typeof this.options.onClick === 'function')
			this.options.onClick.call(null, this, this.value);
		item.select.call(item);
	}

	/**
	 * Selects the item by setting 'isActive' property to 'true'.
	 * Calls options.onSelect if it's valid function
	 * @method
	 */
	select() {
		this.isActive = true;
		this.el.classList.add(`${this.className}--active`);
		if (typeof this.options.onSelect === 'function')
			this.options.onSelect.call(null, this, this.value);
	}

	/**
	 * Deselects the item by setting 'isActive' property to 'false'.
	 * Calls options.onDeselect if it's valid function
	 * @method
	 */
	deselect() {
		this.isActive = false;
		this.el.classList.remove(`${this.className}--active`);
		if (typeof this.options.onDeselect === 'function')
			this.options.onDeselect.call(null, this, this.value);
	}

	/**
	 * Gets the wrapper element (Item.el)
	 * @method
	 * @return {HTMLElement} The wrapper element
	 */
	getEl() {
		return this.el;
	}

	/**
	 * Returns whether or not the item is active;
	 * @method
	 * @return {boolean} whether or not the item is active
	 */
	getIsActive() {
		return this.isActive;
	}

	/**
	 * Returns the value stored by the Item;
	 * @method
	 * @return {any} the value stored by the Item
	 */
	getValue() {
		return this.value;
	}
}
