import getEditorHtmlInlined from './assets/common/functions/_getEditorHtmlInlined';
import { merge } from 'datatalks-utils';
import exportCanvasConfiguration from './assets/common/functions/_exportConfig';

export default (emailBuilder, options) => {
	const { editor } = emailBuilder;
	const defaults = {
		onlyGeneralStyles: false,
		storeFullDocument: emailBuilder.getOptions().html.exportFullDocument
	};

	options = merge(defaults, options);

	const html = getEditorHtmlInlined(emailBuilder, {
		exportFullDocument: options.storeFullDocument
	});

	let css = editor.getStyle()?.models;
	const config = exportCanvasConfiguration(emailBuilder);

	if (options.onlyGeneralStyles)
		css = css.filter(rule => rule.get('selectorsAdd'));

	return emailBuilder.getOptions()?.storeStyles
		? { html, css, config }
		: { html, config };
};
