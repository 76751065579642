import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		base: null,
		checkStorageDiff: true
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			if (
				typeof emailBuilder.getOptions().onStorageClear === 'function'
			) {
				emailBuilder
					.getOptions()
					.onStorageClear(
						editor.StorageManager.getStorages(
							editor.StorageManager.getCurrent()
						),
						emailBuilder
					);
				if (commandOptions.checkStorageDiff)
					editor.runCommand('check-diff-storage');
			}
		}
	};
};
