import { LinkedInputs } from 'datatalks-ui';

export default (obj, options) => {
	return new LinkedInputs({
		items: [
			{
				label: 'Top left',
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').topLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'topLeft',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label: 'Top right',
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').topRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'topRight',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label: 'Bottom right',
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').bottomRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'bottomRight',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label: 'Bottom left',
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').bottomLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'bottomLeft',
							inputValue
						);
					},
					unit: 'px'
				}
			}
		]
	}).getEl();
};
