import { validateStyleObj } from '../../common/functions/_utilFunctions';
import {
	noInteractionPreset,
	addSpace as _addSpace
} from '../_componentsUtils';
import updateStyle from '../common/_updateCommonStyle';

export default comp => {
	const innerComponents = [];
	const addSpace = _addSpace.bind(null, comp, true, innerComponents);

	if (comp.get('displayImage')) {
		const image = {
			type: 'image-wrapper',
			imgSrc: comp.attributes.imgSrc,
			useBorderRadius: comp.get('imageUseBorderRadius'),
			borderRadius: comp.get('imageBorderRadius'),
			borderColor: comp.get('imageBorderColor'),
			borderStyle: comp.get('imageBorderStyle'),
			borderWidth: comp.get('imageBorderWidth'),
			imgWidth: comp.attributes.imageWidth,
			href: comp.get('imageHref'),
			hasLink: comp.get('imageHasLink')
		};

		innerComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				attributes: {
					align: comp.attributes.imageAlignment
				},
				components: image
			},
			...noInteractionPreset
		});
	}

	if (comp.get('displayTitle')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				style: {
					'text-align': comp.get('headingAlignment')
				},
				components: {
					type: 'text',
					style: validateStyleObj(comp.get('headingStyle')),
					text: comp.attributes.title
				}
			},
			...noInteractionPreset
		});
	}

	if (comp.get('displayText')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				style: {
					'text-align': comp.get('textAlignment')
				},
				components: {
					type: 'text',
					style: validateStyleObj(comp.get('textStyle')),
					text: comp.attributes.textContent
				}
			},
			...noInteractionPreset
		});
	}

	if (comp.get('displayButton')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				attributes: {
					align: comp.get('buttonAlignment')
				},
				components: {
					type: 'button',
					backgroundColor: comp.get('buttonBackgroundColor'),
					height: comp.get('buttonHeight'),
					width: comp.get('buttonWidth'),
					fontFamily: comp.get('buttonFontFamily'),
					fontSize: comp.get('buttonFontSize'),
					fontStyle: comp.get('buttonFontStyle'),
					color: comp.get('buttonColor'),
					fontWeight: comp.get('buttonFontWeight'),
					borderWidth: comp.get('buttonBorderWidth'),
					borderStyle: comp.get('buttonBorderStyle'),
					borderColor: comp.get('buttonBorderColor'),
					borderRadius: comp.get('buttonBorderRadius'),
					useBorderRadius: comp.get('buttonUseBorderRadius'),
					href: comp.get('buttonHref'),
					hrefState: comp.get('buttonHrefState'),
					style: {
						'letter-spacing': comp.get('buttonLetterSpacing')
					},
					text: comp.attributes.buttonText,
					lineHeight: comp.get('buttonLineHeight'),
					textAlignment: comp.get('buttonTextAlignment'),
					textDecorationLine: comp.get('buttonTextDecorationLine')
				}
			},
			...noInteractionPreset
		});
	}

	updateStyle(comp);

	return [
		{
			type: 'margins',
			innerComponents,
			hasMargins: comp.get('hasMargins'),
			margins: {
				top: comp.get('topMargin'),
				right: comp.get('rightMargin'),
				bottom: comp.get('bottomMargin'),
				left: comp.get('leftMargin')
			},
			marginsColor: {
				top: comp.get('topMarginColor'),
				right: comp.get('rightMarginColor'),
				bottom: comp.get('bottomMarginColor'),
				left: comp.get('leftMarginColor')
			}
		}
	];
};
