import { merge } from 'datatalks-utils';
import openPreviewModal from './_openModal';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			openPreviewModal(editor);
		}
	};
};
