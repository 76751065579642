/* eslint-disable require-jsdoc */
import {
	addClassesString,
	setContent,
	merge,
	showEl,
	hideEl,
	EventEmitter
} from 'datatalks-utils';

export default class SectionItem {
	constructor(options = {}) {
		const defaults = {
			cssClass: 'section-item',
			classPrefix: 'eb-',
			extendedClasses: '',
			tagName: 'div',
			label: null,
			content: null,
			labelExtendedClasses: '',
			contentExtendedClasses: '',
			isHidden: false
		};

		this.options = merge(defaults, options);

		this.emitter = new EventEmitter();
		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.init();
	}

	init() {
		this.createWrapper();
		this.createLabel();
		this.createContent();
		this.draw();
	}

	createWrapper() {
		this.element = document.createElement(this.options.tagName);
		addClassesString(
			this.element,
			`${this.className} ${` ${this.options.extendedClasses}` || ''}`
		);

		if (this.options.isHidden) {
			this.hide();
		}
	}

	createLabel() {
		if (this.options.label) {
			this.label = document.createElement('label');
			addClassesString(
				this.label,
				`eb-label ${this.className}__label ${
					this.options.labelExtendedClasses || ''
				}`
			);
			setContent(this.label, this.options.label);
		}
	}

	createContent() {
		if (this.options.content) {
			this.content = document.createElement('div');
			addClassesString(
				this.content,
				`${this.className}__content ${
					this.options.contentExtendedClasses || ''
				}`
			);
			setContent(this.content, this.options.content);
		}
	}

	draw() {
		setContent(this.element, [this.label, this.content]);
	}

	hide() {
		hideEl(this.element);
	}

	show() {
		showEl(this.element);
	}

	getEl() {
		return this.element;
	}
}
