/* eslint-disable require-jsdoc */
import { generateContent } from './_TreeView.ts';
import style from './editor.css';
import {
	createEditor,
	$getRoot,
	LineBreakNode,
	ParagraphNode as LexicalParagraphNode,
	TextNode,
	$insertNodes,
	$getSelection,
	$isRangeSelection,
	$createNodeSelection,
	$setSelection,
	SELECT_ALL_COMMAND,
	FOCUS_COMMAND,
	BLUR_COMMAND,
	COMMAND_PRIORITY_LOW,
	COMMAND_PRIORITY_CRITICAL,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
	$isTextNode,
	$createTextNode
} from 'lexical';
import { createEmptyHistoryState, registerHistory } from '@lexical/history';
import { ParagraphNode } from './_ParagraphNode';
import { ObjectNode } from './_ObjectNode';
import { $patchStyleText } from '@lexical/selection';
import {
	LinkNode as LexicalLinkNode,
	$isLinkNode,
	TOGGLE_LINK_COMMAND
} from '@lexical/link';
import { LinkNode } from './_LinkNode';
import {
	registerRichText,
	HeadingNode as LexicalHeadingNode
} from '@lexical/rich-text';
import { HeadingNode } from './_HeadingNode';
import { registerPlainText } from '@lexical/plain-text';
import { CodeNode } from '@lexical/code';
import { ListNode, ListItemNode } from '@lexical/list';
import listPlugin from './_listPlugin';
import linkPlugin from './_linkPlugin';
import theme from './_theme';
import { themeRoot } from './_theme';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import onChangePlugin from './_onChangePlugin';
import onSelectionChangePlugin from './_onSelectionChangePlugin';
import { merge } from 'lodash-es';
import TextEditorToolbar from './_TextEditorToolbar';
import {
	addClassesString,
	isValidColor,
	arrayAvg,
	EventEmitter
} from 'datatalks-utils';
import { getTextColorsFromEditorState, getUsedTypesColors } from './_utils';
import { getLuminance } from 'color2k';

const availableNodes = {
	LineBreakNode,
	ParagraphNode,
	TextNode,
	HeadingNode,
	CodeNode,
	ListNode,
	ListItemNode,
	LinkNode,
	ObjectNode
};

export default class TextEditor {
	constructor(options = {}) {
		const dependencyDefaults = {
			useRichText: true
		};

		const dependencyOptions = merge(dependencyDefaults, options);

		this.defaults = {
			namespace: 'EmailBuilderTextEditor',
			theme: theme('eb-editor'),
			onError: console.error,
			useNodes: {
				LineBreakNode: dependencyOptions.useRichText,
				ParagraphNode: dependencyOptions.useRichText,
				TextNode: dependencyOptions.useRichText,
				HeadingNode: dependencyOptions.useRichText,
				CodeNode: dependencyOptions.useRichText,
				ListNode: dependencyOptions.useRichText,
				ListItemNode: dependencyOptions.useRichText,
				LinkNode: dependencyOptions.useRichText,
				ObjectNode: true
			},
			transformHTML: null,
			onChange: null,
			onLoad: null,
			element: null,
			plugins: [
				dependencyOptions.useRichText ? listPlugin : null,
				dependencyOptions.useRichText
					? registerRichText
					: registerPlainText,
				dependencyOptions.useRichText ? linkPlugin : null
			].filter(p => !!p),
			placeholder: 'Type here...',
			initialContent: '',
			initialState: null,
			useIframe: true,
			useToolbar: true,
			toolbarOptions: {
				className: `${this.className}-toolbar`,
				useRichText: dependencyOptions.useRichText
			},
			classPrefix: 'eb-',
			className: 'prosetyper',
			iframeStyle: style,
			iframeStyleOptions: {},
			selectAllTextOnFocus: true,
			changeableHeadingsWeight: true,
			startBold: false,
			editorBackground: '#ffffff',
			autoEditorBackground: true,
			logTreeView: false
		};

		this.defaults.iframeStyleRoot = themeRoot(
			merge(
				this.defaults.iframeStyleOptions,
				options.iframeStyleOptions || {}
			)
		);

		this.defaults.toolbarOptions.className = `${
			options.className || this.defaults.className
		}-toolbar`;

		this.options = merge(this.defaults, dependencyDefaults, options);

		this.nodes = [];
		Object.keys(this.options.useNodes).forEach(nodeName => {
			if (this.options.useNodes[nodeName]) {
				this.nodes.push(availableNodes[nodeName]);
			}
		});

		this.prefixedClassName = `${this.options.classPrefix}${this.options.className}`;
		this.className = this.options.className;
		this.editorBackground = this.options.editorBackground || '#ffffff';
		this.wrapper = this.options.element || document.createElement('div');
		this.wrapper.className = this.prefixedClassName;
		this.editor = null;
		this.config = null;
		this.onChange = this.options.onChange;
		this.parser = new DOMParser();
		this.isShowingPlaceholder =
			!this.options.initialContent &&
			!this.options.initialState &&
			this.options.placeholder;
		this.editorHasFocus = false;
		this.eventEmitter = new EventEmitter();
		this.commandLogs = [];
		this.commandLogsLimit = 20;
		this.registeredObjects = new WeakSet();

		this.init();
	}

	isLinkNode(node) {
		return $isLinkNode(node);
	}

	isRangeSelection(selection) {
		return $isRangeSelection(selection);
	}

	changeFont(font) {
		if (font)
			this.editor.update(() => {
				const selection = $getSelection();
				if (!$isRangeSelection(selection)) {
					return false;
				}
				$patchStyleText(selection, {
					'font-family': font
				});
				return true;
			});
	}

	init() {
		const textEditor = this;

		if (textEditor.nodes.includes(LinkNode)) {
			textEditor.nodes.push({
				replace: LexicalLinkNode,
				with: node => {
					return new LinkNode({
						url: node.__url,
						attributes: {
							rel: node.__rel,
							target: node.__target,
							title: node.__title
						}
					});
				}
			});
		}

		if (textEditor.nodes.includes(ParagraphNode)) {
			textEditor.nodes.push({
				replace: LexicalParagraphNode,
				with: node => {
					return new ParagraphNode(node);
				}
			});
		}

		if (textEditor.nodes.includes(HeadingNode)) {
			textEditor.nodes.push({
				replace: LexicalHeadingNode,
				with: node => {
					return new HeadingNode(node.__tag);
				}
			});
		}

		textEditor.config = {
			namespace: textEditor.options.namespace,
			theme: textEditor.options.theme,
			onError: textEditor.options.onError,
			nodes: textEditor.nodes
		};

		textEditor.editor = createEditor(textEditor.config);

		textEditor.unregisterHistory = registerHistory(
			textEditor.editor,
			createEmptyHistoryState(),
			300
		);

		textEditor.editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			() => {},
			COMMAND_PRIORITY_LOW
		);

		textEditor.editor.registerCommand(
			FOCUS_COMMAND,
			() => {
				textEditor.editorHasFocus = true;
			},
			COMMAND_PRIORITY_LOW
		);

		textEditor.editor.registerCommand(
			BLUR_COMMAND,
			() => {
				textEditor.editorHasFocus = false;
			},
			COMMAND_PRIORITY_LOW
		);

		if (textEditor.options.selectAllTextOnFocus) textEditor.setOnFocus();
		textEditor.setOnChange();
		textEditor.setRootElement();
		textEditor.registerPlugins();

		if (
			textEditor.options.initialContent ||
			textEditor.options.initialState
		) {
			textEditor.setInitialContent();
		} else {
			textEditor.showPlaceholder();
		}

		textEditor.stateColors = textEditor.getStateColors();
		if (textEditor.stateColors?.length) {
			this.options.toolbarOptions.defaultColor = [
				...textEditor.stateColors
			].pop();
		}

		if (textEditor.options.logTreeView) {
			onSelectionChangePlugin(
				textEditor.editor,
				(editorState, editor, payload) => {
					console.log(payload);
					console.log(
						generateContent(
							textEditor.editor,
							textEditor.commandLogs
						)
					);
				}
			);
		}

		if (textEditor.options.useToolbar) {
			textEditor.createToolbar();

			onSelectionChangePlugin(textEditor.editor, () => {
				if (textEditor.options.useToolbar) {
					textEditor.editor.getEditorState().read(() => {
						textEditor.toolbar.eventEmitter.emit(
							'selectionChange',
							$getSelection()
						);
					});
				}
			});

			if (textEditor.options.autoEditorBackground) {
				textEditor.toolbar.on('change:color', color => {
					textEditor.evaluateEditorBackground();
				});
			}
		}

		if (textEditor.options.startBold) {
			textEditor.setAllBold();
		}

		textEditor.registerLexicalCommandLogger();

		textEditor.draw();

		textEditor.editor.registerMutationListener(ObjectNode, mutations => {
			const editor = textEditor.editor;
			editor.getEditorState().read(() => {
				for (const [key, mutation] of mutations) {
					const element = editor.getElementByKey(key);
					if (
						(mutation === 'created' || mutation === 'updated') &&
						element !== null &&
						!textEditor.registeredObjects.has(element)
					) {
						textEditor.registeredObjects.add(element);
						element.addEventListener('click', event => {
							setTimeout(
								editor.update.bind(editor, () => {
									const node = editor
										.getEditorState()
										._nodeMap.get(key);
									if (node) {
										editor.focus();
										const nodeSelection =
											$createNodeSelection();
										nodeSelection.add(node.__key);
										node.activate(element);
										$setSelection(nodeSelection);
									}
								}),
								1
							);
						});
					}
				}
			});
		});
	}

	evaluateEditorBackground() {
		// TODO: change to have configurable colors and threshold
		const stateColors = this.getStateColors();

		const colors = stateColors?.length
			? stateColors
			: getUsedTypesColors(this);

		if (colors?.length) {
			const luminances = colors
				.map(color =>
					isValidColor(color) ? getLuminance(color) : null
				)
				.filter(l => l !== null);
			if (luminances.filter(l => l > 0.8).length) {
				this.setEditorBackground('#3d3d3d');
			} else if (luminances.filter(l => l < 0.2).length) {
				this.setEditorBackground('#ffffff');
			} else if (arrayAvg(luminances) > 0.5) {
				this.setEditorBackground('#3d3d3d');
			} else {
				this.setEditorBackground('#ffffff');
			}
		}
	}

	getStateColors(state = this.editor.getEditorState()) {
		return getTextColorsFromEditorState(this.editor.getEditorState());
	}

	getSelectionColor(selection) {
		let color;
		this.editor.read(() => {
			selection = selection || $getSelection();
			if ($isRangeSelection(selection)) {
				const el = this.editor.getElementByKey(
					selection.anchor.getNode().getKey()
				);
				if (el && getComputedStyle(el)?.color) {
					color = getComputedStyle(el).color;
				}
			}
		});
		return color;
	}

	setAllBold() {
		const textEditor = this;
		textEditor.editor.dispatchCommand(SELECT_ALL_COMMAND);
		textEditor.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
	}

	showPlaceholder() {
		this.isShowingPlaceholder = true;
	}

	setState(state) {
		const textEditor = this;
		if (state.hasOwnProperty('root'))
			state = textEditor.editor.parseEditorState(state);

		textEditor.editor.setEditorState(state);
	}

	setInitialContent() {
		const textEditor = this;
		textEditor.editor.update(() => {
			if (textEditor.options.initialState) {
				if (textEditor.options.initialState.hasOwnProperty('root')) {
					textEditor.options.initialState =
						textEditor.editor.parseEditorState(
							textEditor.options.initialState
						);
				}

				textEditor.setState(textEditor.options.initialState);
			} else {
				textEditor.setHtml(textEditor.options.initialContent);
			}
		});
		textEditor.isShowingPlaceholder = false;
	}

	setHtml(htmlString) {
		this.editor.update(() => {
			const dom = this.parser.parseFromString(htmlString, 'text/html');
			$getRoot().select();
			$getRoot().clear();
			$insertNodes($generateNodesFromDOM(this.editor, dom));
		});
	}

	addHtml(htmlString) {
		this.editor.update(() => {
			const dom = this.parser.parseFromString(htmlString, 'text/html');
			$getRoot().select();
			$insertNodes($generateNodesFromDOM(this.editor, dom));
		});
	}

	getSelectionColor(selection) {
		selection = selection || $getSelection();
		if (
			selection &&
			this.options.useRichText &&
			$isRangeSelection(selection)
		) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.color) {
				return getComputedStyle(el).color;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	findClosestNodeOfType(node, type) {
		let parent = node;
		while (parent != null) {
			if (parent instanceof type) {
				return parent;
			}
			parent = parent.getParent();
		}
		return null;
	}

	editorAddObjectNode(options) {
		const textEditor = this;
		const editor = this.editor;
		let objectNode;
		editor.update(() => {
			const selection = $getSelection();
			const color = textEditor.getSelectionColor(selection);
			// TODO: create a setStyleProperty method in ObjectNode
			if (color) options.style = `color: ${color};`;
			objectNode = new ObjectNode(options);

			if (selection && selection.isCollapsed()) {
				const anchorNode = selection.anchor.getNode();
				if (
					textEditor.isLinkNode(anchorNode) ||
					textEditor.isLinkNode(anchorNode.getParent())
				) {
					const linkNode = textEditor.isLinkNode(anchorNode)
						? anchorNode
						: anchorNode.getParent();

					const anchorOffset = selection.anchor.offset;

					const linkChildren = linkNode.getChildren();
					const newChildren = [];

					let currentOffset = 0;
					let inserted = false;

					linkChildren.forEach(child => {
						if ($isTextNode(child)) {
							const textContent = child.getTextContent();
							const textLength = textContent.length;

							if (
								currentOffset + textLength >= anchorOffset &&
								!inserted
							) {
								const beforeText = textContent.slice(
									0,
									anchorOffset - currentOffset
								);
								const afterText = textContent.slice(
									anchorOffset - currentOffset
								);

								if (beforeText) {
									newChildren.push(
										$createTextNode(beforeText).setFormat(
											child.getFormat()
										)
									);
								}

								newChildren.push(objectNode);

								if (afterText) {
									newChildren.push(
										$createTextNode(afterText).setFormat(
											child.getFormat()
										)
									);
								}

								inserted = true;
							} else {
								newChildren.push(child);
							}

							currentOffset += textLength;
						} else {
							newChildren.push(child);
						}
					});

					const newLinkNode = new LinkNode(
						linkNode.__url,
						linkNode.__attributes
					);
					newChildren.forEach(child => newLinkNode.append(child));

					linkNode.insertBefore(newLinkNode);
					linkNode.remove();
				} else {
					$insertNodes([objectNode]);
				}
			} else {
				const root = $getRoot();
				const latestParagraphNode = root
					.getChildren()
					.reverse()
					.find(node => node instanceof ParagraphNode);

				if (latestParagraphNode) {
					latestParagraphNode.select();
					const color = textEditor.getSelectionColor($getSelection());
					if (color && objectNode.setStyle)
						objectNode.setStyle(`color: ${color};`);
					$insertNodes([objectNode]);
				} else {
					root.select();
					$insertNodes([objectNode]);
				}
			}
		});
		return objectNode;
	}

	clear() {
		this.editor.update(() => {
			$getRoot().select();
			$getRoot().clear();
		});
	}

	registerPlugins() {
		this.options.plugins.forEach(plugin => {
			plugin(this.editor);
		});
	}

	setEditorBackground(color) {
		if (isValidColor(color)) {
			this.editorBackground = color;
			// TODO: change to use CSS variables with default colors
			this.frame.style.backgroundColor = this.editorBackground;
		} else {
			console.warn(
				'Trying to set an invalid background color on editor.',
				this,
				color
			);
		}
	}

	setRootElement() {
		const textEditor = this;
		if (true) {
			// TODO: textEditor.options.useIframe
			textEditor.frame = document.createElement('iframe');
			textEditor.frame.className = `${textEditor.prefixedClassName}-frame`;
			const blob = new Blob(
				[
					`
				<!DOCTYPE html>
				<html>
				<head>
				</head>
				<body>
				</body>
				</html>
			`
				],
				{ type: 'text/html' }
			);
			textEditor.frame.src = URL.createObjectURL(blob);

			textEditor.frame.onload = () => {
				// create iframe style
				const style = document.createElement('style');
				const options = textEditor.getOptions();
				style.innerHTML = `${options.iframeStyle}\n${options.iframeStyleRoot}`;

				// append iframe style to iframe head
				if (textEditor.frame.contentDocument)
					textEditor.frame.contentDocument.head.appendChild(style);

				textEditor.frame.contentDocument.body.addEventListener(
					'click',
					e => {
						window.parent.postMessage('click', '*');
					}
				);

				// create iframe editor
				textEditor.editorEl =
					textEditor.frame.contentDocument.createElement('div');
				textEditor.editorEl.contentEditable = true;
				if (textEditor.options.autoEditorBackground) {
					textEditor.evaluateEditorBackground();
				} else {
					textEditor.setEditorBackground(textEditor.editorBackground);
				}
				textEditor.editorEl.className = `${textEditor.prefixedClassName}-editor`;
				if (textEditor.options.changeableHeadingsWeight)
					textEditor.editorEl.classList.add(
						`${textEditor.prefixedClassName}-editor--changeable-headings-weight`
					);
				textEditor.editor.setRootElement(textEditor.editorEl);

				// append iframe editor to iframe body
				textEditor.frame.contentDocument.body.append(
					textEditor.editorEl
				);

				textEditor.editor.update(() => {
					textEditor.html = $generateHtmlFromNodes(textEditor.editor);
					if (typeof options.onLoad === 'function')
						options.onLoad(
							textEditor,
							textEditor.editor.getEditorState(),
							textEditor.html
						);
					textEditor.eventEmitter.emit(
						'load',
						textEditor,
						textEditor.editor.getEditorState(),
						textEditor.html
					);
				});
			};
		}
	}

	getOptions() {
		return this.options;
	}

	setOptions(obj, override = false) {
		this.options = !override ? merge(this.options, obj) : obj;
		if (obj.hasOwnProperty('iframeStyleOptions')) {
			this.options.iframeStyleRoot = themeRoot(
				this.options.iframeStyleOptions
			);
		}
	}

	draw() {
		if (this.options.useToolbar) {
			this.wrapper.appendChild(this.toolbar.getEl());
			this.wrapper.appendChild(
				addClassesString(
					document.createElement('div'),
					`${this.prefixedClassName}-separator`
				)
			);
		}
		this.wrapper.appendChild(this.frame);
	}

	setOnChange() {
		onChangePlugin(this.editor, {
			onChange: this.handleOnChange.bind(this),
			ignoreSelectionChange: true
		});
	}

	setOnFocus() {
		const textEditor = this;
		textEditor.editor.registerCommand(
			FOCUS_COMMAND,
			() => {
				if (!$getSelection())
					textEditor.editor.dispatchCommand(SELECT_ALL_COMMAND);
			},
			COMMAND_PRIORITY_LOW
		);
	}

	registerLexicalCommandLogger() {
		const textEditor = this;
		const unregisterCommandListeners = new Set();
		let i = 0;
		for (const [command] of textEditor.editor._commands) {
			unregisterCommandListeners.add(
				textEditor.editor.registerCommand(
					command,
					payload => {
						i += 1;
						textEditor.commandLogs.push({
							index: i,
							payload,
							type: command.type ? command.type : 'UNKNOWN'
						});

						if (
							textEditor.commandLogs.length >
							textEditor.commandLogsLimit
						) {
							textEditor.commandLogs.shift();
						}

						return false;
					},
					COMMAND_PRIORITY_CRITICAL
				)
			);
		}

		return () =>
			unregisterCommandListeners.forEach(unregister => unregister());
	}

	handleOnChange(editorState, editor) {
		const textEditor = this;
		if (textEditor.options.logTreeView)
			console.log(generateContent(editor, textEditor.commandLogs));
		editor.update(() => {
			if (textEditor.options.useRichText)
				textEditor.html = $generateHtmlFromNodes(editor);
			if (textEditor.options.transformHTML) {
				const transformed = textEditor.options.transformHTML(
					textEditor.html
				);
				if (typeof transformed === 'string' && transformed.length)
					textEditor.html = transformed;
			}
			if (typeof this.onChange === 'function') {
				this.onChange(
					textEditor.options.useRichText
						? textEditor.html
						: $getRoot().getTextContent(),
					textEditor.options.useRichText ? editorState : null,
					editor
				);
			}
		});
	}

	getHtml() {
		return this.html;
	}

	getTextContent() {
		let textContent = '';
		this.editor.read(() => {
			const root = $getRoot();
			textContent = root.getTextContent();
		});
		return textContent;
	}

	getEditorState() {
		return this.editor.getEditorState();
	}

	createToolbar() {
		this.toolbar = new TextEditorToolbar(
			merge(
				{ editor: this.editor, textEditor: this },
				this.options.toolbarOptions
			)
		);
	}

	addLink(href) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, href);
	}

	getEl() {
		return this.wrapper;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		if (this.toolbar && typeof this.toolbar.destroy === 'function') {
			this.toolbar.destroy();
		}
	}
}
