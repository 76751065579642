import { merge, addClassesString, setContent } from 'datatalks-utils';

import styleTabOptions from '../../common/_styleTabOptions';

import backgroundAccordion from '../../commonAccordions/style/_background';
import marginsAccordion from '../../commonAccordions/style/_margins';
import spacingAccordion from '../../commonAccordions/style/_spacing';
import borderAccordion from '../../commonAccordions/style/_border';

export default (obj, options = {}, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const defaults = {
		extendedClasses: '',
		background: true,
		margins: true,
		spacing: true,
		border: true,
		backgroundAccordionOptions: {
			backgroundColor: {
				label: 'Color',
				value: obj.component.get('backgroundColor'),
				onChange: color =>
					obj.component.setAndRerender('backgroundColor', color),
				getEmptyColor: editor.getStyleRules.bind(
					null,
					'component',
					'background-color'
				),
				emptyColorLabel: "Components' general background color"
			}
		},
		marginsAccordionOptions: {
			hasMargins: {
				label: 'Choose an option',
				value: obj.component.get('hasMargins'),
				onChange: useMargins => {
					obj.component.setAndRerender('hasMargins', useMargins);
				}
			},
			margins: {
				label: 'Margins',
				left: obj.component.get('leftMargin'),
				right: obj.component.get('rightMargin'),
				top: obj.component.get('topMargin'),
				bottom: obj.component.get('bottomMargin'),
				onLeftChange: value => {
					obj.component.setAndRerender('leftMargin', value);
				},
				onRightChange: value => {
					obj.component.setAndRerender('rightMargin', value);
				},
				onTopChange: value => {
					obj.component.setAndRerender('topMargin', value);
				},
				onBottomChange: value => {
					obj.component.setAndRerender('bottomMargin', value);
				},
				changeableUnit: true
			},
			marginsColor: {
				left: obj.component.get('leftMarginColor'),
				right: obj.component.get('rightMarginColor'),
				top: obj.component.get('topMarginColor'),
				bottom: obj.component.get('bottomMarginColor'),
				onLeftChange: color =>
					obj.component.setAndRerender('leftMarginColor', color),
				onRightChange: color =>
					obj.component.setAndRerender('rightMarginColor', color),
				onTopChange: color =>
					obj.component.setAndRerender('topMarginColor', color),
				onBottomChange: color =>
					obj.component.setAndRerender('bottomMarginColor', color)
			}
		},
		spacingAccordionOptions: {
			space: {
				label: 'Spacing between elements',
				value: obj.component.get('spacingBetweenElements'),
				onChange: value => {
					obj.component.setAndRerender(
						'spacingBetweenElements',
						value
					);
				},
				changeableUnit: false
			}
		}
	};

	defaults.borderAccordionOptions = styleTabOptions(obj, 'border');

	options = merge(defaults, options);

	const styleTab = addClassesString(
		setContent(document.createElement('div'), [
			options.background &&
				backgroundAccordion(
					options.backgroundAccordionOptions,
					customTrait
				).getEl(),
			options.margins &&
				marginsAccordion(
					options.marginsAccordionOptions,
					customTrait
				).getEl(),
			options.spacing &&
				spacingAccordion(
					options.spacingAccordionOptions,
					customTrait
				).getEl(),
			options.border &&
				borderAccordion(
					options.borderAccordionOptions,
					customTrait
				).getEl()
		]),
		options.extendedClasses
	);

	return styleTab;
};
