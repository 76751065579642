/**
 * Maps attributes in a Handlebars template based on a mapping function or object.
 *
 * @param {string} template - The Handlebars template string.
 * @param {function|Object} mapObject - A function or an object used to map the attributes.
 *   If a function is provided, it will be called with the attribute name and a boolean indicating
 *   if it is an "each" block. If an object is provided, it should contain key-value pairs where
 *   the key is the original attribute name and the value is the mapped attribute name.
 * @return {string} - The modified template with mapped attributes.
 */
export default function mapAttributes(template, mapObject) {
	if (typeof mapObject === 'function') {
		const eachStack = [];
		const regex = /{{#each\s+(\w+)}}|{{\/each}}|{{(\w+)}}/g;

		template = template.replace(regex, (match, eachKey, key) => {
			if (eachKey) {
				eachStack.push(eachKey);
				return `{{#each ${mapObject(eachKey, true)}}}`;
			} else if (match === '{{/each}}') {
				eachStack.pop();
				return match;
			} else if (key) {
				const insideEach = eachStack.length > 0;
				return `{{${mapObject(key, false, insideEach)}}}`;
			}
			return match;
		});
	} else if (typeof mapObject === 'object') {
		for (const key in mapObject) {
			if (mapObject.hasOwnProperty(key)) {
				const regex = /{{#each\s+(\w+)}}|{{(\w+)}}/g;
				template = template.replace(regex, (match, eachKey, key) => {
					if (eachKey && mapObject.hasOwnProperty(eachKey)) {
						return `{{#each ${mapObject[eachKey]}}}`;
					} else if (key && mapObject.hasOwnProperty(key)) {
						return `{{${mapObject[key]}}}`;
					}
					return match;
				});
			}
		}
	}
	return template;
}
