import { $getRoot, $getSelection } from 'lexical';
import { parseStyleString } from 'datatalks-utils';

/**
 * Retrieves the text colors from the provided editor state.
 *
 * @param {EditorState} editorState - The editor state object.
 * @return {String[] | null} The text colors array or null if no text color is found.
 */
function getTextColorsFromEditorState(editorState) {
	const textColors = [];

	editorState.read(() => {
		const root = $getRoot();
		const selection = $getSelection();
		if (selection || root) {
			const nodes = selection ? selection.getNodes() : getAllNodes(root);

			if (nodes.length) {
				nodes.forEach(node => {
					if (node.getStyle) {
						let style = node.getStyle();
						if (style) {
							if (typeof style === 'string') {
								style = parseStyleString(style);
							}
							if (style.color) {
								textColors.push(style.color);
							}
						}
					}
				});
			}
		}
	});

	return textColors;
}

/**
 * Returns the style options' attribute to look for based on the given node.
 *
 * @param {Object} node - The node to check.
 * @return {string|null} - The style attribute to look for, or null if none found.
 */
function getNodeStyleAttributeToLookFor(node) {
	if (node.getType) {
		if (node.getType() === 'link') return 'link';
		if (node.getType() === 'paragraph') return 'p';
	}

	if (node.getTag) {
		if (['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.getTag())) {
			return node.getTag();
		} else {
			return null;
		}
	}

	return null;
}

/**
 * Retrieves the default colors for the node types that are being used in the provided text editor.
 *
 * @param {TextEditor} textEditor - The text editor object.
 * @return {Array<string>} - An array of the default colors used in the text editor.
 */
function getUsedTypesColors(textEditor) {
	const colors = [];
	textEditor.editor.read(() => {
		const root = $getRoot();
		if (root) {
			const nodes = getAllNodes(root);
			nodes.forEach(node => {
				const styleAttribute = getNodeStyleAttributeToLookFor(node);
				if (
					styleAttribute &&
					textEditor.options.iframeStyleOptions[styleAttribute]?.color
				) {
					colors.push(
						textEditor.options.iframeStyleOptions[styleAttribute]
							.color
					);
				}
			});
		}
	});
	return colors;
}

/**
 * Recursively retrieves all nodes from the root node.
 *
 * @param {LexicalNode} node - The root node.
 * @return {Array<LexicalNode>} An array of all nodes.
 */
function getAllNodes(node) {
	let nodes = [node];
	if (node.getChildren) {
		node.getChildren().forEach(child => {
			nodes = nodes.concat(getAllNodes(child));
		});
	}
	return nodes;
}

export {
	getTextColorsFromEditorState,
	getUsedTypesColors,
	getNodeStyleAttributeToLookFor,
	getAllNodes
};
