/* eslint-disable require-jsdoc */
import { getIcon } from 'datatalks-icons';

export default emailBuilder => {
	const options = emailBuilder.getOptions();

	let extraDropdowns = [];

	if (options.dynamicLinks?.length) {
		extraDropdowns = extraDropdowns.concat(
			options.dynamicLinks.map(dropdown => {
				return {
					type: 'link',
					position: dropdown.dropdownPosition || 'top',
					dropdownOptions: {
						itemIsSelected: evaluateDynamicLink,
						isSelect: true,
						allowMultiSelection: false,
						useControlContentTemplate: true,
						useArrow: false,
						modifierClasses: ['icon'],
						controlContentTemplate: dropdown.icon
							? () =>
									getIcon(dropdown.icon, {
										size: 'lg'
									})
							: null,
						items: dropdown.values.map(item => {
							return {
								selectable: true,
								content: item.label,
								data: {
									href: item.href
								},
								onSelect: ({ toolbar }) => {
									toolbar.editorAddLink(item.href);
								}
							};
						}),
						placeholder: `Set ${dropdown.category}`
					}
				};
			})
		);
	}

	if (options.dynamicValues?.length) {
		extraDropdowns = extraDropdowns.concat(
			options.dynamicValues.map(dropdown => {
				return {
					type: 'text',
					position: dropdown.dropdownPosition || 'top',
					dropdownOptions: {
						isSelect: false,
						allowMultiSelection: false,
						useControlContentTemplate: true,
						useArrow: false,
						modifierClasses: ['icon'],
						controlContentTemplate: dropdown.icon
							? () =>
									getIcon(dropdown.icon, {
										size: 'lg'
									})
							: null,
						items: dropdown.values.map(item => {
							return {
								selectable: false,
								content: item.label,
								data: {
									value: item.value
								},
								onClick: ({ toolbar }) => {
									console.log('item selected');
									toolbar.editorAddText(item.value);
								}
							};
						}),
						placeholder: `Set ${dropdown.category}`
					}
				};
			})
		);
	}

	emailBuilder.addOptions({
		textEditorOptions: {
			toolbarOptions: {
				extraDropdowns
			}
		}
	});

	function evaluateDynamicLink(item, { textEditor, selection }) {
		if (textEditor.isRangeSelection(selection)) {
			const node = selection.anchor.getNode();

			if (
				textEditor.isLinkNode(node.getParent()) ||
				textEditor.isLinkNode(node)
			) {
				if (textEditor.isLinkNode(node.getParent())) {
					return (
						node.getParent().getURL() ===
						(item.options.data?.href.url || item.options.data?.href)
					);
				} else if (textEditor.isLinkNode(node)) {
					return (
						node.getURL() ===
						(item.options.data?.href?.url || item.data.href)
					);
				}
			}
		}
		return false;
	}
};
