import { merge } from 'datatalks-utils';
import getItemsList from '../../Header/components/contentTab/linksAccordion/_items';
import Sortable from 'sortablejs';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		extendedClasses: '',
		componentMethods: {
			addItem: 'addItem',
			changeItemsOrder: 'changeItemsOrder'
		},
		itemsListOptions: {
			className: 'items-list-wrapper',
			button: {
				display: true,
				onClick: (el, ev, buttonClass) => {
					const newItem = obj.component.addItem.call(obj.component);
					// if (newItem) obj.trait.addItem(newItem);
					if (!obj.component.canAddMoreItems()) buttonClass.disable();
				},
				content: `+ ${localize('Add new item')}`,
				appendTo: null,
				isOutline: true,
				extendedClasses: 'eb-w-full eb-mt-4',
				dataAction: 'create',
				isDisabled: !obj.component.canAddMoreItems()
			},
			componentProperties: {
				itemsList: 'items'
			}
		}
	};

	options = merge(defaults, options);

	const items = getItemsList.call(
		null,
		obj,
		options.itemsListOptions,
		customTrait
	);
	if (obj.trait.itemsListElement.childElementCount) {
		new Sortable(obj.trait.itemsListElement, {
			handle: '[eb-data-action="drag"]',
			onSort: evt => {
				obj.component[options.componentMethods.changeItemsOrder].call(
					obj.component,
					evt.oldIndex,
					evt.newIndex
				);
			}
		});
	}

	const contentTab = addClassesString(
		setContent(document.createElement('div'), items),
		options.extendedClasses
	);

	return contentTab;
};
