import linksAccordion from '../../Header/components/contentTab/_linksAccordion';
import { addClassesString, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}) => {
	const defaults = {
		extendedClasses: '',
		skipAccordionTitle: true,
		linksAccordionOptions: {
			itemsListOptions: {
				button: {
					display: true,
					onClick: null,
					content: '+ Add new item',
					appendTo: null,
					isOutline: true,
					extendedClasses: 'eb-w-full eb-mt-4',
					dataAction: 'create'
				},
				componentProperties: {
					itemsList: 'linksList'
				}
			}
		}
	};

	options = merge(defaults, options);

	const contentTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? linksAccordion(obj).getContent()
				: linksAccordion(obj).getEl()
		),
		options.extendedClasses
	);

	return contentTab;
};
