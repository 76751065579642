import { camelCase } from 'lodash-es';
import spaceAccordion from '../../../commonAccordions/style/_spacing';
import colorAccordion from '../../../commonAccordions/style/_background';
import { addClassesString, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		componentName: obj.component.get('name'),
		accordionsVisibility: {
			color: true,
			spacing: true
		}
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		spaceAccordionOptions: {
			space: {
				value: obj.component.get(
					camelCase(`${defaults.componentName} dimension`)
				),
				onChange: value =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} dimension`),
						value
					)
			}
		},
		colorAccordionOptions: {
			backgroundColor: {
				value: obj.component.get(
					camelCase(`${defaults.componentName} color`)
				),
				onChange: color =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} color`),
						color
					)
			},
			accordion: {
				title: 'Inner color'
			},
			colorPickerProp: 'backgroundCpx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	const space = spaceAccordion.call(
		null,
		options.spaceAccordionOptions,
		customTrait
	);
	const color = colorAccordion.call(
		null,
		options.colorAccordionOptions,
		customTrait
	);

	const styleTab = addClassesString(
		setContent(document.createElement('div'), [
			options.accordionsVisibility.spacing
				? options.skipAccordionTitle
					? space.getContent()
					: space.getEl()
				: null,
			options.accordionsVisibility.color
				? options.skipAccordionTitle
					? color.getContent()
					: color.getEl()
				: null
		]),
		options.extendedClasses
	);

	return styleTab;
};
