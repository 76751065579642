import * as commandsBuild from './_exportCommandsBuild';

export default (commandName, emailBuilder) => {
	const { editor } = emailBuilder;
	editor.Commands.add(
		emailBuilder.getCommandId(commandName),
		commandsBuild[commandName](
			editor.getCommandOptions(commandName),
			emailBuilder
		)
	);
};
