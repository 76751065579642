import { Accordion, SectionItem } from 'datatalks-ui';
import { ColorPickerInput } from 'email-builder-ui';
import { merge } from 'datatalks-utils';

export default (obj, options, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const localize = obj.component.localize;
	const defaults = {
		traitsVisibility: {
			primary: true,
			secondary: false
		},
		primary: {
			label:
				localize('traits.styles.properties.background', true) ||
				localize('Color'),
			value: obj.component.get('backgroundColor'),
			onChange: color =>
				obj.component.setAndRerender('backgroundColor', color),
			emptyColor: editor.getStyleRules.bind(
				null,
				'component',
				'background-color'
			),
			emptyColorLabel: localize("Components' general background color")
		},
		secondary: {
			label: 'Secondary color',
			value: null,
			onChange: null,
			emptyColor: null,
			emptyColorLabel: null
		}
	};

	options = merge(defaults, options);

	if (options.traitsVisibility.primary && !customTrait.backgroundCp)
		customTrait.backgroundCp = new ColorPickerInput({
			color: options.primary.value,
			onChange: cpi => {
				if (typeof options.primary.onChange === 'function')
					options.primary.onChange(cpi.getColor());
			},
			emptyColor: options.primary.emptyColor,
			emptyColorLabel: options.primary.emptyColorLabel
		});

	if (options.traitsVisibility.secondary && !customTrait.secondaryCp)
		customTrait.secondaryCp = new ColorPickerInput({
			color: options.secondary.value,
			emptyColor: options.secondary.emptyColor,
			emptyColorLabel: options.secondary.emptyColorLabel,
			onChange: cpi => {
				if (typeof options.secondary.onChange === 'function')
					options.secondary.onChange(cpi.getColor());
			}
		});

	const backgroundAccordionContent = [
		options.traitsVisibility.primary &&
			new SectionItem({
				label: options.primary.label,
				content: customTrait.backgroundCp.getEl()
			}).getEl(),
		options.traitsVisibility.secondary &&
			new SectionItem({
				label: options.secondary.label,
				content: customTrait.secondaryCp.getEl()
			}).getEl()
	];

	const backgroundAccordion = new Accordion({
		title:
			localize('traits.styles.sections.background', true) ||
			localize('Background'),
		content: backgroundAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return backgroundAccordion;
};
