import { merge } from 'datatalks-utils';
import { getUnit, setContent } from 'datatalks-utils';
import { InputNumber, Accordion, SectionItem } from 'datatalks-ui';

export default (options = {}) => {
	const defaults = {
		space: {
			label: 'Space dimension',
			value: null,
			onChange: null,
			changeableUnit: false
		},
		accordion: {
			title: 'Spacing',
			color: 'grey',
			type: 'extend'
		}
	};

	options = merge(defaults, options);

	const buttonAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.space.label,
			content: new InputNumber({
				defaultValue: options.space.value,
				unit: getUnit(options.space.value),
				changeableUnit: options.space.changeableUnit,
				onChange: (value, unit, inputValue) => {
					if (typeof options.space.onChange === 'function')
						options.space.onChange(inputValue);
				}
			}).getEl()
		}).getEl()
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: buttonAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
