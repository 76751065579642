import headerAccordionContentTemplate from './Header/components/contentTab/linksAccordion/_accordionContent';
import headerAccordionTitleTemplate from './Header/components/contentTab/linksAccordion/_itemAccordionTitle';
import footerAccordionContentTemplate from './Footer/components/contentTab/linksAccordion/_accordionContent';
import footerAccordionTitleTemplate from './Footer/components/contentTab/linksAccordion/_itemAccordionTitle';

export default (basedir = './') => [
	{
		dir: basedir + 'base',
		type: 'base'
	},
	{
		dir: basedir + 'atoms/Button',
		type: 'button'
	},
	{
		dir: basedir + 'atoms/Text',
		type: 'text'
	},
	{
		dir: basedir + 'LinksList',
		type: 'links-list',
		options: {
			createInputOptions: {
				hasList: true
			}
		}
	},
	{
		dir: basedir + 'CallToAction',
		type: 'call-to-action'
	},
	{
		dir: basedir + 'Footer',
		type: 'footer',
		options: {
			createInputOptions: {
				hasList: true,
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						accordionContentTemplate:
							footerAccordionContentTemplate,
						accordionTitleTemplate: footerAccordionTitleTemplate,
						titleProp: 'content'
					}
				}
			}
		}
	},
	{
		dir: basedir + 'Header',
		type: 'header',
		options: {
			createInputOptions: {
				hasList: true,
				itemsListMutationOptions: {
					listItemElementTemplateOptions: {
						accordionContentTemplate:
							headerAccordionContentTemplate,
						accordionTitleTemplate: headerAccordionTitleTemplate,
						titleProp: 'content'
					}
				}
			}
		}
	},
	{
		dir: basedir + 'Hero',
		type: 'hero'
	},
	{
		dir: basedir + 'atoms/ImageWrapper',
		type: 'image-wrapper'
	},
	{
		dir: basedir + 'atoms/Space',
		type: 'space'
	},
	{
		dir: basedir + 'List',
		type: 'list',
		options: {
			createInputOptions: {
				hasList: true
			}
		}
	}
];
