import { functionHasBody } from 'datatalks-utils';

export default editor => {
	editor.on('run:open-blocks', (opts, sender) => {
		editor.runCommand('show-main-view-panel');
		editor.Panels.getButton('main-view', 'open-blocks-c').set(
			'active',
			true
		);
		editor.stopCommand('open-tm');
		editor?.Blocks?.blocksView?.el?.parentElement?.classList?.add(
			'blocks-wrapper--active'
		);
	});
	editor.on('stop:open-blocks', (opts, sender) => {
		editor?.Blocks?.blocksView?.el?.parentElement?.classList?.remove(
			'blocks-wrapper--active'
		);
	});
	editor.on('run:open-tm', (opts, sender) => {
		editor.runCommand('show-traits-view-panel');
		editor.stopCommand('show-main-view-panel');
		editor.stopCommand('open-blocks');
		editor.stopCommand('open-general');
		editor?.TraitManager?.view?.el?.parentElement?.classList?.add(
			'traits-wrapper--active'
		);

		if (
			editor.getSelected().getTraits()?.length &&
			typeof editor.getSelected().getTraits()[0].get('setView') ===
				'function'
		) {
			if (
				Object.keys(editor.Commands.getActive()).includes(
					'open-style-traits'
				)
			) {
				editor.getSelected().getTraits()[0].get('setView')('style');
			} else {
				editor.getSelected().getTraits()[0].get('setView')('content');
			}
		}
	});
	editor.on('stop:open-tm', (opts, sender) => {
		editor.stopCommand('show-traits-view-panel');
		editor?.TraitManager?.view?.el?.parentElement?.classList?.remove(
			'traits-wrapper--active'
		);
	});
	editor.on('run:open-tm', () => {
		if (!functionHasBody(editor.TraitManager.view.onRender))
			editor.TraitManager.view.onRender = () => {
				if (
					!editor.getSelected()?.get('isCompound') &&
					editor.getSelected()?.getTraits()?.length < 2
				) {
					editor.TraitManager.getTraitsViewer().el.classList.add(
						`${
							editor.TraitManager.getTraitsViewer().className
						}--single-trait`
					);
				} else {
					editor.TraitManager.getTraitsViewer().el.classList.remove(
						`${
							editor.TraitManager.getTraitsViewer().className
						}--single-trait`
					);
				}
			};
	});
};
