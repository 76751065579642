import { camelCase } from 'lodash-es';
import textAccordion from '../../../commonAccordions/content/_text';
import { addClassesString, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		componentName: obj.component.get('name')
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		textAccordionOptions: {
			textToggler: {
				showToggler: true,
				startActive: obj.component.get(
					camelCase(`display ${defaults.componentName}`)
				),
				clickTogglesAccordion: true,
				onChange: isActive => {
					obj.component.setAndRerender(
						camelCase(`display ${defaults.componentName}`),
						isActive
					);
				}
			},
			initialText: obj.component.get(
				camelCase(`${defaults.componentName} text`)
			),
			initialState:
				obj.component.get(
					camelCase(`${defaults.componentName} editorState`)
				) || null,
			onUpdate: (html, editorState) => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} text`),
					html
				);
				obj.component.set(
					camelCase(`${defaults.componentName} editorState`),
					editorState
				);
			},
			textEditorProp: 'textEditorx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const accordion = textAccordion.call(
		null,
		options.textAccordionOptions,
		obj,
		customTrait
	);

	const contentTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return contentTab;
};
