import { merge } from 'datatalks-utils';

export default (emailBuilder, options) => {
	const defaults = {
		ignore: {
			src: [],
			name: [],
			funcs: [emailBuilder.isImagePlaceholder.bind(emailBuilder)]
		}
	};

	options = merge(defaults, options);

	return [
		...emailBuilder.editor
			.getWrapper()
			.findType('image-wrapper')
			.map(comp => ({
				src: comp.get('imgSrc'),
				name: comp.get('imgName')
			})),
		...emailBuilder.editor
			.getWrapper()
			.findType('image')
			.map(comp => ({
				src: comp.get('src'),
				name: comp.get('name')
			}))
	].filter(
		img =>
			!options.ignore.src.includes(img.src) &&
			!options.ignore.name.includes(img.name) &&
			!options.ignore.funcs.some(func => func(img))
	);
};
