/* eslint-disable require-jsdoc */
export default function OnSelectionChangePlugin(editor, onSelectionChange) {
	if (onSelectionChange) {
		return editor.registerUpdateListener(({ editorState }) => {
			onSelectionChange(editorState, editor);
		});
	}

	return null;
}
