import { merge } from 'datatalks-utils';
import createInputFunc from './_createInput';

export default (type, options) => {
	const defaults = {
		createInputOptions: {
			cssClass: 'gjs-trait',
			extendedClasses: '',
			hasList: false
		}
	};

	options = merge(defaults, options);

	const createInput = createInputFunc.bind(
		null,
		options.createInputOptions,
		type
	);

	return {
		noLabel: true,
		createInput
	};
};
