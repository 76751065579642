import { translationFramework } from './_editor';
import {
	TextEditor as _TextEditor,
	Dropdown as _Dropdown,
	ColorPickerInput as _ColorPickerInput
} from 'datatalks-ui';

import { merge } from 'datatalks-utils';

/**
 * Class representing a TextEditor.
 * @extends _TextEditor
 */
export class TextEditor extends _TextEditor {
	/**
	 * Create a TextEditor.
	 * @param {Object} options - The options for the TextEditor.
	 * @param {Object} options.toolbarOptions - The toolbar options.
	 * @param {Object} options.toolbarOptions.dropdownsOptions - The dropdown options.
	 * @param {string} options.toolbarOptions.dropdownsOptions.placeholder - The placeholder text for dropdowns.
	 */
	constructor(options) {
		const defaults = {
			toolbarOptions: {
				lineHeightPlaceholder:
					translationFramework.getTranslation('Line height'),
				fontsDropdownPlaceholder:
					translationFramework.getTranslation('Set font'),
				fontSizesDropdownPlaceholder:
					translationFramework.getTranslation('Set font size'),
				dropdownsOptions: {
					placeholder:
						translationFramework.getTranslation('Select an item')
				}
			}
		};
		super(merge(defaults, options));
	}
}

/**
 * Class representing a Dropdown.
 * @extends _Dropdown
 */
export class Dropdown extends _Dropdown {
	/**
	 * Create a Dropdown.
	 * @param {Object} options - The options for the dropdown.
	 */
	constructor(options) {
		const defaults = {
			placeholder: translationFramework.getTranslation('Select an item')
		};
		super(merge(defaults, options));
	}
}

/**
 * Class representing a ColorPickerInput.
 * @extends _ColorPickerInput
 */
export class ColorPickerInput extends _ColorPickerInput {
	/**
	 * Create a ColorPickerInput.
	 * @param {Object} options - The options for the ColorPickerInput.
	 */
	constructor(options) {
		const none =
			translationFramework.getTranslation('misc.none', true) ||
			translationFramework.getTranslation('none');
		const defaults = {
			emptyColorLabel: none,
			transparentColorLabel: none,
			placeholder: translationFramework.getTranslation('Select a color')
		};
		super(merge(defaults, options));
	}
}
