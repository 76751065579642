/* eslint-disable require-jsdoc */
import { merge, isElement } from 'datatalks-utils';
import { Popup } from 'datatalks-ui';
import { autoUpdate, computePosition } from '@floating-ui/dom';

export default class TextEditorPopup {
	constructor(toolbar, options = {}) {
		const defaults = {
			cssClass: `${toolbar.className}__popup`,
			extendedClasses: '',
			title: '',
			content: null,
			trigger: null,
			donNotHidePopupOnElementsClick: []
		};

		this.options = merge(defaults, options);
		this.toolbar = toolbar;
		this.trigger = this.options.trigger;

		this.init();
	}

	init() {
		this.createPopup();
		this.placePopup();
		this.draw();
		this.addListeners();
	}

	createPopup() {
		this.popup = new Popup({
			title: this.options.title,
			extendedClasses: `${this.options.cssClass}${
				this.options.extendedClasses
					? ` ${this.options.extendedClasses}`
					: ''
			}`,
			content: this.options.content
		});
		this.popup.on('open', () => {
			this.toolbar.activePopups.push(this);
		});
		this.popup.on('close', () => {
			this.toolbar.activePopups
				.filter(activePopup => activePopup === this)
				.forEach(element => {
					this.toolbar.activePopups.splice(
						this.toolbar.activePopups.indexOf(element),
						1
					);
				});
		});
		this.popupEl = this.popup.getEl();
	}

	placePopup() {
		const textEditorEl =
			this.toolbar.textEditor.wrapper || this.toolbar.element.parentNode;
		if (textEditorEl) {
			autoUpdate(textEditorEl, this.popupEl, () => {
				computePosition(textEditorEl, this.popupEl, {
					placement: 'bottom'
				}).then(({ x, y }) => {
					Object.assign(this.popupEl.style, {
						left: `${x}px`,
						top: `${y}px`
					});
				});
			});
		}
	}

	draw() {
		document.body.appendChild(this.popupEl);
	}

	addListeners() {
		const editorPopup = this;
		document.body.addEventListener('click', e => {
			if (
				!(
					e.target == this.popupEl ||
					editorPopup.popupEl.contains(e.target) ||
					editorPopup.options.donNotHidePopupOnElementsClick.includes(
						e.target
					) ||
					(isElement(editorPopup.trigger) &&
						(e.target == editorPopup.trigger ||
							editorPopup.trigger.contains(e.target)))
				)
			)
				editorPopup.popup.close();
		});
		// window.addEventListener('message', ev => {
		// 	if (ev.data === 'click') {
		// 		editorPopup.popup.close();
		// 	}
		// });
		if (isElement(editorPopup.trigger))
			editorPopup.trigger.addEventListener(
				'click',
				editorPopup.popup.open.bind(editorPopup.popup)
			);
	}

	setTrigger(element) {
		const editorPopup = this;
		if (isElement(element)) {
			editorPopup.trigger.removeEventListener(
				'click',
				editorPopup.popup.open.bind(editorPopup.popup)
			);
			editorPopup.trigger = element;
			editorPopup.trigger.addEventListener(
				'click',
				editorPopup.popup.open.bind(editorPopup.popup)
			);
		}
	}
}
