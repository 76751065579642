import importConfig from './_importConfig';
import importTemplate from './_importTemplate';

export default editor => {
	const frameDoc = editor.getWrapper().getEl().ownerDocument;
	// const canvas = editor.Canvas.getElement();
	if (frameDoc) {
		frameDoc.addEventListener('dragover', e => {
			e.preventDefault();
		});

		frameDoc.addEventListener('drop', e => {
			const file = e.dataTransfer.files[0];
			if (file?.type === 'application/json') {
				e.preventDefault();

				const reader = new FileReader();

				// Read the file content as text
				reader.readAsText(file);

				reader.onload = () => {
					const obj = JSON.parse(reader.result);
					if (obj.hasOwnProperty('config')) {
						importTemplate(editor, obj);
					} else {
						importConfig(editor, obj);
					}
				};

				reader.onerror = error => {
					console.error('Error reading the file:', error);
				};
			}
		});
	}
};
