import { debounce } from 'lodash-es';
import { merge } from 'datatalks-utils';

export default (componentOptions = {}, editor) => {
	const debouncedRender = debounce(comp => {
		comp.components(comp.componentRender(comp));
	}, editor.getEmailBuilder().getOptions().renderingDebounceTime);

	return {
		getEditor() {
			return editor;
		},

		setAndRerender(prop, value) {
			// TODO: create a class `ComponentEvent` to structure and standardize its methods and props
			const ev = {
				stopPropagation: false
			};
			const comp = this;
			comp.set(prop, value);
			comp.trigger(`change:attributes:${prop}`, { ev });
			if (ev.preventDefault) return;
			debouncedRender(comp);
		},

		findTypes(types) {
			const comp = this;
			let children = [];
			types.forEach(type => {
				children = children.concat(comp.findType(type));
			});
			return children;
		},

		rootComponentInitialized(options) {
			const comp = this;
			const defaults = {
				runInitMethod: false
			};

			options = merge(defaults, options);

			if (options.runInitMethod) {
				comp.init();
			}

			if (typeof comp.onRootComponentInitialized === 'function') {
				comp.onRootComponentInitialized();
			}
		},

		localize(key, nullable = false) {
			return editor
				.getEmailBuilder()
				.translationFramework.getTranslation(key, nullable);
		}
	};
};
