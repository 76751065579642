import { RadioButtonGroup } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import { toBoolean } from 'datatalks-utils';
import { merge } from 'datatalks-utils';

export default (options = {}) => {
	const defaults = {
		value: null,
		onChange: null,
		icons: {
			vertical: getIcon('layout-bottom-fill', { size: 'xl' }),
			horizontal: getIcon('layout-right-fill', { size: 'xl' })
		},
		labels: {
			vertical: 'Vertical space',
			horizontal: 'Horizontal space'
		}
	};

	options = merge(defaults, options);

	const radioButtonGroupOptions = {
		onChange: (allSelected, changedItem, selectedItems, noneSelected) => {
			if (typeof options.onChange === 'function')
				options.onChange(toBoolean(changedItem.getValue()));
		},
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				label: options.labels.vertical,
				value: 'true',
				selected: !!options.value,
				icon: options.icons.vertical
			},
			{
				label: options.labels.horizontal,
				value: 'false',
				selected: !options.value,
				icon: options.icons.horizontal
			}
		]
	};

	const marginsButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return marginsButtonGroup;
};
