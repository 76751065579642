import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, opts = {}) => {
			const em = editor.getModel();
			const models = [...editor.getSelectedAll()]
				.map(
					compModel =>
						compModel.delegate?.copy?.(merge({}, compModel)) ||
						compModel
				)
				.filter(Boolean);
			models.length && em.set('clipboard', models);
		}
	};
};
