import { merge } from 'datatalks-utils';
import { kebabCase } from 'lodash-es';

export default emailBuilder => {
	if (
		emailBuilder.styles.width == emailBuilder.defaultStyles.width &&
		emailBuilder.editor.getStyleRules('wrapper', 'width')
	)
		emailBuilder.setStyleProp(
			'width',
			emailBuilder.editor.getStyleRules('wrapper', 'width')
		);

	Object.keys(emailBuilder.getStyleProp('fontColors')).forEach(el => {
		if (
			emailBuilder.styles.fontColors[el] ==
				emailBuilder.defaultStyles.fontColors[el] &&
			emailBuilder.editor.getStyleRules(el, 'color')
		)
			emailBuilder.setStyleProp('fontColors', {
				...emailBuilder.getStyleProp('fontColors'),
				[el]: emailBuilder.editor.getStyleRules(el, 'color')
			});
	});

	Object.keys(emailBuilder.getStyleProp('fontSizes')).forEach(el => {
		if (
			emailBuilder.styles.fontSizes[el] ==
				emailBuilder.defaultStyles.fontSizes[el] &&
			emailBuilder.editor.getStyleRules(el, 'font-size')
		)
			emailBuilder.setStyleProp('fontSizes', {
				...emailBuilder.getStyleProp('fontSizes'),
				[el]: emailBuilder.editor.getStyleRules(el, 'font-size')
			});
	});

	Object.keys(emailBuilder.getStyleProp('lineHeights')).forEach(el => {
		if (
			emailBuilder.styles.lineHeights[el] ==
				emailBuilder.defaultStyles.lineHeights[el] &&
			emailBuilder.editor.getStyleRules(el, 'line-height')
		)
			emailBuilder.setStyleProp('lineHeights', {
				...emailBuilder.getStyleProp('lineHeights'),
				[el]: emailBuilder.editor.getStyleRules(el, 'line-height')
			});
	});

	Object.keys(emailBuilder.getStyleProp('fonts')).forEach(el => {
		if (
			emailBuilder.styles.fonts[el] ==
				emailBuilder.defaultStyles.fonts[el] &&
			emailBuilder.editor.getStyleRules(el, 'font-family')
		)
			emailBuilder.setStyleProp(
				'fonts',
				merge(emailBuilder.getStyleProp('fonts'), {
					[el]: emailBuilder.getFontsFromCssValue(
						emailBuilder.editor.getStyleRules(el, 'font-family')
					)
				})
			);
	});

	if (
		emailBuilder.styles.links.underline ==
			emailBuilder.defaultStyles.links.underline &&
		emailBuilder.editor.getStyleRules('link', 'text-decoration')
	)
		emailBuilder.setStyleProp('links', {
			...emailBuilder.getStyleProp('links'),
			underline:
				emailBuilder.editor.getStyleRules('link', 'text-decoration') ===
				'underline'
					? true
					: false
		});

	if (
		emailBuilder.styles.links.underlineColor ==
			emailBuilder.defaultStyles.links.underlineColor &&
		emailBuilder.editor.getStyleRules('link', 'text-decoration-color')
	)
		emailBuilder.setStyleProp('links', {
			...emailBuilder.getStyleProp('links'),
			underlineColor: emailBuilder.editor.getStyleRules(
				'link',
				'text-decoration-color'
			)
		});

	if (
		emailBuilder.styles.buttons.height ==
			emailBuilder.defaultStyles.buttons.height &&
		emailBuilder.editor.getStyleRules('button', 'height')
	)
		emailBuilder.setStyleProp('buttons', {
			...emailBuilder.getStyleProp('buttons'),
			height: emailBuilder.editor.getStyleRules('button', 'height')
		});

	if (
		emailBuilder.styles.buttons.lineHeight ==
			emailBuilder.defaultStyles.buttons.lineHeight &&
		emailBuilder.editor.getStyleRules('button', 'line-height')
	)
		emailBuilder.setStyleProp('buttons', {
			...emailBuilder.getStyleProp('buttons'),
			lineHeight: emailBuilder.editor.getStyleRules(
				'button',
				'line-height'
			)
		});

	if (
		emailBuilder.styles.buttons.backgroundColor ==
			emailBuilder.defaultStyles.buttons.backgroundColor &&
		emailBuilder.editor.getStyleRules('button', 'background-color')
	)
		emailBuilder.setStyleProp('buttons', {
			...emailBuilder.getStyleProp('buttons'),
			backgroundColor: emailBuilder.editor.getStyleRules(
				'button',
				'background-color'
			)
		});

	if (
		emailBuilder.styles.buttons.fontFamily ==
			emailBuilder.defaultStyles.buttons.fontFamily &&
		emailBuilder.editor.getStyleRules('button', 'font-family')
	)
		emailBuilder.setStyleProp('buttons', {
			...emailBuilder.getStyleProp('buttons'),
			fontFamily: emailBuilder.getFontsFromCssValue(
				emailBuilder.editor.getStyleRules('button', 'font-family')
			).font
		});

	if (
		emailBuilder.styles.buttons.color ==
			emailBuilder.defaultStyles.buttons.color &&
		emailBuilder.editor.getStyleRules('button', 'color')
	)
		emailBuilder.setStyleProp('buttons', {
			...emailBuilder.getStyleProp('buttons'),
			color: emailBuilder.editor.getStyleRules('button', 'color')
		});

	['top', 'bottom', 'left', 'right'].forEach(side => {
		if (
			emailBuilder.styles.buttons.paddings[side] ==
				emailBuilder.defaultStyles.buttons.paddings[side] &&
			emailBuilder.editor.getStyleRules('button', `padding-${side}`)
		)
			emailBuilder.setStyleProp(
				'buttons',
				merge(emailBuilder.getStyleProp('buttons'), {
					paddings: {
						[side]: emailBuilder.editor.getStyleRules(
							'button',
							`padding-${side}`
						)
					}
				})
			);
	});

	['topLeft', 'topRight', 'bottomRight', 'bottomLeft'].forEach(corner => {
		if (
			emailBuilder.styles.buttons.borderRadius[corner] ==
				emailBuilder.defaultStyles.buttons.borderRadius[corner] &&
			emailBuilder.editor.getStyleRules(
				'button',
				`border-${kebabCase(corner)}-radius`
			)
		)
			emailBuilder.setStyleProp(
				'buttons',
				merge(emailBuilder.getStyleProp('buttons'), {
					borderRadius: {
						[corner]: emailBuilder.editor.getStyleRules(
							'button',
							`border-${kebabCase(corner)}-radius`
						)
					}
				})
			);
	});
};
