import getEditorHtml from '../../common/functions/_getEditorHtmlInlined';
import { Modal, Tabs, Alert } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';

const devices = {
	mobile: {
		name: 'mobile',
		width: '375px',
		iconName: 'smartphone-line',
		alertTextContent:
			'To optimize mobile view, go to GENERAL SETTINGS --> RENDERING OPTIONS --> Turn on “Mobile Optimization”'
	},
	desktop: {
		name: 'desktop',
		width: '100%',
		iconName: 'computer-line',
		alertTextContent:
			'Mobile optimiazation is turned-on, which may affect how some components are displayed on desktop. To prioritize desktop settings go to GENERAL SETTINGS --> RENDERING OPTIONS --> Turn off “Mobile Optimization”'
	}
};

export default editor => {
	const container = document.createElement('div');
	container.style.display = 'flex';
	container.style.flexDirection = 'column';
	container.style.width = '100%';
	container.style.maxWidth = '900px';

	const header = document.createElement('div');
	header.style.display = 'flex';
	header.style.justifyContent = 'space-between';
	header.style.alignItems = 'center';
	header.style.padding = '10px 20px';

	const headerLeft = document.createElement('div');

	const title = document.createElement('h3');
	title.textContent = 'Preview';
	title.style.fontSize = '1.3rem';

	const subtitle = document.createElement('p');
	subtitle.textContent =
		'Final display may vary across different clients like Gmail, Outlook, as they use different rendering engines.';
	subtitle.style.fontSize = '1.1rem';
	subtitle.style.color = '#7E8299';

	headerLeft.appendChild(title);
	headerLeft.appendChild(subtitle);

	const closeButton = document.createElement('button');
	closeButton.appendChild(getIcon('close-line', { size: 'lg' }));
	closeButton.className = 'eb-pn-btn';

	header.appendChild(headerLeft);
	header.appendChild(closeButton);

	const alert = new Alert({
		icon: getIcon('information-line', { size: 'lg' }),
		description: devices.mobile.alertTextContent,
		type: 'info',
		isHidden: editor
			.getEmailBuilder()
			.getAdvancedOption('isMobileOptimized')
	});

	alert.getEl().style.margin = '10px 20px';

	const body = document.createElement('div');
	body.style.borderTop = '1px solid #e0e0e0';

	const iframeWrapper = document.createElement('div');
	iframeWrapper.style.overflow = 'auto';
	iframeWrapper.style.height = '600px';
	iframeWrapper.style.width = '100%';
	iframeWrapper.style.display = 'flex';
	iframeWrapper.style.justifyContent = 'center';
	iframeWrapper.style.backgroundColor = '#73778E';

	const iframe = document.createElement('iframe');
	iframe.style.border = 'none';
	iframe.style.height = '100%';
	iframe.srcdoc = getEditorHtml(editor.getEmailBuilder());
	iframeWrapper.appendChild(iframe);

	onDeviceSelect(devices.mobile, iframeWrapper, iframe);

	const tabs = new Tabs({
		items: Object.values(devices).map(device => ({
			tabContent: getTabContent(device),
			paneContent: document.createElement('div'),
			startActive: device.name === devices.mobile.name,
			onSelect: () => {
				setAlert(alert, device, editor);
				onDeviceSelect(device, iframeWrapper, iframe);
			}
		}))
	});

	body.appendChild(tabs.getEl());
	body.appendChild(iframeWrapper);

	const footer = document.createElement('div');
	footer.style.display = 'flex';
	footer.style.justifyContent = 'end';
	footer.style.padding = '10px 20px';
	footer.style.borderTop = '1px solid #e0e0e0';

	const buttonRefresh = document.createElement('button');
	buttonRefresh.textContent = 'Refresh';
	buttonRefresh.className = 'eb-btn eb-btn--primary';

	buttonRefresh.addEventListener('click', () => {
		iframe.srcdoc = getEditorHtml(editor.getEmailBuilder());
	});

	footer.appendChild(buttonRefresh);

	container.appendChild(header);
	container.appendChild(alert.getEl());
	container.appendChild(body);
	container.appendChild(footer);

	const modal = new Modal({
		content: container
	});

	closeButton.addEventListener('click', () => {
		modal.close();
	});

	modal.open();
};

const onDeviceSelect = (device, iframeWrapper, iframe) => {
	if (device.name !== devices.desktop.name) {
		iframeWrapper.style.padding = '20px 0';
		iframe.style.borderRadius = '32px';
		iframe.style.border = '7px solid #B5B4C3';
		iframe.style.width = device.width;
	} else {
		iframeWrapper.style.padding = '0';
		iframe.style.borderRadius = '0';
		iframe.style.border = 'none';
		iframe.style.width = device.width;
	}
};

const setAlert = (alert, device, editor) => {
	if (
		(device.name === devices.mobile.name &&
			!editor.getEmailBuilder().getAdvancedOption('isMobileOptimized')) ||
		(device.name === devices.desktop.name &&
			editor.getEmailBuilder().getAdvancedOption('isMobileOptimized'))
	) {
		alert.show();
		alert.setDescription(device.alertTextContent);
	} else {
		alert.hide();
	}
};

const getTabContent = device => {
	const tabWrapper = document.createElement('div');
	tabWrapper.style.display = 'flex';
	tabWrapper.style.gap = '10px';
	tabWrapper.style.alignItems = 'center';

	const icon = getIcon(device.iconName, { size: 'lg' });

	const title = document.createElement('span');
	title.textContent = device.name.toUpperCase();

	tabWrapper.appendChild(icon);
	tabWrapper.appendChild(title);

	return tabWrapper;
};
