/**
 * FileReaderWrapper
 * @namespace FileReaderWrapper
 */

/**
 * The result of the file reading.
 * @typedef { Object } FileReaderWrapperResult
 * @property { boolean } success - Whether or not the data is successfully read.
 * @property { string } errorMessage - The reason for the error to be thrown.
 * @property { ProgressEvent } e - The {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader/progress_event|progress event} that is fired periodically as the FileReader reads data.
 * @memberof FileReaderWrapper
 */

/**
 * Callback to handle a FileReader event.
 * @callback FileReaderEventCallback
 * @param {FileReaderWrapperResult} result - The result object of the file reading.
 * @memberof FileReaderWrapper
 */

/**
 * Callback to handle a FileReader event.
 * @callback FileReaderWrapperCallback
 * @param {FileReader} fileReader - The {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader|FileReader} object itself.
 * @memberof FileReaderWrapper
 */

/**
 * The object containing the callbacks for the file reader events
 * @typedef { Object } FileReaderEventCallbacks
 * @property { FileReaderEventCallback } onload - Fired when a read has completed successfully.
 * @property { FileReaderEventCallback } onerror - Fired when the read failed due to an error.
 * @property { FileReaderEventCallback } onabort - Fired when a read has been aborted.
 * @property { FileReaderWrapperCallback } dataRead - Fired when the data has been read from the blob, but no result is given.
 * @memberof FileReaderWrapper
 */

/**
 * The options to pass in the file reader function
 * @typedef { Object } FileReaderWrapperOptions
 * @property { string } readMethod - The method to use to read data from the blob.
 * @property { FileReaderEventCallbacks } callbacks - The object containing the callbacks for the file reader events.
 * @memberof FileReaderWrapper
 */

/**
 * Creates a file reader and calls callback functions passed through options.
 * @param {Blob} file - The {@link https://developer.mozilla.org/en-US/docs/Web/API/Blob|Blob} to read data from.
 * @param {FileReaderWrapperOptions} options - The options object.
 * @memberof FileReaderWrapper
 */

export default (file, options) => {
	let result = {
		success: true,
		errorMessage: '',
		e: null
	};

	const fileReader = new FileReader();
	fileReader.onload = function (e) {
		result = {
			...result,
			e
		};
		if (typeof options.callbacks.onload === 'function') {
			options.callbacks.onload.call(fileReader, result);
		}
	};
	fileReader.onerror = function (e) {
		result = {
			...{
				success: false,
				errorMessage: fileReader.error,
				e
			}
		};
		if (typeof options.callbacks.onerror === 'function') {
			options.callbacks.onerror.call(fileReader, result);
		}
	};
	fileReader.onabort = function (e) {
		result = {
			...{
				success: false,
				errorMessage: 'File upload aborted',
				e
			}
		};
		if (typeof options.callbacks.onabort === 'function') {
			options.callbacks.onabort.call(fileReader, result);
		}
	};
	fileReader.readAsDataURL(file);
	if (typeof options.callbacks.dataRead === 'function') {
		options.callbacks.dataRead.call(fileReader, fileReader);
	}
};
