import { merge } from 'datatalks-utils';
import { Dropdown } from 'email-builder-ui';
import localize from '../../../common/_localize';

export default options => {
	const defaults = {
		extendedClasses: '',
		value: null,
		onChange: null,
		onReset: null,
		resettable: false,
		resetLabel: localize('Same as email'),
		resetValue: null,
		availableLineHeights: [1, 1.5, 2, 2.5, 3, 3.5, 4],
		extraDropdownItems: [],
		useExtraOnTop: true
	};

	options = merge(defaults, options);

	const items = options.availableLineHeights.map(lh => {
		return {
			content: lh,
			value: lh,
			active: parseFloat(options.value) - parseFloat(lh) === 0
		};
	});

	if (options.extraDropdownItems.length) {
		if (options.useExtraOnTop) {
			items.unshift(...options.extraDropdownItems);
		} else {
			items.push(...options.extraDropdownItems);
		}
	}

	if (options.onReset || options.resettable) {
		items.unshift({
			content: options.resetLabel,
			value: options.resetValue,
			active: !options.value,
			isDefault: true
		});
	}

	return new Dropdown({
		extendedClasses: options.extendedClasses,
		triggerOnChangeOnDefaultItemActivation: false,
		resettable: !!options.onReset || options.resettable,
		items,
		onChange: (dropdown, activeItem) => {
			if (typeof options.onChange === 'function') {
				options.onChange(activeItem.getValue());
			}
		},
		onReset: (dropdown, defaultItem) => {
			if (typeof options.onReset === 'function') {
				options.onReset(defaultItem.getValue());
			}
		}
	});
};
