import { camelCase } from 'lodash-es';
import spaceAccordion from '../../../commonAccordions/content/_space';
import { addClassesString, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}) => {
	let defaults = {
		componentName: obj.component.get('name')
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		spaceAccordionOptions: {
			spaceToggler: {
				showToggler: true,
				startActive: obj.component.get(
					camelCase(`display ${defaults.componentName}`)
				),
				clickTogglesAccordion: true,
				onChange: isActive => {
					obj.component.setAndRerender(
						camelCase(`display ${defaults.componentName}`),
						isActive
					);
				}
			},
			isVertical: obj.component.get(
				camelCase(`${defaults.componentName} isVertical`)
			),
			onChange: isVertical => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} isVertical`),
					isVertical
				);
			}
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const accordion = spaceAccordion.call(null, options.spaceAccordionOptions);

	const contentTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return contentTab;
};
