import icon from './_buildSvg';
import { merge } from 'datatalks-utils';

/**
 * The options to configure the HTML of the ImagePlaceholder
 * @typedef { Object } ImagePlaceholderHTMLOptions
 * @property { string } dimensionsDivider - The divider to display in between the recommended dimensions. Defaults to 'x' => 1920x1080.
 * @property { string } divider - The divider to display in between recommended dimensions and recommended size. Defaults to '•'.
 * @property { function | string } icon - The HTML string that displays the icon or a function that returns the HTML string.
 * @property { ImagePlaceholderIconOptions } iconOptions - The options to configure the icon if it uses the default icon.
 * @property { number } recommendedFileSize - The recommended width of the image.
 * @property { string } recommendedFileSizeUnit - The unit (suffix) of the recommended file size for the image.
 * @property { number } recommendedWidthRatio - The recommended ratio of image's width to height.
 * @property { number } recommendedHeightRatio - The recommended ratio of image's height to width.
 * @property { number } recommendedWidth - The recommended width in pixels for the image.
 * @property { boolean } showSpecsInBold - Whether or not to show recommended specs in a bold text.
 * @property { string } text - The text to display behind the recommended specs. Defaults to 'Recommended specs'.
 * @memberof ImagePlaceholder
 */

/**
 * Returns HTML string for image placeholder
 * @param { ImagePlaceholderHTMLOptions } options - The options to configure the HTML element.
 * @return {string } The HTML string for the image placeholder.
 * @memberof ImagePlaceholder
 */
export default function (options) {
	const defaults = {
		align: 'center',
		dimensionsDivider: 'x',
		divider: '•',
		icon: icon,
		iconOptions: {
			fill: '#B5B4C3',
			height: '42',
			viewBox: '0 0 46 42',
			width: '46'
		},
		recommendedFileSize: 20,
		recommendedFileSizeUnit: 'Kb',
		recommendedHeightRatio: 9,
		recommendedWidthRatio: 16,
		recommendedWidth: 2400,
		showSpecsInBold: true,
		text: 'Recommended specs'
	};

	options = merge(defaults, options);

	return `
		<div align="${
			options.align
		}" style="overflow:hidden" data-ph="true" class="img-ph">
			<div class="img-ph__icon">
				${
					options.icon === defaults.icon
						? icon.call({}, options.iconOptions)
						: typeof options.icon === 'function'
						? icon()
						: icon
				}
			</div>
			<div class="img-ph__text">
				<span>${options.text}</span>
				${options.showSpecsInBold ? '<strong>' : ''}
					<span>${parseInt(options.recommendedWidth)}</span>
					<span>${options.dimensionsDivider}</span>
					<span>${parseInt(
						options.recommendedWidth *
							(options.recommendedHeightRatio /
								options.recommendedWidthRatio)
					)}</span>
					<span>${options.divider}</span>
					<span>${options.recommendedFileSize}</span>
					<span>${options.recommendedFileSizeUnit}</span>
				${options.showSpecsInBold ? '</strong>' : ''}
			</div>
		</div>
	`;
}
