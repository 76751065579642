import { cloneDeep, debounce } from 'lodash-es';
import { arrayMove, arrayRemove } from 'datatalks-utils';
import baseMethods from '../common/methods/_baseMethods';

export default (componentOptions, editor) => {
	const debouncedRender = debounce(comp => {
		comp.components(comp.componentRender(comp));
	}, editor.getEmailBuilder().getOptions().renderingDebounceTime);

	return {
		init() {
			this.setStyle({
				...this.getStyle(),
				width: this.get('width')
			});
			if (!this.get('items').length) {
				this.set(
					'items',
					Array(this.get('numberOfStartingItems'))
						.fill({})
						.map(item =>
							cloneDeep({
								type: this.get('itemType'),
								...this.get('itemDefaultOptions'),
								...this.get('itemOptions')
							})
						)
				);
				this.rerender();
			}
		},
		setAndRerender(attr, value, forceItemsRerender = true) {
			this.set(attr, value);
			this.rerender(forceItemsRerender);
		},

		addItem(itemOptions = {}) {
			const newItem = {
				type: this.get('itemType'),
				...this.get('itemDefaultOptions'),
				...this.get('itemOptions'),
				...itemOptions
			};
			this.get('items').push(newItem);
			this.rerender();

			this.get('getCustomTrait')().draw();

			return newItem;
		},

		canAddMoreItems() {
			return this.get('items').length < this.get('maxItems');
		},

		deleteItem(item, callback) {
			arrayRemove(this.get('items'), item);

			if (!this.get('items').length) {
				this.remove();
			} else {
				this.rerender(true);
			}

			this.get('getCustomTrait')().draw();

			if (typeof callback === 'function') {
				callback();
			}
		},

		changeItemsOrder(oldIndex, newIndex, forceItemsRerender = true) {
			arrayMove(this.get('items'), oldIndex, newIndex);
			this.rerender(forceItemsRerender);
		},

		changeItemProp(target, propName, value, rerender = true, callback) {
			if (this.get('itemPropsMap'))
				propName = this.get('itemPropsMap')[propName] || propName;

			if (target) {
				const item = this.get('items').filter(
					item => item == target
				)[0];
				item[propName] = value;
				if (rerender) this.rerender();
				this.emitUpdate(`item:${propName}`, item, value);
				this.emitUpdate('item', item);
			}

			if (typeof callback === 'function') {
				callback();
			}
		},

		changeItemsProp(propName, value, changeItemsOptions = true) {
			if (this.get('itemPropsMap'))
				propName = this.get('itemPropsMap')[propName] || propName;

			if (changeItemsOptions) {
				this.set('itemOptions', {
					...this.get('itemOptions'),
					[propName]: value
				});
			}

			this.get('items').forEach(item => {
				item[propName] = value;
				this.emitUpdate(`item:${propName}`, item, value);
				this.emitUpdate('item', item);
			});
			this.rerender();
			this.emitUpdate('items', this.get('items'));
		},

		getItemsProp(prop) {
			if (this.get('itemPropsMap'))
				prop = this.get('itemPropsMap')[prop] || prop;

			return this.get('items')[0][prop];
		},

		getItemProp(target, prop) {
			if (this.get('itemPropsMap'))
				prop = this.get('itemPropsMap')[prop] || prop;

			if (target) {
				const item = this.get('items').filter(
					comp => comp == target
				)[0];
				return item[prop];
			} else {
				console.error(`Item doesn't contain a property called ${prop}`);
			}
		},

		rerender() {
			debouncedRender(this);
		},
		...baseMethods(componentOptions, editor)
	};
};
