import { merge, cssStyleObjectToString } from 'datatalks-utils';

import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';

export default (comp, options) => {
	const defaults = {
		debug: false,
		parentWidth: null,
		style: getComputedStyle(comp)
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const listWidth = comp.get('listWidth') || 'auto';
	const nColumns = comp.get('linksList')?.length;
	const align = comp.get('listAlign') || 'center';
	const colPadding = comp.get('linksHasMargins')
		? `padding-left: ${comp.get(
				'linksLeftMargin'
		  )}; padding-right: ${comp.get(
				'linksRightMargin'
		  )}; padding-bottom: ${comp.get(
				'linksBottomMargin'
		  )}; padding-top: ${comp.get('linksTopMargin')};`
		: '';
	const gap = parseFloat(comp.get('gap'));
	const linksBackground = comp.get('linksBackground');
	const isWrapable = comp.get('isWrapable');

	/**
	 * Calculates the width of the list item based on the parent width, provided options and number of columns.
	 * @param {boolean} isWrapper - Whether it should calculate list's width or texts' width.
	 * @return {number} The width of the parent element.
	 */
	function getParentWidth(isWrapper = false) {
		return (
			(parseFloat(options.parentWidth) - gap * (nColumns - 1)) /
				nColumns -
			(comp.get('linksHasMargins') && !isWrapper
				? parseFloat(comp.get('linksLeftMargin')) +
				  parseFloat(comp.get('linksRightMargin'))
				: 0)
		);
	}

	const tableHtml = `
		<table role="presentation" width="${
			options.parentWidth
		}" style="width:100%;max-width:${
		options.parentWidth
	};${cssStyleObjectToString(options.style)}" ${
		options.style['background-color']
			? `bgcolor="${options.style['background-color']}"`
			: ''
	}>
			<tr>
				${comp
					.get('linksList')
					.map(
						(item, index) =>
							`${
								index != 0
									? `<td width="${gap}" style="width:${gap}px;padding-left:${
											gap / 2
									  }px;padding-right:${gap / 2}px;"></td>`
									: ''
							}
							<td style="${
								listWidth == 'auto'
									? ''
									: `width:${100 / nColumns}%;`
							}; ${colPadding}; background-color: ${linksBackground};" align="${comp.get(
								'linksAlignment'
							)}" bgcolor="${linksBackground}">
								${comp.findType(!comp.get('isTextList') ? 'link' : 'text')[index].toHTML({
									parentWidth: `${getParentWidth()}px`,
									style: {
										display: 'inline-block'
									}
								})}
							</td>`
					)
					.join('')}

		</tr>
	</table>`;

	const innerHtml = isWrapable
		? `
		<div ${
			options.debug ? 'data-eb-name="links-list-first"' : ''
		} class="${nColumns}-col" style="width:100%;max-width:${
				options.parentWidth
		  };text-align:${align};font-size:0;${cssStyleObjectToString(
				options.style
		  )}">
			<!--[if mso]>
		  		<table role="presentation" width="${options.parentWidth}" style="width:${
				options.parentWidth
		  };${cssStyleObjectToString(options.style)}" ${
				options.style['background-color']
					? `bgcolor="${options.style['background-color']}"`
					: ''
		  }>
					<tr>
						${comp
							.get('linksList')
							.map(
								(item, index) =>
									`${
										index != 0
											? `<td width="${gap}" style="width:${gap}px;padding-left:${
													gap / 2
											  }px;padding-right:${
													gap / 2
											  }px;"></td>`
											: ''
									}
									<td style="${
										listWidth == 'auto'
											? ''
											: `width:${100 / nColumns}%;`
									}; ${colPadding}; background-color: ${linksBackground};" align="${comp.get(
										'linksAlignment'
									)}" bgcolor="${linksBackground}">
									<![endif]-->
										<div class="column" style="${
											listWidth == 'auto'
												? ''
												: `width:100%;`
										}max-width:${
										getParentWidth(true) + 'px'
									};display: inline-block;background-color:${linksBackground};${
										index != 0
											? `margin-left:${gap}px;`
											: ''
									}; text-align: ${comp.get(
										'linksAlignment'
									)}">

											<div ${
												options.debug
													? 'data-eb-name="links-list-last"'
													: ''
											} style="${colPadding};">
												${comp.findType(!comp.get('isTextList') ? 'link' : 'text')[index].toHTML({
													parentWidth: `${getParentWidth()}px`,
													style: {
														display: 'inline-block'
													}
												})}
											</div>

										</div>
									<!--[if mso]>
									</td>`
							)
							.join('')}

					</tr>
				</table>
			<![endif]-->
		</div>
	`
		: tableHtml;

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log('List HTML: ', comp.get('hasMargins') ? html : innerHtml);

	return comp.get('hasMargins') ? html : innerHtml;
};
