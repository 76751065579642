import { noInteractionPreset } from '../_componentsUtils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';

export default comp => {
	comp.setStyle({
		width: comp.get('listWidth')
	});

	comp.addAttributes({ align: comp.get('listAlign') });

	const cells = [];

	comp.attributes.linksList.forEach((link, index, arr) => {
		const style = validateStyleObj({
			background: comp.get('linksBackground'),
			'border-color': comp.get('linksBorderColor'),
			'border-style': comp.get('linksBorderStyle'),
			'border-width': comp.get('linksBorderWidth'),
			'border-radius': comp.get('linksBorderRadius')
				? Object.values(comp.get('linksBorderRadius')).join(' ')
				: ''
			// TODO: delete when footer would be updated with this props
		});

		const cell = {
			type: 'cell',
			style,
			components: {
				type: !comp.get('isTextList') ? 'link' : 'text',
				style: validateStyleObj({
					color: comp.get('linksColor'),
					display: 'block',
					'font-weight': comp.get('linksWeight'),
					padding: comp.get('linksHasMargins')
						? `${comp.get('linksTopMargin')} ${comp.get(
								'linksRightMargin'
						  )} ${comp.get('linksBottomMargin')} ${comp.get(
								'linksLeftMargin'
						  )}`
						: '0'
				}),
				attributes: !comp.get('isTextList')
					? {
							href: link.href,
							class: comp.attributes.cssClass + '__link',
							target: link.target || '_blank'
					  }
					: null
			},
			attributes: {
				align: comp.attributes.linksAlignment,
				class: comp.attributes.cssClass + '__link-container'
			}
		};

		if (typeof link.content === 'object') {
			cell.components.components = link.content;
		} else if (comp.get('isTextList')) {
			cell.components.text = link.content;
		} else {
			cell.components.content = link.content;
		}

		cells.push(cell);

		if (index < arr.length - 1 && parseInt(comp.attributes.gap) > 0)
			cells.push({
				type: 'cell',
				attributes: {
					class: comp.attributes.cssClass + '__gap'
				},
				style: validateStyleObj({
					width: comp.attributes.gap
				})
			});
	});

	return {
		type: 'row',
		components: cells,
		style: validateStyleObj({
			'font-family': comp.get('linksFont'),
			'letter-spacing': comp.get('linksLetterSpacing')
		}),
		...noInteractionPreset
	};
};
