import { validateStyleObj } from '../../../common/functions/_utilFunctions';
import { getHTMLTags } from 'datatalks-utils';

export default comp => {
	if (comp.get('width') === 'auto') {
		comp.set('width', '100%');
	}

	comp.setStyle(
		validateStyleObj({
			'background-color': comp.get('backgroundColor'),
			'font-family': comp.get('fontFamily'),
			color: comp.get('color'),
			'font-weight': comp.get('fontWeight'),
			'border-width': comp.get('borderWidth'),
			'border-style': comp.get('borderStyle'),
			'border-color': comp.get('borderColor'),
			'text-align': comp.get('textAlign'),
			width: comp.get('width'),
			'border-radius': comp.get('useBorderRadius')
				? Object.values(comp.get('borderRadius')).join(' ')
				: '0px'
		})
	);

	const tag = getHTMLTags(comp.get('text'), true)[0];
	if (tag) comp.set('tagName', tag);
	comp.set('content', comp.get('text'));

	return null;
};
