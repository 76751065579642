import { merge } from 'datatalks-utils';
import { setContent } from 'datatalks-utils';
import layoutButtonGroup from './common/_layout';
import { Accordion, SectionItem } from 'datatalks-ui';

export default (options = {}) => {
	const defaults = {
		layout: {
			label: "Choose the item's image position",
			value: null,
			onChange: null
		},
		accordion: {
			title: 'Layout',
			color: 'grey',
			type: 'extend',
			startOpen: true
		}
	};

	options = merge(defaults, options);

	const layoutAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.layout.label,
			content: layoutButtonGroup(options.layout).getEl()
		}).getEl()
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: layoutAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		startOpen: options.accordion.startOpen
	});

	return buttonAccordion;
};
