// import { cloneDeep } from 'lodash-es';
import { merge, mapObject, insertAfterEveryItem } from 'datatalks-utils';

import { noInteractionPreset } from '../_componentsUtils';
import updateStyle from '../common/_updateCommonStyle';

export default parent => {
	let itemsOptions = merge(
		parent.get('itemDefaultOptions'),
		parent.get('itemOptions')
	);

	if (parent.get('itemPropsMap'))
		itemsOptions = mapObject(itemsOptions, parent.get('itemPropsMap'));

	parent.set('itemOptions', itemsOptions);

	const getCell = item => {
		return {
			type: 'cell',
			attributes: {
				align: item.align || itemsOptions.align
			},
			style: {
				'vertical-align': item.vAlign || itemsOptions.vAlign,
				width: `calc((100% - ${
					parseFloat(parent.get('spacingBetweenElements')) *
					(parent.attributes.items.length - 1)
				}px)/${parent.attributes.items.length})`
			},
			components: comp => {
				return item;
			}
		};
	};

	const innerComponents = [];
	if (parent.attributes.isVertical) {
		let itemsList = [];
		parent.attributes.items.forEach(item => {
			itemsList.push({
				type: 'row',
				components: getCell(item),
				...noInteractionPreset
			});
		});

		itemsList = insertAfterEveryItem(itemsList, {
			type: 'space',
			dimension: parent.get('spacingBetweenElements'),
			isVertical: true
		});

		innerComponents.push(itemsList);
	} else {
		innerComponents.push({
			type: 'row',
			components: comp => {
				return insertAfterEveryItem(
					parent.attributes.items.map(item => getCell(item)),
					{
						type: 'space',
						dimension: parent.get('spacingBetweenElements'),
						isVertical: false
					}
				);
			},
			...noInteractionPreset
		});
	}

	// TODO: add an option to skip style update on render
	updateStyle(parent);

	return [
		{
			type: 'margins',
			innerComponents,
			hasMargins: parent.get('hasMargins'),
			margins: {
				top: parent.get('topMargin'),
				right: parent.get('rightMargin'),
				bottom: parent.get('bottomMargin'),
				left: parent.get('leftMargin')
			},
			marginsColor: {
				top: parent.get('topMarginColor'),
				right: parent.get('rightMarginColor'),
				bottom: parent.get('bottomMarginColor'),
				left: parent.get('leftMarginColor')
			}
		}
	];
};
