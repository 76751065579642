/* eslint-disable require-jsdoc */
import { merge } from 'lodash-es';
import { colorPicker } from 'datatalks-ui';
import { addClassesString, setContent, EventEmitter } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { toHex } from 'color2k';

export default class ColorPickerInput {
	constructor(options = {}) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'color-picker-input',
			extendedClasses: '',
			valueColorType: 'hex',
			color: null,
			emptyColor: '#00000000',
			emptyColorLabel: 'none',
			transparentColorLabel: 'none',
			allowNullEmptyColorIfLabel: true,
			resetIcon: getIcon('close-line', { size: 'md' }),
			preventDefaultOpacityChange: false,
			colorPickerOptions: {
				preset: false,
				position: 'bottom-end',
				swatches: [
					'#1F1451',
					'#9B51E0',
					'#A5A6F6',
					'#2F80ED',
					'#56CCF2',
					'#7ED286',
					'#219653',
					'#F95540',
					'#F35625',
					'#FFCD70',
					'#FDDDD3'
				]
			},
			noInput: false,
			onChange: null,
			onReset: null
		};

		this.options = merge(defaults, options);
		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.element = null;
		this.isTransparent = false;
		this.hasEmptyColor =
			(typeof this.options.emptyColor != 'function' &&
				!!this.options.emptyColor) ||
			(typeof this.options.emptyColor === 'function' &&
				this.options.emptyColor()) ||
			(this.options.allowNullEmptyColorIfLabel &&
				this.options.emptyColorLabel);
		if (this.hasEmptyColor) {
			this.emptyColorIsFunc =
				typeof this.options.emptyColor === 'function';
			this.emptyColor = this.emptyColorIsFunc
				? this.options.emptyColor()
				: this.options.emptyColor;
			this.emptyColor = this.emptyColor || 'transparent';
			this.color = this.options.color || this.emptyColor;
		} else {
			this.color = this.options.color || 'transparent';
		}
		this.colorObj = null;

		this.eventEmitter = new EventEmitter();

		this.init();
	}

	init() {
		this.createElement();
		if (!this.options.noInput) this.createInput();
		if (!this.options.noInput) this.createResetBtn();
		this.createSwatch();
		this.createTransparentCnt();
		this.initColorPicker();
		this.setupPickerEvents();
		this.draw();
	}

	createElement() {
		this.element = document.createElement('div');
		this.element.className = this.className;
		if (this.options.noInput)
			this.element.classList.add(`${this.className}--no-input`);
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
	}

	createInput() {
		this.input = document.createElement('input');
		this.input.className = `eb-input ${this.className}__input`;
		if (this.hasEmptyColor) {
			this.input.value = this.getColor() || this.emptyColor;
		} else {
			this.input.value = this.getColor();
		}
	}

	createResetBtn() {
		this.resetBtn = document.createElement('div');
		this.resetBtn.className = `${this.className}__reset-btn`;
		setContent(this.resetBtn, this.options.resetIcon);
		this.resetBtn.addEventListener('click', () => {
			this.resetColor(true);
		});
	}

	createSwatch() {
		this.swatch = document.createElement('div');
		this.swatch.className = `${this.className}__swatch`;
	}

	createTransparentCnt() {
		this.transparentCnt = document.createElement('div');
		this.transparentCnt.className = `${this.className}__transparent-sign`;
	}

	initColorPicker() {
		this.colorPicker = colorPicker(this.element, {
			...this.options.colorPickerOptions,
			color: this.getColor()
		});
		this.colorObj = this.colorPicker.getColor();
		this.setTransparent(this.colorObj.a === 0);
		if (!this.options.noInput) this.updateInputValue();
	}

	setupPickerEvents() {
		const cpi = this;
		cpi.colorPicker.on('change', event => {
			cpi.onChange.call(cpi, event);
		});
		const listener = ev => {
			if (
				ev.data === 'click' &&
				typeof cpi?.colorPicker?.close === 'function'
			) {
				cpi.colorPicker.close();
			}
		};
		cpi.colorPicker.on('open', () => {
			window.addEventListener('message', listener);
		});
		cpi.colorPicker.on('close', () => {
			window.removeEventListener('message', listener);
		});
	}

	onChange(event) {
		if (
			!this.options.preventDefaultOpacityChange &&
			this.isFullTransparent() &&
			this.hasColor(event) &&
			this.colorIsTransparent(event)
		) {
			this.colorPicker.setColor({
				...this.colorPicker.getColor(),
				a: 1
			});
		}
		this.colorObj = this.colorPicker.getColor();
		this.setTransparent(this.colorObj.a === 0);
		this.setColor(this.colorObj);
		if (!this.options.noInput) this.updateInputValue();
		if (typeof this.options.onChange === 'function') {
			this.options.onChange.call(null, this, event);
		}
		this.eventEmitter.emit('change', this, event);
		if (this.hasEmptyColor && !this.emptyColorIsSet()) this.draw();
	}

	isFullTransparent() {
		return this.colorIsFullTransparent(this.colorObj);
	}

	colorIsFullTransparent(colorObj) {
		return !colorObj.r && !colorObj.g && !colorObj.b && !colorObj.a;
	}

	colorIsTransparent(colorObj) {
		return !colorObj.a;
	}

	hasColor(colorObj) {
		return !(!colorObj.r && !colorObj.g && !colorObj.b);
	}

	setColor(colorObj) {
		this.color = colorObj[this.options.valueColorType] || colorObj.hex;
	}

	changeColor(color) {
		this.colorPicker.setColor(color);
	}

	getColor() {
		return typeof this.color === 'function' ? this.color() : this.color;
	}

	updateInputValue() {
		if (this.shouldDisplayEmpty()) {
			this.input.value = this.options.emptyColorLabel;
		} else if (this.isTransparent) {
			this.input.value = this.options.transparentColorLabel;
		} else {
			this.input.value = this.getColor();
		}
	}

	shouldDisplayEmpty() {
		return (
			(this.isTransparent &&
				this.options.allowNullEmptyColorIfLabel &&
				this.options.emptyColorLabel) ||
			(this.hasEmptyColor &&
				toHex(this.getColor()) === toHex(this.emptyColor))
		);
	}

	changeSwatchColor(color) {
		this.colorPicker.setColor(color);
	}

	resetColor(redraw = true) {
		if (this.emptyColorIsFunc) {
			this.colorPicker
				.setColor(this.options.emptyColor() || 'transparent')
				.trigger('change');
			if (this.options.emptyColor() != this.emptyColor)
				this.emptyColor = this.options.emptyColor() || 'transparent';
		} else {
			this.colorPicker.setColor(this.emptyColor).trigger('change');
		}
		this.colorObj = this.colorPicker.getColor();
		this.setTransparent(this.colorObj.a === 0);
		this.setColor(this.colorObj);
		if (redraw) this.draw();
		if (!this.options.noInput) this.updateInputValue();
		this.eventEmitter.emit('resetColor', this, this.emptyColor);
		if (typeof this.options.onReset === 'function') {
			this.options.onReset.call(null, this, this.emptyColor);
		}
	}

	emptyColorIsSet() {
		return this.getColor() === this.emptyColor;
	}

	removeResetBtn() {
		if (this.element.contains(this.resetBtn)) {
			this.element.removeChild(this.resetBtn);
		}
	}

	draw() {
		setContent(this.element, [
			!this.options.noInput ? this.input : null,
			!this.options.noInput &&
			this.hasEmptyColor &&
			!this.emptyColorIsSet()
				? this.resetBtn
				: null,
			this.swatch,
			this.transparentCnt
		]);
	}

	getEl() {
		return this.element;
	}

	getColorPicker() {
		return this.colorPicker;
	}

	setTransparent(isTransparent) {
		if (this.isTransparent != isTransparent) {
			this.isTransparent = isTransparent;
			this.handleTransparencyChange();
		}
	}

	handleTransparencyChange() {
		if (this.isTransparent) {
			this.element.classList.add(`${this.className}--transparent`);
		} else {
			this.element.classList.remove(`${this.className}--transparent`);
		}
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		// TODO: add more destroy logic
		if (typeof this.colorPicker.destroy === 'function') {
			this.colorPicker.destroy();
		}
	}
}
