/* eslint-disable require-jsdoc */
import { merge } from 'lodash-es';
import RadioButtonGroupItem from './_radioButtonGroupItem';

/**
 * The callback function to be called when Radio Button Group Selection has changed.
 * @callback RadioButtonGroup~onChangeCallback
 * @param { boolean } allSelected - Wether or not all items became selected.
 * @param { RadioButtonGroupItem } changedItem - The item clicked.
 * @param { RadioButtonGroupItem[] } selectedItems - All the selected Items.
 * @param { boolean } noneSelected - Wether or not the group became without selection.
 */

/**
 * Options to configure Radio Button Group
 * @typedef { Object } RadioButtonGroupOptions
 * @property { RadioButtonGroup~onChangeCallback } onChange - Triggered when the selected items are changed.
 * @property { string } cssClass - Class to use as the block class of the group (BEM).
 * @property { string } extendedClasses - Other classes to use in the root element of the group.
 * @property { RadioButtonGroupItem[] | RadioButtonGroupItemOptions[] } items - The array of Radio Button Group Items to group.
 * @property { boolean } linkItemsWithSameValue - If true, when an item is changed, the items with the same value will be changed as well to. Selection would be linked with value.
 * @property { boolean } singleSelection - If true, only one value can be selected. Others would be deselected when selecting a value. Defaults to false.
 * @property { boolean } allowNoSelection - Whether or not to allow none of the items being selected
 */

/**
 * Returns a Radio Button Group Item.
 * @param { RadioButtonGroupOptions } opts - The options to configure the Radio Button Group.
 * @return { HTMLElement } - The item HTML element.
 */
export default class RadioButtonGroup {
	constructor(opts = {}) {
		const defaults = {
			onChange: null,
			classPrefix: 'eb-',
			cssClass: 'radio-btn-group',
			extendedClasses: '',
			linkItemsWithSameValue: true,
			singleSelection: false,
			allowNoSelection: true,
			items: []
		};

		this.opts = merge(defaults, opts);

		this.className = `${this.opts.classPrefix}${this.opts.cssClass}`;

		this.onchangeCbs = [];

		if (opts.items.length) {
			this.init();
		} else {
			throw new Error(
				'You must pass items to Radio Button Group options.'
			);
		}
	}

	init() {
		this.element = document.createElement('div');
		this.element.className = `${this.className}${
			this.opts.extendedClasses ? ' ' + this.opts.extendedClasses : ''
		}`;
		this.items = [...this.opts.items];

		if (typeof this.opts.onChange === 'function')
			this.onchangeCbs.push(this.opts.onChange);

		this.createItems();

		if (!this.opts.allowNoSelection && !this.getSelectedItems().length)
			this.selectItem(this.items[0]);

		this.appendItems();
	}

	getItem(item) {
		return (
			this.items.filter(i => {
				return i === item;
			})[0] || false
		);
	}

	getSelectedItems() {
		return this.items.filter(item => {
			return item.isSelected();
		});
	}

	getEl() {
		return this.element;
	}

	areAllItemsSelected() {
		return this.getSelectedItems().length === this.items.length;
	}

	selectValue(value) {
		this.items.forEach(item => {
			if (item.getValue() == value && !item.isSelected())
				item.selectItem();
		});
	}

	unselectValue(value) {
		this.items.forEach(item => {
			if (item.getValue() == value && item.isSelected())
				item.unselectItem();
		});
	}

	selectItem(item) {
		if (!item.isSelected() && this.getItem(item))
			this.getItem(item).selectItem();
	}

	unselectItem(item) {
		if (item.isSelected() && this.getItem(item))
			this.getItem(item).unselectItem();
	}

	unselectAll() {
		this.items.forEach(item => {
			item.unselectItem();
		});
	}

	selectAll() {
		this.items.forEach(item => {
			item.selectItem();
		});
	}

	onchange(isSelected, item) {
		this.onchangeCbs.forEach(cb => {
			if (typeof cb === 'function')
				cb.call(
					this,
					this.areAllItemsSelected(),
					item,
					this.getSelectedItems(),
					!this.getSelectedItems().length
				);
		});
	}

	onSelectionChanged(isSelected, item) {
		if (this.opts.linkItemsWithSameValue) {
			if (isSelected) {
				this.selectValue(item.getValue());
			} else {
				this.unselectValue(item.getValue());
			}
		}

		this.onchange(isSelected, item);
	}

	createItems() {
		const group = this;
		group.items = group.items.map(item => {
			if (item instanceof RadioButtonGroupItem) {
				return item;
			} else {
				return new RadioButtonGroupItem(item);
			}
		});

		group.items.forEach(item => {
			item.on('change', group.onSelectionChanged.bind(group));

			if (group.opts.singleSelection)
				item.on('click', group.unselectAll.bind(group), true);
		});
	}

	appendItems() {
		this.element.append(...this.items.map(item => item.getEl()));
	}

	on(eventName, callback, prioritize) {
		eventName = eventName.trim().toLowerCase();
		if (
			typeof this['on' + eventName] === 'function' &&
			Array.isArray(this['on' + eventName + 'Cbs']) &&
			typeof callback === 'function'
		) {
			if (prioritize === true) {
				this['on' + eventName + 'Cbs'].unshift(callback);
			} else if (typeof prioritize === 'number') {
				this['on' + eventName + 'Cbs'].splice(prioritize, 0, callback);
			} else {
				this['on' + eventName + 'Cbs'].push(callback);
			}
		} else {
			console.error(
				`${eventName} is not a valid RadioButtonGroupItem event name.`
			);
		}
	}
}
