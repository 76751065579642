import { merge } from 'datatalks-utils';
import imageAccordion from '../../Header/components/contentTab/_imageAccordion';
import textAccordion from './contentTab/_textAccordion';
import buttonAccordion from './contentTab/_buttonAccordion';
import titleAccordion from './contentTab/_titleAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait) => {
	const defaults = {
		extendedClasses: '',
		imageAccordionOptions: {},
		textAccordionOptions: {},
		buttonAccordionOptions: {},
		titleAccordionOptions: {}
	};

	options = merge(defaults, options);

	const contentTab = addClassesString(
		setContent(document.createElement('div'), [
			imageAccordion
				.call(null, obj, options.imageAccordionOptions, customTrait)
				.getEl(),
			titleAccordion
				.call(null, obj, options.titleAccordionOptions, customTrait)
				.getEl(),
			textAccordion
				.call(null, obj, options.textAccordionOptions, customTrait)
				.getEl(),
			buttonAccordion
				.call(null, obj, options.buttonAccordionOptions, customTrait)
				.getEl()
		]),
		options.extendedClasses
	);

	return contentTab;
};
