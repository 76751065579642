import { Accordion, RadioButtonGroup, SectionItem } from 'datatalks-ui';
import { setContent } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { merge } from 'datatalks-utils';

const getAlignLeft = () => getIcon('align-left', { size: 'xl' });
const getAlignCenter = () => getIcon('align-center', { size: 'xl' });
const getAlignRight = () => getIcon('align-right', { size: 'xl' });
const getAlignTop = () => getIcon('align-top', { size: 'xl' });
const getAlignMiddle = () => getIcon('align-vertically', { size: 'xl' });
const getAlignBottom = () => getIcon('align-bottom', { size: 'xl' });

export default (obj, options = {}) => {
	const defaults = {
		accordion: {
			title: 'Alignment',
			accordionColor: 'grey',
			accordionType: 'extend'
		},
		alignment: {
			active: true,
			label: 'Align items',
			icons: {
				left: getAlignLeft(),
				center: getAlignCenter(),
				right: getAlignRight()
			},
			onChange: (
				allSelected,
				changedItem,
				selectedItems,
				noneSelected
			) => {
				obj.component.changeItemsProp.call(
					obj.component,
					'align',
					changedItem.getValue()
				);
			},
			initialValue: () => obj.component.getItemsProp('align')
		},
		vAlignment: {
			active: true,
			label: 'Items vertical align',
			icons: {
				top: getAlignTop(),
				middle: getAlignMiddle(),
				bottom: getAlignBottom()
			},
			onChange: (
				allSelected,
				changedItem,
				selectedItems,
				noneSelected
			) => {
				obj.component.changeItemsProp.call(
					obj.component,
					'vAlign',
					changedItem.getValue()
				);
			},
			initialValue: obj.component.getItemsProp('vAlign')
		}
	};

	options = merge(defaults, options);

	if (typeof options.alignment.initialValue === 'function')
		options.alignment.initialValue = options.alignment.initialValue();

	const radioButtonGroupOptions = {
		onChange: options.alignment.onChange,
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				value: 'left',
				selected: options.alignment.initialValue === 'left',
				icon: options.alignment.icons.left
			},
			{
				value: 'center',
				selected: options.alignment.initialValue === 'center',
				icon: options.alignment.icons.center
			},
			{
				value: 'right',
				selected: options.alignment.initialValue === 'right',
				icon: options.alignment.icons.right
			}
		]
	};

	const verticalRadioButtonGroupOptions = {
		onChange: options.vAlignment.onChange,
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				value: 'top',
				selected: options.vAlignment.initialValue === 'top',
				icon: options.vAlignment.icons.top
			},
			{
				value: 'middle',
				selected: options.vAlignment.initialValue === 'middle',
				icon: options.vAlignment.icons.middle
			},
			{
				value: 'bottom',
				selected: options.vAlignment.initialValue === 'bottom',
				icon: options.vAlignment.icons.bottom
			}
		]
	};

	const alignmentAccordionContent = setContent(
		document.createElement('div'),
		[
			options.alignment.active &&
				new SectionItem({
					label: options.alignment.label,
					content: new RadioButtonGroup(
						radioButtonGroupOptions
					).getEl()
				}).getEl(),
			options.vAlignment.active &&
				new SectionItem({
					label: options.vAlignment.label,
					content: new RadioButtonGroup(
						verticalRadioButtonGroupOptions
					).getEl()
				}).getEl()
		]
	);

	const alignmentAccordion = new Accordion({
		title: options.accordion.title,
		content: alignmentAccordionContent,
		accordionColor: options.accordion.accordionColor,
		accordionType: options.accordion.accordionType
	});

	return alignmentAccordion;
};
