import {
	merge,
	cssStyleObjectToString,
	isValidDimension
} from 'datatalks-utils';

export default (comp, options) => {
	const defaults = {
		debug: false,
		parentWidth: null,
		isMobileOptimized: false
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isMobileOptimized =
		options.isMobileOptimized || typeof comp.getEditor === 'function'
			? comp
					.getEditor()
					.getEmailBuilder()
					.getAdvancedOption('isMobileOptimized')
			: false;

	const align = comp.get('align') || 'center';
	const imagePlacement = comp.get('imagePlacement');
	const vAlign = comp.get('vAlign');

	const imageSideRatio = comp.get('imageContentRatio');

	const msoImageColWidth =
		imagePlacement == 'top'
			? options.parentWidth
			: parseFloat(options.parentWidth) * imageSideRatio -
			  parseFloat(comp.get('horizontalSpacingBetweenElements')) / 2 +
			  'px';
	const msoSecondColWidth =
		imagePlacement == 'top'
			? options.parentWidth
			: parseFloat(options.parentWidth) * (1 - imageSideRatio) -
			  parseFloat(comp.get('horizontalSpacingBetweenElements')) / 2 +
			  'px';

	/**
	 * Calculates the width of the image based on the parent width and other components margins.
	 * @param {boolean} imageTop - Indicates whether the layout is with image on top.
	 * @return {number} The calculated width of the image.
	 */
	function getImageWidth(imageTop) {
		if (imageTop) {
			return (
				parseFloat(options.parentWidth) -
				parseFloat(comp.get('imageLeftMargin')) -
				parseFloat(comp.get('imageRightMargin'))
			);

			// return parseFloat(options.parentWidth);
		} else {
			// return (
			// 	(parseFloat(options.parentWidth) -
			// 		parseFloat(comp.get('imageLeftMargin')) -
			// 		parseFloat(comp.get('imageRightMargin')) -
			// 		parseFloat(comp.get('horizontalSpacingBetweenElements')) /
			// 			2 -
			// 		max(
			// 			parseFloat(comp.get('titleLeftMargin')) +
			// 				parseFloat(comp.get('titleRightMargin')),
			// 			parseFloat(comp.get('descriptionLeftMargin')) +
			// 				parseFloat(comp.get('descriptionRightMargin')),
			// 			parseFloat(comp.get('buttonLeftMargin')) +
			// 				parseFloat(comp.get('buttonRightMargin'))
			// 		)) *
			// 	imageSideRatio
			// );

			return (
				(parseFloat(options.parentWidth) -
					parseFloat(comp.get('horizontalSpacingBetweenElements')) /
						2 -
					parseFloat(comp.get('imageLeftMargin')) -
					parseFloat(comp.get('imageRightMargin'))) *
				imageSideRatio
			);
		}
	}

	/**
	 * Calculates the width of an element (button, title, description)
	 * based on the layout (top view or side view).
	 *
	 * @param {string} elementName - The name of the element.
	 * @param {boolean} imageTop - Indicates whether the layout is the image on top.
	 * @return {number} The calculated width of the element.
	 */
	function getElementWidth(elementName, imageTop) {
		if (imageTop) {
			return (
				parseFloat(options.parentWidth) -
				parseFloat(comp.get(`${elementName}LeftMargin`)) -
				parseFloat(comp.get(`${elementName}RightMargin`))
			);

			// return parseFloat(options.parentWidth);
		} else {
			// return (
			// 	(parseFloat(options.parentWidth) -
			// 		parseFloat(comp.get('imageLeftMargin')) -
			// 		parseFloat(comp.get('imageRightMargin')) -
			// 		parseFloat(comp.get('horizontalSpacingBetweenElements')) /
			// 			2 -
			// 		parseFloat(comp.get(`${elementName}LeftMargin`)) -
			// 		parseFloat(comp.get(`${elementName}RightMargin`))) *
			// 	(1 - imageSideRatio)
			// );

			return (
				(parseFloat(options.parentWidth) -
					parseFloat(comp.get('horizontalSpacingBetweenElements')) /
						2 -
					parseFloat(comp.get(`${elementName}LeftMargin`)) -
					parseFloat(comp.get(`${elementName}RightMargin`))) *
				(1 - imageSideRatio)
			);
		}
	}

	const prettifyHorizontalSpace =
		isMobileOptimized &&
		isValidDimension(comp.get('horizontalSpacingBetweenElements'))
			? parseFloat(comp.get('horizontalSpacingBetweenElements')) / 2
			: 0;
	const prettifyVerticalSpace =
		isMobileOptimized &&
		isValidDimension(comp.get('verticalSpacingBetweenElements'))
			? parseFloat(comp.get('verticalSpacingBetweenElements')) / 2
			: 0;

	const direction = imagePlacement == 'right' ? 'rtl' : 'ltr';
	const imageStyle = {
		'padding-left':
			parseFloat(comp.get(`imageLeftMargin`)) +
			prettifyHorizontalSpace +
			'px',
		'padding-right':
			parseFloat(comp.get(`imageRightMargin`)) +
			prettifyHorizontalSpace +
			'px',
		'padding-top':
			parseFloat(comp.get(`imageTopMargin`)) +
			prettifyVerticalSpace +
			'px',
		'padding-bottom':
			parseFloat(comp.get(`imageBottomMargin`)) +
			prettifyVerticalSpace +
			'px'
	};

	const imageVAlign =
		comp.get('imageVAlignment') || comp.get('contentVAlignment') || vAlign;

	const column1 = comp.get('displayImage')
		? `
		<!--[if mso]>
		<td style="width:${msoImageColWidth}; ${cssStyleObjectToString(
				imageStyle
		  )}" align="${comp.get(
				'imageAlignment'
		  )}" valign="${imageVAlign}" dir="ltr">
		<![endif]-->
			<div class="ebr-column ebr-full-width-img-col ${
				isMobileOptimized && imagePlacement !== 'top'
					? `ebr-max-width-${
							parseFloat(options.parentWidth) > 500
								? 'cover-'
								: ''
					  }${Math.floor(imageSideRatio * 10) * 10}`
					: ''
			}" style="width:100%;max-width:${msoImageColWidth};display:inline-block;vertical-align:${imageVAlign};direction:ltr;">
				<div style="${cssStyleObjectToString(imageStyle)}">
					<p ${
						options.debug ? 'data-eb-name="list-item-last"' : ''
					} style="margin:0;font-family:Arial,sans-serif;font-size:14px;line-height:0;">
						${comp.findType('image-wrapper')[0].toHTML({
							parentWidth: getImageWidth(imagePlacement == 'top'),
							style: { width: '100%' }
						})}
					</p>
				</div>
			</div>
		<!--[if mso]>
		</td>
		<![endif]-->
	`
		: '';

	/**
	 * Determines whether column containing the Title,
	 * the Description and the Button of the list's item should render.
	 * @return {boolean} True if column 2 should render, false otherwise.
	 */
	function shouldColumn2Render() {
		return (
			comp.get('displayTitle') ||
			comp.get('displayDescription') ||
			comp.get('displayButton')
		);
	}

	/**
	 * Returns the HTML representation of th column containing the Title,
	 * the Description and the Button of the list's item.
	 * @return {string} The HTML representation of the column.
	 */
	function getColumn2Html() {
		let marginString;
		if (comp.get('displayImage'))
			marginString = isMobileOptimized
				? ''
				: comp.get('imagePlacement') === 'top'
				? `margin-top: ${comp.get('verticalSpacingBetweenElements')};`
				: `margin-${comp.get('imagePlacement')}: ${comp.get(
						'horizontalSpacingBetweenElements'
				  )};`;

		/**
		 * Creates a table row with padding and inserts the provided element,
		 * getting the padding information about the element on the component props.
		 *
		 * @param {string} elementName - The name of the element.
		 * @param {string} element - The HTML element to be inserted.
		 * @return {string} The HTML representation of the table row.
		 */
		function createRow(elementName, element) {
			const padding = {
				'padding-left':
					parseFloat(comp.get(`${elementName}LeftMargin`)) +
					prettifyHorizontalSpace +
					'px',
				'padding-right':
					parseFloat(comp.get(`${elementName}RightMargin`)) +
					prettifyHorizontalSpace +
					'px',
				'padding-top':
					parseFloat(comp.get(`${elementName}TopMargin`)) +
					prettifyVerticalSpace +
					'px',
				'padding-bottom':
					parseFloat(comp.get(`${elementName}BottomMargin`)) +
					prettifyVerticalSpace +
					'px'
			};

			if (elementName == 'button') {
				element = `<table style="width: 100%">
					<tr>
						<td align="${comp.get('buttonAlignment')}">
							${element}
						</td>
					</tr>
				</table>`;
			}

			return `<tr>
					<td style="${cssStyleObjectToString(padding)}">
					<![endif]-->
						<div style="display: block; ${cssStyleObjectToString(padding)}">
							${element}
						</div>
					<!--[if mso]>
					</td>
				</tr>`;
		}

		let title;
		let titleColumn;

		if (comp.get('displayTitle')) {
			title = comp.findType('text')[0].toHTML({
				parentWidth: getElementWidth('title', imagePlacement == 'top')
			});

			titleColumn = createRow('title', title);
		}

		let description;
		let descriptionColumn;

		if (comp.get('displayDescription')) {
			const descriptionIndex = comp.get('displayTitle') ? 1 : 0;
			description = comp.findType('text')[descriptionIndex].toHTML({
				parentWidth: getElementWidth(
					'description',
					imagePlacement == 'top'
				),
				style: {
					'margin-top': isMobileOptimized
						? '0px'
						: comp.get('verticalSpacingBetweenElements')
				}
			});

			descriptionColumn = createRow('description', description);
		}

		let button;
		let buttonColumn;

		if (comp.get('displayButton')) {
			button = comp.findType('button')[0].toHTML({
				parentWidth: getElementWidth('button', imagePlacement == 'top'),
				style: {
					'margin-top': isMobileOptimized
						? '0px'
						: comp.get('verticalSpacingBetweenElements')
				}
			});

			buttonColumn = createRow('button', button);
		}

		const contentVAlign =
			comp.get('contentVAlignment') ||
			comp.get('imageVAlignment') ||
			vAlign;

		return `
			<!--[if mso]>
			<td style="width:${msoSecondColWidth};" valign="${contentVAlign}" dir="ltr">
			<![endif]-->
				<div class="ebr-column ${
					isMobileOptimized &&
					imagePlacement !== 'top' &&
					comp.get('displayImage')
						? `ebr-max-width-${
								parseFloat(options.parentWidth) > 500
									? 'cover-'
									: ''
						  }${100 - Math.floor(imageSideRatio * 10) * 10}`
						: ''
				}" style="${
			marginString || ''
		} width:100%;max-width:${msoSecondColWidth};display:inline-block;vertical-align:${contentVAlign};direction:ltr;">
					<div ${
						options.debug ? 'data-eb-name="list-item-last"' : ''
					} style="font-size:14px;line-height:18px;text-align:left;">
						<!--[if mso]>
						<table role="presentation" width="100%">
							${titleColumn || ''}
							${
								titleColumn && descriptionColumn
									? `<tr>
									<td style="height:${comp.get('verticalSpacingBetweenElements')}"></td>
								</tr>`
									: ''
							}
							${descriptionColumn || ''}
							${
								descriptionColumn && buttonColumn
									? `<tr>
									<td style="height:${comp.get('verticalSpacingBetweenElements')}"></td>
								</tr>`
									: ''
							}
							${buttonColumn || ''}
						</table>
						<![endif]-->
					</div>
				</div>
			<!--[if mso]>
			</td>
			<![endif]-->
		`;
	}

	const column2 = shouldColumn2Render() ? getColumn2Html() : '';

	const msoHorizontalSpace =
		parseFloat(comp.get('horizontalSpacingBetweenElements')) > 0
			? `
	<!--[if mso]>
	<td style="width:${comp.get('horizontalSpacingBetweenElements')};"></td>
	<![endif]-->
`
			: '';

	const topView = `
		<!--[if mso]>
			<td>
				<table>
					<tr>
					<![endif]-->
						${column1}
					<!--[if mso]>
					</tr>
					${
						shouldColumn2Render()
							? `
								<tr>
									<td style="height:${comp.get('verticalSpacingBetweenElements')};"></td>
								</tr>
								<tr>
								<![endif]-->
									${column2}
								<!--[if mso]>
								</tr>
							`
							: ''
					}
				</table>
			</td>
		<![endif]-->
	`;

	const html = `
		<div ${options.debug ? 'data-eb-name="list-item-first"' : ''} class="ebr-${
		imagePlacement == 'top' ? 'top' : 'side'
	}-col" style="text-align:${align};font-size:0;width:100%;direction:${direction};">
			<!--[if mso]>
			<table role="presentation" width="100%" dir="${direction}">
			<tr>
			<![endif]-->
				${imagePlacement == 'top' ? topView : column1 + msoHorizontalSpace + column2}
			<!--[if mso]>
			</tr>
			</table>
			<![endif]-->
		</div>
	`;

	if (options.debug) console.log('List Item HTML: ', html);

	return html;
};
