import {
	blockCustom,
	components,
	load,
	commands,
	update,
	undoRedo,
	storage
} from './eventListeners/_eventListeners';

export default emailBuilder => {
	const { editor } = emailBuilder;
	// Storage:Load
	storage(emailBuilder);
	// Block:Custom
	editor.on('block:custom', blockCustom.bind(emailBuilder, editor));
	// Components
	components(editor);
	// Load
	editor.on('load', load.bind(emailBuilder, editor));
	// Update
	editor.on('update', update.bind(emailBuilder, editor));
	// Commands
	commands(editor);
	// Undo/Redo
	editor.on('undo redo', undoRedo.bind(emailBuilder, editor));
};
