import { RadioButtonGroup } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import { toBoolean } from 'datatalks-utils';
import { merge } from 'datatalks-utils';

// const withMargins = getIcon('fullscreen-exit-line', { size: 'xl' });
// const fullWidth = getIcon('fullscreen-line', { size: 'xl' });

export default (options = {}) => {
	const defaults = {
		value: null,
		onChange: null,
		icons: {
			fullWidth: getIcon('fullscreen-line', { size: 'xl' }),
			withMargins: getIcon('fullscreen-exit-line', { size: 'xl' })
		}
	};

	options = merge(defaults, options);

	const radioButtonGroupOptions = {
		onChange: (allSelected, changedItem, selectedItems, noneSelected) => {
			if (typeof options.onChange === 'function')
				options.onChange(toBoolean(changedItem.getValue()));
		},
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				label: 'Fit to container',
				value: 'false',
				selected: !options.value,
				icon: options.icons.fullWidth
			},
			{
				label: 'With margins',
				value: 'true',
				selected: !!options.value,
				icon: options.icons.withMargins
			}
		]
	};

	const marginsButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return marginsButtonGroup;
};
