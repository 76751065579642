import {
	merge,
	cssStyleObjectToString,
	insertAfterEveryItem
} from 'datatalks-utils';

import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';

export default (comp, options) => {
	const defaults = {
		debug: false,
		parentWidth: null,
		style: getComputedStyle(comp),
		imageWidth: comp.get('imageWidth'),
		space: comp.get('spacingBetweenElements')
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const debugStrStart = options.debug ? 'data-eb-name="hero-first"' : '';
	const debugStrEnd = options.debug ? 'data-eb-name="hero-last"' : '';

	const marginsWidth = comp.get('hasMargins')
		? parseFloat(comp.get('leftMargin')) +
		  parseFloat(comp.get('rightMargin')) +
		  'px'
		: 0;

	const textIndex = comp.get('displayTitle') ? 1 : 0;

	/**
	 * Retrieves the HTML representation of a specific element based on its name.
	 *
	 * @param {string} name - The name of the element to retrieve.
	 * @return {string} The HTML representation of the element.
	 */
	function getElement(name) {
		const parentWidth = `${
			parseFloat(options.parentWidth) - parseFloat(marginsWidth)
		}px`;
		switch (name) {
			case 'image':
				return comp
					.findType('image-wrapper')[0]
					.toHTML({ parentWidth });
				break;

			case 'title':
				return comp.findType('text')[0].toHTML({ parentWidth });
				break;

			case 'text':
				return comp.findType('text')[textIndex].toHTML({ parentWidth });
				break;

			case 'button':
				return comp.findType('button')[0].toHTML({ parentWidth });
				break;

			default:
				console.warn('Invalid element name: ', name);
				return '';
				break;
		}
	}

	/**
	 * Generates an HTML table row with the provided content.
	 *
	 * @param {string} content - The content to be placed inside the table cell.
	 * @param {boolean} shouldRender - Determines whether the row should be rendered or not.
	 * @param {object} rowOptions - Additional options for the row.
	 * @return {string} - The generated HTML table row.
	 */
	function row(content, shouldRender, rowOptions = {}) {
		const defaults = {
			align: 'center'
		};

		rowOptions = merge(defaults, rowOptions);

		if (shouldRender) {
			content = typeof content === 'function' ? content() : content;
			return `
				<tr>
					<td ${debugStrEnd} align="${rowOptions.align}">
						${content}
					</td>
				</tr>`;
		} else return '';
	}

	const space = `
		<tr>
			<td height="${parseFloat(options.space)}" style="width: 100%;height:${
		options.space
	}"></td>
		</tr>`;

	const imageRow = row(
		getElement.bind(null, 'image'),
		comp.get('displayImage'),
		{
			align: comp.get('imageAlignment')
		}
	);

	const titleRow = row(
		getElement.bind(null, 'title'),
		comp.get('displayTitle'),
		{
			align: comp.get('titleAlignment')
		}
	);

	const textRow = row(
		getElement.bind(null, 'text'),
		comp.get('displayText'),
		{
			align: comp.get('textAlignment')
		}
	);

	const buttonRow = row(
		getElement.bind(null, 'button'),
		comp.get('displayButton'),
		{
			align: comp.get('buttonAlignment')
		}
	);

	const rows = insertAfterEveryItem(
		[imageRow, titleRow, textRow, buttonRow].filter(row => !!row),
		space
	).join(' ');

	const innerHtml = `
		<table ${debugStrStart} style="${cssStyleObjectToString(options.style)}">
			${rows}
		</table>
	`;

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log('List HTML: ', comp.get('hasMargins') ? html : innerHtml);

	return comp.get('hasMargins') ? html : innerHtml;
};
