import orderInfoIcon from '../../assets/blocks/icons/order-info.svg';
import mapAttributes from '../common/_handlebarsAttributesMapping';

const block = {
	id: 'order-info',
	name: 'Order info',
	label: 'Order info',
	icon: orderInfoIcon,
	getContent: (payload, options) => [
		{
			type: 'image-wrapper',
			imgSrc:
				options.previewImageSrc || options.getPreviewImageSrc(payload)
		}
	],
	hasValidPayload: payload => {
		return (
			payload.previewImageSrc &&
			typeof payload.previewImageSrc === 'string'
		);
	},
	toHTML: (payload, options, toHtmlOptions) => {
		let template = `
			<style>
				.order-info__table {
					font-size: 12px;
					width: 100%;
					border-collapse: collapse;
					table-layout: fixed;
				}

				.order-info__table th {
					text-align: left;
				}

				.order-info__table td,
				.order-info__table th {
					padding-top: 5px;
				}

				.order-info__heading3 {
					font-size: 14px;
					font-weight: bold;
				}
			</style>

			<table class="order-info__table order-info-address">
				<tbody>
					<tr>
						<th class="order-info__heading3">
							Leveransadress
						</th>
						<th>
							Fakturaadress
						</th>
					</tr>
					<tr>
						<td>
							{{FirstNameDelivery}} {{LastNameDelivery}}
						</td>
						<td>
							{{FirstNameInvoice}} {{LastNameInvoice}}
						</td>
					</tr>
					<tr>
						<td>
							<p>{{Address1Delivery}}</p>
							<p>{{ZipCodeDelivery}} {{CityDelivery}}</p>
						</td>
						<td>
							<p>{{Address1Invoice}}</p>
							<p>{{ZipCodeInvoice}} {{CityInvoice}}</p>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="order-info__table order-info-details">
				<tbody>
					<tr>
						<th class="order-info__heading3">
							Dina Kontaktuppgifter
						</th>
						<th>
							Orderuppgifter
						</th>
					</tr>
					<tr>
						<td>
							E-post: <a href="mailto:{{Email}}">{{Email}}</a>
						</td>
						<td>
							Ordernr: {{transactnum}}
						</td>
					</tr>
					<tr>
						<td>
							Telephon: {{Phone}}
						</td>
						<td>
							Datum: {{OrderPlacedISO}}
						</td>
					</tr>
					<tr>
						<th class="order-info__heading3">
							Leveranssätt
						</th>
						<td>
							{{Payment_Method}}
						</td>
					</tr>
					<tr>
						<td>
							{{Delivery_Method}}
						</td>
						<td></td>
					</tr>
					<tr>
						<th class="order-info__heading3">
							Orderstatus
						</th>
						<td></td>
					</tr>
					<tr>
						<td>
							{{Order_status}}
						</td>
						<td>
						</td>
					</tr>
				</tbody>
			</table>
		`;
		template = mapAttributes(template, options.attributesMap);
		return template;
	}
};

export { block };
