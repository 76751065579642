export default {
	hasMargins: true,
	backgroundColor: null,
	spacingBetweenElements: '12px',
	leftMargin: '15px',
	rightMargin: '15px',
	topMargin: '15px',
	bottomMargin: '15px',
	leftMarginColor: 'transparent',
	rightMarginColor: 'transparent',
	topMarginColor: 'transparent',
	bottomMarginColor: 'transparent',
	borderWidth: null,
	borderStyle: null,
	borderColor: null,
	borderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomLeft: '0px',
		bottomRight: '0px'
	},
	useBorderRadius: false
};
