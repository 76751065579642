/* eslint-disable require-jsdoc */
import { camelCase } from 'lodash-es';
import listItem from './_listItemDefaults';
import button from './_button';
import imageWrapper from './_imageWrapper';
import linksList from './_linksList';
import text from './_text';
import space from './_space';
import margins from './_margins';
import common from './_common';

const componentsDefaults = {
	listItem,
	button,
	imageWrapper,
	linksList,
	text,
	space,
	margins,
	common
};

export function getComponentsDefaultsByType(type = 'common') {
	return componentsDefaults[camelCase(type)];
}

export default componentsDefaults;
