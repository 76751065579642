import { ToggleButton, Accordion, SectionItem } from 'datatalks-ui';
import { addClassesString, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import buttonGroup from '../style/common/_orientation';

export default (options = {}) => {
	const defaults = {
		accordion: {
			title: 'Space',
			color: 'grey',
			type: 'extend'
		},
		spaceToggler: {
			showToggler: true,
			startActive: true,
			clickTogglesAccordion: false,
			onChange: null // (isActive)
		},
		isVertical: null,
		onChange: null, // (isVertical)
		sectionContentExtendedClasses: 'eb-mt-2',
		sectionLabel: 'Space orientation'
	};

	options = merge(defaults, options);

	const spaceAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.sectionLabel,
			content: buttonGroup({
				value: options.isVertical,
				onChange: isVertical => {
					if (typeof options.onChange === 'function')
						options.onChange(isVertical);
				}
			}).getEl(),
			contentExtendedClasses: options.sectionContentExtendedClasses
		}).getEl()
	]);

	const title = options.spaceToggler.showToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.spaceToggler.clickTogglesAccordion,
						startActive: options.spaceToggler.startActive,
						onChange: isActive => {
							if (
								typeof options.spaceToggler.onChange ===
								'function'
							)
								options.spaceToggler.onChange(isActive);
						}
					}).getEl(),
					`<span>${options.accordion.title}</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: 'Image';

	const ImageAccordion = new Accordion({
		title: title,
		content: spaceAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return ImageAccordion;
};
