export default {
	attributes: {},
	cssClass: 'button',
	alignment: 'center',
	letterSpacing: 'normal',
	tagName: 'button',
	text: 'Button',
	name: 'Button',
	backgroundColor: null,
	height: null,
	width: '100%',
	fontFamily: null,
	color: null,
	fontWeight: null,
	borderWidth: '0px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomRight: '0px',
		bottomLeft: '0px'
	},
	useBorderRadius: false,
	textAlignment: 'center',
	toHtmlOptions: {},
	href: null,
	lineHeight: null,
	hasOutlookBorderRadius: false,
	outlookWidth: '150px'
};
