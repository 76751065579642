import {
	ColorPickerInput,
	SectionItem,
	Accordion,
	ToggleableSection,
	LinkedInputs,
	InputNumber
} from 'datatalks-ui';
import { merge, getUnit, allEqual, cssSidesToObject } from 'datatalks-utils';
import { capitalize, kebabCase, startCase } from 'lodash-es';

import fontsDropdown from '../../../traits/commonAccordions/style/common/_fonts';
import _lineHeightDropdown from '../../../traits/commonAccordions/style/common/_lineHeight';

export default (options = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		traitsVisibility: {
			backgroundColor: true,
			color: true,
			fontFamily: true,
			fontFamilyFallback: true,
			innerMargin: true,
			height: true,
			lineHeight: true,
			borderRadius: true
		}
	};

	options = merge(defaults, options);

	const rerenderButton = button => {
		button.componentRender(button);
	};

	const rerenderAllButtons = editor
		.getWrapper()
		.findType('button')
		.forEach.bind(editor.getWrapper().findType('button'), rerenderButton);

	const buttonHeightInput = new InputNumber({
		defaultValue:
			emailBuilder.getStyleProp('buttons').height === 'auto'
				? '40px'
				: emailBuilder.getStyleProp('buttons').height,
		onChange: (value, unit, inputValue) => {
			editor.runCommand('change-general-style-prop', {
				changedProp: 'height',
				oldValue: emailBuilder.getStyleProp('buttons').height,
				newValue: inputValue
			});
			emailBuilder.setStyleProp(
				'buttons',
				merge(emailBuilder.getStyleProp('buttons'), {
					height: inputValue
				})
			);
			emailBuilder.applyGeneralStyles('buttons', 'height');
			rerenderAllButtons(); // TODO: check if it's still needed
		}
	});

	/**
	 * Sets the line height of the button to match its height.
	 */
	function setLineHeightToHeight() {
		editor.setStyleRules(
			'button',
			{
				'line-height': editor.getStyleRules('button', 'height')
			},
			{ addStyles: true }
		);
	}

	const lineHeightDropdown = _lineHeightDropdown({
		availableLineHeights: emailBuilder.getAvailableLineHeights(),
		extraDropdownItems: [
			{
				value: emailBuilder.getStyleProp('buttons').height,
				content: emailBuilder.getLineHeightTypes().sameAsHeight,
				active:
					emailBuilder.getStyleProp('buttons').lineHeight ===
					emailBuilder.getStyleProp('buttons').height
			}
		],
		resetLabel: emailBuilder.getLineHeightTypes().auto,
		value:
			emailBuilder.getStyleProp('buttons').lineHeight ===
			emailBuilder.getLineHeightTypes().auto
				? null
				: emailBuilder.getStyleProp('buttons').lineHeight,
		onChange: value => {
			let lineHeight = value;
			emailBuilder.setStyleProp(
				'buttons',
				merge(emailBuilder.getStyleProp('buttons'), {
					lineHeight: value
				})
			);
			if (value === emailBuilder.getLineHeightTypes().sameAsHeight) {
				lineHeight = emailBuilder.getStyleProp('buttons').height;
				buttonHeightInput.on('change', setLineHeightToHeight);
			} else {
				buttonHeightInput.off('change', setLineHeightToHeight);
			}
			editor.runCommand('change-general-style-prop', {
				changedProp: 'lineHeight',
				oldValue: emailBuilder.getStyleProp('buttons').lineHeight,
				newValue: lineHeight
			});
			emailBuilder.applyGeneralStyles('buttons', 'lineHeight');
			rerenderAllButtons();
		},
		onReset: () => {
			const lineHeight = emailBuilder.getButtonsAutoLineHeight();
			emailBuilder.setStyleProp(
				'buttons',
				merge(emailBuilder.getStyleProp('buttons'), {
					lineHeight: emailBuilder.getLineHeightTypes().auto
				})
			);
			editor.runCommand('change-general-style-prop', {
				changedProp: 'lineHeight',
				oldValue: editor.getStyleRules('button', 'line-height'),
				newValue: lineHeight
			});
			editor.setStyleRules(
				'button',
				{
					'line-height': lineHeight
				},
				{ addStyles: true }
			);
			buttonHeightInput.off('change', setLineHeightToHeight);
		}
	});

	const fontFamilyFallbackSection = new SectionItem({
		label: 'Font Fallback',
		isHidden: !emailBuilder.getStyleProp('buttons').fontFamily,
		content: fontsDropdown({
			useWebFonts: false,
			value: emailBuilder.getStyleProp('buttons').fallbackFontFamily,
			onChange: value => {
				emailBuilder.setStyleProp(
					'buttons',
					merge(emailBuilder.getStyleProp('buttons'), {
						fallbackFontFamily: value
					})
				);
				emailBuilder.applyGeneralStyles('buttons');
			}
		}).getEl()
	});

	return new Accordion({
		title: 'Buttons',
		accordionColor: 'grey',
		accordionType: 'extend',
		content: [
			options.traitsVisibility.backgroundColor &&
				new SectionItem({
					label: 'Background color',
					content: new ColorPickerInput({
						color: emailBuilder.getStyleProp('buttons')
							.backgroundColor,
						onChange: (cpi, ev) => {
							editor.runCommand('change-general-style-prop', {
								changedProp: 'buttonBackgroundColor',
								oldValue:
									emailBuilder.getStyleProp('buttons')
										.backgroundColor,
								newValue: cpi.getColor()
							});
							emailBuilder.setStyleProp(
								'buttons',
								merge(emailBuilder.getStyleProp('buttons'), {
									backgroundColor: cpi.getColor()
								})
							);
							emailBuilder.applyGeneralStyles(
								'buttons',
								'backgroundColor'
							);
						}
					}).getEl()
				}).getEl(),
			options.traitsVisibility.color &&
				new SectionItem({
					label: 'Text color',
					content: new ColorPickerInput({
						color: emailBuilder.getStyleProp('buttons').color,
						onChange: (cpi, ev) => {
							editor.runCommand('change-general-style-prop', {
								changedProp: 'buttonTextColor',
								oldValue:
									emailBuilder.getStyleProp('buttons').color,
								newValue: cpi.getColor()
							});
							emailBuilder.setStyleProp(
								'buttons',
								merge(emailBuilder.getStyleProp('buttons'), {
									color: cpi.getColor()
								})
							);
							emailBuilder.applyGeneralStyles('buttons', 'color');
						}
					}).getEl()
				}).getEl(),
			options.traitsVisibility.fontFamily &&
				new SectionItem({
					label: 'Font',
					content: fontsDropdown({
						value: emailBuilder.getStyleProp('buttons').fontFamily,
						onChange: value => {
							editor.runCommand('change-general-style-prop', {
								changedProp: 'fontFamily',
								oldValue:
									emailBuilder.getStyleProp('buttons')
										.fontFamily,
								newValue: value
							});
							emailBuilder.setStyleProp(
								'buttons',
								merge(emailBuilder.getStyleProp('buttons'), {
									fontFamily: value
								})
							);
							emailBuilder.applyGeneralStyles(
								'buttons',
								'fontFamily'
							);
							fontFamilyFallbackSection.show();
						},
						onReset: () => {
							editor.runCommand('change-general-style-prop', {
								changedProp: 'fontFamily',
								oldValue: editor.getStyleRules(
									'button',
									'font-family'
								),
								newValue: editor.getStyleRules(
									'wrapper',
									'font-family'
								)
							});
							editor.setStyleRules(
								'button',
								{ 'font-family': 'inherit' },
								{ addStyles: true }
							);
							fontFamilyFallbackSection.hide();
						}
					}).getEl()
				}).getEl(),
			options.traitsVisibility.fontFamilyFallback &&
				fontFamilyFallbackSection.getEl(),
			options.traitsVisibility.innerMargin &&
				new SectionItem({
					label: 'Padding',
					content: new LinkedInputs({
						startLinked: allEqual(
							['left', 'right', 'top', 'bottom'].map(
								side =>
									emailBuilder.getStyleProp('buttons')
										.paddings[side]
							)
						),
						items: ['left', 'right', 'top', 'bottom'].map(side => {
							return {
								label: capitalize(side),
								input: {
									defaultValue:
										emailBuilder.getStyleProp('buttons')
											.paddings[side],
									unit: getUnit(
										emailBuilder.getStyleProp('buttons')
											.paddings[side]
									),
									changeableUnit: false,
									onChange: (value, unit, inputValue) => {
										emailBuilder.setStyleProp(
											'buttons',
											merge(
												emailBuilder.getStyleProp(
													'buttons'
												),
												{
													paddings: {
														...emailBuilder.getStyleProp(
															'buttons'
														).paddings,
														[side]: inputValue
													}
												}
											)
										);
										editor.runCommand(
											'change-general-style-prop',
											{
												changedProp: 'padding',
												oldValue: editor.getStyleRules(
													'button',
													'padding'
												),
												newValue: inputValue
											}
										);
										editor.setStyleRules(
											'button',
											{
												[`padding-${side}`]: inputValue
											},
											{ addStyles: true }
										);
										rerenderAllButtons();
									}
								}
							};
						})
					}).getEl()
				}).getEl(),
			// TODO: here
			options.traitsVisibility.height &&
				new ToggleableSection({
					startOpen:
						emailBuilder.getStyleProp('buttons').height == 'auto',
					label: 'Height',
					content: buttonHeightInput.getEl(),
					toggleableContent: true,
					toggleLabel: 'Auto',
					toggleOnShowsContent: false,
					onToggle: isActive => {
						if (isActive) {
							emailBuilder.setStyleProp(
								'buttons',
								merge(emailBuilder.getStyleProp('buttons'), {
									height: 'auto'
								})
							);
							editor.setStyleRules(
								'button',
								{ height: 'auto' },
								{ addStyles: true }
							);
						} else {
							emailBuilder.setStyleProp(
								'buttons',
								merge(emailBuilder.getStyleProp('buttons'), {
									height: buttonHeightInput.getInputValue()
								})
							);
							editor.setStyleRules(
								'button',
								{
									height: buttonHeightInput.getInputValue()
								},
								{ addStyles: true }
							);
						}
					}
				}).getEl(),
			options.traitsVisibility.lineHeight &&
				new SectionItem({
					label: 'Line height',
					content: lineHeightDropdown.getEl()
				}).getEl(),
			options.traitsVisibility.borderRadius &&
				new SectionItem({
					label: 'Border radius',
					content: new LinkedInputs({
						startLinked: allEqual(
							[
								'topLeft',
								'topRight',
								'bottomRight',
								'bottomLeft'
							].map(
								corner =>
									emailBuilder.getStyleProp('buttons')
										.borderRadius[corner]
							)
						),
						items: [
							'topLeft',
							'topRight',
							'bottomRight',
							'bottomLeft'
						].map(corner => {
							return {
								label: startCase(corner),
								input: {
									defaultValue:
										emailBuilder.getStyleProp('buttons')
											.borderRadius[corner],
									onChange: (value, unit, inputValue) => {
										emailBuilder.setStyleProp(
											'buttons',
											merge(
												emailBuilder.getStyleProp(
													'buttons'
												),
												{
													borderRadius: {
														[corner]: inputValue
													}
												}
											)
										);
										emailBuilder.applyGeneralStyles(
											'buttons'
										);
									}
								}
							};
						})
					}).getEl()
				}).getEl()
		]
	});
};
