/**
 * A module with multiple util JS functions
 * @module UtilFunctions
 */

import {
	isEqual,
	merge as _merge,
	mergeWith as _mergeWith,
	kebabCase
} from 'lodash-es';
import { toHex } from 'color2k';

// Constants
const NON_WORD_CHARS_REGEX = /\W+|[_]+/;
const WHITE_SPACE_REGEX = /\s+/;
const SUPPORTED_URL_PROTOCOLS = new Set([
	'http:',
	'https:',
	'mailto:',
	'sms:',
	'tel:'
]);

const CREATE_ELEMENT_DEFAULTS = {
	tagName: 'div',
	className: null,
	id: null,
	attributes: {}
};

const dataUnits = [
	'byte',
	'kilobyte',
	'megabyte',
	'gigabyte',
	'terabyte',
	'petabyte',
	'exabyte',
	'zettabyte',
	'yottabyte'
];

const CssUnits = [
	'cm',
	'mm',
	'in',
	'px',
	'pt',
	'pc',
	'em',
	'ex',
	'ch',
	'rem',
	'vw',
	'vh',
	'vmin',
	'vmax',
	'%'
];

const borderStyles = [
	'none',
	'hidden',
	'dotted',
	'dashed',
	'solid',
	'double',
	'groove',
	'ridge',
	'inset',
	'outset',
	'initial',
	'inherit'
];

const genericFonts = [
	'cursive',
	'fantasy',
	'math',
	'monospace',
	'sans-serif',
	'serif'
];

const webSafeFonts = [
	'Arial',
	'Brush Script MT',
	'Courier New',
	'Garamond',
	'Georgia',
	'Helvetica',
	'Tahoma',
	'Times New Roman',
	'Trebuchet MS',
	'Verdana'
];

const webFonts = ['Calibri', 'Nova Square', 'Nunito', 'Poppins', 'Vina Sans'];

const windowsFonts = [
	'Arial',
	'Arial Black',
	'Bahnschrift',
	'Calibri',
	'Cambria',
	'Cambria Math',
	'Candara',
	'Cascadia Code',
	'Cascadia Mono',
	'Comic Sans MS',
	'Consolas',
	'Constantia',
	'Corbel',
	'Courier New',
	'Ebrima',
	'Franklin Gothic Medium',
	'Gabriola',
	'Gadugi',
	'Georgia',
	'HoloLens MDL2 Assets',
	'Impact',
	'Ink Free',
	'Javanese Text',
	'Leelawadee UI',
	'Lucida Console',
	'Lucida Sans Unicode',
	'Malgun Gothic',
	'Marlett',
	'Microsoft Himalaya',
	'Microsoft JhengHei',
	'Microsoft New Tai Lue',
	'Microsoft PhagsPa',
	'Microsoft Sans Serif',
	'Microsoft Tai Le',
	'Microsoft YaHei',
	'Microsoft Yi Baiti',
	'MingLiU-ExtB',
	'Mongolian Baiti',
	'MS Gothic',
	'MV Boli',
	'Myanmar Text',
	'Nirmala UI',
	'Palatino Linotype',
	'Segoe Fluent Icons',
	'Segoe MDL2 Assets',
	'Segoe Print',
	'Segoe Script',
	'Segoe UI',
	'Segoe UI Emoji',
	'Segoe UI Historic',
	'Segoe UI Symbol',
	'Segoe UI Variable',
	'SimSun',
	'Sitka',
	'Sylfaen',
	'Symbol',
	'Tahoma',
	'Times New Roman',
	'Trebuchet MS',
	'Verdana',
	'Webdings',
	'Wingdings',
	'Yu Gothic'
];

const cssFontWeights = [
	'normal',
	'bold',
	'bolder',
	'lighter',
	'inherit',
	'initial',
	100,
	200,
	300,
	400,
	500,
	600,
	700,
	800,
	900
];

const urlRegExp = new RegExp(
	/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);

/**
 * Checks if a JavaScript function has a non-empty body.
 *
 * @param {Function} func - The function to check.
 * @throws {Error} Throws an error if the input is not a function.
 * @return {boolean} Returns true if the function's body is not empty, otherwise false.
 *
 * @example
 * // Define an empty function
 * function emptyFunction() {}
 *
 * // Define a function with a non-empty body
 * function nonEmptyFunction() {
 *   console.log('Hello, world!');
 * }
 *
 * console.log(functionHasBody(emptyFunction)); // false
 * console.log(functionHasBody(nonEmptyFunction)); // true
 */
function functionHasBody(func) {
	if (typeof func !== 'function') {
		throw new Error('Input is not a function');
	}

	const funcStr = func.toString();
	const bodyStartIndex = funcStr.indexOf('{');
	const bodyEndIndex = funcStr.lastIndexOf('}');

	if (bodyStartIndex === -1 || bodyEndIndex === -1) {
		return false; // Function doesn't have a body
	}

	const bodyContent = funcStr.slice(bodyStartIndex + 1, bodyEndIndex).trim();
	return bodyContent.length > 0;
}

/**
 * Converts an SVG string to a base64-encoded data URL.
 *
 * @param {string} svgString - The SVG string to convert.
 * @return {string} The base64-encoded data URL.
 */
function svgToBase64(svgString) {
	// const svgData = encodeURIComponent(svgString);
	const base64 = btoa(svgString);
	const url = `data:image/svg+xml;base64,${base64}`;
	return url;
}

/**
 * Downloads an HTML file with the specified HTML content.
 *
 * @param {string} htmlContent - The HTML content to be downloaded.
 * @param {object} options - The desired filename for the downloaded file (optional).
 */
function downloadFile(
	htmlContent,
	{ fileName = 'file.html', type = 'text/html' }
) {
	if (htmlContent) {
		const blob = new Blob([htmlContent], { type });
		const downloadUrl = URL.createObjectURL(blob);
		const downloadLink = document.createElement('a');
		downloadLink.href = downloadUrl;
		downloadLink.download = fileName;
		downloadLink.click();
		URL.revokeObjectURL(downloadUrl);
	}
}

/**
 * Debounce will prevent a function from being called twice in a given time.
 * The function called will be delayed by a giving time and if the function is called again, the previous function call is canceled
 * @param { function } cb - The function to call.
 * @param { number } delay - The delay to wait until the function is called again.
 * @return { function } - The given function to call.
 * @function
 */
function debounce(cb, delay = 250) {
	let timeout;

	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			cb(...args);
		}, delay);
	};
}

/**
 * Throttle will call the function passed to it every time the delay ends as long
 * as the trigger for the function is still happening.
 * @param { function } cb - The function to call.
 * @param { number } delay - The delay to wait until the function is called again.
 * @return { function } - The given function to call.
 * @function
 */
function throttle(cb, delay = 1000) {
	let shouldWait = false;
	let waitingArgs;
	const timeoutFunc = () => {
		if (waitingArgs == null) {
			shouldWait = false;
		} else {
			cb(...waitingArgs);
			waitingArgs = null;
			setTimeout(timeoutFunc, delay);
		}
	};

	return (...args) => {
		if (shouldWait) {
			waitingArgs = args;
			return;
		}

		cb(...args);
		shouldWait = true;
		setTimeout(timeoutFunc, delay);
	};
}

/**
 * Transforms a string into a boolean.
 * @param { 'true' | 'false' } string - A string indicating the boolean value.
 * @return { boolean } - The evaluated boolean value.
 * @function
 */
const toBoolean = string => {
	return string.toLowerCase().trim() === 'true' ? true : false;
};

/**
 * Convert string to camelCase text.
 * @param { string } text - The string to be converted.
 * @return { string } The given string in camelCase
 * @function
 */
function camelCase(text) {
	const formatCase = (word, index) => {
		const formattedNonFirstWord =
			word.charAt(0).toUpperCase() + word.slice(1);
		return index === 0 ? word.toLowerCase() : formattedNonFirstWord;
	};

	return text
		.replace(NON_WORD_CHARS_REGEX, ' ')
		.split(WHITE_SPACE_REGEX)
		.map((word, index) => formatCase(word, index))
		.join('');
}

/**
 * Set the inner content of a container. Uses innerHTML prop or append method based on the type of content (string/element)
 * @param { HTMLElement } container - The container to add the content to.
 * @param { HTMLElement | HTMLString | (HTMLElement | HTMLString)[] } content - The content to add to the container.
 * @param { boolean } append - if true it would append the content to the container instead of replacing it.
 * @return { HTMLElement } The container with the new content
 * @function
 */
function setContent(container, content, append) {
	/**
	 * Set the inner content of a container. Uses innerHTML prop or append method based on the type of content (string/element)
	 * @param { HTMLElement } container - The container to add the content to.
	 * @param { HTMLElement | HTMLString | (HTMLElement | HTMLString) [] } content - The content to add to the container.
	 * @param { boolean } append - if true it would append the content to the container instead of replacing it.
	 * @function
	 */
	function setContentInner(container, content, append) {
		if (isElement(content) || isComment(content)) {
			if (!append) container.innerHTML = '';
			container.append(content);
		} else {
			if (!append) {
				container.innerHTML = content;
			} else {
				const auxEl = document.createElement('div');
				auxEl.innerHTML = content;
				container.append(...auxEl.children);
			}
		}
	}
	if (Array.isArray(content) || content instanceof NodeList) {
		if (content instanceof NodeList) content = Array.from(content);
		content
			.filter(c => c !== null && c !== undefined && c !== false)
			.forEach((c, i) => {
				setContentInner(container, c, i != 0 || append);
			});
	} else {
		setContentInner(container, content, append);
	}

	return container;
}

/**
 * Get the percentage of the element's width relative to its parent.
 * @param { HTMLElement } element - The element to get the width from.
 * @param { { numberOnly: boolean, parentEl: HTMLElement } } options - The options object.
 * @return { number | string } The percentage of the element's width comparing to its parent
 * @function
 */
function getElementWidthPercentage(
	element,
	options = { numberOnly: false, parentEl: null }
) {
	const defaults = {
		numberOnly: false,
		parentEl: null
	};

	options = merge(defaults, options);

	const parent = isElement(options.parentEl)
		? options.parentEl
		: element.parentNode;
	if (!parent) {
		console.error(
			"The element you're trying to get the width from doesn't have a parent element"
		);
	}
	const elementWidth = element.offsetWidth;
	const parentWidth = parent.offsetWidth;

	const percentage = (elementWidth / parentWidth) * 100;
	if (options.numberOnly) return percentage;
	return `${percentage}%`;
}

/**
 * Adds multiple classes inside a string to an element.
 * @param { HTMLElement } element - The element to add the classes to.
 * @param { string } classes - The string with all the classes that need to be added.
 * @param { string } separator - The pattern describing where each split should occur,
 * in order to split 'classes' string into multiple classes.
 * @return { HTMLElement } The given element.
 * @function
 */
function addClassesString(element, classes, separator = ' ') {
	if (classes) {
		if (separator != ' ') {
			separator = separator.trim();
			element.classList.add(...trimAll(classes).split(separator));
		} else {
			element.classList.add(...classes.trim().split(separator));
		}
	}
	return element;
}

/**
 * Returns the passed string without whitespace.
 * @param { string } str - The string to remove the whitespace from.
 * @return { string } The given string without whitespace.
 * @function
 */
function trimAll(str) {
	return str.replace(/\s/g, '');
}

/**
 * Transforms HTML code in plain text.
 * @param { string } html - A string with valid HTML code.
 * @return { string } - The text that were inside the HTML code.
 * @function
 */
const htmlToPlainText = html => {
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = html;
	return tempDiv.textContent || tempDiv.innerText || '';
};

/**
 * Checks whether or not a givin object is an HTML element.
 * @param { any } el - The object to check if is element.
 * @return { boolean } Whether or not 'el' is an HTML element.
 * @function
 */
const isElement = el => {
	return typeof HTMLElement === 'object'
		? el instanceof HTMLElement
		: el &&
				typeof el === 'object' &&
				el !== null &&
				el.nodeType === 1 &&
				typeof el.nodeName === 'string';
};

/**
 * Removes all the spaces of a string.
 * @param { string } string - The string to remove the spaces from.
 * @param { boolean } onlySpaceChar - If set to true it would only remove literal space characters.
 * @return { string } - The string without spaces.
 * @function
 */
function removeSpaces(string, onlySpaceChar) {
	return onlySpaceChar ? string.replace(/ /g, '') : string.replace(/\s/g, '');
}

/**
 * Adds an event listener to the given DOM element and attaches the specified callback function to it.
 *
 * @param {HTMLElement} element - The DOM element to which the event listener will be attached.
 * @param {string} eventType - The type of the event to listen for (e.g., 'click', 'keydown', 'mouseover', etc.).
 * @param {Function} callback - The function to be executed when the specified event is triggered.
 * @param {Object|boolean} [options] - An optional object specifying listener options, such as 'capture', 'once', or 'passive',
 *                                     or a boolean value indicating whether the event should be captured during the event's
 *                                     capturing phase (true) or bubbling phase (false). Default is false.
 * @return {HTMLElement} The same DOM element that the event listener was added to.
 *
 * @example
 * // Adding a click event listener to a button element
 * const myButton = document.getElementById('my-button');
 * elementAddEvent(myButton, 'click', () => {
 *   console.log('Button clicked!');
 * });
 */
function elementAddEvent(element, eventType, callback, options) {
	element.addEventListener(eventType, callback, options);
	return element;
}

/**
 * Finds the element where the caret is in at the moment of the call.
 * @param { HTMLElement } [parent=null] - If passed it would only return an element if is a descendent of the passed element.
 * @param { boolean } [directChild=false] - If true, the parent param needs to represent a direct parent, else just a ancestor.
 * @return { HTMLElement } - The Element where the caret is in.
 * @function
 */
function getCaretElement(parent, directChild = false) {
	let el = window.getSelection().getRangeAt(0).commonAncestorContainer;
	el = isElement(el) ? el : el.parentNode;
	if (parent) {
		if (
			(directChild && el.parentNode == parent) ||
			(!directChild && parent.contains(el))
		) {
			return el;
		} else {
			return null;
		}
	} else {
		return el;
	}
}

/**
 * Moves an element of the given array from an index to another.
 * @param { Array } array - The array to operate in.
 * @param { number } from - the index of the item to move.
 * @param { number } to - The new index of the item.
 * @function
 */
function arrayMove(array, from, to) {
	array.splice(to, 0, array.splice(from, 1)[0]);
}

/**
 * Splits an array into multiple parts.
 *
 * @param {Array} array - The array to be split.
 * @param {number} parts - The number of parts to split the array into.
 * @return {Array} - An array containing the split parts of the original array.
 */
function splitArrayIntoParts(array, parts) {
	const result = [];
	for (let i = 0; i < parts; i++) {
		const start = Math.floor((i * array.length) / parts);
		const end = Math.floor(((i + 1) * array.length) / parts);
		result.push(array.slice(start, end));
	}
	return result;
}

/**
 * Removes a given item from the given array.
 * @param { Array } array - The array to operate in.
 * @param { any } item - The item to be removed.
 * @function
 */
function arrayRemove(array, item) {
	array.splice(array.indexOf(item), 1);
}

/**
 * Converts an object in an array.
 * @param { object } object - The object to convert.
 * @param { boolean } includeObjectKey - Whether or not to include the keys of the object in the array. If set to
 * false, the value of each array item would be the value of each key, if set to true, each array item would be an
 * object. Defaults to false.
 * @return {Array } The object converted in an array.
 * @function
 */
function objToArray(object, includeObjectKey = false) {
	return Object.keys(object).map(function (key) {
		if (includeObjectKey) {
			return { [key]: object[key] };
		} else {
			return object[key];
		}
	});
}

/**
 * Returns a string with the unit of a given value. i.e: '100px' would return 'px'.
 * @param { string } value - The value to get the unit from (i.e. '100px').
 * @return { string } - The unit of the given value.
 * @function
 */
function getUnit(value) {
	if (value?.trim) {
		const match = value.trim().match(/[a-zA-Z%]+/);
		return match ? match[0] : null;
	}
	console.error('getUnit: value must be a string');
	return null;
}

/**
 * Returns the estimated image file size in bytes.
 * @param { number } width - The width of the image.
 * @param { number } height - The height of the image.
 * @param { number } colorDepth - The color depth of the image. Defaults to 24 [bits of color].
 * @param { number } compression - The compression rate of the image. Defaults to 20:1 => 0.05.
 * @return { number } - The image size in bytes.
 * @function
 */
function getImageSize(width, height, colorDepth = 24, compression = 0.05) {
	return (width * height * colorDepth) / 8 / compression;
}

/**
 * Converts a value between two data units.
 * @param { number } value - The value to convert.
 * @param { string } from - The unit of the value to convert from.
 * @param { string } to - The unit to convert the value to.
 * @param { boolean } binary - Whether or not to use Base 2 conversion.
 * @return { number } - The value converted.
 * @function
 */
function convertDataUnit(value, from, to, binary = false) {
	// parameters validation
	if (dataUnits.indexOf(to) < 0)
		throw new Error(`Couldn't resolve unit '${to}'`);
	else if (dataUnits.indexOf(from) < 0)
		throw new Error(`Couldn't resolve unit '${from}'`);
	else if (isNaN(parseInt(value))) throw new Error('value must be a number');

	if (dataUnits.indexOf(to) > dataUnits.indexOf(from)) {
		return (
			parseInt(value) /
			Math.pow(
				binary ? 1024 : 1000,
				dataUnits.indexOf(to) - dataUnits.indexOf(from)
			)
		);
	} else {
		return (
			parseInt(value) *
			Math.pow(
				binary ? 1024 : 1000,
				dataUnits.indexOf(from) - dataUnits.indexOf(to)
			)
		);
	}
}

/**
 * Adds one or multiple classes to an element.
 * @param { Element } element - The element to add the css class(es) to.
 * @param { string | string[] } cssClass - The css class(es) to be applied
 * to the element. The strings should be valid css classes. Warning: Cannot
 * contain whitespace.
 * @return { Element } The given element.
 * @function
 */
function addCssClass(element, cssClass) {
	if (Array.isArray(cssClass)) {
		if (
			cssClass.filter(str => {
				return str.indexOf(' ') > -1;
			}).length
		) {
			cssClass.forEach((str, idx) => {
				cssClass[idx] = removeSpaces(cssClass[idx]);
			});
			console.error(
				'cssClass cannot contain white spaces in addCssClass'
			);
		}

		element.classList.add(...cssClass);
	} else {
		if (cssClass.indexOf(' ') > -1) {
			cssClass = removeSpaces(cssClass);
			console.error(
				'cssClass cannot contain white spaces in addCssClass'
			);
		}

		element.classList.add(cssClass);
	}
	return element;
}

/**
 * Gets the first child of an element that isn't a textNode.
 * @param { Element } element - The element get the child from.
 * @return { Element } The first child of the given element excluding textNodes.
 * @function
 */
function getFirstChild(element) {
	var firstChild = element.firstChild;
	while (firstChild != null && firstChild.nodeType == 3) {
		firstChild = firstChild.nextSibling;
	}
	return firstChild;
}

/**
 * TODO: docs for this one
 * @param { Element } node - The element get the child from.
 * @param { object } data - The element get the child from.
 * @param { boolean } useBracketsSearch - The element get the child from.
 * @return { Element } The first child of the given element excluding textNodes.
 * @function
 */
function template(node, data, useBracketsSearch) {
	return node.innerHTML.replace(
		useBracketsSearch ? /%(\w*)%/g : /{(\w*)}/g, // or /{(\w*)}/g for "{this} instead of %this%"
		function (m, key) {
			return data.hasOwnProperty(key) ? data[key] : '';
		}
	);
}

/**
 * Creates a node element based on an options object
 * @param { CreateElementOptions } options - The options to config the creation of the element.
 * @return { Element } The element created with the given options.
 * @function
 */
function createElement(options) {
	options = {
		...CREATE_ELEMENT_DEFAULTS,
		...options
	};

	const element = document.createElement(options.tagName);
	if (options.className) element.className = options.className;
	if (options.id) element.setAttribute('id', options.className);
	if (options.attributes) {
		for (const attr in options.attributes) {
			if (options.attributes.hasOwnProperty(attr))
				element[attr] = options.attributes[attr];
		}
	}

	return element;
}

/**
 * Converts an object with properties topLeft, topRight, bottomLeft, and bottomRight into a CSS string.
 * @param {Object} propsObj - The object containing the properties topLeft, topRight, bottomLeft, and bottomRight.
 * @return {string} The CSS string representing the converted properties.
 */
function convertCornerPropsToCSS(propsObj) {
	const { topLeft, topRight, bottomLeft, bottomRight } = propsObj;
	return `${topLeft} ${topRight} ${bottomRight} ${bottomLeft}`;
}

/**
 * Converts a CSS string representing border radius into an object with properties topLeft, topRight, bottomLeft, and bottomRight.
 * @param {string} cssString - The CSS string representing border radius.
 * @return {Object} The object containing the properties topLeft, topRight, bottomLeft, and bottomRight.
 */
function convertCssStringToObj(cssString) {
	const values = cssString.split(' ');
	const defaultProps = {
		topLeft: '0',
		topRight: '0',
		bottomLeft: '0',
		bottomRight: '0'
	};

	if (values.length === 1) {
		return {
			...defaultProps,
			topLeft: values[0],
			topRight: values[0],
			bottomLeft: values[0],
			bottomRight: values[0]
		};
	} else if (values.length === 2) {
		return {
			...defaultProps,
			topLeft: values[0],
			topRight: values[1],
			bottomLeft: values[0],
			bottomRight: values[1]
		};
	} else if (values.length === 3) {
		return {
			...defaultProps,
			topLeft: values[0],
			topRight: values[1],
			bottomLeft: values[2],
			bottomRight: values[1]
		};
	} else if (values.length >= 4) {
		return {
			...defaultProps,
			topLeft: values[0],
			topRight: values[1],
			bottomRight: values[2],
			bottomLeft: values[3]
		};
	}

	return defaultProps;
}

/**
 * Returns a string without tabs or line-feed (newline) characters.
 * @param { string } str - The string to shallow.
 * @param { boolean } removeTabs - Whether or not to remove tabs. Defaults to true.
 * @return { string } The given string without tabs or line-feed (newline) characters.
 * @function
 */
function shallowString(str, removeTabs = true) {
	str = str.replace(/(\r\n|\n|\r)/gm, '');
	str = removeTabs ? str.replace(/\t/gm, '') : str;
	return str;
}

/**
 * Converts an object into an array recursively.
 * @param { object } val - The object to operate in.
 * @return { Array } The given object converted in array.
 * @function
 */
function propertiesToArray(val) {
	// By default (val is not object or array, return the original value)
	var result = val;
	// If object passed the result is the return value of Object.entries()
	if (typeof val === 'object' && !Array.isArray(val)) {
		result = Object.entries(val);
		// If one of the results is an array or object, run this function on them again recursively
		result.forEach(attr => {
			attr[1] = propertiesToArray(attr[1]);
		});
	}
	// If array passed, run this function on each value in it recursively
	else if (Array.isArray(val)) {
		val.forEach((v, i, a) => {
			a[i] = propertiesToArray(v);
		});
	}
	// Return the result
	return result;
}

/**
 * Searches for a given key recursively starting in a given object or array.
 * @param { object | Array } obj - The object to search the key in.
 * @param { string } key - The key to retrieve the value from.
 * @return { Any | null } The value for the given key if founded. Else returns null.
 * @function
 */
function searchObjectRecursively(obj, key) {
	if (typeof obj === 'object' && !Array.isArray(obj)) {
		if (obj[key]) {
			return obj[key];
		}
	} else if (Array.isArray(obj)) {
		let result;
		obj.forEach(item => {
			if (searchObjectRecursively(item, key)) {
				result = searchObjectRecursively(item, key);
			}
		});
		if (result) return result;
	}

	return;
}

/**
 * Removes a tag and its content from an HTML string.
 * @param { string } tagName - The tag to remove.
 * @param { string } str - The string to remove from.
 * @return { string } The given HTML string without the tag and its content.
 * @function
 */
function removeHtmlByTag(tagName, str) {
	const regexBody = /(\d*?|\D*?)/gm;
	const regex = new RegExp(
		'<' + tagName + '>' + regexBody.source + '</' + tagName + '>',
		'gm'
	);
	return str.replace(regex, '');
}

/**
 * A simple event emitter class that allows subscribing to
 * and emitting events.
 */
class EventEmitter {
	/**
	 * Constructs a new EventEmitter instance.
	 */
	constructor() {
		/**
		 * A dictionary that stores event names as keys,
		 * and an array of associated callbacks as values.
		 * @type {Object.<string, Function[]>}
		 * @private
		 */
		this.events = {};
	}

	/**
	 * Subscribes to an event with the provided callback.
	 * @param {string} eventName - The name of the event to subscribe to.
	 * @param {Function} callback - The callback function to be executed
	 * when the event is emitted.
	 */
	on(eventName, callback) {
		if (!this.events[eventName]) {
			this.events[eventName] = [];
		}
		this.events[eventName].push(callback);
	}

	/**
	 * Unsubscribes from an event with the provided callback.
	 * @param {string} eventName - The name of the event to unsubscribe from.
	 * @param {Function} callback - The callback function to be removed.
	 */
	off(eventName, callback) {
		if (!this.events[eventName]) {
			return;
		}
		this.events[eventName] = this.events[eventName].filter(
			cb => cb !== callback
		);
	}

	/**
	 * Emits an event with the provided arguments, triggering all
	 * associated callbacks.
	 * @param {string} eventName - The name of the event to emit.
	 * @param {...*} args - Arguments to be passed to the event callbacks.
	 */
	emit(eventName, ...args) {
		if (!this.events[eventName]) {
			return;
		}
		this.events[eventName].forEach(callback => {
			callback(...args);
		});
	}
}

/**
 * Copies the specified text to the clipboard using the Clipboard API.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @return {Promise<void>} - A Promise that resolves when the text is successfully copied to the clipboard.
 *                            If there's an error, the Promise is rejected with an error message.
 *
 * @example
 * // Usage example:
 * copyToClipboard('Hello, world!').then(() => {
 *   console.log('Text copied to clipboard.');
 * }).catch((error) => {
 *   console.error('Failed to copy text:', error);
 * });
 */
function copyToClipboard(text) {
	return navigator.clipboard.writeText(text);
}

/**
 * Recursively compares two JavaScript objects and logs the keys and values that are different.
 *
 * @param {Object} obj1 - The first JavaScript object to compare.
 * @param {Object} obj2 - The second JavaScript object to compare.
 * @param {string[]} [path=[]] - An optional array to keep track of the current path during recursion.
 * @return {boolean} Whether obj1 and obj2 are equal. Would return true if there's any difference between them.
 */
function deepCompareAndLog(obj1, obj2, path = []) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	const allKeys = new Set([...keys1, ...keys2]);
	let isEqual = true;

	for (const key of allKeys) {
		const newPath = path.concat(key);
		const value1 = obj1[key];
		const value2 = obj2[key];

		if (typeof value1 === 'object' && typeof value2 === 'object') {
			isEqual = deepCompareAndLog(value1, value2, newPath);
		} else {
			if (value1 !== value2) {
				console.log(`Difference found at path: ${newPath.join('.')}`);
				console.log(`Value 1: ${value1}`);
				console.log(`Value 2: ${value2}`);
				isEqual = false;
			}
		}
	}
	return isEqual;
}

/**
 * Finds the differences between two objects or arrays and logs them to the console.
 * @param {Object|Array} obj1 - The first object or array to compare.
 * @param {Object|Array} obj2 - The second object or array to compare.
 */
function findDifferences(obj1, obj2) {
	const stack = [{ obj1, obj2 }];
	const diffs = [];

	while (stack.length > 0) {
		const { obj1, obj2 } = stack.pop();

		// Check if the objects are arrays
		const isArray = Array.isArray(obj1) && Array.isArray(obj2);

		// Check if the objects are equal
		if (isEqual(obj1, obj2)) {
			continue;
		}

		// Log the differences
		if (isArray) {
			obj1.forEach((item, index) => {
				stack.push({ obj1: item, obj2: obj2[index] });
			});
		} else {
			Object.keys(obj1).forEach(key => {
				if (!isEqual(obj1[key], obj2[key])) {
					diffs.push({ key, obj1: obj1[key], obj2: obj2[key] });
				}
			});
		}
	}

	if (diffs.length) console.log('Differences:');
	diffs.forEach(({ key, obj1, obj2 }) => {
		console.log(`Key: ${key}`);
		console.log('Object 1:', obj1);
		console.log('Object 2:', obj2);
	});
}

/**
 * Removes all occurrences of a specified word (substring) from a given string.
 *
 * @param {string} inputString - The input string from which to remove the word.
 * @param {string} substring - The substring to be removed from the input string.
 * @param {boolean} isWord - If the substring to be removed is a word or a simple substring.
 * @param {number} occurrences - The number of times it should do the removal (all by default). @default -1
 * @return {string} The modified string with the specified word removed.
 *
 * @example
 * const inputString = "This is a sample sentence with a word to remove.";
 * const wordToRemove = "word";
 * const modifiedString = removeWord(inputString, wordToRemove);
 * console.log(modifiedString);
 * // Output: "This is a sample sentence with a to remove."
 */
function removeWord(inputString, substring, isWord = false, occurrences = -1) {
	const regex = new RegExp(isWord ? `\\b${substring}\\b` : substring, 'gi');
	let resultString;

	if (occurrences < 1) {
		resultString = inputString.replace(regex, '');
	} else {
		const matches = inputString.match(regex);
		for (let i = 0; i < occurrences; i++) {
			inputString = removeFirstSubstring(inputString, matches[i]);
		}
		resultString = inputString;
	}

	return resultString;
}

/**
 * Removes the first occurrence of a substring from a string using a regular expression.
 * @param {string} inputString - The input string to remove the substring from.
 * @param {string} substring - The substring to remove from the input string.
 * @return {string} The output string with the first occurrence of the substring removed.
 */
function removeFirstSubstring(inputString, substring) {
	const regex = new RegExp(substring);
	const index = inputString.search(regex);
	if (index !== -1) {
		return (
			inputString.slice(0, index) +
			inputString.slice(index + substring.length)
		);
	} else {
		return inputString;
	}
}

/**
 * Removes quotation marks from a string.
 * @param {string} inputString - The string to remove quotation marks from.
 * @return {string} The input string without quotation marks.
 */
function unquoteString(inputString) {
	return inputString.replace(/['"]+/g, '');
}

/**
 * Converts a string of HTML into a DOM element.
 *
 * @param {string} html - The HTML string to convert.
 * @param {Object} [options] - An optional object containing additional options.
 * @param {boolean} [options.returnMultiple=false] - Whether to return multiple elements or just the first one.
 * @return {Element} The resulting DOM element.
 */
function htmlToElement(html, options = {}) {
	const defaults = {
		returnMultiple: false
	};

	options = merge(defaults, options);

	const template = document.createElement('template');
	template.innerHTML = html.trim();
	return options.returnMultiple
		? template.content.childNodes
		: template.content.firstChild;
}

/**
 * Sanitizes a URL by checking if it is a supported URL protocol.
 * If the URL is not supported, it returns 'about:blank'.
 * @param {string} url - The URL to sanitize.
 * @return {string} - The sanitized URL.
 */
function sanitizeUrl(url) {
	try {
		const parsedUrl = new URL(url);
		// eslint-disable-next-line no-script-url
		if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
			return 'about:blank';
		}
	} catch {
		return url;
	}
	return url;
}

/**
 * Validates a URL.
 * @param {string} url - The URL to be validated.
 * @return {boolean} - Returns true if the URL is valid, otherwise false.
 */
function validateUrl(url) {
	return url === 'https://' || urlRegExp.test(url);
}

/**
 * Returns a string with the valid unit of a given value or the default unit if the unit cannot be resolved.
 *
 * @param {string} value - The string containing the unit.
 * @param {Object} options - The options for resolving the unit.
 * @param {Array<string>} options.validUnits - An array of valid units.
 * @param {string|null} options.defaultUnit - The default unit to return if the unit cannot be resolved.
 * @param {boolean} options.isDebug - Flag indicating whether to log debug information.
 * @param {string} options.errorMessage - The error message to log if the unit cannot be resolved.
 * @return {string|null} The resolved unit or the default unit if the unit cannot be resolved.
 */
function getValidUnit(value, options) {
	const defaultOptions = {
		validUnits: CssUnits,
		defaultUnit: null,
		isDebug: false,
		errorMessage: `Value should contain a valid unit or a default unit should be provided, ${value}`
	};
	options = merge(defaultOptions, options);

	const extractedUnit = getUnit(value);
	if (
		extractedUnit &&
		isValidUnit(
			extractedUnit,
			merge(options, {
				errorMessage: `getValidUnit: Unit is not valid, ${extractedUnit}`
			})
		)
	) {
		return extractedUnit;
	}

	if (options.isDebug) console.error('getValidUnit:', options.errorMessage);
	return options.defaultUnit;
}

/**
 * Checks if a value contains a valid number or one of the acceptable values.
 *
 * @param {string|number} value - The value to be checked.
 * @param {Object} options - The options for validation.
 * @param {Array} options.acceptableValues - An array of acceptable values.
 * @param {boolean} options.isDebug - A flag indicating whether to enable debug mode.
 * @param {string} options.errorMessage - The error message to be displayed if the value is invalid.
 * @return {boolean} - Returns true if the value has a valid value or is one of the acceptable values, otherwise returns false.
 */
function isValidValue(value, options) {
	const defaultOptions = {
		acceptableValues: null,
		isDebug: false,
		errorMessage: `Value doesn't contain a valid number or is not one of the acceptable values, ${value}`
	};
	options = merge(defaultOptions, options);

	if (
		options.acceptableValues?.includes(value) ||
		(!isNaN(parseFloat(value)) && !String(value).includes('NaN'))
	) {
		return true;
	}

	if (options.isDebug) {
		console.error('isValidValue:', options.errorMessage);
	}

	return false;
}

/**
 * Checks if a value is a valid dimension or one of the acceptable values.
 *
 * @param {string} value - The value to be checked.
 * @param {Object} options - The options object.
 * @param {Array} options.acceptableValues - An array of acceptable values.
 * @param {Array} options.validUnits - An array of valid units.
 * @param {boolean} options.isDebug - Flag to enable debug mode.
 * @param {string} options.errorMessage - The error message to be displayed.
 * @return {boolean} - Returns true if the value is a valid dimension or one of the acceptable values, otherwise false.
 */
function isValidDimension(value, options) {
	const defaultOptions = {
		acceptableValues: null,
		validUnits: CssUnits,
		isDebug: false,
		errorMessage: `Value should be a valid dimension or one of the acceptable values, ${value}`
	};
	options = merge(defaultOptions, options);

	if (
		isValidValue(value, options) &&
		(options.acceptableValues?.includes(value) ||
			isValidUnit(getUnit(value), options))
	) {
		return true;
	}

	if (options.isDebug)
		console.error('isValidDimension:', options.errorMessage);

	return false;
}

/**
 * Checks if a given unit is a valid unit. By default, all CSS units are considered valid.
 *
 * @param {string} unit - The unit to be checked.
 * @param {Object} options - The options for the validation.
 * @param {string[]} options.validUnits - An array of valid units. All CSS units are valid by default.
 * @param {boolean} [options.isDebug=false] - Whether to log an error message to the console in case of an invalid unit.
 * @param {string} [options.errorMessage] - The error message to be logged in case of an invalid unit.
 * @return {boolean} - Returns true if the unit is valid, false otherwise.
 */
function isValidUnit(unit, options) {
	const defaultOptions = {
		validUnits: CssUnits,
		isDebug: false,
		errorMessage: `Unit should be one of the valid units, ${unit}`
	};
	options = merge(defaultOptions, options);

	if (!options.validUnits || options.validUnits.includes(unit)) return true;

	if (options.isDebug) console.error('isValidUnit:', options.errorMessage);

	return false;
}

/**
 * Inserts an object after every item in the array except the last one.
 *
 * @param {Array} arr - The input array.
 * @param {*} obj - The object to be inserted after every item.
 * @return {Array} - The modified array with the object inserted after every item but the last.
 *
 * @example
 * // Example usage:
 * let originalArray = [1, 2, 3];
 * let newArray = insertAfterEveryItem(originalArray, 0);
 * console.log(newArray); // Output: [1, 0, 2, 0, 3]
 */
function insertAfterEveryItem(arr, obj) {
	return arr.reduce((result, current, index, array) => {
		result.push(current);
		if (index < array.length - 1) {
			result.push(obj);
		}
		return result;
	}, []);
}

/**
 * Checks for circular dependencies in a JavaScript object.
 *
 * @param {Object} obj - The object to check for circular dependencies.
 * @param {Set<Object>} [seenObjects=new Set()] - A set to keep track of visited objects (used internally for recursion).
 * @param {string} [currentPath=''] - The current path in the object hierarchy (used internally for logging).
 * @return {boolean} - Returns `true` if a circular dependency is found, otherwise `false`.
 *
 * @example
 * const obj1 = { name: 'Object 1' };
 * const obj2 = { name: 'Object 2', child: obj1 };
 * obj1.parent = obj2;
 *
 * const hasCircularDep = hasCircularDependency(obj1);
 * if (!hasCircularDep) {
 *   console.log('No circular dependency found.');
 * }
 */
function hasCircularDependency(obj, seenObjects = new Set(), currentPath = '') {
	if (typeof obj !== 'object' || obj === null) {
		// Not an object, no circular dependency
		return false;
	}

	if (seenObjects.has(obj)) {
		console.log(`Circular dependency found at path: ${currentPath}`);
		return true;
	}

	seenObjects.add(obj);

	for (const key in obj) {
		if (!obj.hasOwnProperty(key)) continue;
		const nextPath = currentPath ? `${currentPath}.${key}` : key;
		if (hasCircularDependency(obj[key], seenObjects, nextPath)) {
			return true;
		}
	}

	seenObjects.delete(obj);
	return false;
}

/**
 * Merges multiple objects into a single object without mutating any of them.
 *
 * @param {...Object} sources - The objects to merge.
 * @return {Object} - The merged object.
 */
function merge(...sources) {
	return _merge({}, ...sources);
}

/**
 * Merges multiple objects into a single object using a customizer function.
 *
 * @param {Object} object - The destination object to merge into.
 * @param {...Object} sources - The objects to merge.
 * @param {Function} customizer - The function to customize the merging behavior. Should return the value
 * to keep. If it returns undefined, the default merging behavior will be used.
 * @return {Object} - A new object resulting of the merged objects.
 *
 * @example
 * const object = { a: [1,2,3] };
 * const source1 = { a: [9,8] };
 *
 * console.log(mergeWith(object, source1));
 * // Output: { a: [9, 8, 3] }
 *
 * console.log(mergeWith({a: arr1}, {a:arr2}, (objValue, srcValue) => {
 *   if (Array.isArray(objValue)) {
 *     return objValue.concat(srcValue);
 *   }
 * }));
 * // Output: { a: [1, 2, 3, 9, 8] }
 *
 * console.log(mergeWith({a: arr1}, {a:arr2}, (objValue, srcValue) => {
 *   if (Array.isArray(objValue)) {
 *     return objValue;
 *   }
 * }));
 * // Output: { a: [1, 2, 3] }
 *
 * console.log(mergeWith({a: arr1}, {a:arr2}, (objValue, srcValue) => {
 *   if (Array.isArray(objValue)) {
 *     return srcValue;
 *   }
 * }));
 * // Output: { a: [9, 8] }
 */
function mergeWith(object, ...sources) {
	return _mergeWith({}, object, ...sources);
}

/**
 * Checks if the given value is an array.
 *
 * @param {*} obj - The value to check.
 * @return {boolean} - Returns true if the value is an array, otherwise returns false.
 */
function isArray(obj) {
	return Array.isArray(obj);
}

/**
 * Checks if the given value is an object.
 *
 * @param {*} obj - The value to be checked.
 * @return {boolean} - Returns true if the value is an object, otherwise returns false.
 */
function isObject(obj) {
	return typeof obj === 'object';
}

/**
 * Checks if the given value is a function.
 *
 * @param {any} obj - The value to check.
 * @return {boolean} - Returns true if the value is a function, otherwise returns false.
 */
function isFunction(obj) {
	return typeof obj === 'function';
}

/**
 * Executes a provided function once for each array element.
 *
 * @param {Array} array - The array to iterate over.
 * @param {Function} callback - The function to execute for each element.
 * @return {Array} - The original array (can be mutated).
 */
function forEach(array, callback) {
	array.forEach(callback);
	return array;
}

/**
 * Checks if a value is undefined.
 * @param {*} obj - The value to check.
 * @return {boolean} - Returns true if the value is undefined, otherwise returns false.
 */
function isUndefined(obj) {
	return typeof obj === 'undefined';
}

/**
 * Checks if a value is a boolean.
 * @param {any} obj - The value to check.
 * @return {boolean} - Returns true if the value is a boolean, otherwise returns false.
 */
function isBoolean(obj) {
	return typeof obj === 'boolean';
}

/**
 * Checks if the given value is a string.
 *
 * @param {*} obj - The value to check.
 * @return {boolean} - Returns true if the value is a string, otherwise returns false.
 */
function isString(obj) {
	return typeof obj === 'string';
}

/**
 * Copies attributes from the source element to the target element.
 * @param {Element} sourceElement - The source element to copy attributes from.
 * @param {Element} targetElement - The target element to copy attributes to.
 * @return {Element} - The target element with copied attributes.
 */
function copyAttributes(sourceElement, targetElement) {
	Array.from(sourceElement.attributes).forEach(attr => {
		targetElement.setAttribute(attr.name, attr.value);
	});

	return targetElement;
}

/**
 * Checks if a string contains HTML tags.
 * @param {string} str - The string to check.
 * @return {boolean} - True if the string contains HTML tags, false otherwise.
 */
function isHTML(str) {
	var doc = new DOMParser().parseFromString(str, 'text/html');
	return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

/**
 * Retrieves the HTML tags from the given HTML string.
 * @param {string} htmlString - The HTML string to parse.
 * @param {boolean} onlyFirstLevel - Indicates whether to retrieve only the tags from the first level of the HTML structure.
 * @return {string[]} - An array of lowercase tag names.
 */
function getHTMLTags(htmlString, onlyFirstLevel) {
	var doc = new DOMParser().parseFromString(htmlString, 'text/html');
	if (onlyFirstLevel) {
		return Array.from(doc.body.childNodes)
			.filter(node => node.nodeType === 1) // Node.ELEMENT_NODE
			.map(node => node.tagName.toLowerCase());
	} else {
		var allElements = doc.getElementsByTagName('*');
		return Array.from(allElements).map(node => node.tagName.toLowerCase());
	}
}

/**
 * Converts an object into a string of CSS variables.
 * @param {Object} obj - The object to convert.
 * @return {string} - The string of CSS variables.
 */
function objToCSSVariables(obj) {
	let cssVariables = '';

	for (const key in obj) {
		if (typeof obj[key] === 'object') {
			for (const subKey in obj[key]) {
				if (obj[key].hasOwnProperty(subKey))
					cssVariables += `    --${subKey}-${key}: '${obj[key][subKey]}';\n`;
			}
		} else {
			cssVariables += `    --${key}: ${obj[key]};\n`;
		}
	}

	return cssVariables;
}

/**
 * Checks if all elements in an array are equal.
 *
 * @param {Array} arr - The array to check.
 * @return {boolean} - Returns true if all elements are equal, false otherwise.
 */
function allEqual(arr) {
	return arr.every(val => val === arr[0]);
}

/**
 * Maps the properties of an object using a provided mapping object.
 * @param {Object} obj - The object to be mapped.
 * @param {Object} map - The mapping object containing the property mappings.
 * @param {boolean} [keepUnmapped=true] - Whether to keep the unmapped properties in the resulting object.
 * @return {Object} - The mapped object.
 */
function mapObject(obj, map, keepUnmapped = true) {
	const result = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && map.hasOwnProperty(key)) {
			result[map[key]] = obj[key];
		} else if (keepUnmapped && obj.hasOwnProperty(key)) {
			result[key] = obj[key];
		}
	}
	return result;
}

/**
 * Checks if a color is valid.
 * @param {string} color - The color to be checked.
 * @param {Object} options - The options for the function.
 * @param {boolean} options.returnColor - Whether to return the parsed color or just a boolean indicating validity. Default is true.
 * @return {string|boolean} - The parsed color if returnColor is true and the color is valid, otherwise false.
 */
function isValidColor(color, { returnColor = true } = {}) {
	try {
		const parsedColor = toHex(color);
		if (parsedColor) {
			return returnColor ? parsedColor : true;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
}

/**
 * Adds one or more elements to the beginning of an array and returns the new length of the array.
 *
 * @param {Array} arr - The array to modify.
 * @param {...*} items - The elements to add to the beginning of the array.
 * @return {number} - The new length of the array.
 */
function arrayUnshift(arr, ...items) {
	return items.concat(arr);
}

/**
 * Checks if a string ends with a specified suffix.
 * @param {string} str - The string to check.
 * @param {string} suffix - The suffix to check for.
 * @param {boolean} caseSensitive - Whether the comparison should be case-sensitive.
 * @return {boolean} - True if the string ends with the suffix, false otherwise.
 */
function endsWith(str, suffix, caseSensitive) {
	if (caseSensitive) {
		return str.indexOf(suffix, str.length - suffix.length) !== -1;
	} else {
		return (
			str
				.toLowerCase()
				.indexOf(suffix.toLowerCase(), str.length - suffix.length) !==
			-1
		);
	}
}

/**
 * Converts a CSS value containing sides (i.e. margins or paddings) to
 * object notation.
 *
 * @param {string} string - The CSS sides string to convert.
 * @return {Object} - An object with individual side values (top, right, bottom, left).
 *
 * @example
 * // returns {top: 10, right: 10, bottom: 10, left: 10}
 * cssSidesToObject("10px");
 *
 * @example
 * // returns {top: 10, right: 20, bottom: 10, left: 20}
 * cssSidesToObject("10px 20px");
 *
 * @example
 * // returns {top: 10, right: 20, bottom: 30, left: 20}
 * cssSidesToObject("10px 20px 30px");
 *
 * @example
 * // returns {top: 10, right: 20, bottom: 30, left: 40}
 * cssSidesToObject("10px 20px 30px 40px");
 */
function cssSidesToObject(string) {
	const values = string.split(' ').map(p => parseFloat(p));

	let top;
	let right;
	let bottom;
	let left;
	if (values.length === 1) {
		[top, right, bottom, left] = [
			values[0],
			values[0],
			values[0],
			values[0]
		];
	} else if (values.length === 2) {
		[top, bottom] = [values[0], values[0]];
		[right, left] = [values[1], values[1]];
	} else if (values.length === 3) {
		[top, right, left] = [values[0], values[1], values[1]];
		bottom = values[2];
	} else if (values.length === 4) {
		[top, right, bottom, left] = values;
	}

	return { top, right, bottom, left };
}

/**
 * Converts a CSS value containing corners shorthand or full notation to
 * object notation.
 *
 * @param {string} string - The CSS string to convert.
 * @return {Object} - An object with individual corner values (topLeft, topRight, bottomRight, bottomLeft).
 *
 * @example
 * // returns {topLeft: 10, topRight: 10, bottomRight: 10, bottomLeft: 10}
 * cssCornersToObject("10px");
 *
 * @example
 * // returns {topLeft: 10, topRight: 20, bottomRight: 10, bottomLeft: 20}
 * cssCornersToObject("10px 20px");
 *
 * @example
 * // returns {topLeft: 10, topRight: 20, bottomRight: 30, bottomLeft: 20}
 * cssCornersToObject("10px 20px 30px");
 *
 * @example
 * // returns {topLeft: 10, topRight: 20, bottomRight: 30, bottomLeft: 40}
 * cssCornersToObject("10px 20px 30px 40px");
 */
function cssCornersToObject(string) {
	const values = string.split(' ').map(p => Number(p.replace('px', '')));

	let topLeft;
	let topRight;
	let bottomRight;
	let bottomLeft;
	if (values.length === 1) {
		[topLeft, topRight, bottomRight, bottomLeft] = [
			values[0],
			values[0],
			values[0],
			values[0]
		];
	} else if (values.length === 2) {
		[topLeft, bottomRight] = [values[0], values[0]];
		[topRight, bottomLeft] = [values[1], values[1]];
	} else if (values.length === 3) {
		[topLeft, topRight, bottomLeft] = [values[0], values[1], values[1]];
		bottomRight = values[2];
	} else if (values.length === 4) {
		[topLeft, topRight, bottomRight, bottomLeft] = values;
	}

	return { topLeft, topRight, bottomRight, bottomLeft };
}

/**
 * Calculates the average of an array of numbers.
 *
 * @param {Array} arr - The array of numbers.
 * @param {number} [decimalPlaces=0] - The number of decimal places for the average result.
 * @return {number} The average of the array.
 *
 * @example
 * // returns 3
 * calculateAverage([1, 2, 3, 4, 5]);
 *
 * @example
 * // returns 3.33
 * calculateAverage([1, 2, 3, 4, 5], 2);
 *
 * @example
 * // returns 2
 * calculateAverage(['1', '2', '3']);
 */
function arrayAvg(arr, decimalPlaces = 0) {
	if (!Array.isArray(arr)) {
		throw new Error('The first argument must be an array.');
	}

	if (typeof decimalPlaces !== 'number') {
		throw new Error('The second argument must be a number.');
	}

	const numbers = arr.map(Number);
	const sum = numbers.reduce((a, b) => a + b, 0);
	const average = sum / numbers.length;

	return Number(average.toFixed(decimalPlaces));
}

/**
 * Calculates the average of a list of numbers.
 *
 * @param {...number} numbers - The numbers to calculate the average of.
 * @return {number} The average of the numbers.
 */
function avg(...numbers) {
	return arrayAvg(numbers, 2);
}

/**
 * Converts a CSS style object to a string representation.
 *
 * @param {Object} style - The CSS style object to convert.
 * @param {Object} options - The options for the conversion.
 * @param {boolean} [options.convertToKebabCase=true] - Whether to convert CSS property names to kebab case.
 * @param {boolean} [options.trim=true] - Whether to trim the resulting string.
 * @return {string} The string representation of the CSS style object.
 *
 * @example
 * const style = {
 *   backgroundColor: 'red',
 *   fontSize: '16px',
 *   fontWeight: 'bold'
 * };
 *
 * const options = {
 *   convertToKebabCase: true,
 *   trim: false
 * };
 *
 * const result = cssStyleObjectToString(style, options);
 * console.log(result);
 * // Output: "background-color: red; font-size: 16px; font-weight: bold;"
 */
function cssStyleObjectToString(style, options) {
	const defaults = {
		convertToKebabCase: true,
		trim: true
	};

	options = merge(defaults, options);

	const { convertToKebabCase, trim } = options;

	let result = '';
	for (const prop in style) {
		if (style.hasOwnProperty(prop)) {
			result += `${convertToKebabCase ? kebabCase(prop) : prop}: ${
				style[prop]
			}; `;
		}
	}
	return trim ? result.trim() : result;
}

/**
 * Parses a CSS style string into an object.
 *
 * @param {string} styleString - The CSS style string.
 * @return {Object} The parsed style object.
 */
function parseStyleString(styleString) {
	const styleObject = {};
	const declarations = styleString.split(';');

	declarations.forEach(declaration => {
		if (declaration.trim()) {
			const [property, value] = declaration.split(':');
			if (property && value) {
				styleObject[property.trim()] = value.trim();
			}
		}
	});

	return styleObject;
}

/**
 * Deletes a given property or an array of properties from an object.
 * @param {Object} obj - The object from which to delete properties.
 * @param {(string|string[])} props - The property or properties to delete.
 * @param {boolean} [mutate=true] - Whether to mutate the original object or return a new one.
 * @return {Object} - The object after deleting the properties.
 * @throws {TypeError} - If the first argument is not an object or the second argument is not a string or an array of strings.
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * deleteProps(obj, 'a', false); // returns a new object { b: 2, c: 3 }, obj is unchanged
 * deleteProps(obj, ['b', 'c'], true); // returns obj { a: 1 }, obj is changed
 */
function deleteProps(obj, props, mutate = true) {
	if (typeof obj !== 'object' || obj === null) {
		throw new TypeError('First argument must be an object.');
	}
	if (typeof props === 'string') {
		if (!mutate) {
			obj = { ...obj };
		}
		if (props in obj) {
			delete obj[props];
		}
	} else if (Array.isArray(props)) {
		if (!mutate) {
			obj = { ...obj };
		}
		props.forEach(prop => {
			if (typeof prop !== 'string') {
				throw new TypeError(
					'All elements in the array must be strings.'
				);
			}
			if (prop in obj) {
				delete obj[prop];
			}
		});
	} else {
		throw new TypeError(
			'Second argument must be a string or an array of strings.'
		);
	}
	return obj;
}

/**
 * Set the style of a DOM node with a JavaScript object.
 *
 * @param {Node} node - The DOM node to style.
 * @param {Object} styleObj - The style object.
 *
 * @example
 * // Change the color and background color of a node with kebab-case properties
 * const node = document.querySelector('#myNode');
 * setNodeStyle(node, { color: 'red', 'background-color': 'black' });
 *
 * @example
 * // Change the color and background color of a node with camel-case properties
 * const node = document.querySelector('#myNode');
 * setNodeStyle(node, { fontWeight: 600, 'backgroundColor': 'black' });
 */
function setNodeStyle(node, styleObj) {
	Object.keys(styleObj).forEach(key => {
		node.style[camelCase(key)] = styleObj[key];
	});
}

/**
 * Returns the maximum value among the passed arguments.
 * If a string containing a number is passed, it will be converted to a number.
 *
 * @param  {...any} args - The arguments to compare.
 * @return {number} The maximum value among the passed arguments.
 *
 * @example
 * // returns 5
 * max(1, '2', 3, '5', 4);
 *
 * @example
 * // returns 10
 * max('1', '10', '3', '5', '4');
 */
function max(...args) {
	return Math.max(...args.map(arg => Number(arg)));
}

/**
 * Creates and returns a comment with some content to be rendered conditionally in Outlook.
 *
 * @param {string} content - The content to be rendered inside the comment.
 * @return {Comment} The created comment element.
 */
function outlookConditionalRenderComment(content) {
	return document.createComment(`[if mso]>${content}<![endif]`);
}

/**
 * Checks if the given object is a comment node.
 *
 * @param {Object} obj - The object to check.
 * @return {boolean} - Returns true if the object is a comment node, otherwise returns false.
 */
function isComment(obj) {
	return typeof Comment === 'object'
		? obj instanceof Comment
		: obj && typeof obj === 'object' && obj !== null && obj.nodeType === 8;
}

/**
 * This function is used to show an element.
 *
 * @param {Object} obj - The object that needs to be shown.
 * @return {Element} - The element that was shown or null if the object is not an element.
 */
function showEl(obj) {
	const el = isElement(obj)
		? obj
		: typeof obj.getEl === 'function'
		? obj.getEl()
		: null;
	if (el && el.classList) {
		el.classList.remove('eb-hidden');
	} else {
		console.warn('obj is not an element');
	}
	return el;
}

/**
 * This function is used to hide an element.
 *
 * @param {Object} obj - The object that needs to be hidden.
 * @return {Element} - The element that was shown or null if the object is not an element.
 */
function hideEl(obj) {
	const el = isElement(obj)
		? obj
		: typeof obj.getEl === 'function'
		? obj.getEl()
		: null;
	if (el && el.classList) {
		el.classList.add('eb-hidden');
	} else {
		console.warn('obj is not an element');
	}
	return el;
}

/**
 * Compares two version strings.
 *
 * @param {string} v1 - The first version string.
 * @param {string} v2 - The second version string.
 * @return {number} - Returns -1 if v1 < v2, 1 if v1 > v2, and 0 if they are equal.
 */
function compareVersions(v1, v2) {
	const parts1 = v1.split('.').map(Number);
	const parts2 = v2.split('.').map(Number);
	const maxLength = Math.max(parts1.length, parts2.length);

	for (let i = 0; i < maxLength; i++) {
		const part1 = parts1[i] || 0;
		const part2 = parts2[i] || 0;

		if (part1 > part2) return 1;
		if (part1 < part2) return -1;
	}

	return 0;
}

/**
 * Removes single quotes from a string.
 *
 * @param {string} str - The input string.
 * @return {string} The string with single quotes removed.
 *
 * @example
 * // Returns "Hello World"
 * removeSingleQuotes("'Hello World'");
 *
 * @example
 * // Returns "This is a test"
 * removeSingleQuotes("This is a test'");
 *
 * @example
 * // Returns "I'm happy"
 * removeSingleQuotes("I'm happy");
 */
function removeSingleQuotes(str) {
	return str.replace(/^'|'$/g, '');
}

/**
 * Wraps the provided content in an MSO conditional comment if `outlookDebug` is false.
 *
 * @param {string} content - The content to be conditionally wrapped.
 * @param {boolean} [outlookDebug=false] - Flag to determine if the content should be wrapped in an MSO conditional comment.
 * @return {string} - The original content or the content wrapped in an MSO conditional comment.
 */
function renderMsoConditional(content, outlookDebug = false) {
	return outlookDebug ? content : `<!--[if mso]>${content}<![endif]-->`;
}

/**
 * Renders content conditionally based on whether the email client is Microsoft Outlook.
 *
 * @param {string} content - The HTML content to be conditionally rendered.
 * @param {boolean} [outlookDebug=false] - Flag to determine if the content should be rendered for debugging in Outlook.
 * @return {string} - The conditional HTML content.
 */
function renderNotMsoConditional(content, outlookDebug = false) {
	return outlookDebug ? '' : `<!--[if !mso]><!-->${content}<!--<![endif]-->`;
}

/**
 * Converts a CSSStyleDeclaration object to a JavaScript object.
 *
 * @param {CSSStyleDeclaration} style - The CSSStyleDeclaration object to convert.
 * @return {Object} - The converted JavaScript object.
 *
 * @example
 * const style = window.getComputedStyle(element);
 * const styleObj = cssStyleDeclarationToObject(style);
 * console.log(styleObj);
 * // Output: { backgroundColor: "red", fontSize: "16px", fontWeight: "bold" }
 */
function cssStyleDeclarationToObject(style) {
	const styleObj = {};
	for (let i = 0; i < style.length; i++) {
		const prop = style[i];
		styleObj[prop] = style.getPropertyValue(prop);
	}
	return styleObj;
}

export {
	removeFirstSubstring,
	functionHasBody,
	svgToBase64,
	downloadFile,
	addCssClass,
	addClassesString,
	trimAll,
	convertDataUnit,
	getImageSize,
	getUnit,
	objToArray,
	arrayMove,
	splitArrayIntoParts,
	arrayRemove,
	removeSpaces,
	getCaretElement,
	isElement,
	htmlToPlainText,
	camelCase,
	toBoolean,
	getFirstChild,
	template,
	createElement,
	shallowString,
	propertiesToArray,
	searchObjectRecursively,
	removeHtmlByTag,
	getElementWidthPercentage,
	setContent,
	throttle,
	debounce,
	convertCornerPropsToCSS,
	convertCssStringToObj,
	copyToClipboard,
	elementAddEvent,
	deepCompareAndLog,
	findDifferences,
	removeWord,
	htmlToElement,
	unquoteString,
	sanitizeUrl,
	validateUrl,
	getValidUnit,
	isValidValue,
	isValidDimension,
	isValidUnit,
	insertAfterEveryItem,
	hasCircularDependency,
	merge,
	mergeWith,
	isArray,
	isObject,
	isFunction,
	forEach,
	isUndefined,
	isBoolean,
	isString,
	copyAttributes,
	isHTML,
	getHTMLTags,
	objToCSSVariables,
	allEqual,
	mapObject,
	isValidColor,
	arrayUnshift,
	endsWith,
	cssSidesToObject,
	cssCornersToObject,
	arrayAvg,
	avg,
	cssStyleObjectToString,
	parseStyleString,
	deleteProps,
	setNodeStyle,
	max,
	outlookConditionalRenderComment,
	isComment,
	compareVersions,
	removeSingleQuotes,
	cssStyleDeclarationToObject,
	EventEmitter,
	dataUnits,
	CssUnits,
	borderStyles,
	genericFonts,
	webSafeFonts,
	windowsFonts,
	webFonts,
	cssFontWeights,
	showEl,
	hideEl,
	renderMsoConditional,
	renderNotMsoConditional
};
