import {
	addClassesString,
	setContent,
	isElement,
	EventEmitter
} from 'datatalks-utils';
import { DropdownItem } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import { merge } from 'lodash-es';
import { computePosition, flip, offset } from '@floating-ui/dom';

/**
 * The options to configure the Dropdown
 * @typedef { Object } DropdownOptions
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string= } cssClass - The base CSS class of the wrapper element of
 * the Dropdown. @default 'dropdown'
 * @property { Array<(DropdownItem | DropdownItemOptions)> } items
 * An array of items to use inside the dropdown (as dropdown options). @default []
 * @property { string= } extendedClasses - CSS classes to extend the style
 * of the Dropdown. @default ''
 * @property { (DropdownItem | DropdownItemOptions)= } activeItem
 * The item that should start active. @default null
 * @property { (string | HTMLString)= } placeholder - The placeholder to show when
 * none of the options is selected. @default 'Select an item'
 * @property { (string | HTMLString)= } arrowDown - The icon to display on the dropdown
 * when it's opened.
 * @property { (string | HTMLString)= } arrowUp - The icon to display on the dropdown
 * when it's closed.
 * @property { DropdownOnChangeCallback= } onChange - The function to call when the dropdown's
 * active item changes
 * @property { DropdownOnControlClickCallback= } onControlClick - The function to call when the control
 * of the dropdown is clicked
 * @property { boolean } closeDropdownOnItemClick - Whether or not the Dropdown should
 * close when an item is clicked. @default true
 * @property { boolean } toggleDropdownOnControlClick - Whether the Dropdown should toggle or just
 * open when the control is clicked. @default true
 * @property { boolean } onControlClickPreventDefault - Whether or not the default behavior should
 * be prevented when the control is clicked.  @default false
 * @property { boolean } startOpened - Whether or not the dropdown should start opened. @default false
 * @property { boolean } openShowsArrowUp - If true, when opened the dropdown will show the arrow up,
 * otherwise it will show the arrow down. The opposite for the closed state. @default true
 */

/**
 * A function to call when the dropdown's active item changes.
 * @callback DropdownOnChangeCallback
 * @param { Dropdown } dropdown - The dropdown itself
 * @param { DropdownItem } activeItem - The new active item after the change
 */

/**
 * A function to call when the control of the dropdown is clicked
 * @callback DropdownOnControlClickCallback
 * @param { Dropdown } dropdown - The dropdown itself
 */

/**
 * Class representing a Dropdown.
 * @class
 * @property { DropdownOptions }  options - The passed options merged with the default values
 * to configure the Dropdown.
 * @property { DropdownItem[] }  items - The items to fill the dropdown options with.
 * @property { boolean }  isOpen - Whether or not the dropdown is opened.
 * @property { HTMLElement }  element - The wrapper element of the dropdown.
 */
export default class Dropdown {
	/**
	 * Creates the needed properties to build the Dropdown. Calls convertItems method.
	 * Calls init method at last.
	 * @constructor
	 * @param { DropdownOptions } options - The options to create the dropdown.
	 */
	constructor(options = {}) {
		const defaults = {
			items: [],
			activeItem: null,
			classPrefix: 'eb-',
			cssClass: 'dropdown',
			extendedClasses: '',
			placeholder: 'Select an item',
			arrowSize: 'xl',
			size: 'md',
			onChange: null,
			onReset: null,
			OnControlClick: null,
			closeDropdownOnItemClick: true,
			toggleDropdownOnControlClick: true,
			onControlClickPreventDefault: false,
			onControlClickStopPropagation: false,
			startOpened: false,
			openShowsArrowUp: true,
			closeDropdownOnWindowClick: true,
			resettable: false,
			triggerOnChangeOnDefaultItemActivation: true,
			triggerOnChangeOnStartingItemActivation: false,
			useFloater: true
		};

		defaults.arrowDown = getIcon('arrow-down-s-line', {
			size: options.arrowSize || defaults.arrowSize || options.size
		});
		defaults.arrowUp = getIcon('arrow-up-s-line', {
			size: options.arrowSize || defaults.arrowSize || options.size
		});

		this.options = merge(defaults, options);

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;
		this.eventEmitter = new EventEmitter();
		this.items = this.options.items;
		this.isOpen = this.options.startOpened;
		this.resettable = this.options.resettable;
		this.defaultItem = null;
		this.element = null;

		if (this.items.length) {
			this.convertItems();
			this.init();
		}
	}

	/**
	 * Converts the items passed as an object in DropdownItem instances.
	 * @method
	 */
	convertItems() {
		this.items = this.items.map(item => {
			return item instanceof DropdownItem ? item : new DropdownItem(item);
		});
		if (this.resettable)
			this.defaultItem =
				this.items.find(item => item.isDefaultItem()) || this.items[0];
	}

	/**
	 * Creates the wrapper element, assigns it to element property and adds the attributes.
	 * Calls draw method.
	 * @method
	 */
	init() {
		this.element = document.createElement('div');
		this.element.classList.add(this.className);
		this.element.classList.add(`${this.className}--${this.options.size}`);
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);

		this.setupOutClickEvents();

		this.draw();
	}

	/**
	 * Adds the listeners to clicks outside the dropdown and closes the dropdown based on options.
	 * @method
	 */
	setupOutClickEvents() {
		const dropdown = this;
		if (dropdown.options.closeDropdownOnWindowClick) {
			const cb = e => {
				if (
					!e ||
					!(
						e.target == dropdown.options.arrowDown ||
						e.target == dropdown.options.arrowUp ||
						e.target == dropdown.element ||
						dropdown.element.contains(e.target)
					)
				)
					dropdown.close();
			};
			document.body.addEventListener('click', cb);
			window.addEventListener('message', ev => {
				if (ev.data === 'click') {
					cb();
				}
			});
		}
	}

	/**
	 * Calls methods: setupItems, createControl, drawControl
	 * @method
	 */
	draw() {
		this.setupItems();
		this.createControl();
		this.createControlArrow();
		this.createControlContent();
		this.drawControl();
		this.createItemsContainer();
		this.drawItemsContainer();
		if (this.options.useFloater) this.setupFloater();
		this.setActiveItem();
		if (this.isOpen) this.open;
	}

	/**
	 * Sets up the floating events and properties for the items container.
	 */
	setupFloater() {
		computePosition(this.control, this.itemsContainer, {
			placement: 'bottom-start',
			middleware: [flip(), offset(2)]
		}).then(({ x, y }) => {
			this.element.style.setProperty('--floater-coords-left', `${x}px`);
			this.element.style.setProperty('--floater-coords-top', `${y}px`);
		});
	}

	/**
	 * Loops through the items to add the class and set a click event listener to call
	 * onItemClick method.
	 * @method
	 */
	setupItems() {
		const dropdown = this;
		dropdown.items.forEach(item => {
			const el = item.getEl();
			el.classList.add(`${dropdown.className}__item`);
			el.addEventListener('click', e => {
				dropdown.onItemClick.call(dropdown, item);
			});
		});
	}

	/**
	 * Deactivates all items (by calling deactivateAllItems method), calls the
	 * select method of the passed item and activates it (by calling activateItem method).
	 * @method
	 * @param { (DropdownItem) } item - the item that should be drawn
	 * inside the control
	 */
	onItemClick(item) {
		this.deactivateAllItems();
		this.activateItem(item, true);
		if (this.options.closeDropdownOnItemClick) this.close();
	}

	/**
	 * Loops through items array to remove the modifier class 'active' and call
	 * item's deselect method.
	 * @method
	 */
	deactivateAllItems() {
		const dropdown = this;
		dropdown.items.forEach(item => {
			item.getEl().classList.remove(
				`${dropdown.className}__item--active`
			);
			item.deselect();
		});
	}

	/**
	 * Resets the dropdown by deactivating all items and activating the default item.
	 */
	reset() {
		if (this.resettable) {
			this.deactivateAllItems();
			this.activateItem(this.defaultItem, false);
			this.resetCallback();
		} else {
			console.warn('Dropdown is not resettable');
		}
	}

	/**
	 * Emits 'reset' event and calls the function passed through options.onReset if exists.
	 */
	resetCallback() {
		this.eventEmitter.emit('reset', this, this.defaultItem);
		if (typeof this.options.onReset === 'function')
			this.options.onReset(this, this.defaultItem);
	}

	/**
	 * Sets one and only one active item if there's many active items in the items array.
	 * @method
	 */
	setActiveItem() {
		const activeItems = this.items.filter(item => item.getIsActive());
		if (activeItems.length) {
			this.deactivateAllItems();
			this.activateItem(
				activeItems.length ? activeItems[0] : null,
				false,
				{ isDraw: true }
			);
		}
	}

	/**
	 * Activates the specified item in the dropdown.
	 * It assigns activeItem property to the given
	 * item and redraw the control passing the item
	 * (by calling drawControl method)
	 * @method
	 * @param {Object} item - The item to activate.
	 * @param {boolean} itemClicked - Indicates whether or not the activation
	 * proceeded of a user click on the item.
	 * @param {Object} options - Additional options for activation.
	 * @param {boolean} options.isDraw - Indicates whether it comes from the
	 * draw method directly.
	 */
	activateItem(item, itemClicked, options) {
		const defaults = {
			isDraw: false
		};

		options = merge(defaults, options);

		if (this.activeItem != item) {
			this.activeItem = item;
			if (typeof this.options.onChange === 'function') {
				if (
					this.resettable &&
					(item != this.defaultItem ||
						(item == this.defaultItem &&
							this.options
								.triggerOnChangeOnDefaultItemActivation))
				) {
					this.options.onChange.call(
						null,
						this,
						item,
						item === this.defaultItem
					);
				} else if (
					!this.resettable &&
					options.isDraw ===
						this.options.triggerOnChangeOnStartingItemActivation
				) {
					this.options.onChange.call(null, this, item);
				}
			}

			if (this.resettable && item == this.defaultItem && itemClicked)
				this.resetCallback();
		}
		item.getEl().classList.add(`${this.className}__item--active`);
		item.select.call(item);
		this.drawControl(this.activeItem);
	}

	/**
	 * Creates the control element and assigns it to the control property. Adds its
	 * classes and event listeners.
	 * @method
	 */
	createControl() {
		this.control = document.createElement('div');
		this.control.classList.add(`${this.className}__control`);
		this.control.addEventListener('click', e => {
			if (this.options.onControlClickStopPropagation) e.stopPropagation();
			this.onControlClick.call(this);
		});
	}

	/**
	 * Creates the control element and assigns it to the controlContent property. Adds its
	 * CSS classes.
	 * @method
	 */
	createControlContent() {
		this.controlContent = document.createElement('div');
		this.controlContent.classList.add(`${this.className}__control-content`);
	}

	/**
	 * Creates the control arrow element and assigns it to the controlArrow property. Adds its
	 * CSS classes.
	 * @method
	 */
	createControlArrow() {
		this.controlArrow = document.createElement('div');
		this.controlArrow.classList.add(`${this.className}__control-arrow`);
	}

	/**
	 * Handles the Control click. Toggles the dropdown by default. Calls options.onControlClick if passed
	 * @method
	 */
	onControlClick() {
		if (!this.options.onControlClickPreventDefault) {
			if (this.options.toggleDropdownOnControlClick) {
				this.toggle();
			} else {
				this.open();
			}
		}
		if (typeof this.options.onControlClick === 'function')
			this.options.onControlClick.call(null, this);
	}

	/**
	 * Updates the content of the control by calling updateControlContent method with the current
	 * active item. Draws the control inside the wrapper element
	 * @method
	 */
	drawControl() {
		this.updateControlContent(this.activeItem);
		this.updateControlArrow();
		this.control.append(this.controlContent);
		this.control.append(this.controlArrow);
		this.element.append(this.control);
	}

	/**
	 * Creates the wrapper element that contains the options. Adds its class name.
	 * @method
	 */
	createItemsContainer() {
		this.itemsContainer = document.createElement('div');
		this.itemsContainer.classList.add(`${this.className}__options`);
		this.element.append(this.itemsContainer);
	}

	/**
	 * Clears the options' wrapper inner HTML and append each item's element'.
	 * @method
	 */
	drawItemsContainer() {
		const dropdown = this;
		dropdown.itemsContainer.innerHTML = '';
		dropdown.itemsContainer.append(
			...dropdown.items.map(item => item.getEl())
		);
	}

	/**
	 * Draws the item inside the control element. If item isn't passed it
	 * draws the placeholder instead.
	 * @method
	 * @param { (DropdownItem | undefined) } item - the item that should be
	 * drawn inside the control
	 */
	updateControlContent(item) {
		if (item) {
			const content = Array.isArray(item.content)
				? item.content.map(node => node.cloneNode(true))
				: isElement(item.content)
				? item.content.cloneNode(true)
				: item.content;
			setContent(this.controlContent, content);
		} else {
			setContent(this.controlContent, this.options.placeholder);
		}
	}

	/**
	 * Updates the arrow of the dropdown based on the isOpen property and on the option
	 * regarding what arrow to choose when the dropdown is open (options.openShowsArrowUp)
	 * @method
	 */
	updateControlArrow() {
		if (
			(this.isOpen && this.options.openShowsArrowUp) ||
			(!this.isOpen && !this.options.openShowsArrowUp)
		) {
			setContent(this.controlArrow, this.options.arrowUp);
		} else {
			setContent(this.controlArrow, this.options.arrowDown);
		}
	}

	/**
	 * Opens the dropdown by adding the 'open' modifier class and setting 'isOpen'
	 * property to 'true'.
	 * @method
	 */
	open() {
		this.element.classList.add(`${this.className}--open`);
		this.isOpen = true;
		this.updateControlArrow();
		if (this.options.useFloater) this.setupFloater();
	}

	/**
	 * Closes the dropdown by removing the 'open' modifier class and setting
	 * 'isOpen' property to 'false'.
	 * @method
	 */
	close() {
		this.element.classList.remove(`${this.className}--open`);
		this.isOpen = false;
		this.updateControlArrow();
	}

	/**
	 * Toggles the dropdown by calling close method if the dropdown is opened
	 * (if the property 'isOpen' is set to 'true'), or by calling the close
	 * method in the opposite case.
	 * @method
	 */
	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	/**
	 * returns the wrapper element of the dropdown (Dropdown.element).
	 * @method
	 * @return {HTMLElement} The element bounded to the 'element' property.
	 */
	getEl() {
		return this.element;
	}

	/**
	 * Registers an event listener for the specified event.
	 *
	 * @param {string} event - The name of the event to listen for.
	 * @param {Function} callback - The callback function to be executed when the event is triggered.
	 */
	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	/**
	 * Unsubscribes a callback function from an event.
	 *
	 * @param {string} event - The event name.
	 * @param {Function} callback - The callback function to unsubscribe.
	 */
	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
