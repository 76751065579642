import ImageInput from '../../../common/imgFileInput/_imgFileInputPreview';
import { addClassesString, setContent } from 'datatalks-utils';
import {
	ToggleableSection,
	ToggleButton,
	Input,
	Accordion,
	SectionItem
} from 'datatalks-ui';
import { merge } from 'datatalks-utils';

export default (obj, options = {}) => {
	const defaults = {
		hasImageToggler: true,
		toggleBtnClickTogglesAccordion: false,
		useInput: false,
		// TODO: replace with (typeof pluginOptions.onAssetsOpen === 'function' && pluginOptions.onAssetsOpenOverridesDefault)
		image: {
			value: obj.component.get('imgSrc'),
			onChange: (fileInput, imgSrc) => {
				obj.component.changeImage.call(obj.component, imgSrc);
			},
			onDelete: () => {
				obj.component.removeImage.call(obj.component);
			}
		}
	};

	options = merge(defaults, options);

	const ImageAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: 'Upload your image',
			content: new ImageInput({
				image: options.image.value,
				useInput: options.useInput,
				callbacks: {
					wrapperClick: (fileInput, e) => {
						const editor = obj.component.getEditor();
						editor.waitForAssets((opts, sender, a) => {
							fileInput.set(sender.imgSrc, sender.imgName);
						});
					},
					change: (fileInput, imgSrc) => {
						if (typeof options.image.onChange === 'function') {
							options.image.onChange(fileInput, imgSrc);
						}
					},
					delete: () => {
						if (typeof options.image.onDelete === 'function') {
							options.image.onDelete();
						}
					}
				}
			}).getEl(),
			contentExtendedClasses: 'eb-mt-2'
		}).getEl(),
		new ToggleableSection({
			label: 'Add a link to your image',
			content: new SectionItem({
				label: 'Add a URL',
				content: new Input({
					onChange: (e, input) =>
						obj.component.setAndRerender('imageHref', input.value),
					initialValue: obj.component.get('imageHref')
				}).getEl()
			}).getEl(),
			toggleableContent: true,
			startOpen: obj.component.get('imageHasLink'),
			onToggle: isActive =>
				obj.component.setAndRerender('imageHasLink', isActive)
		}).getEl()
	]);

	const title = options.hasImageToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get('displayImage'),
						onChange: isActive => {
							obj.component.toggleImage.call(obj.component);
						}
					}).getEl(),
					`<span>Image</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: 'Image';

	const ImageAccordion = new Accordion({
		title: title,
		content: ImageAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return ImageAccordion;
};
