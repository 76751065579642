import { merge, compareVersions } from 'datatalks-utils';

/**
 * Determines whether legacy styles should be retrieved based on the provided data.
 *
 * @param {object} data - The data object containing configuration and CSS information.
 * @return {boolean} - Returns true if legacy styles should be retrieved, otherwise false.
 */
function shouldGetLegacyStyles(data) {
	return (
		(!data.config.version ||
			compareVersions(data.config.version, '2.3.1') < 0) &&
		data?.css
	);
}

/**
 * Converts data from expected structure on the remote to data that the builder can read.
 * @param {Object} data - The data to be converted.
 * @return {Object} - The converted data.
 */
function defaultConverter(data) {
	return {
		config: JSON.parse(data.config),
		html: data.html,
		css: JSON.parse(data.css)
	};
}

export default (emailBuilder, data, options = {}) => {
	const { editor } = emailBuilder;
	const defaults = {
		customConverter: null,
		skipConversion: false
	};

	options = merge({}, defaults, options);

	if (!options.skipConversion)
		if (typeof options.customConverter === 'function') {
			data = options.customConverter(data);
		} else if (
			typeof editor.StorageManager.getCurrentStorage().dataConverter ===
			'function'
		) {
			data =
				editor.StorageManager.getCurrentStorage().dataConverter(data);
		} else {
			data = defaultConverter(data);
		}

	const store = {};
	if (data?.config) {
		store.config = data.config || null;

		if (data.config.styles)
			emailBuilder.addStyleOptions(data.config.styles);

		if (data.config.components)
			editor.setComponents(data.config.components);

		if (data.config.layout)
			emailBuilder.addLayoutOptions(data.config.layout);
	}
	if (shouldGetLegacyStyles(data)) {
		store.css = data.css;
		if (data.css.length) {
			data.css.forEach(rule => {
				editor.Css.setRule(
					rule.selectors.length ? rule.selectors : rule.selectorsAdd,
					rule.style,
					{ addStyles: true }
				);
			});
		}
	}
	editor.setStyleProperties();
	emailBuilder.setStoredData(merge({}, store));
	if (shouldGetLegacyStyles(data)) emailBuilder.getLegacyStyles();
	emailBuilder.applyGeneralStyles();
};
