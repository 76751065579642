import registerCommand from './_registerCommand';

export default emailBuilder => {
	const { editor } = emailBuilder;

	const defaults = {
		exportConfig: {
			active: true,
			options: {}
		},
		importConfig: {
			active: true,
			options: {}
		},
		showSavePanel: {
			active: true,
			options: {}
		},
		showMainViewPanel: {
			active: true,
			options: {}
		},
		showTraitsViewPanel: {
			active: true,
			options: {}
		},
		exportHtml: {
			active: true,
			options: {}
		},
		exportAll: {
			active: true,
			options: {}
		},
		downloadTemplate: {
			active: true,
			options: {}
		},
		openGeneral: {
			active: true,
			options: {}
		},
		changeContext: {
			active: true,
			options: {}
		},
		openTraitManager: {
			active: true,
			options: {}
		},
		openContentTraits: {
			active: true,
			options: {}
		},
		openStyleTraits: {
			active: true,
			options: {}
		},
		clearStorage: {
			active: true,
			options: {}
		},
		checkDiffStorage: {
			active: true,
			options: {}
		},
		previewEmail: {
			active: true,
			options: {}
		},
		assetSet: {
			active: true,
			options: {}
		},
		changeGeneralStyleProp: {
			active: true,
			options: {}
		},
		goBack: {
			active: true,
			options: {}
		},
		undo: {
			active: true,
			options: {}
		},
		redo: {
			active: true,
			options: {}
		},
		copy: {
			active: true,
			options: {}
		},
		paste: {
			active: true,
			options: {}
		},
		htmlPreview: {
			active: true,
			options: {}
		}
	};

	const commandsOptions = editor.addCommandsOptions(defaults);

	for (const cmd in commandsOptions) {
		if (commandsOptions[cmd].active) {
			registerCommand(cmd, emailBuilder);
		}
	}
};
