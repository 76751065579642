import { merge } from 'lodash-es';

/* eslint-disable require-jsdoc */
export default prefix => {
	return {
		ltr: `${prefix}-ltr`,
		rtl: `${prefix}-rtl`,
		placeholder: `${prefix}-placeholder`,
		paragraph: `${prefix}-paragraph`,
		quote: `${prefix}-quote`,
		heading: {
			h1: `${prefix}-heading-h1`,
			h2: `${prefix}-heading-h2`,
			h3: `${prefix}-heading-h3`,
			h4: `${prefix}-heading-h4`,
			h5: `${prefix}-heading-h5`
		},
		list: {
			nested: {
				listitem: `${prefix}-nested-listitem`
			},
			ol: `${prefix}-list-ol`,
			ul: `${prefix}-list-ul`,
			listitem: `${prefix}-listitem`
		},
		image: `${prefix}-image`,
		link: `${prefix}-link`,
		text: {
			bold: `${prefix}-text-bold`,
			italic: `${prefix}-text-italic`,
			overflowed: `${prefix}-text-overflowed`,
			hashtag: `${prefix}-text-hashtag`,
			underline: `${prefix}-text-underline`,
			strikethrough: `${prefix}-text-strikethrough`,
			underlineStrikethrough: `${prefix}-text-underlineStrikethrough`,
			code: `${prefix}-text-code`
		},
		code: `${prefix}-code`
	};
};

export function themeConfig(options) {
	const defaults = {
		h1: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '2em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		h2: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '1.5em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		h3: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '1.17em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		h4: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '1em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		h5: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '0.83em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		h6: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '0.67em',
			'font-weight': 'bold',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		p: {
			color: '#000',
			'font-family': 'Arial',
			'font-size': '1em',
			'font-weight': 'normal',
			'line-height': '1.5',
			'margin-top': '0',
			'margin-bottom': '0'
		},
		link: {
			color: 'blue'
		}
	};
	return merge(defaults, options);
}

export function themeVariables(config) {
	config = themeConfig(config);
	return Object.keys(config)
		.map(key => {
			const value = config[key];
			const style = Object.keys(value)
				.map(prop => {
					return `--${key}-${prop}: ${value[prop]};`;
				})
				.join(' ');
			return style;
		})
		.join(' ');
}

export function themeRoot(config) {
	return `
		:root {
			${themeVariables(config)}
		}
	`;
}
