import { merge, setContent, addClassesString } from 'datatalks-utils';
import colorPicker from './common/_color';
import alignmentSection from './common/_alignment';
import textFont from './common/_fonts';
import textWeight from './common/_weight';
import textBorderStyle from './common/_borderStyle';
import {
	Accordion,
	SectionItem,
	section,
	ToggleableSection
} from 'datatalks-ui';
import sliderInput from './common/_sliderOrInput';
import borderRadius from './common/_borderRadius';
import { getIcon } from 'datatalks-icons';

export default (options = {}, customTrait = {}) => {
	const defaults = {
		traitsVisibility: {
			backgroundColor: true,
			color: true,
			font: true,
			weight: true,
			border: false,
			alignment: true,
			borderRadius: true
		},
		backgroundColor: {
			label: 'Background color',
			value: null,
			onChange: null, // (color)
			getEmptyColor: null,
			emptyColorLabel: null
		},
		color: {
			label: 'Color',
			value: null,
			onChange: null, // (color)
			getEmptyColor: null,
			emptyColorLabel: null
		},
		font: {
			label: 'Font',
			value: 'Arial',
			onChange: null // (value)
		},
		weight: {
			label: 'Font weight',
			value: 'normal',
			onChange: null // (value)
		},
		accordion: {
			title: 'Text',
			color: 'grey',
			type: 'extend'
		},
		border: {
			label: 'Border',
			style: {
				label: 'Style',
				value: 'solid',
				onChange: null // (value)
			}
		},
		width: {
			label: 'Width',
			value: null,
			unit: '',
			onChange: null // (value)
		},
		alignment: {
			label: 'Alignment',
			onChange: null,
			value: 'center'
		},
		borderRadius: {
			label: 'Border radius',
			useBorderRadius: true,
			onToggle: null,
			toggleableContent: null,
			corners: null,
			inputsStartLinked: true
		},
		textColorPickerProp: 'textCp',
		backgroundColorPickerProp: 'textBackgroundCp'
	};

	options = merge(defaults, options);

	if (
		options.traitsVisibility.color &&
		!customTrait[options.textColorPickerProp]
	)
		customTrait[options.textColorPickerProp] = colorPicker(options.color);

	if (
		options.traitsVisibility.backgroundColor &&
		!customTrait[options.backgroundColorPickerProp]
	)
		customTrait[options.backgroundColorPickerProp] = colorPicker(
			options.backgroundColor
		);

	const buttonAccordionContent = setContent(document.createElement('div'), [
		options.traitsVisibility.width &&
			setContent(
				document.createElement('div'),
				sliderInput(options.width)
			),
		options.traitsVisibility.alignment &&
			new SectionItem({
				label: options.alignment.label,
				content: alignmentSection(options.alignment).getEl()
			}).getEl(),
		options.traitsVisibility.backgroundColor
			? new SectionItem({
					label: options.backgroundColor.label,
					content:
						customTrait[options.backgroundColorPickerProp].getEl()
			  }).getEl()
			: null,
		options.traitsVisibility.font
			? new SectionItem({
					label: options.font.label,
					content: textFont(options.font).getEl()
			  }).getEl()
			: null,
		options.traitsVisibility.weight
			? new SectionItem({
					label: options.weight.label,
					content: textWeight(options.weight).getEl()
			  }).getEl()
			: null,
		options.traitsVisibility.color
			? new SectionItem({
					label: options.color.label,
					content: customTrait[options.textColorPickerProp].getEl()
			  }).getEl()
			: null,
		options.traitsVisibility.border
			? section({
					label: options.border.label,
					content: [
						new SectionItem({
							label: options.border.style.label,
							content: textBorderStyle(
								options.border.style
							).getEl()
						}).getEl()
					]
			  })
			: null,
		options.traitsVisibility.borderRadius &&
			new ToggleableSection({
				startOpen: options.borderRadius.useBorderRadius,
				label: options.borderRadius.label,
				toggleableContent: options.borderRadius.toggleableContent,
				onToggle: isActive => {
					if (typeof options.borderRadius.onToggle === 'function')
						options.borderRadius.onToggle(isActive);
				},
				content: [
					borderRadius(options.borderRadius),
					addClassesString(
						setContent(document.createElement('div'), [
							getIcon('information-line', { size: 'lg' }),
							addClassesString(
								setContent(document.createElement('p'), [
									'Border radius is not supported by Windows Outlook.'
								]),
								'eb-fonts eb-fonts-text'
							)
						]),
						'eb-flex eb-gap-2 eb-align-items-center eb-mt-4'
					)
				]
			}).getEl()
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: buttonAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
