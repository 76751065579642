import {
	validateStyleObj,
	validateBorder
} from '../../../common/functions/_utilFunctions';
import { cssSidesToObject, isValidDimension } from 'datatalks-utils';

export default comp => {
	const getGeneralStyle = comp
		.getEditor()
		.getStyleRules.bind(comp.getEditor(), 'button');
	const getStyleProp = comp
		.getEditor()
		.getEmailBuilder()
		.getStyleProp.bind(comp.getEditor().getEmailBuilder(), 'buttons');
	const borderRadius = comp.get('useBorderRadius')
		? Object.values(comp.get('borderRadius')).join(' ')
		: [
				getGeneralStyle('border-top-left-radius') || 0,
				getGeneralStyle('border-top-right-radius') || 0,
				getGeneralStyle('border-bottom-right-radius') || 0,
				getGeneralStyle('border-bottom-left-radius') || 0
		  ].join(' ');
	const padding =
		comp.get('padding') ||
		[
			getGeneralStyle('padding-top') || 0,
			getGeneralStyle('padding-right') || 0,
			getGeneralStyle('padding-bottom') || 0,
			getGeneralStyle('padding-left') || 0
		].join(' ');

	const borderWidth =
		comp.get('borderWidth') || getGeneralStyle('border-width') || '0px';
	const height = comp.get('height') || getStyleProp().height;

	let lineHeight =
		comp.get('lineHeight') ||
		comp.getEditor().getEmailBuilder().getStyleProp('buttons').lineHeight;
	if (
		lineHeight ===
		comp.getEditor().getEmailBuilder().getLineHeightTypes().auto
	) {
		lineHeight =
			parseFloat(height) -
			(cssSidesToObject(padding).top +
				cssSidesToObject(padding).bottom +
				cssSidesToObject(borderWidth).top +
				cssSidesToObject(borderWidth).bottom) +
			'px';
	} else if (
		lineHeight ===
		comp.getEditor().getEmailBuilder().getLineHeightTypes().sameAsHeight
	) {
		lineHeight = height;
	}

	const style = {
		'background-color':
			comp.get('backgroundColor') || getGeneralStyle('background-color'),
		'font-size':
			comp.get('fontSize') ||
			comp.getEditor().getStyleRules('wrapper', 'font-size'),
		'min-height': height,
		'line-height': lineHeight,
		width: isValidDimension(comp.get('width'), {
			acceptableValues: ['auto']
		})
			? comp.get('width')
			: '100%',
		'font-family': comp.get('fontFamily') || getGeneralStyle('font-family'),
		color: comp.get('color') || getGeneralStyle('color'),
		'font-weight': comp.get('fontWeight') || getGeneralStyle('font-weight'),
		'border-width': borderWidth,
		'border-style': comp.get('borderStyle'),
		'border-color': comp.get('borderColor'),
		'border-radius': borderRadius,
		'text-align': comp.get('textAlignment'),
		'vertical-align': 'middle',
		display: 'inline-block',
		'mso-padding-alt': 0,
		padding,
		'text-decoration': comp.get('textDecorationLine') || 'none',
		'font-style': comp.get('fontStyle')
	};

	return validateStyleObj(validateBorder(style));
};
