import createAccordionFromItem from './Header/components/contentTab/linksAccordion/_createItem';
import ListItem from './abstracts/_headerItem';
import { arrayRemove, isElement } from 'datatalks-utils';

export default (obj, options) => {
	const defaults = {
		itemsList: [],
		itemsListElement: document.createElement('div'),
		itemsListElementCssClass: 'items-list',
		listItemElementTemplate: createAccordionFromItem,
		listItemElementTemplateOptions: {}
	};

	options = {
		...defaults,
		...options
	};

	obj.trait.itemsList = options.itemsList;
	obj.trait.itemsListElement = options.itemsListElement;
	obj.trait.itemsListElement.className = options.itemsListElementCssClass;

	obj.trait.addItems = items => {
		if (!(items[0] instanceof ListItem)) {
			items = items.map(
				item =>
					new ListItem({
						element: options.listItemElementTemplate.call(
							null,
							obj,
							item,
							options.listItemElementTemplateOptions
						),
						item
					})
			);
		}
		obj.trait.itemsList.push(...items);

		obj.trait.itemsListElement.append(...items.map(item => item.getEl()));
	};

	obj.trait.addItem = (item, customTrait = {}) => {
		if (!(item instanceof ListItem)) {
			item = new ListItem({
				element: options.listItemElementTemplate.call(
					null,
					obj,
					item,
					options.listItemElementTemplateOptions,
					customTrait
				),
				item
			});
		}
		obj.trait.itemsList.push(item);

		obj.trait.itemsListElement.append(item.getEl());
	};

	obj.trait.setItems = (items, customTrait) => {
		if (!(items[0] instanceof ListItem)) {
			items = items.map((item, index) => {
				item.id = index;
				return new ListItem({
					element: options.listItemElementTemplate.call(
						null,
						obj,
						item,
						options.listItemElementTemplateOptions,
						customTrait
					),
					item
				});
			});
		}
		obj.trait.itemsList = items;
		obj.trait.itemsListElement.innerHTML = '';
		obj.trait.itemsListElement.append(...items.map(item => item.getEl()));
	};

	obj.trait.removeItem = item => {
		if (!(item instanceof ListItem)) {
			item = obj.trait.itemsList.filter(
				listItem =>
					listItem.getItem() === item || listItem.getEl() === item
			)[0];
		}

		if (item) {
			arrayRemove(obj.trait.itemsList, item);
		} else {
			console.log(
				"Couldn't remove item since it can't be found in trait's itemsList"
			);
		}

		if (isElement(item.getEl())) {
			item.getEl().remove();
		} else {
			console.log("Couldn't remove item since it's not an element");
		}
	};
};
