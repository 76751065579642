import toHtml from './_toHtml';
import { startCase } from 'lodash-es';
import { merge } from 'datatalks-utils';

export default (componentOptions = {}, emailBuilder) => {
	const defaults = {
		name: 'Wrapper',
		toHtml,
		toHtmlOptions: {
			replaceWrapperByTable: false
		}
	};

	componentOptions = merge(defaults, componentOptions);

	return {
		extend: componentOptions.name,
		model: {
			defaults: {
				name: startCase(componentOptions.name)
			},
			toHTML(options) {
				return toHtml(
					this,
					merge(componentOptions.toHtmlOptions, options, {
						useOuterBackground:
							emailBuilder.layout.useOuterBackground,
						outerBackground: emailBuilder.styles.background.outer,
						backgroundColor: emailBuilder.styles.background.email,
						width: emailBuilder.editor.getStyleRules(
							'wrapper',
							'width'
						),
						preHeader: emailBuilder.getOptions().html.usePreHeader
							? emailBuilder.getOptions().html.preHeaderText
							: '',
						ignoreEmptyPreHeader:
							!emailBuilder.getOptions().html.usePreHeader
					})
				);
			}
		}
	};
};
