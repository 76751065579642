import { validateStyleObj } from '../../../common/functions/_utilFunctions';
import { cssSidesToObject, extractPlainText, isHTML } from 'datatalks-utils';

export default comp => {
	const getGeneralStyle = comp
		.getEditor()
		.getStyleRules.bind(comp.getEditor(), 'button');
	const generalStyleProp = comp
		.getEditor()
		.getEmailBuilder()
		.getStyleProp('buttons');

	let lineHeight = comp.get('lineHeight') || generalStyleProp.lineHeight;
	if (
		lineHeight ===
		comp.getEditor().getEmailBuilder().getLineHeightTypes().auto
	) {
		lineHeight = getAutoLineHeight();
	} else if (
		lineHeight ===
		comp.getEditor().getEmailBuilder().getLineHeightTypes().sameAsHeight
	) {
		lineHeight = comp.get('height') || getGeneralStyle('height');
	}

	/**
	 * Calculates the auto line height for the button component.
	 * @return {string} The calculated auto line height.
	 */
	function getAutoLineHeight() {
		const borderWidth =
			comp.get('borderWidth') || getGeneralStyle('border-width') || '0px';
		const height = comp.get('height') || generalStyleProp.height || '40px';
		const padding =
			comp.get('padding') ||
			[
				generalStyleProp.paddings.top || '0px',
				generalStyleProp.paddings.right || '0px',
				generalStyleProp.paddings.bottom || '0px',
				generalStyleProp.paddings.left || '0px'
			].join(' ');
		return (
			parseFloat(height) -
			(cssSidesToObject(padding).top +
				cssSidesToObject(padding).bottom +
				cssSidesToObject(borderWidth).top +
				cssSidesToObject(borderWidth).bottom) +
			'px'
		);
	}

	comp.setStyle(
		validateStyleObj({
			'background-color': comp.get('backgroundColor'),
			'font-family': comp.get('fontFamily'),
			'font-size': comp.get('fontSize'),
			'font-style': comp.get('fontStyle'),
			color: comp.get('color'),
			'font-weight': comp.get('fontWeight'),
			'border-width': comp.get('borderWidth'),
			'border-style': comp.get('borderStyle'),
			'border-color': comp.get('borderColor'),
			'border-radius': comp.get('useBorderRadius')
				? Object.values(comp.get('borderRadius')).join(' ')
				: null,
			'min-height': comp.get('height'),
			height: 'auto',
			width: comp.get('width'),
			'text-align': comp.get('textAlignment'),
			'line-height': lineHeight,
			'text-decoration-line': comp.get('textDecorationLine')
		})
	);

	return {
		type: 'textnode',
		content: isHTML(comp.get('text'))
			? extractPlainText(comp.get('text'))
			: comp.get('text')
	};
};
