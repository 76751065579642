import { merge } from 'datatalks-utils';
import addGenericTrait from './_register';

export default emailBuilder => {
	const defaults = {
		generic: {
			active: true,
			addTrait: addGenericTrait,
			options: {}
		}
	};

	const traitsOptions = merge(
		defaults,
		emailBuilder.getTraitsOptions() || {}
	);

	for (const trait in traitsOptions) {
		if (
			traitsOptions[trait].active &&
			typeof traitsOptions[trait].addTrait === 'function'
		) {
			traitsOptions[trait].addTrait(emailBuilder);
		}
	}
};
