import * as componentsBuild from './_exportComponentsBuild';
import { kebabCase } from 'lodash-es';

export default (componentName, emailBuilder) => {
	const { editor } = emailBuilder;

	editor.DomComponents.addType(
		kebabCase(componentName),
		componentsBuild[componentName](
			editor.getComponentOptions(componentName),
			emailBuilder
		)
	);
};
