import { merge } from 'datatalks-utils';
import imageAccordion from './contentTab/_imageAccordion';
import linksAccordion from './contentTab/_linksAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		extendedClasses: '',
		imageAccordionOptions: {},
		linksAccordionOptions: {
			itemsListOptions: {
				button: {
					onClick: () => {
						const newItem = obj.component.addLink.call(
							obj.component
						);
						if (newItem) obj.trait.addItem(newItem, customTrait);
					}
				}
			}
		}
	};

	options = merge(defaults, options);

	const contentTab = addClassesString(
		setContent(document.createElement('div'), [
			imageAccordion
				.call(null, obj, options.imageAccordionOptions, customTrait)
				.getEl(),
			linksAccordion
				.call(null, obj, options.linksAccordionOptions, customTrait)
				.getEl()
		]),
		options.extendedClasses
	);

	return contentTab;
};
