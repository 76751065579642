/* eslint-disable require-jsdoc */
import { merge } from 'lodash-es';
import { addClassesString, setContent, EventEmitter } from 'datatalks-utils';
import { ToggleButton } from 'datatalks-ui';

export default class ToggleableSection {
	constructor(options) {
		const defaults = {
			className: 'toggleable-section',
			classPrefix: 'eb-',
			extendedClasses: '',
			tagName: 'div',
			type: 'section',
			label: null,
			content: null,
			labelExtendedClasses: '',
			contentExtendedClasses: '',
			toggleableContent: false,
			// TODO: change this option name to "startActive",
			// as we have `toggleOnShowsContent` and to be opened may now mean to be deactivated
			startOpen: false,
			onToggle: null,
			onOpen: null,
			onClose: null,
			toggleLabel: null,
			toggleOnShowsContent: true
		};

		this.options = merge(defaults, options);

		this.element = null;
		this.content = null;
		this.label = null;
		this.cssClass = `${this.options.classPrefix}${this.options.className}`;
		this.isOpen =
			this.options.toggleableContent &&
			this.options.startOpen === this.options.toggleOnShowsContent;

		this.eventEmitter = new EventEmitter();

		this.init();
	}

	init() {
		this.createElements();

		if (this.isOpen || !this.options.toggleableContent) this.open();

		if (this.options.toggleableContent) this.createToggleButton();

		if (typeof this.options.onToggle === 'function')
			this.on('toggle', this.options.onToggle);

		if (typeof this.options.onOpen === 'function')
			this.on('open', this.options.onOpen);

		if (typeof this.options.onClose === 'function')
			this.on('close', this.options.onClose);

		this.draw();
	}

	createElements() {
		this.element = addClassesString(
			document.createElement(this.options.tagName),
			`${this.cssClass}${
				this.options.extendedClasses
					? ` ${this.options.extendedClasses}`
					: ''
			}`
		);

		if (this.options.type !== 'section')
			this.element.classList.add(
				`${this.cssClass}--${this.options.type}`
			);

		this.label = setContent(
			addClassesString(
				document.createElement('label'),
				`eb-label ${this.cssClass}__label${
					this.options.labelExtendedClasses
						? ` ${this.options.labelExtendedClasses}`
						: ''
				}`
			),
			this.options.label || ''
		);

		if (this.options.toggleLabel) {
			this.toggleLabel = setContent(
				addClassesString(
					document.createElement('label'),
					`eb-label ${this.cssClass}__toggle-label${
						this.options.toggleLabelExtendedClasses
							? ` ${this.options.toggleLabelExtendedClasses}`
							: ''
					}`
				),
				this.options.toggleLabel || ''
			);
		}

		this.content = setContent(
			addClassesString(
				document.createElement('div'),
				`${this.cssClass}__content${
					this.options.contentExtendedClasses
						? ` ${this.options.contentExtendedClasses}`
						: ''
				}`
			),
			this.options.content
		);
	}

	createToggleButton() {
		const ts = this;
		ts.toggleButton = new ToggleButton({
			onChange: isActive => {
				if (isActive === ts.options.toggleOnShowsContent) ts.open();
				else ts.close();

				ts.eventEmitter.emit('toggle', isActive, this);
			},
			startActive: ts.options.startOpen
		});
	}

	draw() {
		if (this.options.toggleableContent)
			setContent(this.element, [
				addClassesString(
					setContent(document.createElement('div'), [
						this.label,
						this.options.toggleLabel
							? addClassesString(
									setContent(document.createElement('div'), [
										this.toggleLabel,
										this.toggleButton.getEl()
									]),
									`${this.cssClass}__toggle-label-wrapper`
							  )
							: this.toggleButton.getEl()
					]),
					`${this.cssClass}__label-wrapper`
				),
				this.content
			]);
		else setContent(this.element, [this.label, this.content]);
	}

	open() {
		this.element.classList.add(`${this.cssClass}--open`);
		this.isOpen = true;
		this.eventEmitter.emit('open', this);
	}

	close() {
		this.element.classList.remove(`${this.cssClass}--open`);
		this.isOpen = false;
		this.eventEmitter.emit('close', this);
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
