import { capitalize } from 'lodash-es';
import { toHex } from 'color2k';

import {
	getUnit,
	setContent,
	addClassesString,
	webSafeFonts,
	webFonts,
	cssFontWeights,
	borderStyles,
	merge,
	isValidDimension,
	isValidUnit,
	getValidUnit
} from 'datatalks-utils';
import {
	ColorPickerInput,
	InputNumber,
	Accordion,
	SectionItem,
	section,
	Dropdown,
	ToggleableSection
} from 'datatalks-ui';
import buttonBorderRadius from './buttonAccordion/_buttonBorderRadius';
import buttonAlignment from './buttonAccordion/_buttonAlignment';
// import letterSpacingContainer from '../../../Header/components/styleTab/linksAccordion/_letterSpacing';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		traitsVisibility: {
			width: true,
			align: true,
			height: true,
			font: true,
			weight: true,
			color: true,
			backgroundColor: true,
			borderWidth: true,
			borderStyle: true,
			borderColor: true,
			borderRadius: true
		},
		buttonBorderRadiusOptions: {},
		buttonAlignmentOptions: {},
		letterSpacingOptions: {
			elementName: 'button'
		},
		useWebFonts: true,
		accordion: {
			title: 'Button',
			color: 'grey',
			type: 'extend'
		}
	};

	options = merge(defaults, options);

	let fonts = webSafeFonts;

	if (options.useWebFonts) {
		fonts = fonts.concat(webFonts);
	}

	if (options.traitsVisibility.color && !customTrait.buttonCp)
		customTrait.buttonCp = new ColorPickerInput({
			emptyColor: () =>
				obj.component.getEditor()?.getStyleRules('button', 'color') ||
				null,
			emptyColorLabel: "General button's text color",
			color: obj.component.get('buttonColor'),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender('buttonColor', cpi.getColor());
			}
		});

	if (
		options.traitsVisibility.backgroundColor &&
		!customTrait.buttonBackgroundCp
	)
		customTrait.buttonBackgroundCp = new ColorPickerInput({
			color: obj.component.get('buttonBackgroundColor'),
			emptyColor: () =>
				obj.component
					.getEditor()
					?.getStyleRules('button', 'background-color') || null,
			emptyColorLabel: 'Buttons color',
			onChange: (cpi, ev) => {
				obj.component.setAndRerender(
					'buttonBackgroundColor',
					toHex(cpi.color) === toHex(cpi.emptyColor)
						? null
						: cpi.getColor()
				);
			}
		});

	let outlookBorderRadiusSection;
	/**
	 * Sets the visibility of the Outlook border radius section.
	 * @param {boolean} show - Whether to show the section.
	 */
	function setVisibilityOutookBorderRadiusSection(show) {
		if (!outlookBorderRadiusSection) return;

		if (show) outlookBorderRadiusSection.classList.remove('eb-hidden');
		else outlookBorderRadiusSection.classList.add('eb-hidden');
	}

	if (options.traitsVisibility.borderRadius) {
		outlookBorderRadiusSection = new ToggleableSection({
			startOpen: obj.component.get('buttonHasOutlookBorderRadius'),
			label: 'Force Outlook border-radius',
			content: setContent(document.createElement('div'), [
				addClassesString(
					setContent(document.createElement('p'), [
						'Outlook does not support buttons with auto width and border-radius simultaneously. If you enable this option, the button in Outlook will have a border-raclius and will be full width by default. However, you can still manually adjust the width by specifying a percentage in the input below:'
					]),
					// TODO: Add classes
					''
				),
				new SectionItem({
					label: 'Outlook button width',
					content: new InputNumber({
						defaultValue:
							obj.component.get('buttonOutlookWidth') || '0px',
						unit: getUnit(obj.component.get('buttonOutlookWidth')),
						changeableUnit: false,
						onChange: (value, unit, inputValue) => {
							obj.component.setAndRerender(
								'buttonOutlookWidth',
								inputValue
							);
						}
					}).getEl()
				}).getEl()
			]),
			toggleableContent: true,
			onToggle: value => {
				obj.component.setAndRerender(
					'buttonHasOutlookBorderRadius',
					value
				);
			}
		}).getEl();

		setVisibilityOutookBorderRadiusSection(
			obj.component.get('buttonWidth') == 'auto'
		);
	}

	if (options.traitsVisibility.borderColor && !customTrait.buttonBorderCp)
		customTrait.buttonBorderCp = new ColorPickerInput({
			color: obj.component.get('buttonBorderColor'),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender(
					'buttonBorderColor',
					cpi.getColor()
				);
			}
		});

	let buttonWidth = obj.component.get('buttonWidth');
	if (
		options.traitsVisibility.width &&
		buttonWidth !== 'auto' &&
		(!isValidDimension(buttonWidth) || !isValidUnit(getUnit(buttonWidth)))
	) {
		buttonWidth = isNaN(parseFloat(buttonWidth))
			? '100%'
			: parseFloat(buttonWidth) +
			  getValidUnit(buttonWidth, { defaultUnit: '%' });
		obj.component.setAndRerender('buttonWidth', buttonWidth);
	}

	const buttonWidthInput = new InputNumber({
		defaultValue: buttonWidth === 'auto' ? '' : buttonWidth,
		unit: buttonWidth === 'auto' ? '%' : getUnit(buttonWidth),
		changeableUnit: true,
		onChange: (value, unit, inputValue) => {
			obj.component.setAndRerender('buttonWidth', inputValue);
		}
	});

	let buttonHeightInput;
	const buttonHeightValue =
		obj.component.get('buttonHeight') ||
		obj.component.getEditor()?.getStyleRules('button', 'height');

	const buttonAccordionContent = setContent(document.createElement('div'), [
		options.traitsVisibility.width &&
			new ToggleableSection({
				startOpen: buttonWidth == 'auto',
				label: 'Width',
				content: buttonWidthInput.getEl(),
				toggleableContent: true,
				toggleLabel: 'Auto',
				toggleOnShowsContent: false,
				onToggle: isActive => {
					if (isActive) {
						obj.component.setAndRerender('buttonWidth', 'auto');
					} else {
						obj.component.setAndRerender(
							'buttonWidth',
							buttonWidthInput.getInputValue()
						);
					}
					setVisibilityOutookBorderRadiusSection(isActive);
				}
			}).getEl(),
		options.traitsVisibility.align &&
			new SectionItem({
				label: 'Alignment',
				content: buttonAlignment(
					obj,
					options.buttonAlignmentOptions
				).getEl()
			}).getEl(),
		options.traitsVisibility.height &&
			new ToggleableSection({
				startOpen: buttonHeightValue == 'auto',
				label: 'Height',
				content: (buttonHeightInput = new InputNumber({
					defaultValue:
						buttonHeightValue !== 'auto'
							? buttonHeightValue
							: '40px',
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender(
							'buttonHeight',
							inputValue
						);
					}
				})).getEl(),
				toggleableContent: true,
				toggleLabel: 'Auto',
				toggleOnShowsContent: false,
				onToggle: isActive => {
					if (isActive) {
						obj.component.setAndRerender('buttonHeight', 'auto');
					} else {
						obj.component.setAndRerender(
							'buttonHeight',
							buttonHeightInput.getInputValue()
						);
					}
				}
			}).getEl(),
		section({
			label: 'Text',
			content: [
				options.traitsVisibility.font &&
					new SectionItem({
						label: 'Font',
						content: new Dropdown({
							items: [null, ...fonts.sort()].map(font => {
								return {
									content: font
										? webFonts.includes(font)
											? `${capitalize(font)} *`
											: capitalize(font)
										: 'Same as general style',
									value: font,
									active:
										obj.component.get(
											'buttonFontFamily'
										) === font
								};
							}),
							onChange: (dropdown, activeItem) => {
								obj.component.setAndRerender(
									'buttonFontFamily',
									activeItem.getValue()
								);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.weight &&
					new SectionItem({
						label: 'Text weight',
						content: new Dropdown({
							items: cssFontWeights.map(weight => {
								return {
									content:
										typeof weight === 'string'
											? capitalize(weight)
											: weight,
									value: weight,
									active:
										obj.component.get(
											'buttonFontWeight'
										) === weight
								};
							}),
							onChange: (dropdown, activeItem) => {
								obj.component.setAndRerender(
									'buttonFontWeight',
									activeItem.getValue()
								);
							}
						}).getEl()
					}).getEl(),
				// letterSpacingContainer(obj, options.letterSpacingOptions),
				options.traitsVisibility.color &&
					new SectionItem({
						label: 'Text color',
						content: customTrait.buttonCp.getEl()
					}).getEl()
			]
		}),
		options.traitsVisibility.backgroundColor &&
			section({
				label: 'Background',
				content: new SectionItem({
					label: 'Background color',
					content: customTrait.buttonBackgroundCp.getEl()
				}).getEl()
			}),
		section({
			label: 'Border',
			content: [
				options.traitsVisibility.borderWidth &&
					new SectionItem({
						label: 'Width',
						content: new InputNumber({
							defaultValue:
								obj.component.get('buttonBorderWidth'),
							unit:
								getUnit(
									obj.component.get('buttonBorderWidth')
								) || 'px',
							onChange: (value, unit, inputValue) => {
								obj.component.setAndRerender(
									'buttonBorderWidth',
									inputValue
								);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.borderStyle &&
					new SectionItem({
						label: 'Style',
						content: new Dropdown({
							items: borderStyles.map(style => {
								return {
									content: capitalize(style),
									value: style,
									active:
										obj.component.get(
											'buttonBorderStyle'
										) === style
								};
							}),
							onChange: (dropdown, activeItem) => {
								obj.component.setAndRerender(
									'buttonBorderStyle',
									activeItem.getValue()
								);
							}
						}).getEl()
					}).getEl(),
				options.traitsVisibility.borderColor &&
					new SectionItem({
						label: 'Color',
						content: customTrait.buttonBorderCp.getEl()
					}).getEl(),
				options.traitsVisibility.borderRadius &&
					new ToggleableSection({
						label: 'Border radius',
						content: setContent(document.createElement('div'), [
							buttonBorderRadius(
								obj,
								options.buttonBorderRadiusOptions
							),
							outlookBorderRadiusSection
						]),
						toggleableContent: true,
						startOpen: obj.component.get('buttonUseBorderRadius'),
						onToggle: isActive =>
							obj.component.setAndRerender(
								'buttonUseBorderRadius',
								isActive
							)
					}).getEl()
			]
		})
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: buttonAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
