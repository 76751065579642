import { translationFramework } from 'email-builder';

/**
 * Localizes a given key using the translation framework.
 *
 * @param {string} key - The key to be localized.
 * @param {boolean} nullable - Whether to return null or the key if the translation is not found.
 * @return {string} - The localized string if the translation framework is available, otherwise the original key.
 */
export default function localize(key, nullable = false) {
	if (translationFramework.getTranslation) {
		return translationFramework.getTranslation(key, nullable);
	} else {
		console.warn('translationFramework is not available in this context');
		return null;
	}
}
