import { merge } from 'datatalks-utils';
import { validateStyleObj } from '../common/functions/_utilFunctions';

/* eslint-disable require-jsdoc */
export const noInteractionPreset = {
	editable: false,
	selectable: false,
	hoverable: false,
	layerable: false,
	draggable: false,
	highlightable: false,
	locked: true,
	droppable: true,
	propagate: [
		'editable',
		'selectable',
		'hoverable',
		'layerable',
		'draggable',
		'highlightable',
		'locked',
		'droppable'
	]
};

export function addSpace(
	component,
	isVertical = true,
	innerComponentsArray,
	componentProperty = 'spacingBetweenElements'
) {
	const spaceComp = {
		type: 'space',
		dimension: component.get(componentProperty || 'spacingBetweenElements'),
		isVertical: isVertical
	};
	if (Array.isArray(innerComponentsArray)) {
		innerComponentsArray.push(spaceComp);
	} else {
		component.append(spaceComp);
	}
}
