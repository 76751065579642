import registerComponent from './_registerComponent';

export default emailBuilder => {
	const { editor } = emailBuilder;

	const defaults = {
		linksList: {
			active: true,
			options: {}
		},
		header: {
			active: true,
			options: {}
		},
		hero: {
			active: true,
			options: {}
		},
		list: {
			active: true,
			options: {
				droppable: false
			}
		},
		listItem: {
			active: true,
			options: {}
		},
		imagePlaceholder: {
			active: true,
			options: {}
		},
		footer: {
			active: true,
			options: {}
		},
		callToAction: {
			active: true,
			options: {}
		},
		button: {
			active: true,
			options: {}
		},
		text: {
			active: true,
			options: {}
		},
		imageWrapper: {
			active: true,
			options: {}
		},
		space: {
			active: true,
			options: {}
		},
		margins: {
			active: true,
			options: {}
		},
		wrapper: {
			active: true,
			options: {}
		},
		link: {
			active: true,
			options: {}
		}
	};

	const componentsOptions = editor.addComponentsOptions(defaults);

	for (const comp in componentsOptions) {
		if (componentsOptions[comp].active) {
			registerComponent(comp, emailBuilder);
		}
	}
};
