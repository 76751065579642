/**
 * The options to configure the Icon of the ImagePlaceholder
 * @typedef { Object } ImagePlaceholderIconOptions
 * @property { string } fill - The color to fill the svg icon with.
 * @property { string } height - The height of the svg icon.
 * @property { string } viewBox - The viewBox of the svg icon.
 * @property { string } width - The width of the svg icon.
 * @memberof ImagePlaceholder
 */

/**
 * Returns svg element for image placeholder's icon
 * @param { ImagePlaceholderIconOptions } options - The options to configure the svg element.
 * @return {string } The HTML string with the SVG element for the image placeholder's icon.
 * @memberof ImagePlaceholder
 */

export default options => {
	const defaults = {
		fill: '#B5B4C3',
		height: '42',
		viewBox: '0 0 46 42',
		width: '46'
	};

	options = {
		...defaults,
		...options
	};

	return `
	<svg width="${options.width}" height="${options.height}" viewBox="${options.viewBox}" fill="${options.fill}" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.863 41.25L6.818 41.295L6.77075 41.25H2.732C2.13983 41.2494 1.57212 41.0137 1.1536 40.5948C0.735085 40.1759 0.5 39.6079 0.5 39.0158V2.98425C0.504118 2.39335 0.740526 1.82779 1.15815 1.40974C1.57578 0.99169 2.14111 0.754713 2.732 0.75H43.268C44.501 0.75 45.5 1.75125 45.5 2.98425V39.0158C45.4959 39.6066 45.2595 40.1722 44.8418 40.5903C44.4242 41.0083 43.8589 41.2453 43.268 41.25H6.863ZM41 27.75V5.25H5V36.75L27.5 14.25L41 27.75ZM41 34.113L27.5 20.613L11.363 36.75H41V34.113ZM14 18.75C12.8065 18.75 11.6619 18.2759 10.818 17.432C9.97411 16.5881 9.5 15.4435 9.5 14.25C9.5 13.0565 9.97411 11.9119 10.818 11.068C11.6619 10.2241 12.8065 9.75 14 9.75C15.1935 9.75 16.3381 10.2241 17.182 11.068C18.0259 11.9119 18.5 13.0565 18.5 14.25C18.5 15.4435 18.0259 16.5881 17.182 17.432C16.3381 18.2759 15.1935 18.75 14 18.75Z"/>
	</svg>
	`;
};
