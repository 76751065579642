import { noInteractionPreset } from '../_componentsUtils';
import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import { merge } from 'datatalks-utils';
import { toPng } from 'html-to-image';
import html from './_buildHtml';
import { getImageSize, convertDataUnit } from 'datatalks-utils';

/**
 * The options to configure the build of the ImagePlaceholder component
 * @typedef { Object } ImagePlaceholderBuildOptions
 * @property { object } attributes
 * @property { string } cssClass
 * @property { ImagePlaceholderHTMLOptions } imagePlaceholderHTMLOptions
 * @property { string } width
 * @memberof ImagePlaceholder
 */

/**
 * Returns an image placeholder component build configured with the passed options
 * @param { ImagePlaceholderBuildOptions } componentOptions
 * @param { GrapesJS.Editor } editor
 * @return { ImagePlaceholderComponentBuild }
 * @memberof ImagePlaceholder
 */
export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		attributes: {},
		cssClass: 'gjs-comp-header',
		imagePlaceholderHTMLOptions: {},
		width: '100%',
		autoRecommendedWidth: true,
		doubleDimensionValues: true,
		autoRecommendedFileSize: true
	};

	const options = merge(defaults, componentOptions, COMPONENTS_DEFAULTS);

	return {
		model: {
			defaults: () => ({
				...options,
				tagName: 'div',
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				style: {
					width: options.width
				},
				imagePlaceholderHTMLOptions:
					options.imagePlaceholderHTMLOptions,
				phHtml: null,
				components: comp => comp.componentRender(comp),
				imgSrc: null
				// ...noInteractionPreset
			}),

			init() {
				this.updatePhHtml();
				this.components(this.componentRender(this));
				editor.on('component:mount', component => {
					if (component === this)
						setTimeout(() => {
							this.set('mounted', true);
						}, 3000);
				});
			},

			updatePhHtml(recommendedWidth, recommendedFileSize) {
				if (recommendedWidth)
					this.set('imagePlaceholderHTMLOptions', {
						...this.get('imagePlaceholderHTMLOptions'),
						recommendedWidth
					});

				if (recommendedFileSize) {
					let recommendedFileSizeUnit = 'Kb';

					if (recommendedFileSize.toString().length > 9) {
						recommendedFileSizeUnit = 'Tb';
						recommendedFileSize = parseInt(
							recommendedFileSize / 1000000000
						);
					} else if (recommendedFileSize.toString().length > 6) {
						recommendedFileSizeUnit = 'Gb';
						recommendedFileSize = parseInt(
							recommendedFileSize / 1000000
						);
					} else if (recommendedFileSize.toString().length > 3) {
						recommendedFileSizeUnit = 'Mb';
						recommendedFileSize = parseInt(
							recommendedFileSize / 1000
						);
					}

					this.set('imagePlaceholderHTMLOptions', {
						...this.get('imagePlaceholderHTMLOptions'),
						recommendedFileSize,
						recommendedFileSizeUnit
					});
				}

				this.set(
					'phHtml',
					html.call(null, this.get('imagePlaceholderHTMLOptions'))
				);
			},

			updated(prop) {
				if (prop === 'mounted' && this.isHtml()) {
					this.convertToImage();
				}
				if (prop === 'imagePlaceholderHTMLOptions') {
					this.updatePhHtml();
					this.updateImage();
				}
			},

			isHtml() {
				return this.getEl()?.firstChild?.hasAttribute('data-ph');
			},

			componentRender: comp => {
				if (comp.attributes.imgSrc) {
					return {
						type: 'image',
						src: comp.attributes.imgSrc
					};
				} else {
					return comp.get('phHtml');
				}
			},

			updateImage() {
				this.set('imgSrc', null);
				this.components(this.componentRender(this));
				this.convertToImage();
			},

			convertToImage() {
				let width =
					options.imagePlaceholderHTMLOptions.recommendedWidth;
				let size =
					options.imagePlaceholderHTMLOptions.recommendedFileSize;

				if (options.autoRecommendedWidth)
					width =
						this.getEl().clientWidth *
						(options.doubleDimensionValues ? 2 : 1);

				if (options.autoRecommendedFileSize)
					size = this.updatePhHtml(width, size);

				toPng(this.getEl().firstChild)
					.then(dataUrl => {
						this.set('imgSrc', dataUrl);
						this.components(this.componentRender(this));
					})
					.catch(function (error) {
						console.error('oops, something went wrong!', error);
					});
			}
		}
	};
};
