import {
	ColorPickerInput,
	Accordion,
	SectionItem,
	Dropdown,
	InputNumber,
	ToggleableSection
} from 'datatalks-ui';
import { merge, borderStyles } from 'datatalks-utils';
import { capitalize } from 'lodash-es';

import borderRadius from './common/_borderRadius';

export default (options, customTrait = {}) => {
	const defaults = {
		accordion: {
			title: 'Border',
			color: 'grey',
			type: 'extend'
		},
		traitsVisibility: {
			borderWidth: true,
			borderStyle: true,
			borderColor: true,
			borderRadius: true
		},
		borderWidth: {
			label: 'Border width',
			value: null,
			onChange: null,
			unit: null
		},
		borderStyle: {
			label: 'Border style',
			value: null,
			onChange: null,
			resettable: false,
			resetLabel: 'Same as Email',
			resetValue: null,
			onReset: null
		},
		borderColor: {
			label: 'Border Color',
			value: null,
			onChange: null,
			emptyColor: null,
			emptyColorLabel: null,
			onReset: null
		},
		borderRadius: {
			label: 'Border Radius',
			corners: {
				topLeft: {
					value: null,
					onChange: null,
					unit: 'px',
					label: 'Top left'
				},
				topRight: {
					value: null,
					onChange: null,
					unit: 'px',
					label: 'Top right'
				},
				bottomRight: {
					value: null,
					onChange: null,
					unit: 'px',
					label: 'Bottom right'
				},
				bottomLeft: {
					value: null,
					onChange: null,
					unit: 'px',
					label: 'Bottom left'
				}
			}
		},
		showBorderRadius: false,
		onBorderRadiusToggle: null
	};

	options = merge(defaults, options);

	const borderStyleItems = borderStyles.map(style => {
		return {
			content: capitalize(style),
			value: style,
			active: options.borderStyle.value === style
		};
	});

	const styleDropdownResettable =
		typeof options.borderStyle.onReset === 'function' ||
		options.borderStyle.resettable;

	if (styleDropdownResettable) {
		borderStyleItems.unshift({
			content: options.borderStyle.resetLabel,
			value: options.borderStyle.resetValue,
			active: !options.borderStyle.value,
			isDefault: true
		});
	}

	if (options.traitsVisibility.borderColor && !customTrait.borderCp)
		customTrait.borderCp = new ColorPickerInput({
			color: options.borderColor.value,
			onChange: cpi => {
				if (typeof options.borderColor.onChange === 'function')
					options.borderColor.onChange(cpi.getColor());
			},
			onReset: options.borderColor.onReset,
			emptyColor: options.borderColor.emptyColor,
			emptyColorLabel: options.borderColor.emptyColorLabel
		});

	const borderAccordionContent = [
		options.traitsVisibility.borderWidth &&
			new SectionItem({
				label: options.borderWidth.label,
				content: new InputNumber({
					defaultValue: options.borderWidth.value,
					unit: options.borderWidth.unit,
					onChange: (value, unit, inputValue) => {
						if (typeof options.borderWidth.onChange === 'function')
							options.borderWidth.onChange(inputValue);
					}
				}).getEl()
			}).getEl(),
		options.traitsVisibility.borderColor &&
			new SectionItem({
				label: options.borderColor.label,
				content: customTrait.borderCp.getEl()
			}).getEl(),

		options.traitsVisibility.borderStyle &&
			new SectionItem({
				label: options.borderStyle.label,
				content: new Dropdown({
					items: borderStyleItems,
					onChange: (dropdown, activeItem) => {
						if (typeof options.borderStyle.onChange === 'function')
							options.borderStyle.onChange(activeItem.getValue());
					},
					resettable: styleDropdownResettable
				}).getEl()
			}).getEl(),
		options.traitsVisibility.borderRadius &&
			new ToggleableSection({
				toggleableContent: true,
				label: options.borderRadius.label,
				startOpen: options.showBorderRadius,
				onToggle: options.onBorderRadiusToggle,
				content: borderRadius(options.borderRadius)
			}).getEl()
	];

	const borderAccordion = new Accordion({
		title: options.accordion.title,
		content: borderAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return borderAccordion;
};
