import { COMPONENTS_DEFAULTS } from '../../../../config/_config';
import render from './_render';
import { getComponentsDefaultsByType } from '../../../componentsDefaults/_componentsDefaults';
import baseMethods from '../../common/methods/_baseMethods';
import { merge } from 'datatalks-utils';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = getComponentsDefaultsByType('image-wrapper');

	const options = merge(COMPONENTS_DEFAULTS, defaults, componentOptions);

	return {
		model: {
			defaults: () => ({
				...options,
				name: options.name,
				attributes: {
					...options.attributes,
					class: options.cssClass,
					src: options.imgSrc
				},
				components: comp => comp.componentRender(comp)
			}),

			cache: false,

			toHTML(toHtmlOptions = {}) {
				return toHtml(
					this,
					merge(options.toHtmlOptions, toHtmlOptions)
				);
			},

			componentRender: render,
			...baseMethods(options, editor)
		}
	};
};
