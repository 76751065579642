export default emailBuilder => {
	const pluginOpts = emailBuilder.getOptions();

	const { title, titleOnChange } = pluginOpts.titleConfig;

	const titleInput = document.createElement('input');
	titleInput.className = 'eb-input';
	titleInput.value = title;
	titleInput.addEventListener('change', e => {
		if (typeof titleOnChange === 'function' && titleInput.value != title) {
			titleOnChange(e.target.value);
		}
	});
	emailBuilder.on('change:options:titleConfig', options => {
		if (titleInput.value != options.title) titleInput.value = options.title;
	}); // TODO: possible loop if titleOnChange changes config
	return titleInput;
};
