/* eslint-disable require-jsdoc */
import { getComponentsDefaultsByType } from './componentsDefaults/_componentsDefaults';
import { getComponentsRenderByType } from './components/_componentsRender';
import { camelCase } from 'lodash-es';

function getComponentMethods(compName) {}

function getComponentRender(compName) {
	return getComponentsRenderByType(compName);
}

function getComponentDefaults(compName, prefix) {
	prefix = prefix === false ? false : prefix || compName;
	const defaults = getComponentsDefaultsByType(compName);
	const props = {};
	for (const key in defaults) {
		if (defaults.hasOwnProperty(key))
			props[prefix ? camelCase(prefix + ' ' + key) : key] = defaults[key];
	}
	return props;
}

export {
	getComponentMethods,
	getComponentRender,
	getComponentDefaults
	// availableComponents
};
