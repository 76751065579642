/* eslint-disable require-jsdoc */
export default function getStyleTabOptions(obj, accordion) {
	const editor = obj.component.getEditor();
	const localize = obj.component.localize;
	const defaults = {
		border: {
			borderWidth: {
				// label: 'Border width',
				value:
					obj.component.get('borderWidth') ||
					editor.getStyleRules('component', 'border-width') ||
					0,
				onChange: width =>
					obj.component.setAndRerender('borderWidth', width)
			},
			borderStyle: {
				// label: 'Border style',
				value: obj.component.get('borderStyle'),
				onChange: style =>
					obj.component.setAndRerender('borderStyle', style),
				resettable: true,
				resetLabel: localize('Same as email'),
				resetValue: null,
				onReset: obj.component.setAndRerender('borderStyle', null)
			},
			borderColor: {
				// label: 'Border Color',
				value: obj.component.get('borderColor'),
				onChange: color =>
					obj.component.setAndRerender('borderColor', color),
				onReset: () =>
					obj.component.setAndRerender('borderColor', null),
				emptyColor: () =>
					editor.getStyleRules('component', 'border-color'),
				emptyColorLabel: localize('Same as email')
			},
			borderRadius: {
				// label: 'Border Radius',
				corners: {
					topLeft: {
						value: obj.component.get('borderRadius')?.topLeft,
						onChange: value =>
							obj.component.setAndRerender('borderRadius', {
								...obj.component.get('borderRadius'),
								topLeft: value
							}),
						unit: 'px'
						// label: 'Top left'
					},
					topRight: {
						value: obj.component.get('borderRadius')?.topRight,
						onChange: value =>
							obj.component.setAndRerender('borderRadius', {
								...obj.component.get('borderRadius'),
								topRight: value
							}),
						unit: 'px'
						// label: 'Top right'
					},
					bottomRight: {
						value: obj.component.get('borderRadius')?.bottomRight,
						onChange: value =>
							obj.component.setAndRerender('borderRadius', {
								...obj.component.get('borderRadius'),
								bottomRight: value
							}),
						unit: 'px'
						// label: 'Bottom right'
					},
					bottomLeft: {
						value: obj.component.get('borderRadius')?.bottomLeft,
						onChange: value =>
							obj.component.setAndRerender('borderRadius', {
								...obj.component.get('borderRadius'),
								bottomLeft: value
							}),
						unit: 'px'
						// label: 'Bottom left'
					}
				}
			},
			showBorderRadius: obj.component.get('useBorderRadius'),
			onBorderRadiusToggle: isActive =>
				obj.component.setAndRerender('useBorderRadius', isActive)
		}
	};

	return accordion ? defaults[accordion] : defaults;
}
