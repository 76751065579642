import orderSummaryIcon from '../../assets/blocks/icons/order-summary.svg';
import mapAttributes from '../common/_handlebarsAttributesMapping';

const block = {
	id: 'order-summary',
	name: 'Order summary',
	label: 'Order summary',
	icon: orderSummaryIcon,
	getContent: (payload, options) => [
		{
			type: 'image-wrapper',
			imgSrc:
				options.previewImageSrc || options.getPreviewImageSrc(payload)
		}
	],
	hasValidPayload: payload => {
		return (
			payload.previewImageSrc &&
			typeof payload.previewImageSrc === 'string'
		);
	},
	toHTML: (payload, options, toHtmlOptions) => {
		let template = `
			<style>
				.order-summary__table {
					width: 100%;
					border-collapse: collapse;
				}

				.order-summary__table td {
					padding-top: 10px;
					border-bottom: none;
					border: none;
				}

				.order-summary__table .order-summary__spacer {
					width: 1px;
					height: 1px;
					padding: 25px;
				}

				.order-summary__table tfoot td {
					padding-bottom: 0;
				}

				.order-summary__table tfoot tr:not(:first-child) td {
					padding-top: 0;
				}

				.order-summary__table tfoot {
					border-top: 1px solid #ddd;
				}

				.order-summary__product-image {
					width: 50px;
					margin-right: 10px;
				}

				.order-summary__product-image img {
					width: 100%;
				}

				.order-summary__value {
					text-align: right;
				}

				.order-summary-item__description,
				.order-summary__total-value,
				.order-summary-item__price,
				.order-summary__total-label {
					font-weight: bold;
				}
			</style>

			<table class="order-summary__table">
				<tbody>
					{{#each OrderLine}}
						<tr data-template="order-summary-item">
							<td><img src="{{ImageURL}}" class="order-summary__product-image" alt="{{ProductName}}"></td>
							<td>
								<p class="order-summary-item__eta">{{EstimatedDeliveryDate}}</p>
								<p class="order-summary-item__description">{{qtyOrdered}}x {{ProductName}}</p>
							</td>
							<td class="order-summary__value order-summary-item__price">{{OrderLineTotalWithTax}}</td>
						</tr>
					{{/each}}
					<tr>
						<td class="order-summary__spacer"></td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2">Frakt</td>
						<td class="order-summary__value">{{TotalDeliveryCostWithVat}}</td>
					</tr>

					<tr>
						<td colspan="2" class="order-summary__total-label">Summa (inkl. moms)</td>
						<td class="order-summary__value order-summary__total-value">{{OrderTotalWithTax}}</td>
					</tr>
				</tfoot>
			</table>
		`;
		template = mapAttributes(template, options.attributesMap);
		return template;
	}
};

export { block };
