/* eslint-disable require-jsdoc */
import { isEqual } from 'lodash-es';
import { findDifferences } from 'datatalks-utils';
import styleIsEqual from './_styleDiffChecker';

export default function (emailBuilder, log = false) {
	const { editor } = emailBuilder;

	const storedData = emailBuilder.getStoredData();
	const projectData = JSON.parse(
		JSON.stringify(emailBuilder.getDataToStore(editor.getProjectData()))
	);

	if (styleIsEqual(storedData?.styles || [], projectData.styles || [])) {
		if (!isEqual(storedData?.config?.styles, projectData?.config?.styles))
			return false;

		if (!isEqual(storedData?.config?.layout, projectData?.config?.layout))
			return false;

		let aux1;
		if (storedData?.config?.components?.length) {
			aux1 = storedData.config.components || [];
		} else {
			aux1 = [];
		}
		if (aux1.length) {
			aux1.forEach(comp => {
				if (comp.components) delete comp['components'];
				if (comp.customTrait) delete comp['customTrait'];
			});
		}

		const aux2 = projectData.config.components || [];
		if (aux2.length) {
			aux2.forEach(comp => {
				if (comp.components) delete comp['components'];
				if (comp.customTrait) delete comp['customTrait'];
			});
		}

		if (log) findDifferences(aux1, aux2);

		return isEqual(aux1, aux2);
	} else {
		return false;
	}
}
