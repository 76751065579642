/* eslint-disable require-jsdoc */
import { addClassesString, setContent, EventEmitter } from 'datatalks-utils';
import { DropdownButtonItem } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import { computePosition, flip, offset } from '@floating-ui/dom';

/**
 * The options to configure the Dropdown Button
 * @typedef { Object } DropdownButtonOptions
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string= } cssClass - The base CSS class of the wrapper element of
 * the Dropdown. @default 'dropdown-button'
 * @property { Array<(DropdownButtonItem | DropdownButtonItemOptions)> } items
 * An array of items to use inside the dropdown (as dropdown options). @default []
 * @property { string= } extendedClasses - CSS classes to extend the style
 * of the Dropdown. @default ''
 * @property { (string | HTMLString)= } control - The content of the button that triggers
 * the dropdown. @default 'Select an item'
 * @property { (string | HTMLString)= } arrowDown - The icon to display on the dropdown
 * when it's opened.
 * @property { (string | HTMLString)= } arrowUp - The icon to display on the dropdown
 * when it's closed.
 * @property { DropdownButtonOnControlClickCallback= } onControlClick - The function to call when the control
 * of the dropdown is clicked
 * @property { boolean } closeDropdownOnItemClick - Whether or not the Dropdown should
 * close when an item is clicked. @default true
 * @property { boolean } toggleDropdownOnControlClick - Whether the Dropdown should toggle or just
 * open when the control is clicked. @default true
 * @property { boolean } onControlClickPreventDefault - Whether or not the default behavior should
 * be prevented when the control is clicked.  @default false
 * @property { boolean } startOpened - Whether or not the dropdown should start opened. @default false
 * @property { boolean } openShowsArrowUp - If true, when opened the dropdown will show the arrow up,
 * otherwise it will show the arrow down. The opposite for the closed state. @default true
 */

/**
 * A function to call when the control of the dropdown is clicked
 * @callback DropdownOnControlClickCallback
 * @param { Dropdown } dropdown - The dropdown itself
 */

/**
 * Class representing a Dropdown.
 * @class
 * @property { DropdownButtonOptions }  options - The passed options merged with the default values
 * to configure the Dropdown.
 * @property { DropdownItem[] }  items - The items to fill the dropdown options with.
 * @property { boolean }  isOpen - Whether or not the dropdown is opened.
 * @property { HTMLElement }  element - The wrapper element of the dropdown.
 */
export default class Dropdown {
	/**
	 * Creates the needed properties to build the Dropdown. Calls convertItems method.
	 * Calls init method at last.
	 * @constructor
	 * @param { DropdownButtonOptions } options - The options to create the dropdown.
	 */
	constructor(options = {}) {
		const defaults = {
			items: [],
			classPrefix: 'eb-',
			cssClass: 'dropdown-button',
			extendedClasses: '',
			modifierClasses: [],
			control: 'Select an item',
			arrowDown: getIcon('arrow-down-s-line', { size: 'xl' }),
			arrowUp: getIcon('arrow-up-s-line', { size: 'xl' }),
			OnControlClick: null,
			closeDropdownOnItemClick: true,
			toggleDropdownOnControlClick: true,
			onControlClickPreventDefault: false,
			onControlClickStopPropagation: false,
			closeDropdownOnWindowClick: true,
			startOpened: false,
			placeholder: 'Select an item',
			openShowsArrowUp: true,
			isSelect: false,
			allowMultiSelection: true,
			itemIsSelected: null,
			useControlContentTemplate: false,
			useFloater: true,
			controlContentTemplate: (activeItems, dropdown) => {
				let content;
				if (
					activeItems &&
					(activeItems.length || !Array.isArray(activeItems))
				) {
					if (
						dropdown.isSelect &&
						dropdown.allowMultiSelection &&
						activeItems?.length > 1
					) {
						content = `${activeItems.length} items selected`;
					} else {
						content = Array.isArray(activeItems)
							? activeItems[0].content
							: activeItems.content;
					}
				} else {
					content = dropdown.placeholder;
				}
				return setContent(document.createElement('div'), content);
			},
			useArrow: true
		};

		defaults.closeDropdownOnSelectableItemClick = options.hasOwnProperty(
			'closeDropdownOnItemClick'
		)
			? options.closeDropdownOnItemClick
			: defaults.closeDropdownOnItemClick;

		this.options = {
			...defaults,
			...options
		};

		this.eventEmitter = new EventEmitter();

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.items = this.options.items;
		this.isOpen = this.options.startOpened;
		this.element = null;
		this.isSelect = this.options.isSelect;
		this.itemIsSelected = this.options.itemIsSelected;
		this.allowMultiSelection = this.options.allowMultiSelection;
		this.placeholder = this.options.placeholder;
		if (this.allowMultiSelection) {
			this.activeItem = [];
		} else {
			this.activeItem = null;
		}

		if (this.items.length) {
			this.convertItems();
			this.init();
		}
	}

	/**
	 * Converts the items passed as an object in DropdownButtonItem instances.
	 * @method
	 */
	convertItems() {
		this.items = this.items.map(item => {
			if (item instanceof DropdownButtonItem) {
				if (this.isSelect && !item.isSelectable)
					console.error(
						'Item must be selectable in a dropdown with isSelect option set to true'
					);
				return item;
			} else {
				if (this.isSelect && !item.selectable) {
					console.info('Converting item to selectable');
					return new DropdownButtonItem({
						...item,
						selectable: true
					});
				}

				return new DropdownButtonItem(item);
			}
		});
	}

	destroy() {
		this.eventEmitter.emit('destroy');
	}

	/**
	 * Creates the wrapper element, assigns it to element property and adds the attributes.
	 * Calls draw method.
	 * @method
	 */
	init() {
		this.element = document.createElement('div');
		this.element.classList.add(this.className);
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);

		if (this.options.modifierClasses.length) {
			if (!Array.isArray(this.options.modifierClasses))
				this.options.modifierClasses =
					this.options.modifierClasses.split(' ');
			addClassesString(
				this.element,
				this.options.modifierClasses
					.map(modClass => `${this.className}--${modClass}`)
					.join(' ')
			);
		}

		this.setupItems();
		this.createControl();
		this.createControlArrow();
		this.createControlContent();
		this.createItemsContainer();

		this.setupOutClickEvents();

		this.draw();
		if (this.isOpen) this.open;
	}

	/**
	 * Adds the listeners to clicks outside the dropdown and closes the dropdown based on options.
	 * @method
	 */
	setupOutClickEvents() {
		const dropdown = this;
		if (dropdown.options.closeDropdownOnWindowClick) {
			const cb = e => {
				if (
					dropdown.isOpen &&
					(!e ||
						!(
							e.target == dropdown.options.arrowDown ||
							e.target == dropdown.options.arrowUp ||
							e.target == dropdown.element ||
							dropdown.element.contains(e.target)
						))
				)
					dropdown.close();
			};
			document.body.addEventListener('click', cb);
			dropdown.on('destroy', () =>
				document.body.removeEventListener('click', cb)
			);
			const messageCb = ev => {
				if (dropdown.isOpen && ev.data === 'click') {
					cb();
				}
			};
			window.addEventListener('message', messageCb);
			dropdown.on('destroy', () =>
				window.removeEventListener('message', messageCb)
			);
		}
	}

	/**
	 * Calls methods: setupItems, createControl, drawControl
	 * @method
	 */
	draw() {
		this.drawControl();
		this.drawItemsContainer();
		if (this.options.useFloater) this.setupFloater();
	}

	/**
	 * Sets up the floating events and properties for the items container.
	 */
	setupFloater() {
		computePosition(this.control, this.itemsContainer, {
			placement: 'bottom-start',
			middleware: [flip(), offset(2)]
		}).then(({ x, y }) => {
			this.element.style.setProperty('--floater-coords-left', `${x}px`);
			this.element.style.setProperty('--floater-coords-top', `${y}px`);
		});
	}

	/**
	 * Loops through the items to add the class and set a click event listener to call
	 * onItemClick method.
	 * @method
	 */
	setupItems() {
		const dropdown = this;
		dropdown.items.forEach(item => {
			const el = item.getEl();
			el.classList.add(`${dropdown.className}__item`);
			el.addEventListener('click', e => {
				dropdown.onItemClick.call(dropdown, item);
			});
		});
	}

	/**
	 * Runs when an item is clicked
	 * @method
	 * @param { (DropdownItem) } item - the item that is clicked
	 */
	onItemClick(item) {
		if (
			(item.isSelectable &&
				this.options.closeDropdownOnSelectableItemClick) ||
			(!item.isSelectable && this.options.closeDropdownOnItemClick)
		)
			this.close();

		if (this.isSelect) {
			this.deselectAllItems();
			item.select();
			if (this.allowMultiSelection) {
				this.activeItem.push(item);
				this.activateDropdown();
			} else {
				this.deselectAllItems();
				this.activeItem = item;
				this.activateDropdown();
			}
			this.updateControlContent();
		}
	}

	deselectAllItems() {
		this.items.forEach(item => {
			item.deselect();
		});
		this.deactivateDropdown();
	}

	updateAllItemsSelection(
		predicate,
		options = { preventCallbacks: true, updateControl: true }
	) {
		const dropdown = this;
		dropdown.activeItem = [];
		this.deactivateDropdown();
		if (typeof dropdown.itemIsSelected === 'function') {
			dropdown.items.forEach(item => {
				if (dropdown.itemIsSelected(item, predicate)) {
					item.select(options.preventCallbacks);
					dropdown.activeItem.push(item);
					this.activateDropdown();
				} else {
					item.deselect(options.preventCallbacks);
				}
			});
		}
		if (options.updateControl) this.updateControlContent();
	}

	/**
	 * Creates the control element and assigns it to the control property. Adds its
	 * classes and event listeners.
	 * @method
	 */
	createControl() {
		this.control = document.createElement('div');
		this.control.classList.add(`${this.className}__control`);
		this.control.addEventListener('click', e => {
			if (this.options.onControlClickStopPropagation) e.stopPropagation();
			this.onControlClick.call(this);
		});
	}

	/**
	 * Creates the control element and assigns it to the controlContent property. Adds its
	 * CSS classes.
	 * @method
	 */
	createControlContent() {
		this.controlContent = document.createElement('div');
		this.controlContent.classList.add(`${this.className}__control-content`);
	}

	/**
	 * Creates the control arrow element and assigns it to the controlArrow property. Adds its
	 * CSS classes.
	 * @method
	 */
	createControlArrow() {
		this.controlArrow = document.createElement('div');
		this.controlArrow.classList.add(`${this.className}__control-arrow`);
	}

	/**
	 * Handles the Control click. Toggles the dropdown by default. Calls options.onControlClick if passed
	 * @method
	 */
	onControlClick() {
		if (!this.options.onControlClickPreventDefault) {
			if (this.options.toggleDropdownOnControlClick) {
				this.toggle();
			} else {
				this.open();
			}
		}
		if (typeof this.options.onControlClick === 'function')
			this.options.onControlClick.call(null, this);
	}

	/**
	 * Updates the content of the control by calling updateControlContent method with the current
	 * active item. Draws the control inside the wrapper element
	 * @method
	 */
	drawControl() {
		this.updateControlContent(this.activeItem);
		this.updateControlArrow();
		this.control.append(this.controlContent);
		if (this.options.useArrow) this.control.append(this.controlArrow);
		this.element.append(this.control);
	}

	/**
	 * Creates the wrapper element that contains the options. Adds its class name.
	 * @method
	 */
	createItemsContainer() {
		this.itemsContainer = document.createElement('div');
		this.itemsContainer.classList.add(`${this.className}__options`);
		this.element.append(this.itemsContainer);
	}

	/**
	 * Clears the options' wrapper inner HTML and append each item's element'.
	 * @method
	 */
	drawItemsContainer() {
		const dropdown = this;
		dropdown.itemsContainer.innerHTML = '';
		dropdown.itemsContainer.append(
			...dropdown.items.map(item => item.getEl())
		);
	}

	/**
	 * Draws the control content inside the control element.
	 * @method
	 */
	updateControlContent() {
		if (
			typeof this.options.controlContentTemplate === 'function' &&
			this.options.useControlContentTemplate
		) {
			const newContent = this.options.controlContentTemplate(
				this.activeItem,
				this
			);
			if (newContent.outerHTML !== this.controlContent.innerHTML)
				setContent(this.controlContent, newContent);
		} else {
			setContent(this.controlContent, this.options.control);
		}
	}

	/**
	 * Updates the arrow of the dropdown based on the isOpen property and on the option
	 * regarding what arrow to choose when the dropdown is open (options.openShowsArrowUp)
	 * @method
	 */
	updateControlArrow() {
		if (
			(this.isOpen && this.options.openShowsArrowUp) ||
			(!this.isOpen && !this.options.openShowsArrowUp)
		) {
			setContent(this.controlArrow, this.options.arrowUp);
		} else {
			setContent(this.controlArrow, this.options.arrowDown);
		}
	}

	/**
	 * Opens the dropdown by adding the 'open' modifier class and setting 'isOpen'
	 * property to 'true'.
	 * @method
	 */
	open() {
		this.element.classList.add(`${this.className}--open`);
		this.isOpen = true;
		this.updateControlArrow();
		if (this.options.useFloater) this.setupFloater();
	}

	/**
	 * Closes the dropdown by removing the 'open' modifier class and setting
	 * 'isOpen' property to 'false'.
	 * @method
	 */
	close() {
		this.element.classList.remove(`${this.className}--open`);
		this.isOpen = false;
		this.updateControlArrow();
	}

	/**
	 * Toggles the dropdown by calling close method if the dropdown is opened
	 * (if the property 'isOpen' is set to 'true'), or by calling the close
	 * method in the opposite case.
	 * @method
	 */
	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	activateDropdown() {
		this.element.classList.add(`${this.className}--active`);
	}

	deactivateDropdown() {
		this.element.classList.remove(`${this.className}--active`);
	}

	/**
	 * returns the wrapper element of the dropdown (Dropdown.element).
	 * @method
	 * @return {HTMLElement} The element bounded to the 'element' property.
	 */
	getEl() {
		return this.element;
	}

	/**
	 * Registers an event listener for the specified event.
	 *
	 * @param {string} event - The name of the event to listen for.
	 * @param {Function} callback - The function to be called when the event is triggered.
	 */
	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	/**
	 * Unsubscribes a callback function from an event.
	 *
	 * @param {string} event - The name of the event to unsubscribe from.
	 * @param {Function} callback - The callback function to unsubscribe.
	 */
	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
