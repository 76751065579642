import { merge } from 'datatalks-utils';

const SCOPE_TYPES = {
	global: {
		name: 'global',
		level: 1
	},
	component: {
		name: 'molecule',
		level: 2
	},
	innerComponent: {
		name: 'atom',
		level: 3
	}
};

/* eslint-disable require-jsdoc */
export default (editor, options = {}) => {
	class ScopeManager {
		constructor(options) {
			const defaults = {
				editor,
				items: [
					{
						label: 'Edit Email',
						onSelect: (editor, item) => {
							editor.deselectAll();
						},
						onBack: console.log,
						type: SCOPE_TYPES.global
					}
				]
			};

			this.options = merge(defaults, options);
			this.editor = this.options.editor;
			this.items = this.options.items.sort((a, b) => {
				return a.level === b.level ? 0 : a.level < b.level ? -1 : 1;
			});
		}

		setInitialContext() {
			const item = this.items[this.items.length - 1];
			const options = {
				label: item.label,
				goBack: this.items.length > 1 ? this.goBack.bind(this) : null,
				callback: null
			};
			this.editor.changeContext(options);
		}

		goBack(callChangeContext = true) {
			if (this.items.length > 1) {
				const prevItem = this.items.pop();
				const item = this.items[this.items.length - 1];
				if (typeof prevItem.onBack === 'function')
					prevItem.onBack(editor, prevItem, item);
				const options = {
					label: item.label,
					goBack:
						this.items.length > 1 ? this.goBack.bind(this) : null,
					callback: null
				};
				if (typeof item.onSelect === 'function')
					options.callback = item.onSelect.bind(
						null,
						this.editor,
						item
					);
				if (callChangeContext) this.editor.changeContext(options);
			} else {
				console.error(
					'Cannot go back as this is the top context to reach'
				);
			}
		}

		shouldDrawBackButton() {
			return this.items.length > 1;
		}

		addItem(itemOptions) {
			if (
				!Object.values(SCOPE_TYPES).includes(itemOptions.type) &&
				!Object.values(SCOPE_TYPES)
					.map(type => type.name)
					.includes(itemOptions.type)
			) {
				this.throwError('invalidType');
				return;
			} else if (!Object.values(SCOPE_TYPES).includes(itemOptions.type)) {
				itemOptions.type = Object.values(SCOPE_TYPES).filter(
					type => type.name === itemOptions.type
				)[0];
			}
			if (
				this.items[this.items.length - 1].type?.level >=
				itemOptions.type.level
			) {
				while (
					this.items[this.items.length - 1].type.level >=
					itemOptions.type.level
				) {
					this.goBack(false);
				}
			}
			this.items.push(itemOptions);
			const item = this.items[this.items.length - 1];
			const options = {
				label: item.label,
				goBack: this.items.length > 1 ? this.goBack.bind(this) : null,
				callback: null
			};
			if (typeof item.onSelect === 'function')
				options.callback = item.onSelect.bind(this.editor, item);
			this.editor.changeContext(options);
		}

		throwError(errorType) {
			switch (errorType) {
				case 'invalidType':
					console.error(
						`Scope item type is not valid. It must be one of the following types: ${Object.values(
							SCOPE_TYPES
						)
							.map(type => type.name)
							.join(', ')}`
					);
					break;

				default:
					break;
			}
		}
	}

	editor.ScopeManager = new ScopeManager({
		editor
	});
};
