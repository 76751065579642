import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import render from './_render';
import methods from './_methods';
import { merge } from 'datatalks-utils';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	let defaults = {
		attributes: {},
		spacingBetweenElements: '12px',
		horizontalSpacingBetweenElements: '12px',
		cssClass: 'gjs-comp-footer',
		width: '100%',
		tagName: 'table',
		titlePh: 'Keep in touch and follow us',
		titleAlignment: 'center',
		titleStyle: {
			color: null
		},
		displayTitle: true,
		descriptionPh: '@2022 Your brand. All rights reserved',
		descriptionAlignment: 'center',
		descriptionStyle: {
			color: null
		},
		displayDescription: true,
		socialListGap: '0px',
		socialListSideways: false,
		displaySocialList: true,
		socialListAlignment: 'center',
		socialListVAlignment: 'bottom',
		socialListLeftMargin: '0px',
		socialListRightMargin: '0px',
		socialListTopMargin: '0px',
		socialListBottomMargin: '0px',
		socialListIsWrapable: false,
		useLinksGap: true,
		linksListWidth: 'auto',
		links: '<p><a href="https://app.datatalks.se/">DataTalks CDP</a> | <a href="https://www.datatalks.se/">DataTalks Website</a></p>',
		linksAlignment: 'center',
		linksStyle: {
			color: null
		},
		linksBackground: 'transparent',
		linksWidth: '100%',
		linksBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		linksBorderColor: 'transparent',
		linksBorderStyle: 'solid',
		linksBorderWidth: 0,
		iconWidth: '36px',
		displayLinks: true,
		name: 'Footer',
		addListItemCallback: null,
		deleteLinkCallback: null,
		toHtmlOptions: {}
	};

	defaults = {
		...defaults,
		socialList: [
			{
				content: {
					type: 'image-wrapper',
					attributes: {
						src: null
					},
					style: {
						width: componentOptions.iconWidth || defaults.iconWidth
					},
					renderWidth:
						componentOptions.iconWidth || defaults.iconWidth
				},
				href: 'http://www.facebook.com',
				name: 'Facebook',
				target: '_blank'
			},
			{
				content: {
					type: 'image-wrapper',
					attributes: {
						src: null
					},
					style: {
						width: componentOptions.iconWidth || defaults.iconWidth
					},
					renderWidth:
						componentOptions.iconWidth || defaults.iconWidth
				},
				href: 'http://www.instagram.com',
				name: 'Instagram',
				target: '_blank'
			},
			{
				content: {
					type: 'image-wrapper',
					attributes: {
						src: null
					},
					style: {
						width: componentOptions.iconWidth || defaults.iconWidth
					},
					renderWidth:
						componentOptions.iconWidth || defaults.iconWidth
				},
				href: 'http://www.twitter.com',
				name: 'Twitter',
				target: '_blank'
			}
		],
		newListItem: {
			content: {
				type: 'image-wrapper',
				attributes: {
					src: null
				},
				style: {
					width: componentOptions.iconWidth || defaults.iconWidth
				},
				renderWidth: componentOptions.iconWidth || defaults.iconWidth
			},
			href: 'http://www.newItem.com',
			name: 'New Item',
			target: '_blank'
		}
	};

	const options = merge(
		COMPONENTS_DEFAULTS,
		getComponentsDefaultsByType(),
		defaults,
		componentOptions
	);

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				...options,
				title: `<h3>${options.titlePh}</h3>`,
				description: `<h5>${options.descriptionPh}</h5>`,
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				style: {
					width: options.width
				},
				socialList: [...options.socialList],
				components: comp => comp.componentRender(comp),
				traits: comp => comp.buildTrait(comp)
			}),

			init() {
				this.setStyle({
					...this.getStyle(),
					width: this.get('width')
				});
			},

			toHTML(toHtmlOptions = {}) {
				return toHtml(
					this,
					merge(options.toHtmlOptions, toHtmlOptions)
				);
			},

			componentRender: render,
			...methods(options, editor)
		}
	};
};
