export default {
	attributes: {},
	cssClass: 'gjs-comp-link-list',
	linksAlignment: 'center',
	linksList: [
		{
			content: 'Title 1',
			href: 'http://www.title1.com',
			target: '_blank'
		},
		{
			content: 'Title',
			href: 'http://www.title2.com',
			target: '_blank'
		},
		{
			content: 'Title',
			href: 'http://www.title3.com',
			target: '_blank'
		}
	],
	linksColor: null,
	linksBackground: 'transparent',
	linksFont: null,
	linksWeight: 'normal',
	linksHasMargins: false,
	linksLeftMargin: '0px',
	linksRightMargin: '0px',
	linksTopMargin: '0px',
	linksBottomMargin: '0px',
	linksLetterSpacing: 'auto',
	gap: '20px',
	listWidth: '100%',
	listAlign: 'center',
	tagName: 'table',
	name: 'Links List',
	isTextList: false,
	toHtmlOptions: {},
	isWrapable: true
};
