/* eslint-disable require-jsdoc */
import {
	merge,
	isElement,
	setContent,
	addClassesString
} from 'datatalks-utils';
import { Input, InputNumber } from 'datatalks-ui';

export default class InputLabel {
	constructor(options = {}) {
		const defaults = {
			input: null,
			label: null,
			labelExtendedClasses: '',
			inputWrapperExtendedClasses: '',
			extendedClasses: '',
			isNumber: false,
			prefix: 'eb-',
			cssClass: 'input-label',
			sideBySide: false
		};
		this.options = merge(defaults, options);
		this.input = this.options.input;
		this.label = this.options.label;
		this.isNumber = this.options.isNumber;
		this.prefix = this.options.prefix;
		this.cssClass = this.options.cssClass;
		this.className = `${this.prefix}${this.cssClass}`;
		this.init();
	}

	init() {
		this.setupInput();
		this.createInputWrapper();
		this.createLabel();
		this.createWrapper();
	}

	setupInput() {
		if (this.input instanceof InputNumber) {
			this.isNumber = true;
		} else if (this.input instanceof Input) {
			this.isNumber = false;
		} else if (isElement(this.input)) {
			return;
		} else if (this.isNumber) {
			this.input = new InputNumber(this.input || {});
		} else {
			this.input = new Input(this.input || {});
		}
	}

	createInputWrapper() {
		this.inputWrapper = addClassesString(
			setContent(document.createElement('div'), this.getInput()),
			`${this.className}__input`
		);

		if (this.options.inputWrapperExtendedClasses)
			addClassesString(
				this.inputWrapper,
				this.options.inputWrapperExtendedClasses
			);
	}

	createLabel() {
		this.label = addClassesString(
			setContent(document.createElement('label'), this.label),
			`${this.className}__label`
		);

		if (this.options.labelExtendedClasses)
			addClassesString(this.label, this.options.labelExtendedClasses);
	}

	getInput() {
		if (isElement(this.input)) {
			return this.input;
		} else {
			return this.input.getEl();
		}
	}

	createWrapper() {
		this.element = document.createElement('div');
		this.element.className = this.className;
		if (this.options.sideBySide)
			addClassesString(this.element, `${this.className}--inline`);
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
		setContent(this.element, [this.label, this.inputWrapper]);
	}

	getEl() {
		return this.element;
	}
}
