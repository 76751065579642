/* eslint-disable require-jsdoc */
import { addSpace as _addSpace } from '../_componentsUtils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';
import updateStyle from '../common/_updateCommonStyle';

export default comp => {
	let innerComponents = [];
	const addSpace = _addSpace.bind(null, comp, true, innerComponents);

	if (comp.get('displayTitle')) {
		innerComponents.push({
			type: 'row',
			components: {
				attributes: {
					align: comp.get('titleAlignment')
				},
				style: validateStyleObj(comp.get('titleStyle')),
				type: 'cell',
				components: {
					type: 'text',
					text: comp.attributes.title
				}
			}
		});
	}

	if (comp.get('displayDescription')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			components: {
				attributes: {
					align: comp.get('descriptionAlignment')
				},
				style: validateStyleObj(comp.get('descriptionStyle')),
				type: 'cell',
				components: {
					type: 'text',
					text: comp.attributes.description
				}
			}
		});
	}

	const socialListGapAndMargins = comp.get('socialListIsWrapable')
		? {
				linksHasMargins: true,
				gap: '0px',
				linksLeftMargin:
					parseFloat(comp.get('socialListGap')) / 2 + 'px',
				linksRightMargin:
					parseFloat(comp.get('socialListGap')) / 2 + 'px',
				linksTopMargin:
					parseFloat(comp.get('socialListGap')) / 2 + 'px',
				linksBottomMargin:
					parseFloat(comp.get('socialListGap')) / 2 + 'px'
		  }
		: { linksHasMargins: false, gap: comp.get('socialListGap') };

	if (comp.get('displaySocialList') && !comp.get('socialListSideways')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			attributes: {
				align: 'center'
			},
			components: {
				type: 'cell',
				components: {
					type: 'links-list',
					linksList: comp.attributes.socialList,
					listWidth: comp.attributes.linksListWidth,
					listAlign: comp.get('socialListAlignment'),
					isWrapable: comp.get('socialListIsWrapable'),
					...socialListGapAndMargins
				}
			}
		});
	}

	if (comp.get('displayLinks')) {
		if (innerComponents.length) addSpace();
		innerComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				attributes: {
					align: comp.get('linksAlignment')
				},
				components: {
					type: 'table',
					style: validateStyleObj({
						'border-collapse': 'separate',
						'background-color': comp.attributes.linksBackground,
						'border-radius': `${Object.values(
							comp.attributes.linksBorderRadius
						).join(' ')}`,
						'border-color': comp.attributes.linksBorderColor,
						'border-style': comp.attributes.linksBorderStyle,
						'border-width': comp.attributes.linksBorderWidth,
						width: comp.attributes.linksWidth
					}),
					components: {
						type: 'row',
						components: {
							type: 'cell',
							attributes: {
								align: 'left'
							},
							style: validateStyleObj({
								...comp.get('linksStyle')
							}),
							components: {
								type: 'text',
								text: comp.attributes.links
							}
						}
					}
				}
			}
		});
	}

	const getListWidth = () =>
		(parseFloat(comp.get('iconWidth')) +
			parseFloat(comp.get('socialListRightMargin')) +
			parseFloat(comp.get('socialListLeftMargin'))) *
			comp.get('socialList').length +
		parseFloat(comp.get('socialListGap')) *
			(comp.get('socialList').length - 1) +
		'px';

	if (comp.get('displaySocialList') && comp.get('socialListSideways')) {
		innerComponents = [
			{
				type: 'row',
				components: [
					{
						type: 'cell',
						components: {
							type: 'table',
							components: innerComponents
						}
					},
					{
						type: 'space',
						isVertical: false,
						dimension: comp.get('horizontalSpacingBetweenElements')
					},
					{
						type: 'cell',
						style: {
							'vertical-align': comp.get('socialListVAlignment'),
							width:
								comp.attributes.linksListWidth === 'auto'
									? getListWidth()
									: comp.attributes.linksListWidth
						},
						components: {
							type: 'links-list',
							linksList: comp.attributes.socialList,
							isWrapable: comp.get('socialListIsWrapable'),
							listWidth: '100%',
							listAlign: comp.get('socialListAlignment'),
							...socialListGapAndMargins
						}
					}
				]
			}
		];
	}

	updateStyle(comp);

	return [
		{
			type: 'margins',
			innerComponents,
			hasMargins: comp.get('hasMargins'),
			margins: {
				top: comp.get('topMargin'),
				right: comp.get('rightMargin'),
				bottom: comp.get('bottomMargin'),
				left: comp.get('leftMargin')
			},
			marginsColor: {
				top: comp.get('topMarginColor'),
				right: comp.get('rightMarginColor'),
				bottom: comp.get('bottomMarginColor'),
				left: comp.get('leftMarginColor')
			}
		}
	];
};
