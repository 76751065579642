import editorDropConfigFile from '../assets/common/_editorDropConfigFile';

export default editor => {
	// Flag Wrapper with DevMode attribute
	if (editor.getEmailBuilderOptions().devMode)
		editor
			.getWrapper()
			.getEl()
			.setAttribute(
				'data-dev-mode',
				editor.getEmailBuilderOptions().devMode
			);

	// Listen for config file drop
	editorDropConfigFile(editor);

	// Listen to canvas click to change context and send message to parent window
	editor.Canvas.getDocument().body.addEventListener('click', e => {
		window.parent.postMessage('click', '*');
		if (e.target === editor.Canvas.getDocument().body) {
			editor.deselectAll();
			editor.ScopeManager.goBack();
		}
	});

	// Set base theme
	editor.setBaseTheme();
	editor.getEmailBuilder().applyGeneralStyles();
	if (editor.getEmailBuilderOptions().autoLoad) editor.StorageManager.load();
};
