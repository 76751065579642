import { merge } from 'datatalks-utils';
import buttonAccordion from '../../../Hero/components/contentTab/_buttonAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		extendedClasses: '',
		buttonAccordionOptions: {
			textEditorProp: 'buttonEditorx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const accordion = buttonAccordion.call(
		null,
		obj,
		options.buttonAccordionOptions,
		customTrait
	);

	const contentTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return contentTab;
};
