import {
	$createParagraphNode,
	$getSelection,
	$isRangeSelection,
	SELECT_ALL_COMMAND,
	FORMAT_TEXT_COMMAND
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { $createCodeNode } from '@lexical/code';
import { $createHeadingNode } from '@lexical/rich-text';
import {
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	REMOVE_LIST_COMMAND
} from '@lexical/list';
import { getIcon as _getIcon } from 'datatalks-icons';
import { merge } from 'datatalks-utils';

export default (toolbar, editor, options) => {
	const defaults = {
		formattersVisibility: {
			code: true,
			paragraph: true,
			h1: true,
			h2: true,
			h3: true,
			h4: true,
			h5: true,
			h6: true,
			bulletList: true,
			numberedList: true
		},
		startBold: {
			code: false,
			paragraph: false,
			h1: false,
			h2: false,
			h3: false,
			h4: false,
			h5: false,
			h6: false,
			bulletList: false,
			numberedList: false
		}
	};

	options = merge(defaults, options);

	/**
	 * Sets the text to bold if necessary when using the formatter.
	 *
	 * @param {string} formatter - The formatter to check.
	 */
	function setBoldIfNecessary(formatter) {
		if (options.startBold[formatter]) {
			editor.dispatchCommand(SELECT_ALL_COMMAND);
			toolbar.ifSelectionNotBold(
				editor.dispatchCommand.bind(
					toolbar.editor,
					FORMAT_TEXT_COMMAND,
					'bold'
				)
			);
		}
	}

	const formatters = {
		code: {
			name: 'codeblock',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createCodeNode());
					}
				});
				setBoldIfNecessary('code');
			},
			icon: getIcon('code-box-line'),
			type: 'code'
		},
		paragraph: {
			name: 'paragraph',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createParagraphNode());
					}
				});
				setBoldIfNecessary('paragraph');
			},
			icon: getIcon('p'),
			type: 'paragraph'
		},
		h1: {
			name: 'heading 1',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h1'));
					}
				});
				setBoldIfNecessary('h1');
			},
			icon: getIcon('h-1'),
			type: 'h1'
		},
		h2: {
			name: 'heading 2',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h2'));
					}
				});
				setBoldIfNecessary('h2');
			},
			icon: getIcon('h-2'),
			type: 'h2'
		},
		h3: {
			name: 'heading 3',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h3'));
					}
				});
				setBoldIfNecessary('h3');
			},
			icon: getIcon('h-3'),
			type: 'h3'
		},
		h4: {
			name: 'heading 4',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h4'));
					}
				});
				setBoldIfNecessary('h4');
			},
			icon: getIcon('h-4'),
			type: 'h4'
		},
		h5: {
			name: 'heading 5',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h5'));
					}
				});
				setBoldIfNecessary('h5');
			},
			icon: getIcon('h-5'),
			type: 'h5'
		},
		h6: {
			name: 'heading 6',
			format: () => {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => $createHeadingNode('h6'));
					}
				});
				setBoldIfNecessary('h6');
			},
			icon: getIcon('h-6'),
			type: 'h6'
		},
		bulletList: {
			name: 'bullet list',
			format: () => {
				if (true) {
					editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
				} else {
					editor.dispatchCommand(REMOVE_LIST_COMMAND);
				}
				setBoldIfNecessary('bulletList');
			},
			icon: getIcon('list-unordered'),
			type: 'ul'
		},
		numberedList: {
			name: 'numbered list',
			format: () => {
				if (true) {
					editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
				} else {
					editor.dispatchCommand(REMOVE_LIST_COMMAND);
				}
				setBoldIfNecessary('numberedList');
			},
			icon: getIcon('list-ordered'),
			type: 'ol'
		}
	};

	Object.keys(formatters).forEach(formatter => {
		if (!options.formattersVisibility[formatter])
			delete formatters[formatter];
	});

	return formatters;
};

/**
 * Get the icon with the specified name. Bounded with the size 'md'.
 * @param {string} name - The name of the icon.
 * @return {string} The icon HTML.
 */
function getIcon(name) {
	return _getIcon(name, { size: 'md' });
}
