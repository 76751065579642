import loadData from './_loadData';

export default (editor, templateData) => {
	try {
		loadData(editor.getEmailBuilder(), templateData, {
			customConverter: data => {
				const { config, html, styles, css } = data;
				const style = styles || css;
				return {
					config,
					html,
					css: style
				};
			}
		});
	} catch (error) {
		console.error('Failed to import template:', error);
	}
};
