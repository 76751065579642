import { getUnit } from 'datatalks-utils';

import { addSpace as _addSpace } from '../_componentsUtils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';
import updateStyle from '../common/_updateCommonStyle';

export default comp => {
	let image;

	const innerComponents = [];
	const addVerticalSpace = _addSpace.bind(null, comp, true, innerComponents);

	const recommendedWidth =
		getUnit(comp.attributes.imageWidth) == '%'
			? (parseInt(comp.attributes.imageWidth) / 100) * 600
			: getUnit(comp.attributes.imageWidth) == 'px'
			? parseInt(comp.attributes.imageWidth)
			: comp.attributes.imageWidth;

	if (
		comp.attributes.imgSrc ||
		!comp.attributes.useImagePlaceholderComponent
	) {
		image = {
			type: 'image-wrapper',
			style: validateStyleObj({
				'border-radius': comp.attributes.imageUseBorderRadius
					? Object.values(comp.get('imageBorderRadius')).join(' ')
					: null,
				'border-color': comp.get('imageBorderColor'),
				'border-style': comp.get('imageBorderStyle'),
				'border-width': comp.get('imageBorderWidth'),
				width:
					comp.get('layoutVariant') === 0
						? '100%'
						: comp.attributes.imageWidth
			}),
			imgSrc: comp.attributes.imgSrc,
			href: comp.get('imageHref'),
			hasLink: comp.get('imageHasLink')
		};
	} else {
		image = {
			type: 'image-placeholder',
			style: {
				width:
					comp.get('layoutVariant') === 0
						? '100%'
						: comp.attributes.imageWidth
			},
			imagePlaceholderHTMLOptions: {
				recommendedWidth
			}
		};
	}

	if (comp.get('layoutVariant') === 0) {
		// side by side
		const comps = [];
		if (comp.get('displayImage')) {
			comps.push({
				type: 'cell',
				style: {
					width: comp.attributes.imageWidth
				},
				attributes: {
					align: comp.attributes.imageAlignment
				},
				components: image
			});
		}

		if (comp.get('displayLinks')) {
			if (comps.length)
				comps.push({
					type: 'space',
					dimension: comp.get('spacingBetweenElements'),
					isVertical: false
				});

			comps.push({
				type: 'cell',
				style: {
					width: comp.get('useLinksGap')
						? 'auto'
						: comp.attributes.linksListWidth
				},
				components: {
					type: 'links-list',
					linksList: comp.attributes.linksList,
					linksAlignment: 'center',
					listWidth: comp.get('useLinksGap') ? 'auto' : '100%',
					listAlign: comp.attributes.linksListAlign,
					linksBorderRadius: comp.attributes.linksBorderRadius,
					linksUseBorderRadius: comp.attributes.linksUseBorderRadius,
					linksBorderColor: comp.attributes.linksBorderColor,
					linksBorderStyle: comp.attributes.linksBorderStyle,
					linksBorderWidth: comp.attributes.linksBorderWidth,
					linksColor: comp.attributes.linksColor,
					linksBackground: comp.attributes.linksBackground,
					linksFont: comp.attributes.linksFont,
					linksWeight: comp.attributes.linksWeight,
					linksLetterSpacing: comp.attributes.linksLetterSpacing,
					gap: comp.get('useLinksGap') ? comp.get('linksGap') : 0,
					isTextList: comp.get('isTextList')
				}
			});
		}

		innerComponents.push({
			type: 'row',
			components: comps
		});
	} else {
		if (comp.get('displayImage')) {
			innerComponents.push({
				type: 'row',
				components: {
					type: 'cell',
					attributes: {
						colSpan: comp.attributes.linksList.length * 2 - 1,
						align: comp.attributes.imageAlignment
					},
					components: image
				}
			});
		}

		if (comp.get('displayLinks')) {
			if (innerComponents.length) addVerticalSpace();

			innerComponents.push({
				type: 'row',
				components: {
					type: 'cell',
					components: {
						type: 'links-list',
						linksList: comp.attributes.linksList,
						linksAlignment: 'center',
						listWidth: comp.get('useLinksGap')
							? 'auto'
							: comp.attributes.linksListWidth,
						listAlign: comp.attributes.linksListAlign,
						linksBorderRadius: comp.attributes.linksBorderRadius,
						linksBorderColor: comp.attributes.linksBorderColor,
						linksBorderStyle: comp.attributes.linksBorderStyle,
						linksBorderWidth: comp.attributes.linksBorderWidth,
						linksColor: comp.attributes.linksColor,
						linksBackground: comp.attributes.linksBackground,
						linksFont: comp.attributes.linksFont,
						linksWeight: comp.attributes.linksWeight,
						linksLetterSpacing: comp.attributes.linksLetterSpacing,
						gap: comp.get('useLinksGap') ? comp.get('linksGap') : 0,
						isTextList: comp.get('isTextList')
					}
				}
			});
		}
	}

	updateStyle(comp);

	return [
		{
			type: 'margins',
			innerComponents,
			hasMargins: comp.get('hasMargins'),
			margins: {
				top: comp.get('topMargin'),
				right: comp.get('rightMargin'),
				bottom: comp.get('bottomMargin'),
				left: comp.get('leftMargin')
			},
			marginsColor: {
				top: comp.get('topMarginColor'),
				right: comp.get('rightMarginColor'),
				bottom: comp.get('bottomMarginColor'),
				left: comp.get('leftMarginColor')
			}
		}
	];
};
