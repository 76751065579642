import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		onlyGeneralStyles: false
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, opts = {}) => {
			if (typeof emailBuilder.getOptions().onExport === 'function') {
				const data = emailBuilder.getDataToStore({
					onlyGeneralStyles: commandOptions.onlyGeneralStyles
				});
				const { html, css, config } = data;

				emailBuilder
					.getOptions()
					.onExport(config, html, css, emailBuilder);
			}
		}
	};
};
