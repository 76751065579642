import { LinkedInputs } from 'datatalks-ui';
import { allEqual } from 'datatalks-utils';

export default (obj, options) => {
	const localize = obj.component.localize;
	return new LinkedInputs({
		startLinked: allEqual(
			['topLeft', 'topRight', 'bottomRight', 'bottomLeft'].map(
				corner => obj.component.get('buttonBorderRadius')[corner]
			)
		),
		items: [
			{
				label: localize('misc.topLeft', true) || localize('Top Left'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').topLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topLeft: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label: localize('misc.topRight', true) || localize('Top Right'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').topRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topRight: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label:
					localize('misc.bottomRight', true) ||
					localize('Bottom Right'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').bottomRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomRight: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label:
					localize('misc.bottomLeft', true) ||
					localize('Bottom Left'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').bottomLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomLeft: inputValue
						});
					},
					unit: 'px'
				}
			}
		]
	}).getEl();
};
