/* eslint-disable require-jsdoc */

import {
	isElement,
	EventEmitter,
	setContent,
	addClassesString
} from 'datatalks-utils';
import { getIcon } from '../icons/_icons';

const accordionColors = ['primary', 'grey', 'grey-outline'];

const accordionTypes = ['normal', 'extend'];

export default class Accordion {
	constructor(opts = {}) {
		const defaults = {
			accordionColor: 'primary',
			accordionType: 'normal',
			classPrefix: 'eb-',
			cssClass: 'accordion',
			extendedClasses: '',
			title: null,
			content: null,
			contentExtendedClasses: '',
			arrowUp: getIcon('arrow-up-s-line', { size: 'xl' }),
			arrowDown: getIcon('arrow-down-s-line', { size: 'xl' }),
			useArrowUpWhenOpen: true,
			startOpen: false
		};

		this.opts = {
			...defaults,
			...opts
		};
		this.eventEmitter = new EventEmitter();
		this.className = `${this.opts.classPrefix}${this.opts.cssClass}`;

		if (this.opts.title && this.opts.content) {
			this.init();
		} else {
			throw new Error('You must pass title and content to Accordion');
		}
	}

	init() {
		this.element = document.createElement('div');
		this.element.className = `${this.className}${
			this.opts.extendedClasses ? ' ' + this.opts.extendedClasses : ''
		}`;

		if (accordionColors.includes(this.opts.accordionColor))
			this.element.classList.add(
				`${this.className}--${this.opts.accordionColor}`
			);

		if (accordionTypes.includes(this.opts.accordionType))
			this.element.classList.add(
				`${this.className}--${this.opts.accordionType}`
			);

		this.control = document.createElement('div');
		this.control.className = `${this.className}__control`;
		this.controlInner = document.createElement('div');
		this.controlInner.className = `${this.className}__control-inner`;
		this.control.append(this.controlInner);
		if (isElement(this.opts.title)) {
			this.controlInner.append(this.opts.title);
		} else {
			this.controlInner.innerHTML = this.opts.title;
		}
		this.control.addEventListener('click', this.toggle.bind(this));

		this.arrowWrapper = document.createElement('div');
		this.arrowWrapper.className = `${this.className}__arrow`;

		if (this.opts.startOpen) {
			this.open();
		} else {
			this.close();
		}

		this.control.append(this.arrowWrapper);

		this.content = addClassesString(
			setContent(document.createElement('div'), this.opts.content),
			[
				`${this.className}__content`,
				this.opts.contentExtendedClasses
			].join(' ')
		);

		this.element.append(this.control, this.content);
		this.eventEmitter.emit('init', this);
	}

	showArrowUp() {
		if (isElement(this.opts.arrowUp)) {
			this.arrowWrapper.innerHTML = '';
			this.arrowWrapper.append(this.opts.arrowUp);
		} else {
			this.arrowWrapper.innerHTML = this.opts.arrowUp;
		}
	}

	showArrowDown() {
		if (isElement(this.opts.arrowDown)) {
			this.arrowWrapper.innerHTML = '';
			this.arrowWrapper.append(this.opts.arrowDown);
		} else {
			this.arrowWrapper.innerHTML = this.opts.arrowDown;
		}
	}

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	open() {
		this.element.classList.add(this.className + '--open');

		if (this.opts.useArrowUpWhenOpen) {
			this.showArrowUp();
		} else {
			this.showArrowDown();
		}

		this.isOpen = true;
		this.eventEmitter.emit('open', this);
	}

	close() {
		this.element.classList.remove(this.className + '--open');

		if (this.opts.useArrowUpWhenOpen) {
			this.showArrowDown();
		} else {
			this.showArrowUp();
		}

		this.isOpen = false;
		this.eventEmitter.emit('close', this);
	}

	setTitle(title) {
		setContent(this.controlInner, title);
	}

	getTitle() {
		return this.control.innerHTML;
	}

	getContent() {
		return this.content.childNodes;
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
