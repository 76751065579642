/* eslint-disable require-jsdoc */
export default class TabItem {
	constructor(options = {}) {
		const defaults = {
			tabContent: '<p>Option1</p>',
			tabExtendedClasses: '',
			paneContent: 'option1 content',
			paneExtendedClasses: '',
			startActive: false,
			showPaneOnTabClick: true,
			onSelect: null,
			active: false
		};
		this.properties = {
			...defaults,
			...options
		};

		for (const prop in this.properties) {
			if (this.properties.hasOwnProperty(prop)) {
				this[prop] = this.properties[prop];
			}
		}
	}

	setPaneElement(element) {
		this.pane = element;
	}

	setTabElement(element) {
		this.tab = element;
	}

	getPane() {
		return this.pane;
	}

	getTab() {
		return this.tab;
	}

	setActive(isActive) {
		this.active = isActive;
	}

	isActive() {
		return this.active;
	}
}
