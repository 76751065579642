/**
 * Retrieves the inner HTML of a component.
 *
 * @param {Object} component - The component object.
 * @param {Object} opts - The options object.
 * @param {number} opts.width - The width of the component.
 * @return {string} - The inner HTML of the component.
 */
export default function getInner(component, opts) {
	const defaults = {
		align: null,
		width: component.get('width')
	};

	opts = Object.assign({}, defaults, opts);

	const children = component.components();
	return children.length
		? children
				.map(c =>
					c.toHTML({ align: opts.align, parentWidth: opts.width })
				)
				.join('')
		: component.content;
}
