import { merge } from 'datatalks-utils';

export default (emailBuilder, options) => {
	const { editor } = emailBuilder;
	const defaults = {
		base: editor?.DomComponents || null,
		appendComponents: false
	};

	options = merge(defaults, options);

	const configurationObject = {};

	const components = options.base?.getComponents
		? options.base.getComponents()
		: editor.DomComponents.getComponents();

	configurationObject.components = components.map(component => {
		const aux = component.toJSON();
		if (!options.appendComponents) delete aux['components'];
		return aux;
	});

	configurationObject.styles = emailBuilder.styles;
	configurationObject.layout = emailBuilder.layout;
	configurationObject.version = emailBuilder.version;
	configurationObject.name = emailBuilder.getOptions().titleConfig.title;

	return configurationObject;
};
