import { merge } from 'datatalks-utils';
import { toHex } from 'color2k';
import { ColorPickerInput } from 'datatalks-ui';

export default (options = {}) => {
	const defaults = {
		value: null,
		onChange: null, // (color)
		getEmptyColor: null,
		emptyColorLabel: null
	};

	options = merge(defaults, options);

	return new ColorPickerInput({
		color: options.value,
		emptyColor: options.getEmptyColor || (() => null),
		emptyColorLabel: options.emptyColorLabel,
		onChange: (cpi, ev) => {
			const isEmptyColor =
				options.getEmptyColor &&
				cpi.emptyColor &&
				toHex(cpi.color) === toHex(cpi.emptyColor);
			if (typeof options.onChange === 'function') {
				if (isEmptyColor) {
					options.onChange(null);
				} else {
					options.onChange(cpi.getColor());
				}
			}
		}
	});
};
