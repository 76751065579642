const svgAttrs =
	'xmlns="http://www.w3.org/2000/svg" width="100" viewBox="0 0 24 24" style="fill: rgba(0,0,0,0.15); transform: scale(0.75)"';

export default {
	alternateText: '',
	attributes: {},
	cssClass: 'gjs-comp-img',
	backgroundColor: null,
	imgWidth: '100%',
	imgSrc: null,
	src: null,
	fallback: `<svg ${svgAttrs}>
	<path d="M8.5 13.5l2.5 3 3.5-4.5 4.5 6H5m16 1V5a2 2 0 0 0-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2z"></path>
  </svg>`,
	name: 'image-wrapper',
	imgName: 'Image placeholder',
	tagName: 'img',
	borderWidth: '0px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomRight: '0px',
		bottomLeft: '0px'
	},
	useBorderRadius: true,
	alignment: 'center',
	hasLink: false,
	href: null,
	toHtmlOptions: {}
};
