import { merge } from 'lodash-es';
import { getIcon } from 'datatalks-icons';
import { addClassesString, setContent } from 'datatalks-utils';
import { Input } from 'datatalks-ui';

/* eslint-disable require-jsdoc */
export default class TextEditorInputActions {
	constructor(options = {}) {
		const defaults = {
			classPrefix: 'eb-',
			className: 'prosetyper-input-actions',
			iconsSize: 'lg',
			context: null,
			onPrimaryButtonClick: null,
			onSecondaryButtonClick: null,
			onInputChange: null,
			inputInitialValue: '',
			inputType: 'text',
			extendedClasses: '',
			inputStopPropagation: true
		};

		defaults.primaryButtonContent = getIcon('check-line', {
			size: options.iconsSize || defaults.iconsSize
		});
		defaults.secondaryButtonContent = getIcon('close-line', {
			size: options.iconsSize || defaults.iconsSize
		});

		this.options = merge(defaults, options);

		this.cssClass = `${this.options.classPrefix}${this.options.className}`;

		this.element = this.options.element || document.createElement('div');

		this.init();
	}

	init() {
		this.input = new Input({
			extendedClasses: `${this.cssClass}__input`
		}).getEl();

		if (this.options.inputStopPropagation)
			this.input.addEventListener('click', e => {
				e.stopPropagation();
			});

		this.input.value = this.options.inputInitialValue;
		this.input.type = this.options.inputType;

		this.primaryButton = addClassesString(
			setContent(
				document.createElement('button'),
				this.options.primaryButtonContent
			),
			`${this.cssClass}__button ${this.cssClass}__button--primary`
		);

		this.secondaryButton = addClassesString(
			setContent(
				document.createElement('button'),
				this.options.secondaryButtonContent
			),
			`${this.cssClass}__button ${this.cssClass}__button--secondary`
		);

		this.setEvents();

		this.draw();
	}

	setInputValue(value) {
		this.input.value = value;
	}

	setEvents() {
		if (typeof this.options.onPrimaryButtonClick === 'function') {
			this.primaryButton.addEventListener('click', e => {
				this.options.onPrimaryButtonClick.call(
					this.options.context,
					this.input.value,
					e
				);
			});
		}

		if (typeof this.options.onSecondaryButtonClick === 'function') {
			this.secondaryButton.addEventListener('click', e => {
				this.options.onSecondaryButtonClick.call(
					this.options.context,
					this.input.value,
					e
				);
			});
		}

		if (typeof this.options.onInputChange === 'function') {
			this.input.addEventListener('input', e => {
				this.options.onInputChange.call(
					this.options.context,
					this.input.value,
					this.input,
					e
				);
			});
		}
	}

	draw() {
		addClassesString(
			setContent(this.element, [
				this.input,
				this.primaryButton,
				this.secondaryButton
			]),
			`${this.cssClass} ${this.options.extendedClasses}`
		);
	}

	getEl() {
		return this.element;
	}
}
