export default editor => {
	editor.on('component:selected', component => {
		if (component === editor.getWrapper()) {
			editor.runCommand('open-blocks');
			editor.stopCommand('open-tm');
		} else {
			editor.runCommand('open-tm');
			editor.stopCommand('open-blocks');
			editor.ScopeManager.addItem({
				type: 'molecule',
				label: `Edit ${component.get('name')}`
			});
		}
	});
	editor.on('component:deselected', component => {
		if (
			!editor.getSelected() ||
			editor.getSelected() === editor.getWrapper()
		) {
			editor.runCommand('open-blocks');
			editor.stopCommand('open-tm');
		}
	});

	editor.on('component:remove:before', component => {
		if (editor.getSelected() === component) {
			editor.selectRemove(component);
		}
	});

	editor.on('block:drag:stop', model => {
		if (model) editor.select(model);
	});
};
