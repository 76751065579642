import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	return {
		extend: 'link',
		model: {
			toHTML(toHtmlOpts = {}) {
				return toHtml(this, toHtmlOpts);
			}
		}
	};
};
