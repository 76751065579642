import { merge, cssStyleObjectToString, deleteProps } from 'datatalks-utils';
import getComputedStyle from './_getComputedStyle';
import { validateStyleObj } from '../../common/functions/_utilFunctions';
import getInnerHTML from '../common/methods/_getInnerHTML';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		align: null,
		parentWidth: null
	};

	options = merge(defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const tableStyle = validateStyleObj({
		width: options.style?.width
	});

	let cellStyle = deleteProps(options.style, ['width'], false);

	if (comp.get('innerRenderType') === 'image-wrapper') {
		cellStyle = merge(cellStyle, { 'line-height': '0' });
		// TODO: this should be set for every component. For that we need to ensure every text has a line-height defined
	}

	const parentWidth =
		parseFloat(options.parentWidth) -
		parseFloat(options.style['padding-left']) -
		parseFloat(options.style['padding-right']);

	const innerHtml = comp.get('innerRenderType')
		? comp
				.findType(comp.get('innerRenderType'))[0]
				.toHTML({ parentWidth: `${parentWidth}px` })
		: getInnerHTML(comp, { width: `${parentWidth}px` });

	const align = options.align ? `align="${options.align}"` : '';

	const html = `
		<table ${
			options.debug ? 'data-eb-name="margins-first"' : ''
		} style="${cssStyleObjectToString(tableStyle)}">
			<tr>
				<td ${align} ${
		options.debug ? 'data-eb-name="margins-last"' : ''
	} style="${cssStyleObjectToString(cellStyle)}">
					${innerHtml}
				</td>
			</tr>
		</table>
	`;

	if (options.debug) console.log('Margins component HTML: ', html);

	return html;
};
