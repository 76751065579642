import { toLower, capitalize } from 'lodash-es';
import { borderStyles, merge } from 'datatalks-utils';
import { Dropdown } from 'email-builder-ui';
import localize from '../../../common/_localize';

export default options => {
	const defaults = {
		extendedClasses: '',
		value: 'solid',
		onChange: null // (value)
	};

	options = merge(defaults, options);

	return new Dropdown({
		extendedClasses: options.extendedClasses,
		items: borderStyles.map(style => {
			return {
				content: capitalize(
					localize(`misc.${style}`, true) || localize(style) || style
				),
				value: style,
				active: toLower(options.value) === toLower(style)
			};
		}),
		onChange: (dropdown, activeItem) => {
			if (typeof options.onChange === 'function') {
				options.onChange(activeItem.getValue());
			}
		}
	});
};
