import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			editor.Panels.getPanel('traits-view').set('visible', true);
		},

		stop: (editor, sender) => {
			editor.Panels.getPanel('traits-view').set('visible', false);
		}
	};
};
