import { validateStyleObj } from '../../common/functions/_utilFunctions';
import { noInteractionPreset } from '../_componentsUtils';

export default component => {
	const margins = component.get('margins');
	const hasMargins = component.get('hasMargins');
	const marginsColor = component.get('marginsColor');

	const row = [
		{
			type: 'cell',
			style: validateStyleObj({
				width: hasMargins ? margins.left : 0,
				['background-color']: marginsColor.left
			})
		},
		{
			type: 'cell',
			style: {
				width: hasMargins
					? `calc(100% - (${margins.left} + ${margins.right}))`
					: '100%'
			},
			components: {
				type: 'table',
				style: {
					width: '100%'
				},
				components: [
					{
						type: 'row',
						style: {
							height: hasMargins ? margins.top : 0
						},
						components: {
							type: 'cell',
							style: validateStyleObj({
								['background-color']: marginsColor.top
							})
						}
					},
					...component.get('innerComponents'),
					{
						type: 'row',
						style: {
							height: hasMargins ? margins.bottom : 0
						},
						components: {
							type: 'cell',
							style: validateStyleObj({
								['background-color']: marginsColor.bottom
							})
						}
					}
				]
			}
		},
		{
			type: 'cell',
			style: validateStyleObj({
				width: hasMargins ? margins.right : 0,
				['background-color']: marginsColor.right
			})
		}
	];

	if (component.get('wrapInTable')) {
		component.set('tagName', 'table');
		component.setStyle(
			validateStyleObj({
				...component.getStyle(),
				width: component.get('width')
			})
		);
	}

	return component.get('wrapInTable')
		? {
				type: 'row',
				components: row,
				...noInteractionPreset
		  }
		: row;
};
