import { capitalize } from 'lodash-es';
import { borderStyles, getUnit, setContent, merge } from 'datatalks-utils';
import imageInputSlider from './common/_sliderOrInput';
import imageAlignmentButtonGroup from './common/_alignment';
import imageBorderRadius from './common/_borderRadius';
import {
	ColorPickerInput,
	InputNumber,
	Accordion,
	SectionItem,
	section,
	Dropdown,
	ToggleableSection
} from 'datatalks-ui';

export default (options = {}, customTrait = {}) => {
	const defaults = {
		extendedClasses: '',
		border: {
			width: {
				value: '0px',
				onChange: null // (value)
			},
			style: {
				value: 'solid',
				onChange: null // (value)
			},
			color: {
				value: 'transparent',
				onChange: null // (color)
			}
		},
		toggleBtnClickTogglesAccordion: false,
		imageSliderOptions: {
			sliderOptions: {
				extendedClasses: 'eb-mt-3'
			}
		},
		accordion: {
			title: 'Image',
			color: 'grey',
			type: 'extend'
		},
		imageAlignmentButtonGroupOptions: {},
		imageBorderRadiusOptions: {},
		showBorderRadius: true,
		onBorderRadiusToggle: null,
		innerTraitsVisibility: {
			alignment: true,
			border: true,
			borderRadius: true,
			width: true
		},
		borderColorPickerProp: 'imageBorderCp'
	};

	options = merge(defaults, options);

	if (!Object.values(options.innerTraitsVisibility).some(value => value))
		return;

	if (
		options.innerTraitsVisibility.border &&
		!customTrait[options.borderColorPickerProp]
	)
		customTrait[options.borderColorPickerProp] = new ColorPickerInput({
			color: options.border.color.value,
			onChange: (cpi, ev) => {
				if (typeof options.border.color.onChange === 'function')
					options.border.color.onChange(cpi.getColor());
			}
		});

	const imageAccordionContent = setContent(
		document.createElement('div'),
		[
			options.innerTraitsVisibility.width &&
				imageInputSlider(options.imageSliderOptions),
			options.innerTraitsVisibility.alignment
				? new SectionItem({
						label: 'Alignment',
						content: imageAlignmentButtonGroup(
							options.imageAlignmentButtonGroupOptions
						).getEl()
				  }).getEl()
				: null,
			(options.innerTraitsVisibility.border ||
				options.innerTraitsVisibility.borderRadius) &&
				section({
					label: 'Border',
					content: [
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Width',
								content: new InputNumber({
									defaultValue: options.border.width.value,
									unit: getUnit(options.border.width.value),
									onChange: (value, unit, inputValue) => {
										if (
											typeof options.border.width
												.onChange === 'function'
										)
											options.border.width.onChange(
												inputValue
											);
									}
								}).getEl()
							}).getEl(),
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Style',
								content: new Dropdown({
									items: borderStyles.map(style => {
										return {
											content: capitalize(style),
											value: style,
											active:
												options.border.style.value ===
												style
										};
									}),
									onChange: (dropdown, activeItem) => {
										if (
											typeof options.border.style
												.onChange === 'function'
										)
											options.border.style.onChange(
												activeItem.getValue()
											);
									}
								}).getEl()
							}).getEl(),
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Color',
								content:
									customTrait[
										options.borderColorPickerProp
									].getEl()
							}).getEl(),
						options.innerTraitsVisibility.borderRadius &&
							new ToggleableSection({
								toggleableContent: true,
								label: 'Border radius',
								startOpen: options.showBorderRadius,
								onToggle: options.onBorderRadiusToggle,
								content: imageBorderRadius(
									options.imageBorderRadiusOptions
								)
							}).getEl()
					]
				})
		].flat()
	);

	const ImageAccordion = new Accordion({
		title: options.accordion.title,
		content: imageAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		extendedClasses: options.extendedClasses || ''
	});

	return ImageAccordion;
};
