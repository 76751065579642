import { toLower, capitalize } from 'lodash-es';
import { webSafeFonts, webFonts, merge, genericFonts } from 'datatalks-utils';
import { Dropdown } from 'datatalks-ui';

export default options => {
	const defaults = {
		extendedClasses: '',
		value: 'Arial',
		onChange: null, // (value)
		useWebFonts: true,
		useGenericFonts: true,
		onReset: null,
		resettable: false,
		resetLabel: 'Same as Email',
		resetValue: null
	};

	options = merge(defaults, options);

	let fonts = webSafeFonts;

	if (options.useWebFonts) {
		fonts = fonts.concat(webFonts);
	}

	if (options.useGenericFonts) {
		fonts = fonts.concat(genericFonts);
	}

	fonts.sort((a, b) => {
		if (a.toLowerCase() < b.toLowerCase()) return -1;
		if (a.toLowerCase() > b.toLowerCase()) return 1;
		return 0;
	});

	const items = fonts.map(font => {
		return {
			content: webFonts.includes(font)
				? `${capitalize(font)} *`
				: capitalize(font),
			value: font,
			active: toLower(options.value) === toLower(font)
		};
	});

	if (options.onReset || options.resettable) {
		items.unshift({
			content: options.resetLabel,
			value: options.resetValue,
			active: !options.value,
			isDefault: true
		});
	}

	return new Dropdown({
		extendedClasses: options.extendedClasses,
		triggerOnChangeOnDefaultItemActivation: false,
		resettable: !!options.onReset || options.resettable,
		items,
		onChange: (dropdown, activeItem) => {
			if (typeof options.onChange === 'function') {
				options.onChange(activeItem.getValue());
			}
		},
		onReset: (dropdown, defaultItem) => {
			if (typeof options.onReset === 'function') {
				options.onReset(defaultItem.getValue());
			}
		}
	});
};
