import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			if (editor.ScopeManager) {
				editor.ScopeManager.goBack();
			} else {
				console.warn(
					"Can't change context since Scope Manager is unavailable"
				);
			}
		}
	};
};
