import { merge } from 'datatalks-utils';
import isStorageDiffEditor from '../../common/functions/_storageDiffEditor';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		base: null,
		callback: (editor, isEqual) => {
			editor[`${isEqual ? 'stop' : 'run'}Command`]('show-save-panel');
		},
		isStorageDiffEditor
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			commandOptions.callback(
				editor,
				commandOptions.isStorageDiffEditor(emailBuilder)
			);
		}
	};
};
