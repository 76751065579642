import { merge, isArray } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, opts = {}) => {
			const em = editor.getModel();
			const clp = em.get('clipboard');
			const lastSelected = editor.getSelected();

			if (clp?.length && lastSelected) {
				editor.getSelectedAll().forEach(sel => {
					const selected = sel.delegate?.copy?.(sel) || sel;
					const { collection } = selected;
					let added;
					if (collection) {
						const at = selected.index() + 1;
						const addOpts = {
							at,
							action: opts.action || 'paste-component'
						};

						if (
							clp.includes(selected) &&
							selected.get('copyable')
						) {
							added = collection.add(selected.clone(), addOpts);
						} else {
							added = doAdd(
								editor,
								clp,
								selected.parent(),
								addOpts
							);
						}
					} else {
						// Page body is selected
						// Paste at the end of the body
						const pageBody =
							em.Pages.getSelected()?.getMainComponent();
						const addOpts = {
							at: pageBody?.components().length || 0,
							action: opts.action || 'paste-component'
						};

						added = doAdd(editor, clp, pageBody, addOpts);
					}

					added = isArray(added) ? added : [added];
					added.forEach(add =>
						editor.trigger('component:paste', add)
					);
				});

				lastSelected.emitUpdate();
			}
		}
	};
};

/**
 * Adds components to the parent component.
 *
 * @param {Editor} ed - The editor instance.
 * @param {Array} clp - The array of components in the editor's
 * clipboard to be added to the parent.
 * @param {Component} parent - The parent component.
 * @param {Object} addOpts - The options for adding components.
 * @return {Array} - The added components.
 */
function doAdd(ed, clp, parent, addOpts) {
	const copyable = clp.filter(cop => cop.get('copyable'));
	const pasteable = copyable.filter(
		cop => ed.Components.canMove(parent, cop).result
	);
	return parent.components().add(
		pasteable.map(cop => cop.clone()),
		addOpts
	);
}
