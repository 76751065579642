import { merge, cssStyleObjectToString } from 'datatalks-utils';
import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		text: comp.get('text'),
		parentWidth: null,
		align: comp.get('align') || 'center',
		verticalAlignment: comp.get('vAlign'),
		sideLayout: comp.get('layoutVariant') === 0,
		imageWidth: comp.get('imageWidth'),
		linksListWidth: comp.get('linksListWidth'),
		spaceSize: comp.get('spacingBetweenElements')
	};

	options = merge({}, defaults, options);

	const sideLayout =
		options.sideLayout &&
		comp.get('displayImage') &&
		comp.get('displayLinks');

	const imageRatio = parseFloat(options.imageWidth) / 100;

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isMobileOptimized = comp
		.getEditor()
		.getEmailBuilder()
		.getAdvancedOption('isMobileOptimized');

	const marginsWidth = comp.get('hasMargins')
		? parseFloat(comp.get('leftMargin')) +
		  parseFloat(comp.get('rightMargin')) +
		  'px'
		: 0;

	/**
	 * Calculates the width of the list item based on the parent width, provided options and number of columns.
	 * @param {boolean} isImage - Whether it should calculate image's width or links' width.
	 * @return {number} The width (without unit, only number) in pixels of the element based on the parent's width.
	 */
	function getParentWidth(isImage) {
		const ratio = isImage ? imageRatio : 1 - imageRatio;
		if (sideLayout)
			return (
				(parseFloat(options.parentWidth) -
					parseFloat(marginsWidth) -
					parseFloat(comp.get('spacingBetweenElements'))) *
				ratio
			);
		else {
			if (isImage) {
				return (
					(parseFloat(options.imageWidth) / 100) *
					(parseFloat(options.parentWidth) - parseFloat(marginsWidth))
				);
			} else {
				return (
					(parseFloat(
						options.linksListWidth == 'auto'
							? 100
							: options.linksListWidth
					) /
						100) *
					(parseFloat(options.parentWidth) - parseFloat(marginsWidth))
				);
			}
		}
	}

	/**
	 * Retrieves the HTML representation of a specific element based on its name.
	 *
	 * @param {string} name - The name of the element to retrieve.
	 * @return {string} The HTML representation of the element.
	 */
	function getElement(name) {
		switch (name) {
			case 'image':
				return comp
					.findType('image-wrapper')[0]
					.toHTML({ parentWidth: imageWidthPx });
				break;

			case 'links':
				return comp
					.findType('links-list')[0]
					.toHTML({ parentWidth: linksWidthPx, isMobileOptimized });
				break;

			default:
				console.warn('Invalid element name: ', name);
				return '';
				break;
		}
	}

	let innerHtml;

	const imageWidthPx = getParentWidth(true) + 'px';
	const linksWidthPx = getParentWidth(false) + 'px';

	const bgString = options.style['background-color']
		? `bgcolor="${options.style['background-color']}"`
		: '';

	const va = options.verticalAlignment;
	const style = cssStyleObjectToString(options.style);
	const columnPadding = isMobileOptimized
		? `padding:${parseFloat(options.spaceSize) / 2}px;`
		: '';

	const debugStrStart = options.debug ? 'data-eb-name="header-first"' : '';
	const debugStrEnd = options.debug ? 'data-eb-name="header-last"' : '';

	if (sideLayout) {
		const linksPerWidth = 100 - parseFloat(options.imageWidth) + '%';
		const imagePerWidth = options.imageWidth;
		innerHtml = `
			<div ${debugStrStart} class="ebr-2-col" style="text-align:${
			options.align
		};font-size:0;${style}">
				<!--[if mso]>
					<table role="presentation" width="100%" style="${style}" ${bgString}>
						<tr>
							<td style="width:${imagePerWidth};padding:0;" align="${
			options.align
		}" valign="${va}">
								<![endif]-->
									<div class="ebr-column ${
										isMobileOptimized
											? `ebr-max-width-cover-${
													Math.floor(
														imageRatio * 10
													) * 10
											  }`
											: ''
									}" style="width:100%;max-width:${imageWidthPx};display:inline-block;vertical-align:${va};${columnPadding}">

										<div ${debugStrEnd} style="padding:0;">
											${getElement('image')}
										</div>

									</div>
								<!--[if mso]>
							</td>

							<td style="width:${options.spaceSize}"></td>

							<td style="width:${linksPerWidth};padding:0;" align="${
			options.align
		}" valign="${va}">
								<![endif]-->
									<div class="ebr-column ${
										isMobileOptimized
											? `ebr-max-width-cover-${
													100 -
													Math.floor(
														imageRatio * 10
													) *
														10
											  }`
											: ''
									}" style="width:100%;max-width:${linksWidthPx};display:inline-block;vertical-align:${va}; margin-left:${
			isMobileOptimized ? '' : options.spaceSize
		};${columnPadding}">

										<div ${debugStrEnd} style="padding:0;">
											${getElement('links')}
										</div>

									</div>
								<!--[if mso]>
							</td>

						</tr>
					</table>
				<![endif]-->
			</div>
	`;
	} else {
		innerHtml = `
			<table style="${style}" ${debugStrStart} ${bgString}>
				${
					comp.get('displayImage')
						? `<tr>
							<td ${debugStrEnd} align="${options.align}">
								${getElement('image')}
							</td>
						</tr>`
						: ''
				}
				${
					comp.get('displayImage') && comp.get('displayLinks')
						? `<tr style="height:${options.spaceSize}"></tr>`
						: ''
				}
				${
					comp.get('displayLinks')
						? `<tr>
								<td ${debugStrEnd} align="${options.align}">
									${getElement('links')}
								</td>
						</tr>`
						: ''
				}
			</table>
		`;
	}

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log('Header HTML: ', comp.get('hasMargins') ? html : innerHtml);

	return comp.get('hasMargins') ? html : innerHtml;
};
