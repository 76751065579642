import { merge } from 'datatalks-utils';
import textAccordion from '../../Hero/components/contentTab/_textAccordion';
import linksAccordion from '../../Header/components/contentTab/_linksAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		extendedClasses: '',
		headingAccordionOptions: {
			componentProperty: 'title',
			displayProperty: 'displayTitle',
			accordion: {
				title: localize('Title')
			},
			textEditorProp: 'headingEditor',
			editorStateProp: 'headingEditorState'
		},
		descriptionAccordionOptions: {
			componentProperty: 'description',
			displayProperty: 'displayDescription',
			accordion: {
				title: localize('Description')
			},
			textEditorProp: 'descriptionEditor',
			editorStateProp: 'descriptionEditorState'
		},
		linksAccordionOptions: {
			componentProperty: 'links',
			displayProperty: 'displayLinks',
			accordion: {
				title:
					localize('components.names.linksList', true) ||
					localize('Links')
			},
			textEditorProp: 'linksEditor',
			editorStateProp: 'linksEditorState'
		},
		socialNetworkAccordionOptions: {
			componentProperties: {
				display: 'displaySocialList'
			},
			componentMethods: {
				addItem: 'addListItem',
				changeItemsOrder: 'changeItemsOrder'
			},
			accordion: {
				title: localize('Social networks')
			},
			itemsListOptions: {
				button: {
					onClick: () => {
						const newItem = obj.component.addListItem.call(
							obj.component
						);
						if (newItem) obj.trait.addItem(newItem);
					}
				},
				componentProperties: {
					itemsList: 'socialList'
				}
			}
		}
	};

	options = merge(defaults, options);

	const contentTab = addClassesString(
		setContent(document.createElement('div'), [
			textAccordion
				.call(null, obj, options.headingAccordionOptions, customTrait)
				.getEl(),
			textAccordion
				.call(
					null,
					obj,
					options.descriptionAccordionOptions,
					customTrait
				)
				.getEl(),
			linksAccordion
				.call(
					null,
					obj,
					options.socialNetworkAccordionOptions,
					customTrait
				)
				.getEl(),
			textAccordion
				.call(null, obj, options.linksAccordionOptions, customTrait)
				.getEl()
		]),
		options.extendedClasses
	);

	return contentTab;
};
