/**
 * The object containing the callbacks for the file reader events
 * @typedef { Object } FooterTraitOptions
 * @property { string } cssClass - The base class to apply to the wrapper container of the footer trait.
 * @property { string } extendedClasses - Other classes to apply to the wrapper container of the footer trait (i.e. utility classes).
 */

/**
 * Creates a trait of the type 'footer' configured with the passed options
 * @param {FooterTraitOptions} options - The options to configure the footer trait.
 */

export default (options = {}) => {
	return {
		name: 'footer',
		type: 'footer',
		options
	};
};
