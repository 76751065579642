import slider from '../../traits/common/rangeSlider/_slider';

export default emailBuilder => {
	const { editor } = emailBuilder;
	const pluginOpts = emailBuilder.getOptions();

	const zoomSlider = slider({
		start: [editor.Canvas.getZoom()],
		range: {
			min: [pluginOpts?.zoom?.min || 5],
			max: [pluginOpts?.zoom?.max || 100]
		},
		orientation: 'vertical',
		pips: {
			mode: 'positions',
			values: [0, 25, 50, 75, 100],
			density: 10,
			format: {
				to: value => value + '%'
			}
		}
	});

	zoomSlider.noUiSlider.on('set', values => {
		editor.Canvas.setZoom(values[0]);
	});

	return zoomSlider;
};
