/* eslint-disable require-jsdoc */
import { addClassesString, setContent } from 'datatalks-utils';
import { Accordion } from 'datatalks-ui';

export default (container, props, editor) => {
	if (!editor.BlockManager.customRendered) {
		const pfx = editor.getConfig().stylePrefix;

		function getContentByCategory(categoryId) {
			return props.blocks
				.filter(block => block.attributes.category === categoryId)
				.map(block => {
					const blockEl = addClassesString(
						setContent(document.createElement('div'), [
							`<div class="${pfx}block__media">${block.attributes.media}</div>`,
							`<div class="${pfx}block-label">${block.attributes.label}</div>`
						]),
						`${pfx}block ${pfx}block--custom`
					);

					blockEl.setAttribute('draggable', true);
					blockEl.addEventListener('dragstart', () =>
						props.dragStart(block)
					);
					blockEl.addEventListener('dragend', () =>
						props.dragStop(block)
					);

					return blockEl;
				});
		}

		const accordions = editor.BlockManager.getCategories().models.map(
			cat => ({
				id: cat.id,
				el: new Accordion({
					title: cat.attributes.label,
					accordionType: 'extend',
					accordionColor: 'grey',
					startOpen: cat.attributes.open,
					content: getContentByCategory(cat.id),
					extendedClasses: `${pfx}block-category-accordion`,
					contentExtendedClasses: `${pfx}block-category-accordion__content`
				}).getEl()
			})
		);

		addClassesString(
			setContent(
				container,
				accordions.map(accordion => accordion.el)
			),
			`${pfx}blocks-container`
		);

		editor.BlockManager.customRendered = true;
	}
};
