import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			if (editor.TraitManager.getCurrent().length)
				editor.TraitManager.getCurrent().forEach(trait => {
					if (typeof trait.get('setView') === 'function')
						trait.get('setView')('style');
				});
		},
		stop: (editor, sender) => {}
	};
};
