import { TextEditor, ToggleButton, Accordion } from 'datatalks-ui';
import { addClassesString, setContent, merge } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';

export default (obj, options = {}, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const defaults = {
		hasTitleToggler: true,
		toggleBtnClickTogglesAccordion: false,
		textEditorProp: 'titleEditor'
	};

	options = merge(defaults, options);

	const titleAccordionContent = document.createElement('div');

	if (!customTrait[options.textEditorProp]) {
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent: obj.component.get('title'),
			initialState: obj.component.get('titleState'),
			onChange: (html, editorState) => {
				obj.component.setAndRerender.call(obj.component, 'title', html);
				obj.component.set('titleState', editorState);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp]
		);
	}

	titleAccordionContent.append(customTrait[options.textEditorProp].getEl());

	const title = options.hasTitleToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get('displayTitle'),
						onChange: isActive => {
							obj.component.setAndRerender(
								'displayTitle',
								isActive
							);
						}
					}).getEl(),
					`<span>Title</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: 'Title';

	const titleAccordion = new Accordion({
		title: title,
		content: titleAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return titleAccordion;
};
