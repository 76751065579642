import { camelCase } from 'lodash-es';
import imageAccordion from '../../../commonAccordions/style/_image';
import { addClassesString, getUnit, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		componentName: obj.component.get('name')
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		imageAccordionOptions: {
			border: {
				width: {
					value: obj.component.get(
						camelCase(`${defaults.componentName} BorderWidth`)
					),
					onChange: value =>
						obj.component.setAndRerender(
							camelCase(`${defaults.componentName} BorderWidth`),
							value
						)
				},
				style: {
					value: obj.component.get(
						camelCase(`${defaults.componentName} BorderStyle`)
					),
					onChange: value =>
						obj.component.setAndRerender(
							camelCase(`${defaults.componentName} BorderStyle`),
							value
						)
				},
				color: {
					value: obj.component.get(
						camelCase(`${defaults.componentName} BorderColor`)
					),
					onChange: color =>
						obj.component.setAndRerender(
							camelCase(`${defaults.componentName} BorderColor`),
							color
						)
				}
			},
			imageSliderOptions: {
				value: obj.component.get(
					camelCase(`${defaults.componentName} ImgWidth`)
				),
				label: 'Width',
				unit: getUnit(
					obj.component.get(
						camelCase(`${defaults.componentName} ImgWidth`)
					)
				),
				onChange: value =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} ImgWidth`),
						value
					)
			},
			imageAlignmentButtonGroupOptions: {
				value: {
					current: obj.component.get(
						camelCase(`${defaults.componentName} Alignment`)
					)
				},
				onChange: (allSelected, changedItem) =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} Alignment`),
						changedItem.getValue()
					)
			},
			imageBorderRadiusOptions: {
				corners: {
					topLeft: {
						value: obj.component.get(
							camelCase(`${defaults.componentName} BorderRadius`)
						)?.topLeft,
						onChange: value =>
							obj.component.setAndRerender(
								camelCase(
									`${defaults.componentName} BorderRadius`
								),
								{
									...obj.component.get(
										camelCase(
											`${defaults.componentName} BorderRadius`
										)
									),
									topLeft: value
								}
							),
						unit: getUnit(
							obj.component.get(
								camelCase(
									`${defaults.componentName} BorderRadius`
								)
							)?.topLeft
						),
						label: 'Top left'
					},
					topRight: {
						value: obj.component.get(
							camelCase(`${defaults.componentName} BorderRadius`)
						)?.topRight,
						onChange: value =>
							obj.component.setAndRerender(
								camelCase(
									`${defaults.componentName} BorderRadius`
								),
								{
									...obj.component.get(
										camelCase(
											`${defaults.componentName} BorderRadius`
										)
									),
									topRight: value
								}
							),
						unit: getUnit(
							obj.component.get(
								camelCase(
									`${defaults.componentName} BorderRadius`
								)
							)?.topRight
						),
						label: 'Top right'
					},
					bottomRight: {
						value: obj.component.get(
							camelCase(`${defaults.componentName} BorderRadius`)
						)?.bottomRight,
						onChange: value =>
							obj.component.setAndRerender(
								camelCase(
									`${defaults.componentName} BorderRadius`
								),
								{
									...obj.component.get(
										camelCase(
											`${defaults.componentName} BorderRadius`
										)
									),
									bottomRight: value
								}
							),
						unit: getUnit(
							obj.component.get(
								camelCase(
									`${defaults.componentName} BorderRadius`
								)
							)?.bottomRight
						),
						label: 'Bottom right'
					},
					bottomLeft: {
						value: obj.component.get(
							camelCase(`${defaults.componentName} BorderRadius`)
						)?.bottomLeft,
						onChange: value =>
							obj.component.setAndRerender(
								camelCase(
									`${defaults.componentName} BorderRadius`
								),
								{
									...obj.component.get(
										camelCase(
											`${defaults.componentName} BorderRadius`
										)
									),
									bottomLeft: value
								}
							),
						unit: getUnit(
							obj.component.get(
								camelCase(
									`${defaults.componentName} BorderRadius`
								)
							)?.bottomLeft
						),
						label: 'Bottom left'
					}
				}
			},
			showBorderRadius: obj.component.get(
				camelCase(`${defaults.componentName} useBorderRadius`)
			),
			onBorderRadiusToggle: isActive =>
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} useBorderRadius`),
					isActive
				),
			borderColorPickerProp: 'imageBorderCpx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const accordion = imageAccordion.call(
		null,
		options.imageAccordionOptions,
		customTrait
	);

	const styleTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return styleTab;
};
