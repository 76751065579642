import {
	validateStyleObj,
	validateBorder
} from '../../../common/functions/_utilFunctions';

export default comp => {
	const style = {
		'background-color': comp.get('backgroundColor'),
		width: comp.get('imgWidth'),
		'border-width': comp.get('borderWidth'),
		'border-style': comp.get('borderStyle'),
		'border-color': comp.get('borderColor'),
		'border-radius': comp.get('useBorderRadius')
			? Object.values(comp.get('borderRadius')).join(' ')
			: null,
		'text-align': comp.get('alignment'),
		display: 'inline-block'
	};

	return validateStyleObj(validateBorder(style));
};
