import {
	merge,
	cssStyleObjectToString,
	deleteProps,
	getUnit
} from 'datatalks-utils';
import getComputedStyle from './_getComputedStyle';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		src: comp.get('imgSrc'),
		alt: comp.get('alternateText'),
		parentWidth: null,
		hasLink: comp.get('hasLink'),
		href: comp.get('href')
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const tableStyle = deleteProps(
		options.style,
		[
			'display',
			'background-color',
			'border-radius',
			'border-width',
			'border-style',
			'border-color'
		],
		false
	);

	const cellStyle = deleteProps(
		options.style,
		['display', 'text-align', 'width'],
		false
	);

	const imageHasLink = options.hasLink && !!options.href;

	/**
	 * Calculates the width of the image based on the provided options and parent width.
	 * @return {number} The calculated width of the image.
	 */
	function getImageWidth() {
		if (getUnit(options.style.width) === 'px')
			return parseFloat(options.style.width);

		return (
			parseFloat(comp.get('renderWidth')) ||
			(parseFloat(options.style.width) / 100) *
				parseFloat(options.parentWidth)
		);
	}

	const msoImage = `<img src="${options.src}" alt="${
		options.alt
	}" width="${getImageWidth()}" />`;

	const image = `<img src="${options.src}" alt="${options.alt}" width="${
		options.style.width
	}" style="${cssStyleObjectToString(
		validateStyleObj(options.style)
	)} max-width:${getImageWidth() + 'px'}"/>`;

	const html = `
		<!--[if !mso]><!-->
			${imageHasLink ? `<a href="${options.href}">` : ''}
		<!--<![endif]-->
			<!--[if mso]>
				<table width="${getImageWidth()}px" style="box-sizing: border-box; border-spacing: 0px; ${cssStyleObjectToString(
		tableStyle
	)}">
					<tr style="box-sizing: border-box;">
						<td style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px; ${cssStyleObjectToString(
							cellStyle
						)}">
							${imageHasLink ? `<a href="${options.href}">` : ''}
								${msoImage}
							${imageHasLink ? '</a>' : ''}
							<![endif]-->
							<!--[if !mso]><!-->
								${image}
							<!--<![endif]-->
							<!--[if mso]>
						</td>
					</tr>
				</table>
			<![endif]-->
		<!--[if !mso]><!-->
			${imageHasLink ? '</a>' : ''}
		<!--<![endif]-->
		`;

	if (options.debug) console.log('List HTML: ', html);

	return html;
};
