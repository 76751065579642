import {
	validateStyleObj,
	validateBorder
} from '../../../common/functions/_utilFunctions';

export default comp => {
	const style = {
		'background-color': comp.get('backgroundColor'),
		'font-family': comp.get('fontFamily'),
		color: comp.get('color'),
		'font-weight': comp.get('fontWeight'),
		'border-width': comp.get('borderWidth'),
		'border-style': comp.get('borderStyle'),
		'border-color': comp.get('borderColor'),
		'text-align': comp.get('textAlign'),
		width: comp.get('width'),
		'border-radius': comp.get('useBorderRadius')
			? Object.values(comp.get('borderRadius')).join(' ')
			: null,
		display: 'block'
	};

	return validateStyleObj(validateBorder(style));
};
