import { RadioButtonGroup, Accordion, SectionItem } from 'datatalks-ui';
import { merge, addClassesString, setContent } from 'datatalks-utils';

export default (obj, options) => {
	const localize = obj.component.localize;
	const defaults = {
		accordion: {
			title:
				localize('traits.styles.sections.layout', true) ||
				localize('Layout'),
			color: 'grey',
			type: 'extended',
			startOpen: true
		}
	};

	options = merge(defaults, options);

	const accordionContent = new SectionItem({
		label: localize(`Social networks' position`),
		content: new RadioButtonGroup({
			singleSelection: true,
			onChange: (allSelected, selectedItem) => {
				obj.component.setAndRerender.call(
					obj.component,
					'socialListSideways',
					selectedItem.getValue()
				);
			},
			items: [
				{
					value: false,
					label: localize('misc.bottom', true) || localize('Bottom'),
					selected: obj.component.get('socialListSideways') === false
				},
				{
					value: true,
					label: localize('misc.right', true) || localize('Right'),
					selected: obj.component.get('socialListSideways') === true
				}
			]
		}).getEl()
	}).getEl();

	return new Accordion({
		title: options.accordion.title,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		content: accordionContent,
		startOpen: options.accordion.startOpen
	});
};
