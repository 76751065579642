import {
	addClassesString,
	getUnit as getStringUnit,
	setContent,
	CssUnits,
	trimAll,
	EventEmitter
} from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';

/* eslint-disable require-jsdoc */
export default class InputNumber {
	constructor(options) {
		const defaults = {
			arrowUp: getIcon('arrow-up-s-line', { size: 'lg' }),
			arrowUpClickPreventDefault: false,
			arrowUpOnClick: null,
			arrowDown: getIcon('arrow-down-s-line', { size: 'lg' }),
			arrowDownClickPreventDefault: false,
			arrowDownOnClick: null,
			classPrefix: 'eb-',
			cssClass: 'input-number',
			defaultValue: 0,
			extendedClasses: '',
			inputExtendedClasses: '',
			increment: 1,
			onChange: null,
			changeableUnit: false,
			availableUnits: CssUnits,
			max: null,
			min: 0,
			unit: null,
			getUnit: value => getStringUnit(value),
			disabled: false,
			emitEventsWhenDisabled: false
		};

		this.options = {
			...defaults,
			...options
		};

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.disabled = this.options.disabled;
		this.eventEmitter = new EventEmitter();
		this.element = null;
		this.downArrow = null;
		this.increment = this.options.increment || 1;
		this.input = null;
		this.value = parseFloat(this.options.defaultValue);
		this.unit =
			this.options.unit || this.options.getUnit(this.value) || 'px';
		this.inputValue = '' + this.value + this.unit;
		this.upArrow = null;
		this.min = this.options.min;
		this.max = this.options.max;

		this.init();
	}

	init() {
		this.createWrapper();
		this.createInput();
		this.createArrows();
		this.draw();
	}

	draw() {
		this.element.append(this.input, this.upArrow, this.downArrow);
	}

	createWrapper() {
		const iN = this;
		iN.element = document.createElement('div');
		iN.element.className = iN.className;
		if (iN.options.extendedClasses) {
			addClassesString(iN.element, iN.options.extendedClasses);
		}
	}

	createInput() {
		const iN = this;
		iN.input = document.createElement('input');
		iN.input.className = `eb-input ${this.className}__input`;
		if (iN.options.inputExtendedClasses) {
			addClassesString(iN.input, iN.options.inputExtendedClasses);
		}
		iN.input.value = iN.inputValue;
		iN.input.addEventListener('change', e => {
			iN.validateAndSetValue.call(iN, e.target.value, true);
		});

		if (iN.disabled) this.disable();
	}

	disable() {
		this.element.classList.add(`${this.className}--disabled`);
		this.input.disabled = true;
		if (this.upArrow) this.disableArrow(this.upArrow);
		if (this.downArrow) this.disableArrow(this.downArrow);
		this.disabled = true;
	}

	enable() {
		this.element.classList.remove(`${this.className}--disabled`);
		this.input.removeAttribute('disabled');
		this.enableArrow(this.upArrow);
		this.enableArrow(this.downArrow);
		this.disabled = false;
	}

	createArrows() {
		const iN = this;
		iN.upArrow = document.createElement('div');
		iN.upArrow.className = `${this.className}__upArrow`;
		iN.upArrow.addEventListener('click', e => {
			iN.onUpArrowClick.call(iN);
		});
		setContent(iN.upArrow, iN.options.arrowUp);

		iN.downArrow = document.createElement('div');
		iN.downArrow.className = `${this.className}__downArrow`;
		iN.downArrow.addEventListener('click', e => {
			iN.onDownArrowClick.call(iN);
		});
		setContent(iN.downArrow, iN.options.arrowDown);
	}

	setValue(inputValue, userInput = false) {
		this.value = parseFloat(inputValue);
		if (this.options.changeableUnit && this.validateUnit(inputValue)) {
			this.inputValue = trimAll(inputValue);
			this.unit = getStringUnit(inputValue);
		} else {
			this.inputValue = '' + this.value + this.unit;
		}

		this.handleChange(userInput);
	}

	validateUnit(string) {
		return this.options.availableUnits.includes(getStringUnit(string));
	}

	getValue() {
		return this.value;
	}

	getUnit() {
		return this.unit;
	}

	getInputValue() {
		return this.inputValue;
	}

	handleChange(userInput) {
		this.input.value = this.inputValue;
		if (this.options.emitEventsWhenDisabled || !this.disabled) {
			if (typeof this.options.onChange === 'function')
				this.options.onChange.call(
					this,
					this.value,
					this.unit,
					this.inputValue,
					userInput
				);
			this.eventEmitter.emit(
				'change',
				this.value,
				this.unit,
				this.inputValue,
				userInput
			);
		}
	}

	incrementValue(userInput = false) {
		this.validateAndSetValue(this.getValue() + this.increment, userInput);
	}

	decrementValue(userInput = false) {
		this.validateAndSetValue(this.getValue() - this.increment, userInput);
	}

	validateAndSetValue(newValue, userInput) {
		if (this.min != null && parseFloat(newValue) <= this.min) {
			newValue = this.min;
			this.disableArrow(this.downArrow);
		} else if (this.max != null && parseFloat(newValue) >= this.max) {
			newValue = this.max;
			this.disableArrow(this.upArrow);
		}

		if (
			this.min != null &&
			parseFloat(newValue) > this.min &&
			this.arrowIsDisabled(this.downArrow)
		)
			this.enableArrow(this.downArrow);

		if (
			this.max != null &&
			parseFloat(newValue) < this.max &&
			this.arrowIsDisabled(this.upArrow)
		)
			this.enableArrow(this.upArrow);

		this.setValue('' + parseFloat(newValue) + this.unit, userInput);
	}

	arrowIsDisabled(arrow) {
		return arrow.classList.contains(`${this.className}__arrow--disabled`);
	}

	disableArrow(arrow) {
		arrow.classList.add(`${this.className}__arrow--disabled`);
	}

	enableArrow(arrow) {
		arrow.classList.remove(`${this.className}__arrow--disabled`);
	}

	onUpArrowClick() {
		if (!this.options.arrowUpClickPreventDefault) this.incrementValue(true);
		if (typeof this.options.arrowUpOnClick === 'function')
			this.options.arrowUpOnClick.call(
				this,
				this.value,
				this.unit,
				this.inputValue
			);
	}

	onDownArrowClick() {
		if (!this.options.arrowDownClickPreventDefault)
			this.decrementValue(true);
		if (typeof this.options.arrowDownOnClick === 'function')
			this.options.arrowDownOnClick.call(
				this,
				this.value,
				this.unit,
				this.inputValue
			);
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	getEl() {
		return this.element;
	}
}
