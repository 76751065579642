import { addClassesString, setContent, merge, getUnit } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { Accordion, section } from 'datatalks-ui';
import { toHex } from 'color2k';
import { capitalize } from 'lodash-es';

import getStyleTabOptions from '../../common/_styleTabOptions';

import backgroundAccordion from '../../Header/components/styleTab/_backgroundAccordion';
import imageAccordion from '../../Header/components/styleTab/_imageAccordion';
// import linksAccordion from '../../Header/components/styleTab/_linksAccordion';
import _marginsAccordion from '../../commonAccordions/style/_margins';
import layoutAccordion from '../../commonAccordions/style/_layout';
import spacingAccordion from './styleTab/_spacingAccordion';
import alignmentAccordion from './styleTab/_alignmentAccordion';
import buttonAccordion from '../../commonAccordions/style/_button';
import borderAccordion from '../../commonAccordions/style/_border';

export default (obj, options, customTrait = {}) => {
	/**
	 * Returns the default margin options for an element.
	 * @param {Object} element - The element object.
	 * @return {Object} - The default margin options.
	 */
	function getDefaultMarginOptions(element) {
		return {
			accordion: {
				onlyContent: true
			},
			traitsVisibility: {
				hasMargins: false,
				marginsColor: false
			},
			margins: {
				label: `${capitalize(element)} margins`,
				left: obj.component.getItemsProp(`${element}LeftMargin`),
				right: obj.component.getItemsProp(`${element}RightMargin`),
				top: obj.component.getItemsProp(`${element}TopMargin`),
				bottom: obj.component.getItemsProp(`${element}BottomMargin`),
				onLeftChange: inputValue =>
					obj.component.changeItemsProp(
						`${element}LeftMargin`,
						inputValue
					),
				onRightChange: inputValue =>
					obj.component.changeItemsProp(
						`${element}RightMargin`,
						inputValue
					),
				onTopChange: inputValue =>
					obj.component.changeItemsProp(
						`${element}TopMargin`,
						inputValue
					),
				onBottomChange: inputValue =>
					obj.component.changeItemsProp(
						`${element}BottomMargin`,
						inputValue
					)
			}
		};
	}
	const defaults = {
		accordionsVisibility: {
			layout: true,
			background: true,
			border: true,
			margins: true,
			image: true,
			alignment: true,
			button: true
		},
		extendedClasses: '',
		backgroundAccordionOptions: {
			traitsVisibility: {
				primary: true,
				secondary: true
			},
			secondary: {
				label: 'Items color',
				value: obj.component.getItemsProp('backgroundColor'),
				emptyColor: 'transparent',
				emptyColorLabel: 'No color',
				onChange: color =>
					obj.component.changeItemsProp('backgroundColor', color)
			}
		},
		marginsAccordionOptions: {
			accordion: {
				title: 'Margins',
				color: 'grey',
				type: 'extend'
			},
			traitsVisibility: {
				list: true,
				innerElements: {
					title: true,
					description: true,
					button: true,
					image: true
				}
			},
			list: {
				accordion: {
					onlyContent: true
				},
				hasMargins: {
					label: 'Choose an option',
					value: obj.component.get('hasMargins'),
					onChange: hasMargins =>
						obj.component.setAndRerender('hasMargins', hasMargins)
				},
				margins: {
					label: 'List Margins',
					left: obj.component.get('leftMargin'),
					right: obj.component.get('rightMargin'),
					top: obj.component.get('topMargin'),
					bottom: obj.component.get('bottomMargin'),
					onLeftChange: inputValue =>
						obj.component.setAndRerender('leftMargin', inputValue),
					onRightChange: inputValue =>
						obj.component.setAndRerender('rightMargin', inputValue),
					onTopChange: inputValue =>
						obj.component.setAndRerender('topMargin', inputValue),
					onBottomChange: inputValue =>
						obj.component.setAndRerender('bottomMargin', inputValue)
				},
				marginsColor: {
					left: obj.component.get('leftMarginColor'),
					right: obj.component.get('rightMarginColor'),
					top: obj.component.get('topMarginColor'),
					bottom: obj.component.get('bottomMarginColor'),
					onLeftChange: color =>
						obj.component.setAndRerender('leftMarginColor', color),
					onRightChange: color =>
						obj.component.setAndRerender('rightMarginColor', color),
					onTopChange: color =>
						obj.component.setAndRerender('topMarginColor', color),
					onBottomChange: color =>
						obj.component.setAndRerender('bottomMarginColor', color)
				}
			},
			title: getDefaultMarginOptions('title'),
			description: getDefaultMarginOptions('description'),
			button: getDefaultMarginOptions('button'),
			image: getDefaultMarginOptions('image')
		},
		spacingAccordionOptions: {
			space: {
				value: obj.component.get('spacingBetweenElements'),
				onChange: value =>
					obj.component.setAndRerender(
						'spacingBetweenElements',
						value
					)
			},
			vertical: {
				value:
					obj.component.getItemsProp(
						'verticalSpacingBetweenElements'
					) || '0px',
				onChange: value => {
					obj.component.changeItemsProp(
						'verticalSpacingBetweenElements',
						value
					);
				}
			},
			horizontal: {
				value:
					obj.component.getItemsProp(
						'horizontalSpacingBetweenElements'
					) || '0px',
				onChange: value => {
					obj.component.changeItemsProp(
						'horizontalSpacingBetweenElements',
						value
					);
				}
			},
			size: {
				slider: {
					value:
						obj.component.getItemsProp('width') === 'auto'
							? 0
							: obj.component.getItemsProp('width'),
					min: 0,
					max: 600,
					interval: 20,
					unit: 'px',
					onChange: value => {
						obj.component.changeItemsProp('width', value);
					}
				},
				toggle: {
					startOpen: obj.component.getItemsProp('width') !== 'auto',
					onToggle: isActive => {
						if (!isActive)
							obj.component.changeItemsProp('width', 'auto');
					}
				}
			}
		},
		layoutAccordionOptions: {
			layout: {
				value: obj.component.getItemsProp('imagePlacement'),
				onChange: value => {
					obj.component.changeItemsProp.call(
						obj.component,
						'imagePlacement',
						value
					);
				}
			}
		},
		imageAccordionOptions: {
			innerTraitsVisibility: {
				alignment: true,
				border: false,
				borderRadius: true,
				width: true
			},
			showBorderRadius: obj.component.getItemsProp(
				'imageUseBorderRadius'
			),
			onBorderRadiusToggle: isActive => {
				obj.component.changeItemsProp('imageUseBorderRadius', isActive);
			},
			componentProperties: {
				imageWidth: 'imgWidth'
			},
			imageSliderOptions: {
				label: 'Image/Content ratio',
				onChange: value => {
					obj.component.changeItemsProp(
						'imageContentRatio',
						parseFloat(value) / 100
					);
				},
				value: obj.component.get('imageContentRatio') * 100 || 50,
				max: 100,
				min: 0,
				unit: '%'
			},
			imageAlignmentOptions: {
				onChange: (allSelected, changedItem) => {
					obj.component.changeItemsProp.call(
						obj.component,
						'imageAlignment',
						changedItem.getValue()
					);
				},
				value: obj.component.getItemsProp('imageAlignment')
			},
			imageBorderRadiusOptions: {
				corners: {
					topLeft: {
						value: parseFloat(
							obj.component.getItemsProp('imageBorderRadius')
								?.topLeft
						),
						onChange: value => {
							obj.component.changeItemsProp('imageBorderRadius', {
								...obj.component.getItemsProp(
									'imageBorderRadius'
								),
								topLeft: value
							});
						},
						unit: getUnit(
							obj.component.getItemsProp('imageBorderRadius')
								?.topLeft
						)
					},
					topRight: {
						value: parseFloat(
							obj.component.getItemsProp('imageBorderRadius')
								?.topRight
						),
						onChange: value => {
							obj.component.changeItemsProp('imageBorderRadius', {
								...obj.component.getItemsProp(
									'imageBorderRadius'
								),
								topRight: value
							});
						},
						unit: getUnit(
							obj.component.getItemsProp('imageBorderRadius')
								?.topRight
						)
					},
					bottomRight: {
						value: parseFloat(
							obj.component.getItemsProp('imageBorderRadius')
								?.bottomRight
						),
						onChange: value => {
							obj.component.changeItemsProp('imageBorderRadius', {
								...obj.component.getItemsProp(
									'imageBorderRadius'
								),
								bottomRight: value
							});
						},
						unit: getUnit(
							obj.component.getItemsProp('imageBorderRadius')
								?.bottomRight
						)
					},
					bottomLeft: {
						value: parseFloat(
							obj.component.getItemsProp('imageBorderRadius')
								?.bottomLeft
						),
						onChange: value => {
							obj.component.changeItemsProp('imageBorderRadius', {
								...obj.component.getItemsProp(
									'imageBorderRadius'
								),
								bottomLeft: value
							});
						},
						unit: getUnit(
							obj.component.getItemsProp('imageBorderRadius')
								?.bottomLeft
						)
					}
				}
			}
		},
		linksAccordionOptions: {},
		alignmentAccordionOptions: {
			alignment: {
				label: `Items' alignment`,
				icons: {
					left: getIcon('layout-left-2-line', { size: 'xl' }),
					center: getIcon('layout-center-2-line', { size: 'xl' }),
					right: getIcon('layout-right-2-line', { size: 'xl' })
				}
			},
			vAlignment: {
				label: `Items' vertical alignment`,
				icons: {
					top: getIcon('layout-top-2-line', { size: 'xl' }),
					middle: getIcon('layout-middle-2-line', { size: 'xl' }),
					bottom: getIcon('layout-bottom-2-line', { size: 'xl' })
				}
			}
		},
		buttonAccordionOptions: {
			background: {
				color: obj.component.getItemsProp('buttonBackgroundColor'),
				onChange: (cpi, ev) => {
					obj.component.changeItemsProp.call(
						obj.component,
						'buttonBackgroundColor',
						toHex(cpi.color) === toHex(cpi.emptyColor)
							? null
							: cpi.getColor()
					);
				}
			},
			width: {
				value: obj.component.getItemsProp('buttonWidth'),
				unit: getUnit(obj.component.getItemsProp('buttonWidth')),
				onChange: (value, unit, inputValue) => {
					obj.component.changeItemsProp('buttonWidth', inputValue);
				}
			},
			align: {
				label: `Button's Alignment`,
				icons: {
					left: getIcon('layout-left-2-line', { size: 'xl' }),
					center: getIcon('layout-center-2-line', { size: 'xl' }),
					right: getIcon('layout-right-2-line', { size: 'xl' })
				},
				value: obj.component.getItemsProp('buttonAlignment'),
				onChange: (allSelected, changedItem) => {
					obj.component.changeItemsProp(
						'buttonAlignment',
						changedItem.getValue()
					);
				}
			},
			textAlign: {
				value: obj.component.getItemsProp('buttonTextAlignment'),
				onChange: (allSelected, changedItem) => {
					obj.component.changeItemsProp(
						'buttonTextAlignment',
						changedItem.getValue()
					);
				}
			},
			height: {
				value:
					obj.component.getItemsProp('buttonHeight') ||
					obj.component
						.getEditor()
						?.getStyleRules('button', 'height'),
				unit: getUnit(obj.component.getItemsProp('buttonHeight')),
				onChange: value => {
					obj.component.changeItemsProp('buttonHeight', value);
				}
			},
			font: {
				value: obj.component.getItemsProp('buttonFontFamily'),
				onChange: value => {
					obj.component.changeItemsProp('buttonFontFamily', value);
				}
			},
			weight: {
				value: obj.component.getItemsProp('buttonFontWeight'),
				onChange: value => {
					obj.component.changeItemsProp('buttonFontWeight', value);
				}
			},
			color: {
				value: obj.component.getItemsProp('buttonColor'),
				onChange: value => {
					obj.component.changeItemsProp('buttonColor', value);
				}
			},
			borderWidth: {
				value: obj.component.getItemsProp('buttonBorderWidth') || 0,
				unit:
					getUnit(obj.component.getItemsProp('buttonBorderWidth')) ||
					'px',
				onChange: value => {
					obj.component.changeItemsProp('buttonBorderWidth', value);
				}
			},
			borderStyle: {
				value: obj.component.getItemsProp('buttonBorderStyle'),
				onChange: value => {
					obj.component.changeItemsProp('buttonBorderStyle', value);
				}
			},
			borderColor: {
				value: obj.component.getItemsProp('buttonBorderColor'),
				onChange: value => {
					obj.component.changeItemsProp('buttonBorderColor', value);
				}
			},
			borderRadius: {
				corners: {
					topLeft: {
						value: parseFloat(
							obj.component.getItemsProp('buttonBorderRadius')
								?.topLeft
						),
						onChange: value => {
							obj.component.changeItemsProp(
								'buttonBorderRadius',
								{
									...obj.component.getItemsProp(
										'buttonBorderRadius'
									),
									topLeft: value
								}
							);
						},
						unit: getUnit(
							obj.component.getItemsProp('buttonBorderRadius')
								?.topLeft
						)
					},
					topRight: {
						value: parseFloat(
							obj.component.getItemsProp('buttonBorderRadius')
								.topRight
						),
						onChange: value => {
							obj.component.changeItemsProp(
								'buttonBorderRadius',
								{
									...obj.component.getItemsProp(
										'buttonBorderRadius'
									),
									topRight: value
								}
							);
						},
						unit: getUnit(
							obj.component.getItemsProp('buttonBorderRadius')
								.topRight
						)
					},
					bottomRight: {
						value: parseFloat(
							obj.component.getItemsProp('buttonBorderRadius')
								.bottomRight
						),
						onChange: value => {
							obj.component.changeItemsProp(
								'buttonBorderRadius',
								{
									...obj.component.getItemsProp(
										'buttonBorderRadius'
									),
									bottomRight: value
								}
							);
						},
						unit: getUnit(
							obj.component.getItemsProp('buttonBorderRadius')
								.bottomRight
						)
					},
					bottomLeft: {
						value: parseFloat(
							obj.component.getItemsProp('buttonBorderRadius')
								.bottomLeft
						),
						onChange: value => {
							obj.component.changeItemsProp(
								'buttonBorderRadius',
								{
									...obj.component.getItemsProp(
										'buttonBorderRadius'
									),
									bottomLeft: value
								}
							);
						},
						unit: getUnit(
							obj.component.getItemsProp('buttonBorderRadius')
								.bottomLeft
						)
					}
				}
			},
			showBorderRadius: obj.component.getItemsProp(
				'buttonUseBorderRadius'
			),
			onBorderRadiusToggle: isActive => {
				obj.component.changeItemsProp(
					'buttonUseBorderRadius',
					isActive
				);
			}
		}
	};

	/* TODO: this should be done in the blocks definition when the obj/component
	 * is passed as a parameter and accessible in onChange function
	 */
	if (
		obj.component?.get &&
		obj.component.get('itemType') === 'image-wrapper'
	) {
		defaults.imageAccordionOptions.imageSliderOptions = {
			label: 'Image size',
			onChange: value => {
				console.log('value', value);
				obj.component.changeItemsProp('imgWidth', value);
			},
			value: parseFloat(obj.component.getItemsProp('imgWidth')),
			max: 600,
			min: 0,
			unit: 'px'
		};
	}

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	const ao = options.marginsAccordionOptions;

	const marginsAccordion = new Accordion({
		title: ao.accordion.title,
		accordionType: ao.accordion.type,
		accordionColor: ao.accordion.color,
		content: [
			ao.traitsVisibility['list'] &&
				section({
					label: 'List',
					content: _marginsAccordion(ao['list'], customTrait)
				}),
			Object.values(ao.traitsVisibility.innerElements).filter(i => !!i)
				.length &&
				section({
					label: "Items' elements",
					content: Object.keys(ao.traitsVisibility.innerElements)
						.filter(side => ao.traitsVisibility.innerElements[side])
						.map(side => Array.from(_marginsAccordion(ao[side])))
						.flat()
				})
		]
	});

	const styleTab = addClassesString(
		setContent(document.createElement('div'), [
			options.accordionsVisibility.layout &&
				layoutAccordion
					.call(null, options.layoutAccordionOptions, customTrait)
					.getEl(),
			options.accordionsVisibility.background &&
				backgroundAccordion
					.call(
						null,
						obj,
						options.backgroundAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.margins && marginsAccordion.getEl(),
			options.accordionsVisibility.border &&
				borderAccordion(
					getStyleTabOptions(obj, 'border'),
					customTrait
				).getEl(),
			spacingAccordion
				.call(null, obj, options.spacingAccordionOptions, customTrait)
				.getEl(),
			options.accordionsVisibility.image &&
				imageAccordion
					.call(null, obj, options.imageAccordionOptions, customTrait)
					.getEl(),
			// linksAccordion.call(null, obj, options.linksAccordionOptions).getEl()
			options.accordionsVisibility.alignment &&
				alignmentAccordion
					.call(
						null,
						obj,
						options.alignmentAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.button &&
				buttonAccordion
					.call(
						null,
						obj,
						options.buttonAccordionOptions,
						customTrait
					)
					.getEl()
		]),
		options.extendedClasses
	);

	return styleTab;
};
