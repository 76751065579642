import { LinkedInputs } from 'datatalks-ui';
import { merge, allEqual } from 'datatalks-utils';

export default options => {
	const defaults = {
		extendedClasses: '',
		corners: {
			topLeft: {
				value: null,
				onChange: null,
				unit: 'px',
				label: 'Top left'
			},
			topRight: {
				value: null,
				onChange: null,
				unit: 'px',
				label: 'Top right'
			},
			bottomRight: {
				value: null,
				onChange: null,
				unit: 'px',
				label: 'Bottom right'
			},
			bottomLeft: {
				value: null,
				onChange: null,
				unit: 'px',
				label: 'Bottom left'
			}
		},
		inputsStartLinked: true
	};

	defaults.inputsStartLinked = allEqual(
		Object.values(options.corners).map(corner => corner.value)
	);

	options = merge(defaults, options);

	return new LinkedInputs({
		startLinked: options.inputsStartLinked,
		items: Object.values(options.corners).map(corner => {
			const { value, onChange, unit, label } = corner;
			return {
				label,
				input: {
					defaultValue: parseFloat(value),
					onChange: (value, unit, inputValue) => {
						if (typeof onChange === 'function')
							onChange(inputValue);
					},
					unit: unit
				}
			};
		})
	}).getEl();
};
