import { noInteractionPreset } from '../_componentsUtils';
import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import render from './_render';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import baseMethods from '../common/methods/_baseMethods';
import toHtml from './_toHtml';
import { merge } from 'datatalks-utils';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = getComponentsDefaultsByType('list-item');

	const options = {
		...COMPONENTS_DEFAULTS,
		...defaults,
		...componentOptions
	};

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				...options,
				name: options.name,
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				style: {
					width: options.width,
					'table-layout': 'fixed'
				},
				tagName: 'table',
				width: options.width,
				align: options.align,
				components: comp => comp.componentRender(comp),
				...noInteractionPreset
			}),
			componentRender: render,
			toHTML(toHtmlOptions = {}) {
				return toHtml(
					this,
					merge(options.toHtmlOptions, toHtmlOptions)
				);
			},
			...baseMethods(componentOptions, editor)
		}
	};
};
