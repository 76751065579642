import { merge } from 'datatalks-utils';
import { setContent } from 'datatalks-utils';
import colorPicker from './common/_color';
import { Accordion, SectionItem } from 'datatalks-ui';

export default (options = {}, customTrait = {}) => {
	const defaults = {
		backgroundColor: {
			label: 'Color',
			value: null,
			onChange: null, // (color)
			getEmptyColor: null,
			emptyColorLabel: null
		},
		accordion: {
			title: 'Background',
			color: 'grey',
			type: 'extend'
		},
		colorPickerProp: 'backgroundCp'
	};

	options = merge(defaults, options);

	if (!customTrait[options.colorPickerProp]) {
		customTrait[options.colorPickerProp] = colorPicker(
			options.backgroundColor
		);
	}

	const buttonAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.backgroundColor.label,
			content: customTrait[options.colorPickerProp].getEl()
		}).getEl()
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: buttonAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
