/**
 * The object containing the callbacks for the file reader events
 * @typedef { Object } HeaderTraitOptions
 * @property { string } cssClass - The base class to apply to the wrapper container of the header trait.
 * @property { string } extendedClasses - Other classes to apply to the wrapper container of the header trait (i.e. utility classes).
 * @property { object } contentTabOptions - The options to configure header trait's content tab.
 * @property { object } styleTabOptions - The options to configure header trait's style tab.
 */

/**
 * Creates a trait of the type 'header' configured with the passed options
 * @param {HeaderTraitOptions} options - The options to configure the header trait.
 */

export default (options = {}) => {
	return {
		type: 'header',
		options
	};
};
