/* eslint-disable require-jsdoc */
import { capitalize } from 'lodash-es';
import {
	addClassesString,
	borderStyles,
	cssFontWeights,
	getUnit,
	setContent,
	webSafeFonts,
	webFonts,
	merge
} from 'datatalks-utils';
import {
	ColorPickerInput,
	ToggleButton,
	InputNumber,
	Accordion,
	SectionItem,
	section,
	Dropdown
} from 'datatalks-ui';
import letterSpacingContainer from './linksAccordion/_letterSpacing';
import linksBorderRadius from './linksAccordion/_linksBorderRadius';
import alignment from '../../../commonAccordions/style/common/_alignment';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		traitsVisibility: {
			font: true,
			weight: true,
			letterSpacing: true,
			color: true,
			backgroundColor: true,
			width: true,
			borderWidth: true,
			borderStyle: true,
			borderColor: true,
			borderRadius: true,
			align: true
		},
		letterSpacingOptions: {
			elementName: 'links'
		},
		linksBorderRadiusOptions: {},
		showBorderRadiusByDefault: true,
		useWebFonts: true,
		alignmentOptions: null
	};

	options = merge(defaults, options);

	let fonts = webSafeFonts;

	if (options.useWebFonts) {
		fonts = fonts.concat(webFonts);
	}

	let borderSection;
	let showBorderRadius = options.showBorderRadiusByDefault;

	const borderRadiusSectionItem = new SectionItem({
		label: 'Border radius',
		content: linksBorderRadius(obj, options.linksBorderRadiusOptions)
	}).getEl();

	if (options.traitsVisibility.color && !customTrait.linksColorCp)
		customTrait.linksColorCp = new ColorPickerInput({
			color: obj.component.get('linksColor'),
			emptyColor: () =>
				obj.component.getEditor()?.getStyleRules('link', 'color') ||
				null,
			emptyColorLabel: 'Links color',
			onChange: (cpi, ev) => {
				obj.component.changeLinksColor.call(
					obj.component,
					cpi.getColor()
				);
			}
		});

	if (
		options.traitsVisibility.backgroundColor &&
		!customTrait.linksBackgroundCp
	)
		customTrait.linksBackgroundCp = new ColorPickerInput({
			color: obj.component.get('linksBackground'),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender.call(
					obj.component,
					'linksBackground',
					cpi.getColor()
				);
			}
		});

	if (options.traitsVisibility.borderColor && !customTrait.linksBorderCp)
		customTrait.linksBorderCp = new ColorPickerInput({
			color: obj.component.get('linksBorderColor'),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender.call(
					obj.component,
					'linksBorderColor',
					cpi.getColor()
				);
			}
		});

	const linksAccordionContent = setContent(
		document.createElement('div'),
		[
			options.traitsVisibility.width &&
				new SectionItem({
					label: 'Width',
					content: new InputNumber({
						defaultValue: obj.component.get('linksWidth'),
						unit: getUnit(obj.component.get('linksWidth')),
						changeableUnit: true,
						onChange: (value, unit, inputValue) => {
							obj.component.setAndRerender(
								'linksWidth',
								inputValue
							);
						}
					}).getEl()
				}).getEl(),

			options.traitsVisibility.align &&
				new SectionItem({
					label: 'Alignment',
					content: alignment(options.alignmentOptions).getEl()
				}).getEl(),

			(options.traitsVisibility.font ||
				options.traitsVisibility.weight ||
				options.traitsVisibility.letterSpacing ||
				options.traitsVisibility.color) &&
				section({
					label: 'Text',
					content: [
						options.traitsVisibility.font &&
							new SectionItem({
								label: 'Font',
								content: new Dropdown({
									items: fonts.sort().map(font => {
										return {
											content: webFonts.includes(font)
												? `${capitalize(font)} *`
												: capitalize(font),
											value: font,
											active:
												obj.component.get(
													'linksFont'
												) === font
										};
									}),
									onChange: (dropdown, activeItem) => {
										obj.component.setAndRerender.call(
											obj.component,
											'linksFont',
											activeItem.getValue()
										);
									}
								}).getEl()
							}).getEl(),
						options.traitsVisibility.weight &&
							new SectionItem({
								label: 'Text weight',
								content: new Dropdown({
									items: cssFontWeights.map(weight => {
										return {
											content:
												typeof weight === 'string'
													? capitalize(weight)
													: weight,
											value: weight,
											active:
												obj.component.get(
													'linksWeight'
												) === weight
										};
									}),
									onChange: (dropdown, activeItem) => {
										obj.component.setAndRerender.call(
											obj.component,
											'linksWeight',
											activeItem.getValue()
										);
									}
								}).getEl()
							}).getEl(),
						options.traitsVisibility.letterSpacing &&
							letterSpacingContainer(
								obj,
								options.letterSpacingOptions
							),
						options.traitsVisibility.color &&
							new SectionItem({
								label: 'Text Color',
								content: customTrait.linksColorCp.getEl()
							}).getEl()
					]
				}),
			options.traitsVisibility.backgroundColor &&
				section({
					label: 'Background',
					content: [
						new SectionItem({
							label: 'Background Color',
							content: customTrait.linksBackgroundCp.getEl()
						}).getEl()
					]
				}),
			(borderSection = section({
				label: 'Border',
				content: [
					options.traitsVisibility.borderWidth &&
						new SectionItem({
							label: 'Width',
							content: new InputNumber({
								defaultValue:
									obj.component.get('linksBorderWidth'),
								unit:
									getUnit(
										obj.component.get('linksBorderWidth')
									) || 'px',
								onChange: (value, unit, inputValue) => {
									obj.component.setAndRerender.call(
										obj.component,
										'linksBorderWidth',
										inputValue
									);
								}
							}).getEl()
						}).getEl(),
					options.traitsVisibility.borderStyle &&
						new SectionItem({
							label: 'Style',
							content: new Dropdown({
								items: borderStyles.map(style => {
									return {
										content: capitalize(style),
										value: style,
										active:
											obj.component.get(
												'linksBorderStyle'
											) === style
									};
								}),
								onChange: (dropdown, activeItem) => {
									obj.component.setAndRerender.call(
										obj.component,
										'linksBorderStyle',
										activeItem.getValue()
									);
								}
							}).getEl()
						}).getEl(),
					options.traitsVisibility.borderColor &&
						new SectionItem({
							label: 'Color',
							content: customTrait.linksBorderCp.getEl()
						}).getEl(),
					options.traitsVisibility.borderRadius &&
						addClassesString(
							setContent(document.createElement('div'), [
								`<label class="eb-label">Border radius</label>`,
								new ToggleButton({
									startActive: showBorderRadius,
									onChange: isActive => {
										showBorderRadius = isActive;
										borderSection.toggleContent(
											borderRadiusSectionItem
										);
									}
								}).getEl()
							]),
							'eb-mt-5 eb-flex eb-items-center eb-justify-between'
						)
				]
			}))
		],
		true
	);

	if (showBorderRadius && options.traitsVisibility.borderRadius)
		borderSection.addContent(borderRadiusSectionItem);

	const linksAccordion = new Accordion({
		title: 'Links',
		content: linksAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return linksAccordion;
};
