import { RadioButtonGroup } from 'datatalks-ui';
import { toBoolean } from 'datatalks-utils';
import { merge } from 'datatalks-utils';

export default (options = {}) => {
	const defaults = {
		value: null,
		onChange: null,
		convertToBoolean: false,
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				value: 'left',
				selected: options.value === 'left',
				label: 'Left'
			},
			{
				value: 'top',
				selected: options.value === 'top',
				label: 'Top'
			},
			{
				value: 'right',
				selected: options.value === 'right',
				label: 'Right'
			}
		]
	};

	options = merge(defaults, options);

	const radioButtonGroupOptions = {
		onChange: (allSelected, changedItem, selectedItems, noneSelected) => {
			if (typeof options.onChange === 'function')
				options.onChange(
					options.convertToBoolean
						? toBoolean(changedItem.getValue())
						: changedItem.getValue()
				);
		},
		singleSelection: options.singleSelection,
		allowNoSelection: options.allowNoSelection,
		items: options.items
	};

	const layoutButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return layoutButtonGroup;
};
