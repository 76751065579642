/* eslint-disable require-jsdoc */
export default class HeaderItem {
	constructor(options = {}) {
		const defaults = {
			element: null,
			item: null
		};

		this.options = {
			...defaults,
			...options
		};

		this.element = this.options.element;
		this.item = this.options.item;
	}

	getItem() {
		return this.item;
	}

	getEl() {
		return this.element;
	}

	setItem(item) {
		this.item = item;
	}

	setElement(element) {
		this.element = element;
	}
}
