export default {
	attributes: {},
	backgroundColor: null,
	cssClass: 'gjs-comp-list-item',
	imgSrc: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+',
	title: '<h3>Heading</h3>',
	align: 'center',
	vAlign: 'middle',
	textAlign: 'left',
	description: '<p>This is a placeholder for the description of the item</p>',
	buttonText: 'Call to action',
	imagePlacement: 'left',
	imgWidth: '100%',
	imageHasLink: false,
	imageHref: null,
	imageBorderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomRight: '0px',
		bottomLeft: '0px'
	},
	imageUseBorderRadius: false,
	imageAlignment: 'left',
	imageLeftMargin: '0px',
	imageRightMargin: '0px',
	imageTopMargin: '0px',
	imageBottomMargin: '0px',
	imageHasMargins: true,
	width: '100%',
	name: 'List Item',
	displayImage: true,
	displayTitle: true,
	titleLeftMargin: '0px',
	titleRightMargin: '0px',
	titleTopMargin: '0px',
	titleBottomMargin: '0px',
	titleHasMargins: true,
	displayDescription: true,
	descriptionLeftMargin: '0px',
	descriptionRightMargin: '0px',
	descriptionTopMargin: '0px',
	descriptionBottomMargin: '0px',
	descriptionHasMargins: true,
	displayButton: true,
	buttonPh: 'Button text',
	buttonBackgroundColor: null,
	buttonHeight: null,
	buttonWidth: '100%',
	buttonFontFamily: null,
	buttonFontSize: null,
	buttonFontStyle: 'normal',
	buttonColor: null,
	buttonFontWeight: null,
	buttonBorderWidth: '0px',
	buttonBorderStyle: 'solid',
	buttonBorderColor: 'transparent',
	buttonAlignment: 'center',
	buttonTextAlignment: 'center',
	buttonBorderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomRight: '0px',
		bottomLeft: '0px'
	},
	buttonLineHeight: null,
	buttonUseBorderRadius: false,
	buttonLetterSpacing: 'normal',
	buttonNormalLetterSpacing: true,
	buttonDefaultLetterSpacingLength: '0.1em',
	buttonLeftMargin: '0px',
	buttonRightMargin: '0px',
	buttonTopMargin: '0px',
	buttonBottomMargin: '0px',
	buttonHasMargins: true,
	buttonTextDecorationLine: null,
	verticalSpacingBetweenElements: '8px',
	horizontalSpacingBetweenElements: '16px',
	imageContentRatio: 0.5,
	toHtmlOptions: {},
	imageVAlignment: 'middle',
	contentVAlignment: 'middle'
};
