/* eslint-disable require-jsdoc */
import { merge } from 'datatalks-utils';
import { noInteractionPreset } from './assets/components/_componentsUtils';
import { kebabCase } from 'lodash-es';

export default function createCustomBlocks(editor, opts = {}) {
	const bm = editor.BlockManager;

	if (!opts.customBlocks.length) return;
	bm.categories.add({
		id: 'custom',
		label: 'Custom Blocks',
		open: true
	});

	opts.customBlocks.forEach(block => {
		const { id, label, getContent, icon, name, toHTML } = block;

		const _name = name || label;
		if (
			typeof block.hasValidPayload === 'function' &&
			!block.hasValidPayload(opts.customBlocksPayload[id])
		)
			return;

		const options = opts?.customBlocksOptions
			? opts.customBlocksOptions[id] || {}
			: {};
		const payload = opts?.customBlocksPayload
			? opts.customBlocksPayload[id] || {}
			: {};

		if (options.active === false) return;

		const content = getContent(payload, options).map(comp =>
			merge(comp, noInteractionPreset)
		);

		if (_name) {
			const component = {
				model: {
					defaults: {
						components: content,
						name: _name
					}
				}
			};
			if (typeof toHTML === 'function')
				component.model = {
					...component.model,
					toHTML: toHTML.bind(
						null,
						opts.customBlocksPayload[id],
						opts.customBlocksOptions[id]
					)
				};
			editor.DomComponents.addType(kebabCase(_name), component);
		}
		if (opts.customBlocksPayload[id] && opts.customBlocksOptions[id]) {
			const block = {
				label,
				content: {
					type: _name ? kebabCase(_name) : 'default',
					tagName: 'div',
					traits: ['name'],
					name: _name,
					hasTraits: false,
					components: content
				},
				category: 'custom',
				media: icon
			};
			if (!icon) delete block.media;
			bm.add(id, block);
		}
	});
}
