import { get } from 'lodash-es';
import { merge } from 'datatalks-utils';

/**
 * TranslationFramework class to manage multiple languages and translations.
 */
export default class TranslationFramework {
	/**
	 * Creates an instance of TranslationFramework.
	 * @param {Object} [options={}] - The options for the TranslationFramework.
	 * @param {string} [options.defaultKey='terms'] - The default key for translations.
	 * @param {string} [options.fallbackLanguage='en-US'] - The language to use as a fallback.
	 * @param {string} [options.currentLanguage=null] - The current language to use for translations.
	 * @param {Object} [options.languages={}] - An object containing key-value pairs of languages and translations.
	 */
	constructor(options = {}) {
		const defaults = {
			defaultKey: 'terms',
			fallbackLanguage: 'en-US',
			currentLanguage: null,
			languages: {},
			onKeyNotFound: null
		};

		this.options = merge(defaults, options);
		this.languages = this.options.languages;
		this.currentLanguage = this.options.currentLanguage;
		this.fallbackLanguage = this.options.fallbackLanguage;
	}

	/**
	 * Adds new options to the existing options by merging them.
	 *
	 * @param {Object} options - The new options to be added.
	 */
	addOptions(options) {
		this.options = merge(this.options, options);
	}

	/**
	 * Registers a language with its translations.
	 * @param {string} languageCode - The code of the language to register (e.g., 'en', 'es').
	 * @param {Object} [translations={}] - An object containing key-value pairs of translations.
	 */
	registerLanguage(languageCode, translations = {}) {
		this.languages[languageCode] = translations;
		this.languages[languageCode].unregistered = new Set();
	}

	/**
	 * Sets the current language for translations.
	 * @param {string} languageCode - The code of the language to set as current.
	 */
	setLanguage(languageCode) {
		if (this.languages[languageCode]) {
			this.currentLanguage = languageCode;
		} else {
			console.warn(`Language ${languageCode} is not registered.`);
		}
	}

	/**
	 * Adds a translation for a specific key in a given language.
	 * @param {Object} options - The options for the translation.
	 * @param {string} options.languageCode - The code of the language to add the translation to.
	 * @param {string} options.key - The key for the translation.
	 * @param {string} options.translation - The translation text.
	 */
	addTranslation({ languageCode, key, translation }) {
		if (!translation) {
			console.warn(`No translation provided for the '${key}' key.`);
			return;
		}
		languageCode =
			languageCode || this.currentLanguage || this.fallbackLanguage;
		key = key || this.options.defaultKey || 'terms';
		if (!this.languages[languageCode]) {
			this.languages[languageCode] = {};
		}
		this.languages[languageCode][key] = translation;
	}

	/**
	 * Gets the translation for a specific key in the current language.
	 * @param {string} key - The key for the translation.
	 * @return {string} - The translation text or a message if not found.
	 * @param {boolean} [nullable=false] - Whether to return null or the key if the translation is not found.
	 */
	getTranslation(key, nullable = false) {
		if (this.currentLanguage && this.languages[this.currentLanguage]) {
			const translation =
				this.languages[this.currentLanguage][key] ||
				get(this.languages[this.currentLanguage], key) ||
				get(this.languages[this.currentLanguage].terms, key);
			if (translation) {
				return translation;
			} else {
				this.notifyUnregistered(key);
				return (
					this.languages[this.fallbackLanguage][key] ||
					get(this.languages[this.fallbackLanguage], key) ||
					get(this.languages[this.fallbackLanguage].terms, key) ||
					(nullable ? null : key)
				);
			}
		}
		return `No language set or translation for ${key} not found.`;
	}

	/**
	 * Notifies that a translation key is unregistered for the current
	 * language and stores it in the TranslationFramework instance.
	 *
	 * @param {string} key - The translation key that is unregistered.
	 */
	notifyUnregistered(key) {
		this.languages[this.currentLanguage].unregistered.add(key);
		if (typeof this.options.onKeyNotFound === 'function') {
			this.options.onKeyNotFound(this.currentLanguage, key);
		}
	}

	/**
	 * Checks if the specified language code exists in the languages object.
	 *
	 * @param {string} languageCode - The code of the language to check.
	 * @return {boolean} - Returns true if the language exists, otherwise false.
	 */
	hasLanguage(languageCode) {
		return !!this.languages[languageCode];
	}

	/**
	 * Loads multiple translations for a specific language.
	 * @param {string} languageCode - The code of the language to load translations for.
	 * @param {Object} translations - An object containing key-value pairs of translations.
	 */
	loadTranslations(languageCode, translations) {
		if (!this.languages[languageCode]) {
			this.languages[languageCode] = {};
		}
		Object.assign(this.languages[languageCode], translations);
	}
}
