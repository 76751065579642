import { merge } from 'datatalks-utils';
import buttonAccordion from '../../../commonAccordions/style/_button';
import { addClassesString, setContent } from 'datatalks-utils';
import { camelCase } from 'lodash-es';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		componentName: 'Button'
	};

	defaults = {
		...defaults,
		extendedClasses: '',
		buttonAccordionOptions: {
			traitsVisibility: {
				font: false,
				weight: false
			},
			showBorderRadius: obj.component.get(
				camelCase(`${defaults.componentName} useBorderRadius`)
			),
			onBorderRadiusToggle: isActive =>
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} useBorderRadius`),
					isActive
				),
			textColorPickerProp: 'buttonCpx',
			backgroundColorPickerProp: 'buttonBackgroundCpx',
			borderColorPickerProp: 'buttonBorderCpx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	const accordion = buttonAccordion.call(
		null,
		obj,
		options.buttonAccordionOptions,
		customTrait
	);

	const styleTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return styleTab;
};
