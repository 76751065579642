import { validateStyleObj } from '../../common/functions/_utilFunctions';

export default comp => {
	return validateStyleObj({
		width: comp.get('width'),
		'padding-top': comp.get('hasMargins')
			? comp.get('margins')?.top || 0
			: 0,
		'padding-right': comp.get('hasMargins')
			? comp.get('margins')?.right || 0
			: 0,
		'padding-bottom': comp.get('hasMargins')
			? comp.get('margins')?.bottom || 0
			: 0,
		'padding-left': comp.get('hasMargins')
			? comp.get('margins')?.left || 0
			: 0
	});
};
