import { ChoosableSection } from 'datatalks-ui';
import { merge, allEqual } from 'datatalks-utils';

import marginsColor from './_marginsColor';

export default (options = {}, customTrait = {}) => {
	const defaults = {
		label: 'Margins color',
		numberOfInputs: 'auto',
		left: null,
		right: null,
		top: null,
		bottom: null,
		onLeftChange: null,
		onRightChange: null,
		onTopChange: null,
		onBottomChange: null,
		labelsTemplate(n, sides) {
			switch (n) {
				case 1:
					return null;
					break;
				case 2:
					return sides[0] === 'left'
						? `Margin X-axis`
						: `Margin Y-axis`;
					break;
				default:
					return `Margin ${sides[0]}`;
					break;
			}
		}
	};

	options = merge(defaults, options);

	if (options.numberOfInputs === 'auto') {
		const aux = {};
		aux.left =
			typeof options.left === 'function' ? options.left() : options.left;
		aux.right =
			typeof options.right === 'function'
				? options.right()
				: options.right;
		aux.top =
			typeof options.top === 'function' ? options.top() : options.top;
		aux.bottom =
			typeof options.bottom === 'function'
				? options.bottom()
				: options.bottom;
		options.numberOfInputs =
			4 -
			(aux.left === aux.right ? 1 : 0) -
			(aux.top === aux.bottom ? 1 : 0) -
			(allEqual([aux.left, aux.right, aux.top, aux.bottom]) ? 1 : 0);

		if (options.numberOfInputs === 3) options.numberOfInputs = 4;
	}

	const section = new ChoosableSection({
		label: options.label,
		items: [
			{
				active:
					(customTrait &&
						customTrait.marginsColorNumberOfInputs === 1) ||
					options.numberOfInputs === 1,
				label: 'One color margins',
				content: marginsColor.bind(null, {
					...options,
					numberOfInputs: 1
				}),
				onSelect: () => {
					if (customTrait) {
						customTrait.marginsColorNumberOfInputs = 1;
					}
				},
				onDeselect: null
			},
			{
				active:
					(customTrait &&
						customTrait.marginsColorNumberOfInputs === 2) ||
					options.numberOfInputs === 2,
				label: 'Color by axis margins',
				content: marginsColor.bind(null, {
					...options,
					numberOfInputs: 2
				}),
				onSelect: () => {
					if (customTrait) {
						customTrait.marginsColorNumberOfInputs = 2;
					}
				},
				onDeselect: null
			},
			{
				active:
					(customTrait &&
						customTrait.marginsColorNumberOfInputs === 4) ||
					options.numberOfInputs === 4,
				label: 'Choose each margin color',
				content: marginsColor.bind(null, {
					...options,
					numberOfInputs: 4
				}),
				onSelect: () => {
					if (customTrait) {
						customTrait.marginsColorNumberOfInputs = 4;
					}
				},
				onDeselect: null
			}
		]
	}).getEl();

	return section;
};
