import { merge, cssStyleObjectToString, getUnit } from 'datatalks-utils';

import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';

export default (comp, options) => {
	const defaults = {
		debug: false,
		parentWidth: null,
		style: getComputedStyle(comp),
		space: comp.get('spacingBetweenElements')
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isMobileOptimized = comp
		.getEditor()
		.getEmailBuilder()
		.getAdvancedOption('isMobileOptimized');
	const nColumns = comp.get('items')?.length;
	const align = comp.get('align') || 'center';
	options.space =
		parseFloat(options.space) -
		(isMobileOptimized && comp.get('itemType') !== 'list-item'
			? parseFloat(
					comp?.get('itemOptions')
						?.horizontalSpacingBetweenElements || 0
			  )
			: 0) +
		'px';
	const colPadding =
		isMobileOptimized && !isNaN(parseFloat(options.space))
			? parseFloat(options.space) / 2 + 'px'
			: 0;
	const parentWidth = options.parentWidth;
	const itemsVerticalAlignment = comp.getItemsProp('vAlign');
	const itemsBackgroundColor = comp.getItemsProp('backgroundColor');

	const marginsWidth = comp.get('hasMargins')
		? parseFloat(comp.get('leftMargin')) +
		  parseFloat(comp.get('rightMargin')) +
		  'px'
		: 0;

	/**
	 * Calculates the width of the list item based on the parent width, provided options and number of columns.
	 * @return {number} The width of the parent element.
	 */
	function getParentWidth() {
		if (
			comp.getItemsProp('width') != 'auto' &&
			getUnit(comp.getItemsProp('width')) == 'px'
		)
			return parseFloat(comp.getItemsProp('width'));
		if (!comp.get('isVertical'))
			return (
				(parseFloat(options.parentWidth) -
					parseFloat(marginsWidth) -
					parseFloat(options.space) *
						(nColumns - (isMobileOptimized ? 0 : 1))) /
				nColumns
			);
		else return parseFloat(options.parentWidth) - parseFloat(marginsWidth);
	}

	/**
	 * Get the HTML representation of a list item at the specified index.
	 * @param {number} index - The index of the list's item.
	 * @return {string} The HTML representation of the list item.
	 */
	function getItem(index) {
		return comp.findType(comp.get('itemType'))[index]?.toHTML({
			parentWidth: `${getParentWidth()}px`,
			isMobileOptimized
		});
	}

	const debugStrStart = options.debug ? 'data-eb-name="list-first"' : '';
	const debugStrEnd = options.debug ? 'data-eb-name="list-last"' : '';

	const itemsBackgroundStyle =
		itemsBackgroundColor && itemsBackgroundColor != 'transparent'
			? `background:${itemsBackgroundColor};`
			: '';

	const itemsBackgroundAttr =
		itemsBackgroundColor && itemsBackgroundColor != 'transparent'
			? `bgcolor="${itemsBackgroundColor}"`
			: '';

	const verticalInnerHtml = `
		<table ${debugStrStart} style="${cssStyleObjectToString(options.style)}">
			${comp
				.get('items')
				.map(
					(item, index) => `
						${
							index !== 0
								? `<tr><td height="${options.space}" style="height:${options.space};"></td></tr>`
								: ''
						}
						<tr>
							<td align="${
								item.align
							}" ${itemsBackgroundAttr} style="${itemsBackgroundStyle}" ${debugStrEnd}>
								${getItem(index)}
							</td>
						</tr>
					`
				)
				.join('')}
		</table>
	`;

	const horizontalInnerHtml = `
		<div ${debugStrStart} class="ebr-${nColumns}-col" style="text-align:${align};font-size:0;${cssStyleObjectToString(
		options.style
	)}">
			<!--[if mso]>
				<table role="presentation" width="100%" style="${cssStyleObjectToString(
					options.style
				)}" ${
		options.style['background-color']
			? `bgcolor="${options.style['background-color']}"`
			: ''
	}>
					<tr>

						${comp
							.get('items')
							.map(
								(item, index) =>
									`${
										index !== 0 && !isMobileOptimized
											? `<td width="${options.space}" style="width:${options.space};"></td>`
											: ''
									}
									<td ${itemsBackgroundAttr} style="width:${
										getParentWidth() +
										2 * parseFloat(colPadding)
									}px;padding:${colPadding};${itemsBackgroundStyle}" valign="${itemsVerticalAlignment}" align="${
										item.align
									}">
									<![endif]-->
										<div class="ebr-column ${
											isMobileOptimized
												? `ebr-max-width-cover-${Math.floor(
														100 / nColumns
												  )}`
												: ''
										}" style="width:100%;max-width:${
										getParentWidth() +
										2 * parseFloat(colPadding)
									}px;display:inline-block;${itemsBackgroundStyle}vertical-align:${itemsVerticalAlignment};${
										index !== 0 && !isMobileOptimized
											? `margin-left:${options.space};`
											: ''
									}">

											<div ${debugStrEnd} style="padding:${colPadding};text-align:${item.align};">
												${getItem(index)}
											</div>

										</div>
									<!--[if mso]>
									</td>`
							)
							.join('')}

					</tr>
				</table>
			<![endif]-->
		</div>
	`;

	const innerHtml = comp.get('isVertical')
		? verticalInnerHtml
		: horizontalInnerHtml;

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log('List HTML: ', comp.get('hasMargins') ? html : innerHtml);

	return comp.get('hasMargins') ? html : innerHtml;
};
