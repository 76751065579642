import { SectionItem } from 'datatalks-ui';
import { Dropdown } from 'email-builder-ui';
import { setContent } from 'datatalks-utils';
import ImageInput from '../../../../common/imgFileInput/_imgFileInputPreview';
import { merge } from 'datatalks-utils';

export default (obj, item, options = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		hasImageToggler: true,
		toggleBtnClickTogglesAccordion: false,
		useInput: false
	};

	options = merge(defaults, options);

	const urlInput = document.createElement('input');
	urlInput.addEventListener('input', e => {
		obj.component.changeItemProp.call(
			obj.component,
			item,
			'href',
			e.target.value
		);
	});
	urlInput.className = 'eb-input';
	if (item.href) {
		urlInput.value = item.href;
	} else {
		urlInput.placeholder = item.url || 'URL';
	}

	return setContent(document.createElement('div'), [
		new SectionItem({
			label: localize('Upload your image'),
			content: new ImageInput({
				image: item.content.imgSrc,
				useInput: options.useInput,
				callbacks: {
					wrapperClick: (fileInput, e) => {
						const editor = obj.component.getEditor();
						editor.waitForAssets((opts, sender, a) => {
							fileInput.set(sender.imgSrc, sender.imgName);
						});
					},
					change: (fileInput, imgSrc) => {
						obj.component.changeItemProp.call(
							obj.component,
							item,
							'name',
							fileInput?.getName()
								? fileInput?.getName()
								: 'Untitled image'
						);
						obj.component.changeItemImage.call(
							obj.component,
							item,
							imgSrc
						);
					},
					delete: () => {
						obj.component.changeItemProp.call(
							obj.component,
							item,
							'name',
							'Image Placeholder'
						);
						obj.component.changeItemImage.call(
							obj.component,
							item,
							null
						);
					}
				}
			}).getEl()
		}).getEl(),
		new SectionItem({
			label: localize('Add a URL'),
			content: urlInput
		}).getEl(),
		new SectionItem({
			label: localize('Open Mode'),
			content: new Dropdown({
				items: [
					{
						content: localize('Open in a new tab/window'),
						value: '_blank',
						active: item.target === '_blank'
					},
					{
						content: localize('Open in the current tab'),
						value: '_self',
						active: item.target === '_self'
					}
				],
				onChange: (dropdown, activeItem) => {
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'target',
						activeItem.getValue()
					);
				}
			}).getEl()
		}).getEl()
	]);
};
