import { unset } from 'lodash-es';
import { merge } from 'datatalks-utils';
import exportCanvasConfiguration from '../../common/functions/_exportConfig';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		base: null
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			if (
				typeof emailBuilder.getOptions().onExportConfig === 'function'
			) {
				const configurationObject = exportCanvasConfiguration(
					emailBuilder,
					commandOptions.base
				);

				configurationObject.components.forEach(component => {
					unset(component, 'attributes.id');
					if (!component.style) unset(component, 'style');
				});

				emailBuilder
					.getOptions()
					.onExportConfig(configurationObject, emailBuilder);
			}
		}
	};
};
