import { camelCase } from 'lodash-es';
import imageAccordion from '../../../commonAccordions/content/_image';
import { addClassesString, setContent, merge } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		componentName: obj.component.get('name')
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		imageAccordionOptions: {
			imageToggler: {
				showToggler: true,
				startActive: true,
				clickTogglesAccordion: obj.component.get(
					camelCase(`display ${defaults.componentName}`)
				),
				onChange: isActive => {
					obj.component.setAndRerender(
						camelCase(`display ${defaults.componentName}`),
						isActive
					);
				}
			},
			imgSrc: obj.component.get(
				camelCase(`${defaults.componentName} imgSrc`)
			),
			wrapperClick: (fileInput, e) => {
				const editor = obj.component.getEditor();
				editor.waitForAssets((opts, sender, a) => {
					fileInput.set(sender.imgSrc, sender.imgName);
				});
			},
			onChange: (fileInput, imgSrc) => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} imgSrc`),
					imgSrc
				);
			},
			onDelete: () => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} imgSrc`),
					null
				);
			},
			linkSectionStartsOpen: obj.component.get(
				camelCase(`${defaults.componentName} hasLink`)
			),
			onLinkSectionToggle: isActive => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} hasLink`),
					isActive
				);
			},
			onInputChange: (e, input) => {
				obj.component.setAndRerender(
					camelCase(`${defaults.componentName} href`),
					input.value
				);
			},
			inputValue: obj.component.get(
				camelCase(`${defaults.componentName} href`)
			)
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const accordion = imageAccordion.call(
		null,
		options.imageAccordionOptions,
		customTrait
	);

	const contentTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? accordion.getContent()
				: accordion.getEl()
		),
		options.extendedClasses
	);

	return contentTab;
};
