export default {
	attributes: {},
	cssClass: 'gjs-comp-text',
	letterSpacing: 'normal',
	tagName: 'p',
	text: 'This is a text box',
	name: 'Text',
	textAlign: 'left',
	backgroundColor: 'transparent',
	width: '100%',
	alignment: 'center',
	fontFamily: null,
	color: null,
	fontWeight: null,
	borderWidth: '0px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	toHtmlOptions: {},
	useBorderRadius: true,
	borderRadius: {
		topLeft: '0px',
		topRight: '0px',
		bottomRight: '0px',
		bottomLeft: '0px'
	}
};
