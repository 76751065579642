import { merge } from 'datatalks-utils';

import loadData from '../assets/common/_loadData';

export default emailBuilder => {
	const { editor } = emailBuilder;

	editor.on('storage:load', data => loadData(emailBuilder, data));

	editor.on('storage:store', data => {
		const { css, config } = data;
		emailBuilder.setStoredData(
			merge(
				{},
				{
					css,
					config
				}
			)
		);
	});
};
