import { merge } from 'datatalks-utils';

import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import render from './_render';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const defaults = getComponentsDefaultsByType('links-list');

	const options = {
		...COMPONENTS_DEFAULTS,
		...defaults,
		...componentOptions
	};

	return {
		model: {
			defaults: () => ({
				...options,
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				components: comp => comp.componentRender(comp)
			}),

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			componentRender: render
		}
	};
};
