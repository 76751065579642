import { camelCase } from 'lodash-es';
import textAccordion from '../../../commonAccordions/style/_text';
import { addClassesString, setContent, merge, getUnit } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import localize from '../../../common/_localize';

const cornerNames = ['Top Left', 'Top Right', 'Bottom Right', 'Bottom Left'];

export default (obj, options = {}, customTrait = {}) => {
	const _localize = obj.component.localize || localize;

	let defaults = {
		componentName: obj.component.get('name'),
		accordionsVisibility: {
			text: true
		}
	};

	defaults.componentName = options.componentName || defaults.componentName;

	defaults = {
		...defaults,
		extendedClasses: '',
		textAccordionOptions: {
			traitsVisibility: {
				backgroundColor: true,
				color: true,
				font: false,
				weight: true,
				border: false,
				width: true,
				borderRadius: true,
				alignment: true
			},
			backgroundColor: {
				label:
					_localize('traits.styles.labels.backgroundColor') ||
					'Background color',
				value: obj.component.get(
					camelCase(`${defaults.componentName} backgroundColor`)
				),
				onChange: color =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} backgroundColor`),
						color
					),
				getEmptyColor: null,
				emptyColorLabel: null
			},
			color: {
				label: _localize('traits.styles.properties.color') || 'Color',
				value: obj.component.get(
					camelCase(`${defaults.componentName} color`)
				),
				onChange: color =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} color`),
						color
					),
				emptyColorLabel: _localize('General text color'),
				getEmptyColor: () =>
					obj.component
						.getEditor()
						?.getStyleRules('wrapper', 'color') || null
			},
			font: {
				label:
					_localize('traits.styles.properties.fontFamily') || 'Font',
				value: obj.component.get(
					camelCase(`${defaults.componentName} fontFamily`)
				),
				onChange: value =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} fontFamily`),
						value
					)
			},
			weight: {
				label:
					_localize('traits.styles.properties.fontWeight') ||
					'Font weight',
				value: obj.component.get(
					camelCase(`${defaults.componentName} fontWeight`)
				),
				onChange: value =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} fontWeight`),
						value
					)
			},
			accordion: {
				title: _localize('components.names.text') || 'Text',
				color: 'grey',
				type: 'extend'
			},
			border: {
				label: _localize('traits.styles.properties.border') || 'Border',
				style: {
					label:
						_localize('traits.styles.properties.style') || 'Style',
					value: obj.component.get(
						camelCase(`${defaults.componentName} borderStyle`)
					),
					onChange: value =>
						obj.component.setAndRerender(
							camelCase(`${defaults.componentName} borderStyle`),
							value
						)
				}
			},
			width: {
				label: _localize('traits.styles.properties.width') || 'Width',
				value: obj.component.get(
					camelCase(`${defaults.componentName} width`)
				),
				unit: getUnit(
					obj.component.get(
						camelCase(`${defaults.componentName} width`)
					)
				),
				onChange: value => {
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} width`),
						value
					);
				}
			},
			alignment: {
				label:
					_localize('traits.styles.sections.alignment', true) ||
					_localize('Alignment'),
				onChange: (allSelected, changedItem) =>
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} alignment`),
						changedItem.getValue()
					),
				value: obj.component.get(
					camelCase(`${defaults.componentName} alignment`)
				),
				left: {
					value: _localize('misc.left') || 'left',
					selected: value => value === 'left',
					icon: getIcon('layout-left-2-line', { size: 'xl' })
				},
				center: {
					value: _localize('misc.center') || 'center',
					selected: value => value === 'center',
					icon: getIcon('layout-center-2-line', { size: 'xl' })
				},
				right: {
					value: _localize('misc.right') || 'right',
					selected: value => value === 'right',
					icon: getIcon('layout-right-2-line', { size: 'xl' })
				}
			},
			borderRadius: {
				label:
					_localize('traits.styles.properties.borderRadius') ||
					'Border radius',
				useBorderRadius: obj.component.get(
					camelCase(`${defaults.componentName} useBorderRadius`)
				),
				onToggle: isActive => {
					obj.component.setAndRerender(
						camelCase(`${defaults.componentName} useBorderRadius`),
						isActive
					);
				},
				toggleableContent: true,
				corners: null,
				inputsStartLinked: true
			},
			textColorPickerProp: 'textCpx',
			backgroundColorPickerProp: 'textBackgroundCpx'
		},
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	if (options.textAccordionOptions.traitsVisibility.borderRadius) {
		options.textAccordionOptions.borderRadius.corners = cornerNames.reduce(
			(acc, corner) => {
				const cornerName = camelCase(corner);
				acc[cornerName] = {
					value: obj.component.get(
						camelCase(`${defaults.componentName} borderRadius`)
					)[cornerName],
					onChange: value =>
						obj.component.setAndRerender(
							camelCase(`${defaults.componentName} borderRadius`),
							merge(
								obj.component.get(
									camelCase(
										`${defaults.componentName} borderRadius`
									)
								),
								{ [cornerName]: value }
							)
						),
					unit: 'px'
				};
				return acc;
			},
			{}
		);
	}

	const accordion = textAccordion.call(
		null,
		options.textAccordionOptions,
		customTrait
	);

	const styleTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.accordionsVisibility.text
				? options.skipAccordionTitle
					? accordion.getContent()
					: accordion.getEl()
				: null
		),
		options.extendedClasses
	);

	return styleTab;
};
