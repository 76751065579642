import {
	cssSidesToObject,
	cssCornersToObject,
	merge,
	arrayAvg,
	cssStyleObjectToString,
	htmlToElement,
	isElement,
	renderNotMsoConditional,
	renderMsoConditional,
	isHTML,
	getUnit
} from 'datatalks-utils';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';
import getComputedStyle from './_getComputedStyle';

export default (comp, options) => {
	const defaults = {
		debug: false,
		outlookDebug: false,
		style: getComputedStyle(comp),
		text: comp.get('text'),
		href: comp.get('href'),
		parentWidth: null,
		hasOutlookBorderRadius: comp.get('hasOutlookBorderRadius') || false
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isPlainText = !isHTML(options.text) || true;
	const isVMLButton =
		options.style.width != 'auto' ||
		options.hasOutlookBorderRadius ||
		!isPlainText;
	const paddingSide = cssSidesToObject(options.style.padding);

	// Outlook VML CALCULATIONS
	const getOutlookVMLButton = () => {
		const height = options.style['min-height'];
		const isBorderVisible = !(
			!options.style['border-width'] ||
			!options.style['border-color'] ||
			parseFloat(options.style['border-width']) < 0.1 ||
			options.style['border-color'] == 'transparent'
		);

		const borderWidthSide = options.style['border-width']
			? parseFloat(options.style['border-width']) || 0
			: 0;
		const msoButtonWidth =
			options.style.width == 'auto'
				? parseFloat(comp.get('outlookWidth')) || 150
				: getUnit(options.style.width) == '%'
				? parseFloat(options.parentWidth) *
				  (parseFloat(options.style.width) / 100)
				: parseFloat(options.style.width);

		const msoHeight =
			height == 'auto'
				? paddingSide.top +
				  2 * borderWidthSide +
				  parseFloat(options.style['font-size']) +
				  'px'
				: height;
		const msoInnerHeight =
			parseFloat(msoHeight) -
			((borderRadiusRatio <= 50 ? borderRadiusRatio : 50) *
				parseFloat(height)) /
				200 -
			(isBorderVisible ? borderWidthSide : 0) +
			'px';

		const borderRadiusCorner = cssCornersToObject(
			options.style['border-radius']
		);
		const borderRadiusRatio = comp.get('useBorderRadius')
			? (arrayAvg(Object.values(borderRadiusCorner)) * 100) /
			  parseFloat(msoHeight)
			: 0;

		// TODO: improve this -> temporary fix
		options.msoText = options.text;
		if (validateStyleObj(options.style)['line-height']) {
			const textEl = htmlToElement(options.text);
			if (isElement(textEl)) {
				textEl.style.lineHeight = validateStyleObj(options.style)[
					'line-height'
				];
				options.text = textEl.outerHTML;
			}
		}
		return `
			<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml"
						xmlns:w="urn:schemas-microsoft-com:office:word"
						${options.href ? `href="${options.href}"` : ''}
						style="height:${msoHeight};
							v-text-anchor:middle;
							width: ${msoButtonWidth}px"
						arcsize="${borderRadiusRatio}%"
						stroke=${isBorderVisible ? 't' : 'f'}
						${
							options.style['border-color']
								? `strokecolor="${options.style['border-color']}"`
								: ''
						}
						strokeweight="${options.style['border-width'] || 0}"
						fillcolor="${options.style['background-color']}">
				<w:anchorlock/>
				<v:textbox inset="0,0,0,0">
					<center>
						<table border="0" cellpadding="0" cellspacing="0" align="center" height="${msoInnerHeight}"  width="${msoButtonWidth}" style="width:${msoButtonWidth}px; height:${msoInnerHeight};margin:0;">
							<tr style="height:${msoInnerHeight}" height="${msoInnerHeight}">
								<td width="${msoButtonWidth}" align="center" valign="middle" style="color:${
			options.style.color
		};font-family:${options.style['font-family']};font-size:${
			options.style['font-size']
		};font-weight:${options.style['font-weight']}; padding-left: ${
			paddingSide.left || 0
		}px; padding-right:${paddingSide.right || 0}px;">
									${options.msoText}
								</td>
							</tr>
						</table>
					</center>
				</v:textbox>
			</v:roundrect>
		`;
	};

	const getOutlookPadding = side => {
		let padding =
			side == 'x'
				? paddingSide.left + paddingSide.right
				: side == 'y'
				? paddingSide.top + paddingSide.bottom
				: paddingSide[side];

		if (
			options.style.height != 'auto' &&
			(side == 'y' || side == 'top' || side == 'bottom')
		) {
			padding =
				parseFloat(options.style['min-height']) -
				parseFloat(options.style['font-size']);
			if (side == 'top' || side == 'bottom') {
				padding = padding / 2;
			}
		}

		return (padding / parseFloat(options.style['font-size'])) * 100 + '%';
	};

	const innerHtml = `
	<${options.href ? 'a' : 'span'} ${
		options.debug ? 'data-eb-name="button-last"' : ''
	}
		class="ebr-button"
		${options.href ? `href="${options.href}"` : ''}
		style="${cssStyleObjectToString(validateStyleObj(options.style))}">
		${
			isVMLButton
				? ''
				: renderMsoConditional(
						`<i style="mso-font-width:${getOutlookPadding(
							'left'
						)};mso-text-raise:${getOutlookPadding(
							'y'
						)}" hidden>&emsp;</i><span style="mso-text-raise:${getOutlookPadding(
							'bottom'
						)};">`,
						options.outlookDebug
				  )
		}
		${
			isPlainText
				? options.text
				: options.text.replace(
						// TODO: find a better way to handle this
						/&quot;/g,
						"'"
				  )
		}
		${
			isVMLButton
				? ''
				: renderMsoConditional(
						`</span><i style="mso-font-width:${getOutlookPadding(
							'right'
						)};" hidden>&emsp;&#8203;</i>`,
						options.outlookDebug
				  )
		}
	</${options.href ? 'a' : 'span'}>
	`;

	const html = `
	<div ${options.debug ? 'data-eb-name="button-first"' : ''}>
	${
		isVMLButton
			? renderNotMsoConditional(innerHtml, options.outlookDebug) +
			  renderMsoConditional(getOutlookVMLButton(), options.outlookDebug)
			: innerHtml
	}
	</div>
	`;

	if (options.debug) console.log('List HTML: ', html);

	return html;
};
