import { addClassesString } from 'datatalks-utils';

/* eslint-disable require-jsdoc */
export default class Icon {
	constructor(options = {}) {
		const defaults = {
			iconName: '',
			cssClass: 'eb-i',
			tagName: 'i',
			extendedClasses: '',
			size: '',
			availableSizes: [
				'xs',
				'sm',
				'default',
				'md',
				'ml',
				'lg',
				'xl',
				'xxl'
			]
		};

		this.options = {
			...defaults,
			...options
		};

		this.element = null;
		this.init();
	}

	init() {
		this.element = document.createElement('i');
		this.element.className = `${this.options.cssClass} ${this.options.cssClass}-${this.options.iconName}`;
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
		if (this.options.size && this.validateSize())
			this.element.classList.add(
				`${this.options.cssClass}--${this.options.size.trim()}`
			);
	}

	validateSize() {
		return this.options.availableSizes.includes(this.options.size.trim());
	}

	getEl() {
		return this.element;
	}
}
