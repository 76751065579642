import { merge } from 'datatalks-utils';
import noUiSlider from 'nouislider/dist/nouislider.mjs';

export default opts => {
	const defaults = {
		classPrefix: 'eb-',
		cssClass: 'slider',
		extendedClasses: '',
		start: [0],
		behaviour: 'tap',
		connect: 'lower',
		margin: null,
		limit: null,
		padding: null,
		step: null,
		orientation: 'horizontal',
		direction: 'ltr',
		tooltips: true,
		tooltipsOnlyWhenHandle: true,
		animate: true,
		handleAttributes: null,
		keyboardSupport: true,
		keyboardDefaultStep: 10,
		keyboardPageMultiplier: 5,
		keyboardMultiplier: 1,
		range: {
			min: [0],
			max: [100]
		},
		format: {
			to: value => parseFloat(value).toFixed(0),
			from: value => value
		}
	};

	opts = merge(defaults, opts);

	const className = `${opts.classPrefix}${opts.cssClass}`;

	const rangeSlider = document.createElement('div');
	rangeSlider.className = `${className}${
		opts.extendedClasses ? ' ' + opts.extendedClasses : ''
	}`;
	if (opts.orientation === 'vertical')
		rangeSlider.classList.add(`${className}--vertical`);
	if (opts.orientation === 'horizontal')
		rangeSlider.classList.add(`${className}--horizontal`);
	if (opts.tooltipsOnlyWhenHandle)
		rangeSlider.classList.add(`${className}--tooltip-handling`);

	noUiSlider.create(rangeSlider, opts);

	return rangeSlider;
};
