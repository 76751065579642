import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender) => {
			editor.Panels.getPanel('save-changes').set('visible', true);
			editor.getEl().classList.add('eb-editor--has-changes');
		},

		stop: (editor, sender) => {
			editor.Panels.getPanel('save-changes').set('visible', false);
			editor.getEl().classList.remove('eb-editor--has-changes');
		}
	};
};
