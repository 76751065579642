import { merge, addClassesString, setContent } from 'datatalks-utils';

import getStyleTabOptions from '../../common/_styleTabOptions';

import backgroundAccordion from '../../Header/components/styleTab/_backgroundAccordion';
import imageAccordion from '../../Header/components/styleTab/_imageAccordion';
import marginsAccordion from '../../Header/components/styleTab/_marginsAccordion';
import buttonAccordion from './styleTab/_buttonAccordion';
import textAccordion from './styleTab/_textAccordion';
import spacingAccordion from './styleTab/_spacingAccordion';
import borderAccordion from '../../commonAccordions/style/_border';

export default (obj, options, customTrait = {}) => {
	const defaults = {
		accordionsVisibility: {
			background: true,
			margins: true,
			border: true,
			spacing: true,
			image: true,
			heading: true,
			text: true,
			button: true
		},
		extendedClasses: '',
		backgroundAccordionOptions: {},
		marginsAccordionOptions: {},
		spacingAccordionOptions: {},
		imageAccordionOptions: {},
		headingAccordionOptions: {
			textAlignmentOptions: {
				componentProperty: 'headingAlignment'
			},
			accordion: {
				title: 'Heading'
			},
			styleObjPropName: 'headingStyle',
			colorPickerProp: 'headingCp'
		},
		textAccordionOptions: {
			colorPickerProp: 'textCp'
		},
		buttonAccordionOptions: {}
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	const styleTab = addClassesString(
		setContent(document.createElement('div'), [
			options.accordionsVisibility.background
				? backgroundAccordion
						.call(
							null,
							obj,
							options.backgroundAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.margins
				? marginsAccordion
						.call(
							null,
							obj,
							options.marginsAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.border
				? borderAccordion(
						getStyleTabOptions(obj, 'border'),
						customTrait
				  ).getEl()
				: null,
			options.accordionsVisibility.spacing
				? spacingAccordion
						.call(
							null,
							obj,
							options.spacingAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.image
				? imageAccordion
						.call(
							null,
							obj,
							options.imageAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.heading
				? textAccordion
						.call(
							null,
							obj,
							options.headingAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.text
				? textAccordion
						.call(
							null,
							obj,
							options.textAccordionOptions,
							customTrait
						)
						.getEl()
				: null,
			options.accordionsVisibility.button
				? buttonAccordion
						.call(
							null,
							obj,
							options.buttonAccordionOptions,
							customTrait
						)
						.getEl()
				: null
		]),
		options.extendedClasses
	);

	return styleTab;
};
