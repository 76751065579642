/* eslint-disable require-jsdoc */
import { camelCase } from 'lodash-es';
import listItem from './ListItem/_render';
import button from './atoms/button/_render';
import linksList from './LinksList/_render';

const componentsRender = {
	listItem,
	button,
	linksList
};

export function getComponentsRenderByType(type) {
	return componentsRender[camelCase(type)];
}

export default componentsRender;
