import traitsTypes from './_traitsTypes';
import buildTrait from './_build';

export default emailBuilder => {
	const { editor } = emailBuilder;
	traitsTypes().forEach(traitInfo => {
		editor.TraitManager.addType(
			traitInfo.type,
			buildTrait(traitInfo.type, traitInfo.options || {})
		);
	});
};
