/* eslint-disable require-jsdoc */
import { camelCase } from 'lodash-es';
import { removeWord, merge } from 'datatalks-utils';
import {
	getComponentMethods,
	getComponentRender,
	getComponentDefaults
	// availableComponents
} from './_componentBuilderUtils.js';
import { getComponentsDefaultsByType } from './componentsDefaults/_componentsDefaults';
import baseMethods from './components/common/methods/_baseMethods.js';
import toHtml from './components/_baseToHtml.js';
import { COMPONENTS_DEFAULTS } from '../config/_config';
import { validateStyleObj } from './common/functions/_utilFunctions';

export default class ComponentBuilder {
	constructor(editor, options = {}) {
		const defaults = {
			components: [],
			isVertical: true,
			getComponentMethods,
			getComponentRender,
			getComponentDefaults
		};

		defaults.baseTraits = {
			background: true,
			margins: true,
			spacing: options.components.length > 1,
			border: true
		};

		this.options = merge(defaults, options);
		this.components = this.options.components;
		this.editor = editor;
		this.name = this.options.name;
		this.init();
	}

	init() {
		const cb = this;
		cb.components = cb.components.map(comp => {
			return {
				name: comp.name || comp.type || comp,
				type: comp.type || comp,
				// methods: cb.options.getComponentMethods(compName),
				render: cb.options.getComponentRender(comp.type),
				props: merge(
					{
						name: comp.name || comp.type || comp
					},
					cb.options.getComponentDefaults(
						comp.type || comp,
						comp.name || comp.type || comp
					),
					comp.options || {}
				)
			};
		});

		cb.register();
	}

	build() {
		const cb = this;
		const getInnerComponents = comp => {
			const innerComps = cb.components
				.map(component => {
					if (
						comp.get(camelCase(`display ${component.name}`)) !==
						false
					) {
						const innerProps = {};

						Object.keys(
							cb.options.getComponentDefaults(
								component.type,
								component.name
							)
						).forEach(key => {
							innerProps[
								camelCase(
									removeWord(
										key,
										camelCase(component.name),
										false,
										1
									) // or type?
								)
							] = comp.get(key);
						});

						return {
							type: 'row',
							components: [
								{
									type: 'cell',
									attributes: {
										align: comp.get(
											camelCase(
												component.name + ' alignment'
											)
										)
									},
									components: {
										type: component.type,
										name: component.name,
										...innerProps
									}
								}
							]
						};
					} else {
						return null;
					}
				})
				.filter(comp => comp !== null);

			return innerComps.flatMap((component, idx, arr) => {
				if (idx === arr.length - 1) {
					return component;
				} else {
					return [
						component,
						{
							type: 'space',
							dimension: comp.get('spacingBetweenElements'),
							isVertical: cb.options.isVertical
						}
					];
				}
			});
		};

		let props = getComponentsDefaultsByType();

		cb.components.forEach(comp => {
			props = merge(props, comp.props);
		});

		const baseTraits = [];

		if (Object.values(this.options.baseTraits).includes(true))
			baseTraits.push({
				type: 'base',
				options: {
					mutateOptions: {
						styleTabOptions: {
							...this.options.baseTraits
						}
					}
				}
			});

		return {
			model: {
				defaults: () => ({
					...COMPONENTS_DEFAULTS,
					tagName: 'table',
					style: { width: '100%' },
					...props,
					components: comp => comp.componentRender(comp),
					traits: [
						...baseTraits,
						...cb.components.map(comp => ({
							type: comp.type,
							name: `trait-${comp.name}`,
							options: {
								mutateOptions: {
									contentTabOptions: {
										extendedClasses:
											baseTraits.length &&
											cb.components.length === 1
												? 'eb-py-4 eb-px-3'
												: '',
										componentName: comp.name,
										skipAccordionTitle:
											cb.components.length === 1
									},
									styleTabOptions: {
										componentName: comp.name,
										skipAccordionTitle:
											cb.components.length === 1 &&
											!baseTraits.length
									}
								}
							}
						}))
					]
				}),
				init() {
					const comps = this.findType(
						cb.components.map(comp => comp.type)[0]
					);

					if (comps.length)
						comps.forEach(ic => {
							if (
								typeof ic.rootComponentInitialized ===
								'function'
							) {
								ic.rootComponentInitialized();
							}
						});
				},
				toHTML(options) {
					return toHtml(
						this,
						merge(
							{
								getAlign: comp => {
									if (comp && comp.get)
										return comp.get(
											camelCase(
												cb.components[0].name +
													' alignment'
											)
										);
								}
							},
							options
						)
					);
				},

				componentRender: comp => {
					comp.setStyle(
						validateStyleObj({
							width: '100%',
							'background-color': comp.get('backgroundColor'),
							'border-width': comp.get('borderWidth'),
							'border-style': comp.get('borderStyle'),
							'border-color': comp.get('borderColor'),
							'border-radius': comp.get('useBorderRadius')
								? Object.values(comp.get('borderRadius')).join(
										' '
								  )
								: null
						})
					);

					return [
						{
							type: 'margins',
							innerComponents: getInnerComponents(comp),
							hasMargins: comp.get('hasMargins'),
							innerRenderType: cb.components.map(
								comp => comp.type
							)[0], // TODO: fix this for components with more than one inner component
							margins: {
								top: comp.get('topMargin'),
								right: comp.get('rightMargin'),
								bottom: comp.get('bottomMargin'),
								left: comp.get('leftMargin')
							},
							marginsColor: {
								top: comp.get('topMarginColor'),
								right: comp.get('rightMarginColor'),
								bottom: comp.get('bottomMarginColor'),
								left: comp.get('leftMarginColor')
							}
						}
					];
				},
				...baseMethods({}, cb.editor)
			}
		};
	}

	register() {
		this.editor.DomComponents.addType(this.name, this.build());
	}
}
