import { merge, cssStyleObjectToString } from 'datatalks-utils';

import getInnerHTML from './common/methods/_getInnerHTML';
import getComputedStyle from './_getComputedStyle';

export default (comp, options) => {
	const defaults = {
		style: getComputedStyle(comp),
		getAlign: null,
		parentWidth: null
	};

	options = merge(defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const borderWidth = parseFloat(options.style['border-width'] || 0);
	const parentWidth = parseFloat(options.parentWidth) - borderWidth * 2;

	const align =
		(typeof options.getAlign === 'function'
			? options.getAlign(comp)
			: null) || 'center';

	return `
	<table data-eb-name="base-first" style="${cssStyleObjectToString(
		options.style
	)}" ${
		options.style['background-color']
			? `bgcolor="${options.style['background-color']}"`
			: ''
	}>
		<tr style="box-sizing: border-box;">
			<td data-eb-name="base-last" style="box-sizing: border-box;">
				${getInnerHTML(comp, { align, width: `${parentWidth}px` })}
			</td
		</tr>
	</table>
	`;
};
