import importConfig from '../../common/_importConfig';
import { elementAddEvent, setContent } from 'datatalks-utils';

export default editor => {
	const modalContent = setContent(document.createElement('div'), [
		`<label class="eb-label" for="configText">Paste your configuration JSON here:</label>`,
		`<textarea id="configText" rows="10"></textarea>`,
		setContent(
			elementAddEvent(document.createElement('button'), 'click', () => {
				const configText = document.getElementById('configText').value;
				importConfig(editor, configText);
				editor.Modal.close();
			}),
			'Import Config'
		)
	]);

	editor.Modal.open({
		title: 'Import Configuration',
		content: modalContent,
		buttons: [
			{
				id: 'close-modal',
				text: 'Close'
			}
		]
	});
};
