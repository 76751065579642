import { merge } from 'datatalks-utils';
import { RadioButtonGroup } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';

const getAlignLeft = () => getIcon('align-item-left-line', { size: 'xl' });
const getAlignCenter = () =>
	getIcon('align-item-horizontal-center-line', { size: 'xl' });
const getAlignRight = () => getIcon('align-item-right-line', { size: 'xl' });

export default options => {
	const defaults = {
		extendedClasses: '',
		itemsOptions: {
			useActiveBorder: false,
			extendedClasses: 'eb-py-2'
		},
		left: {
			value: 'left',
			selected: value => value === 'left',
			icon: getAlignLeft()
		},
		center: {
			value: 'center',
			selected: value => value === 'center',
			icon: getAlignCenter()
		},
		right: {
			value: 'right',
			selected: value => value === 'right',
			icon: getAlignRight()
		},
		onChange: null, // (allSelected, changedItem, selectedItems, noneSelected) => { changedItem.getValue() }
		value: 'left' // obj.component.get('imageAlignment') // 'left' | 'center' | 'right'
	};

	options = merge(defaults, options);

	const radioButtonGroupOptions = {
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				value: options.left.value,
				selected: options.left.selected(options.value),
				icon: options.left.icon,
				...options.itemsOptions
			},
			{
				value: options.center.value,
				selected: options.center.selected(options.value),
				icon: options.center.icon,
				...options.itemsOptions
			},
			{
				value: options.right.value,
				selected: options.right.selected(options.value),
				icon: options.right.icon,
				...options.itemsOptions
			}
		]
	};

	if (typeof options.onChange === 'function')
		radioButtonGroupOptions.onChange = options.onChange;

	const marginsButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return marginsButtonGroup;
};
