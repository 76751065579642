import { noInteractionPreset } from '../_componentsUtils';
import { insertAfterEveryItem } from 'datatalks-utils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';

/**
 * Returns a component with a table structure with margin-cells all around the main content.
 * @param {Object} comp - The parent component to read the properties from.
 * @param {string} name - The name of the element.
 * @param {Object|string} inner - The inner content of the element.
 * @param {boolean} [innerIsComponent=false] - Indicates if the inner content is a component.
 * @param {string} [componentWidth='auto'] - The width of the component.
 * @return {Object} - The component with margins.
 */
function element(
	comp,
	name,
	inner,
	innerIsComponent = false,
	componentWidth = 'auto'
) {
	return [
		{
			type: 'margins',
			innerComponents: [
				{
					type: 'row',
					components: {
						type: 'cell',
						attributes: {
							align: comp.get(`${name}Alignment`)
						},
						[innerIsComponent ? 'components' : 'content']: inner
					}
				}
			],
			wrapInTable: true,
			width: componentWidth,
			hasMargins: comp.get(`${name}HasMargins`),
			margins: {
				top: comp.get(`${name}TopMargin`),
				right: comp.get(`${name}RightMargin`),
				bottom: comp.get(`${name}BottomMargin`),
				left: comp.get(`${name}LeftMargin`)
			},
			marginsColor: {
				top: comp.get(`${name}TopMarginColor`),
				right: comp.get(`${name}RightMarginColor`),
				bottom: comp.get(`${name}BottomMarginColor`),
				left: comp.get(`${name}LeftMarginColor`)
			}
		}
	];
}

export default comp => {
	const components = [];
	let contentComponents = [];

	let image = {
		type: 'image-wrapper',
		imgSrc: comp.attributes.imgSrc,
		href: comp.get('imageHref'),
		hasLink: comp.get('imageHasLink'),
		style: validateStyleObj({
			width: comp.get('imgWidth'),
			'border-radius': comp.get('imageUseBorderRadius')
				? Object.values(comp.get('imageBorderRadius')).join(' ')
				: null
		})
	};

	image = element(comp, 'image', image, true, 'auto');

	if (comp.get('displayTitle')) {
		contentComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				components: element(
					comp,
					'title',
					{
						type: 'text',
						text: comp.attributes.title
					},
					true,
					'100%'
				)
			}
		});
	}

	if (comp.get('displayDescription')) {
		contentComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				components: element(
					comp,
					'description',
					{
						type: 'text',
						text: comp.attributes.description
					},
					true,
					'100%'
				)
			}
		});
	}

	if (comp.get('displayButton')) {
		contentComponents.push({
			type: 'row',
			components: {
				type: 'cell',
				components: element(
					comp,
					'button',
					{
						type: 'button',
						text: comp.attributes.buttonText,
						backgroundColor: comp.get('buttonBackgroundColor'),
						height: comp.get('buttonHeight'),
						width: comp.get('buttonWidth'),
						fontFamily: comp.get('buttonFontFamily'),
						color: comp.get('buttonColor'),
						fontWeight: comp.get('buttonFontWeight'),
						borderWidth: comp.get('buttonBorderWidth'),
						borderStyle: comp.get('buttonBorderStyle'),
						borderColor: comp.get('buttonBorderColor'),
						borderRadius: comp.get('buttonBorderRadius'),
						useBorderRadius: comp.get('buttonUseBorderRadius'),
						lineHeight: comp.get('buttonLineHeight'),
						href: comp.get('buttonHref'),
						textAlignment: comp.get('buttonTextAlignment'),
						alignment: comp.get('buttonAlignment')
					},
					true,
					'100%'
				)
			}
		});
	}

	contentComponents = insertAfterEveryItem(contentComponents, {
		type: 'space',
		isVertical: true,
		dimension: comp.get('verticalSpacingBetweenElements')
	});

	if (contentComponents.length) {
		if (
			['left', 'right'].includes(comp.get('imagePlacement')) &&
			comp.get('displayImage')
		) {
			const innerComponents = [
				{
					type: 'cell',
					attributes: {
						align: comp.get('imageAlignment')
					},
					style: {
						width: `${comp.get('imageContentRatio') * 100}%`
					},
					components: image
				},
				{
					type: 'space',
					isVertical: false,
					dimension: comp.get('horizontalSpacingBetweenElements')
				},
				{
					type: 'cell',
					components: {
						type: 'table',
						components: contentComponents,
						style: {
							width: '100%'
						}
					},
					style: {
						width: `${(1 - comp.get('imageContentRatio')) * 100}%`
					}
				}
			];
			components.push({
				type: 'row',
				components:
					comp.get('imagePlacement') === 'right'
						? innerComponents.reverse()
						: innerComponents
			});
		} else {
			if (comp.get('displayImage'))
				components.push(
					{
						type: 'row',
						components: {
							type: 'cell',
							attributes: {
								align: comp.get('imageAlignment')
							},
							components: image
						}
					},
					{
						type: 'space',
						isVertical: true,
						dimension: comp.get('verticalSpacingBetweenElements')
					}
				);
			components.push(...contentComponents);
		}
	} else {
		if (comp.get('displayImage'))
			components.push({
				type: 'row',
				components: {
					type: 'cell',
					attributes: {
						align: comp.get('imageAlignment')
					},
					components: image
				}
			});
	}

	comp.set(
		'style',
		validateStyleObj({
			...comp.getStyle(),
			width: comp.attributes.width,
			['background-color']: comp.attributes.backgroundColor
		})
	);

	return {
		type: 'tbody',
		style: {
			'text-align': comp.attributes.textAlign
		},
		...noInteractionPreset,
		components
	};
};
