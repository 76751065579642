/* eslint-disable require-jsdoc */
import sliderInput from '../../../commonAccordions/style/common/_sliderOrInput';
import spacingInput from './spacingAccordion/_spacingInput';
import {
	ToggleButton,
	InputNumber,
	Accordion,
	SectionItem
} from 'datatalks-ui';
import { addClassesString, getUnit, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';

export default (obj, options, customTrait) => {
	let defaults = {
		traitsVisibility: {
			space: true,
			horizontalSpace: false,
			spaceBetweenListItems: true
		},
		componentProperties: {
			spaceBetweenInnerComponents: 'spacingBetweenElements',
			horizontalSpaceBetweenInnerComponents:
				'horizontalSpacingBetweenElements',
			spaceBetweenListItems: 'linksGap'
		},
		hasLinksListWidthSlider: true,
		linksWidthSliderOptions: {
			value:
				obj.component.attributes.linksListWidth === 'auto'
					? 100
					: parseInt(obj.component.attributes.linksListWidth),
			onChange: value =>
				obj.component.changeLinksListWidth.call(
					obj.component,
					parseInt(value) + '%'
				),
			sliderOptions: {
				extendedClasses: 'eb-mt-3'
			}
		},
		accordion: {
			title: 'Spacing',
			color: 'grey',
			type: 'extend'
		},
		sectionsLabel: {
			space: 'Spacing between elements',
			horizontalSpace: 'Horizontal spacing between elements',
			barWidth: 'Navigation bar width',
			spaceBetweenListItems: 'Spacing between links'
		}
	};

	defaults = {
		...defaults,
		spacingInputOptions: {
			getValue: obj.component.get.bind(
				obj.component,
				options?.componentProperties?.spaceBetweenListItems ||
					defaults.componentProperties.spaceBetweenListItems
			)
		}
	};

	options = merge(defaults, options);

	let showSlider = !obj.component.get('useLinksGap');

	const spacingAccordionContent = document.createElement('div');
	const dynamicContent = document.createElement('div');

	function setDynamicContent(renderSlider) {
		if (renderSlider) {
			if (getUnit(obj.component.get('linksListWidth')) != '%')
				obj.component.changeLinksWidthToPercentage.call(obj.component);
			setContent(
				dynamicContent,
				sliderInput(
					{
						...options.linksWidthSliderOptions,
						label:
							options.sectionsLabel.barWidth ||
							options.linksWidthSliderOptions.label
					},
					customTrait
				)
			);
		} else {
			obj.component.changeLinksListWidth.call(obj.component, 'auto');
			setContent(
				dynamicContent,
				new SectionItem({
					label: options.sectionsLabel.spaceBetweenListItems,
					content: spacingInput.call(
						null,
						obj,
						options.spacingInputOptions
					)
				}).getEl()
			);
		}
	}
	if (options.traitsVisibility.spaceBetweenListItems) {
		setDynamicContent(showSlider);
	}

	setContent(
		spacingAccordionContent,
		[
			new SectionItem({
				label: options.sectionsLabel.space,
				content: new InputNumber({
					defaultValue: obj.component.get(
						options.componentProperties.spaceBetweenInnerComponents
					),
					unit: getUnit(
						obj.component.get(
							options.componentProperties
								.spaceBetweenInnerComponents
						)
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender(
							options.componentProperties
								.spaceBetweenInnerComponents,
							inputValue
						);
					}
				}).getEl()
			}).getEl(),
			options.traitsVisibility.horizontalSpace &&
				new SectionItem({
					label: options.sectionsLabel.horizontalSpace,
					content: new InputNumber({
						defaultValue: obj.component.get(
							options.componentProperties
								.horizontalSpaceBetweenInnerComponents
						),
						unit: getUnit(
							obj.component.get(
								options.componentProperties
									.horizontalSpaceBetweenInnerComponents
							)
						),
						onChange: (value, unit, inputValue) => {
							obj.component.setAndRerender(
								options.componentProperties
									.horizontalSpaceBetweenInnerComponents,
								inputValue
							);
						}
					}).getEl()
				}).getEl(),
			options.traitsVisibility.spaceBetweenListItems &&
				addClassesString(
					setContent(document.createElement('div'), [
						`<p>${options.sectionsLabel.spaceBetweenListItems}</p>`,
						new ToggleButton({
							startActive: !obj.component.get('useLinksGap'),
							onChange: value => {
								showSlider = value;
								setDynamicContent(showSlider);
								obj.component.setAndRerender(
									'useLinksGap',
									!value
								);
							}
						}).getEl(),
						`<p>${options.sectionsLabel.barWidth}</p>`
					]),
					'eb-flex eb-items-center eb-gap-3 eb-justify-between eb-mt-5'
				),
			options.traitsVisibility.spaceBetweenListItems &&
				addClassesString(dynamicContent, 'eb-mt-3')
		],
		true
	);

	const spacingAccordion = new Accordion({
		title: options.accordion.title,
		content: spacingAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return spacingAccordion;
};
