import _slider from './_slider';
import { ToggleableSection, InputNumber } from 'datatalks-ui';
import { merge } from 'datatalks-utils';

export default (options, store) => {
	const defaults = {
		value: null,
		onChange: null,
		sliderOptions: {},
		InputNumberOptions: {},
		max: 100,
		min: 0,
		unit: '%',
		showInputByDefault: false,
		label: null,
		showInputLabel: 'Specify value',
		onToggle: null,
		storeOptions: {
			attrNames: {
				slider: 'slider'
			}
		}
	};

	options = merge(defaults, options);

	let inputNumberDisabled = !options.showInputByDefault;

	const inputNumber = new InputNumber(
		merge(
			{
				defaultValue: options.value,
				onChange: (value, unit, inputValue) => {
					if (typeof options.onChange === 'function') {
						options.onChange(inputValue);
					}
					slider.noUiSlider.set(value);
				},
				max: options.max,
				min: options.min,
				unit: options.unit,
				disabled: !options.showInputByDefault
			},
			options.InputNumberOptions
		)
	);

	const slider = _slider(
		merge(
			{
				extendedClasses: 'eb-mt-3',
				values: {
					current: parseFloat(options.value),
					min: options.min,
					max: options.max
				},
				onChange: value => {
					if (inputNumberDisabled) {
						if (typeof options.onChange === 'function') {
							options.onChange(value);
						}
						inputNumber.setValue(value);
					}
				},
				unit: options.unit
			},
			options.sliderOptions
		)
	);

	if (store) store[options.storeOptions.attrNames.slider] = slider;

	if (options.showInputByDefault) slider.noUiSlider.disable();

	return [
		new ToggleableSection({
			startOpen: options.showInputByDefault,
			label: options.label,
			toggleLabel: options.showInputLabel,
			toggleableContent: true,
			onToggle: isActive => {
				if (isActive) {
					inputNumberDisabled = false;
					slider.noUiSlider.disable();
					inputNumber.enable();
				} else {
					inputNumberDisabled = true;
					inputNumber.disable();
					slider.noUiSlider.enable();
				}
				if (typeof options.onToggle === 'function') {
					options.onToggle(isActive);
				}
			},
			content: inputNumber.getEl()
		}).getEl(),
		slider
	];
};
