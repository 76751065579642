import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		debug: !!emailBuilder.getOptions().devMode
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, props) => {
			if (commandOptions.debug)
				console.log(
					`General style prop changed: ${props.changedProp};\nOld value: ${props.oldValue};\nNew value: ${props.newValue}`
				);
		}
	};
};
