import buildTrait from './_buildTrait';
import {
	arrayMove,
	arrayRemove,
	getElementWidthPercentage
} from 'datatalks-utils';
import { cloneDeep } from 'lodash-es';
import baseMethods from '../common/methods/_baseMethods';

export default (options, editor) => {
	return {
		addListItem(itemOptions) {
			const newListItem = {
				...cloneDeep(this.get('newListItem')),
				...itemOptions
			};
			this.get('socialList').push(newListItem);
			this.components(this.componentRender(this));
			if (typeof options.addListItemCallback === 'function')
				options.addListItemCallback.call(this, this, newListItem);
			return newListItem;
		},

		deleteItem(item) {
			arrayRemove(this.get('socialList'), item);
			this.components(this.componentRender(this));
			if (typeof options.deleteLinkCallback === 'function')
				options.deleteLinkCallback.call(this, this);
		},

		changeLinksGap(gap) {
			this.set('socialListGap', gap);
			this.components(this.componentRender(this));
		},

		changeItemsOrder(oldIndex, newIndex) {
			arrayMove(this.get('socialList'), oldIndex, newIndex);
			this.components(this.componentRender(this));
		},

		changeItemProp(item, propName, value) {
			const auxItem = this.get('socialList').filter(i => i === item)[0];
			auxItem[propName] = value;
			this.components(this.componentRender(this));
			this.emitUpdate(`item:${propName}`, item, value);
			this.emitUpdate('item', item);
			this.emitUpdate('socialList', this.get('socialList'));
		},

		changeAllItemWidth(value) {
			this.set('iconWidth', value);
			this.get('socialList').forEach(item => {
				item.content.style.width = value;
				item.content.renderWidth = value;
			});
			this.components(this.componentRender(this));
			this.emitUpdate('socialList', this.get('socialList'));
		},

		changeItemImage(item, imgSrc) {
			const auxItem = this.get('socialList').filter(i => i === item)[0];
			auxItem.content.imgSrc = imgSrc;
			this.components(this.componentRender(this));
			this.emitUpdate('item:image', item, imgSrc);
			this.emitUpdate('item', item);
			this.emitUpdate('socialList', this.get('socialList'));
		},

		changeLinksWidthToPercentage() {
			const linksList = this.findType('links-list')[0];
			if (linksList) {
				const linksListEl = linksList.getEl();
				if (linksListEl)
					this.changeLinksListWidth(
						getElementWidthPercentage(linksListEl, {
							parentEl: this.getEl()
						}) || '50%'
					);
			}
		},

		changeLinksListWidth(width) {
			this.set('linksListWidth', width);
			this.components(this.componentRender(this));
		},

		changeLinks(html) {
			this.set('links', html);
			this.components(this.componentRender(this));
		},

		// TODO: create utility function as this is a copy based on the image border radius
		setLinksBorderRadius(corner, value) {
			const aux = {};
			if (
				['topLeft', 'topRight', 'bottomLeft', 'bottomRight'].includes(
					corner
				)
			) {
				aux[corner] = value;
				this.set('linksBorderRadius', {
					...this.get('linksBorderRadius'),
					...aux
				});
				this.components(this.componentRender(this));
			} else {
				const br = { ...this.get('linksBorderRadius') };
				for (const key in br) {
					if (br.hasOwnProperty(key)) aux[key] = value;
				}
				this.set('linksBorderRadius', { ...aux });
				this.components(this.componentRender(this));
			}
		},

		setAndRerender(attr, value) {
			this.set(attr, value);
			this.components(this.componentRender(this));
		},
		buildTrait,
		refreshTrait() {
			this.removeTrait('footer');
			this.addTrait(this.buildTrait(this));
		},
		...baseMethods(options, editor)
	};
};
