import {
	merge,
	cssStyleObjectToString,
	insertAfterEveryItem
} from 'datatalks-utils';
import getComputedStyle from './_getComputedStyle';
import margins from '../common/htmlTemplates/_margins';
import borderWrapper from '../common/htmlTemplates/_borderWrapper';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		text: comp.get('text'),
		parentWidth: null,
		verticalAlignment: comp.get('socialListVAlignment'),
		sideLayout: comp.get('socialListSideways'),
		imageWidth: comp.get('imageWidth'),
		linksListWidth: comp.get('linksListWidth'),
		vSpace: comp.get('spacingBetweenElements'),
		hSpace: comp.get('horizontalSpacingBetweenElements'),
		listAlignment: comp.get('socialListAlignment')
	};

	options = merge({}, defaults, options);

	const debugStrStart = options.debug ? 'data-eb-name="footer-first"' : '';
	const debugStrEnd = options.debug ? 'data-eb-name="footer-last"' : '';

	const isSideLayout =
		options.sideLayout &&
		comp.get('displaySocialList') &&
		(comp.get('displayTitle') ||
			comp.get('displayDescription') ||
			comp.get('displayLinks'));

	const listRatio =
		options.linksListWidth == 'auto'
			? 0.3
			: parseFloat(options.linksListWidth) / 100;

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const isMobileOptimized = comp
		.getEditor()
		.getEmailBuilder()
		.getAdvancedOption('isMobileOptimized');

	const marginsWidth = comp.get('hasMargins')
		? parseFloat(comp.get('leftMargin')) +
		  parseFloat(comp.get('rightMargin')) +
		  'px'
		: 0;

	/**
	 * Calculates the width of the list based on the component's attributes.
	 * If the linksListWidth attribute is set to 'auto', it calculates the width based on the icon width, social list margins, and gaps.
	 * If the linksListWidth attribute is not set to 'auto', it calculates the width based on the parent width and margins width.
	 * @return {number} The calculated width of the list.
	 */
	function getListWidth() {
		if (comp.attributes.linksListWidth === 'auto')
			return comp.get('socialListIsWrapable')
				? (parseFloat(comp.get('iconWidth')) +
						parseFloat(comp.get('socialListGap'))) *
						comp.get('socialList').length
				: parseFloat(comp.get('iconWidth')) *
						comp.get('socialList').length +
						parseFloat(comp.get('socialListGap')) *
							(comp.get('socialList').length - 1);
		else
			return (
				(parseFloat(options.parentWidth) - parseFloat(marginsWidth)) *
				listRatio
			);
	}

	/**
	 * Calculates the width of the list item based on the parent width, provided options and number of columns.
	 * @param {number} rightSideWidth - The width of the list in pixels.
	 * @return {number} The width (without unit, only number) in pixels of the element based on the parent's width.
	 */
	function getParentWidth(rightSideWidth) {
		if (options.sideLayout)
			return (
				parseFloat(options.parentWidth) -
				parseFloat(marginsWidth) -
				parseFloat(rightSideWidth) -
				parseFloat(options.hSpace)
			);
		else return parseFloat(options.parentWidth) - parseFloat(marginsWidth);
	}

	/**
	 * Calculates the width of the links in pixels.
	 * @param {number} parentWidth - The width of the parent element in pixels.
	 * @param {boolean} isBorderWrapper - Indicates whether the links have a border wrapper.
	 * @return {string} The width of the links in pixels.
	 */
	function getLinksWidthPx(parentWidth, isBorderWrapper = false) {
		const borderWidth = isBorderWrapper
			? 0
			: parseFloat(comp.attributes.linksBorderWidth) * 2;
		return (
			(parseFloat(parentWidth) * parseFloat(comp.attributes.linksWidth)) /
				100 -
			borderWidth +
			'px'
		);
	}

	let innerHtml;

	const listWidthPx = getListWidth() + 'px';
	const textWidthPx = getParentWidth(listWidthPx) + 'px';
	const descriptionIndex = comp.get('displayTitle') ? 1 : 0;
	const linksIndex =
		0 +
		(comp.get('displayTitle') ? 1 : 0) +
		(comp.get('displayDescription') ? 1 : 0);

	/**
	 * Retrieves the HTML representation of a specific element based on its name.
	 *
	 * @param {string} name - The name of the element to retrieve.
	 * @return {string} The HTML representation of the element.
	 */
	function getElement(name) {
		switch (name) {
			case 'list':
				return comp
					.findType('links-list')[0]
					.toHTML({ parentWidth: listWidthPx, isMobileOptimized });
				break;

			case 'title':
				return comp
					.findType('text')[0]
					.toHTML({ parentWidth: textWidthPx });
				break;

			case 'description':
				return comp
					.findType('text')
					[descriptionIndex].toHTML({ parentWidth: textWidthPx });
				break;

			case 'links':
				return borderWrapper(
					comp.findType('text')[linksIndex].toHTML({
						parentWidth: getLinksWidthPx(textWidthPx, false)
					}),
					{
						width: getLinksWidthPx(textWidthPx, true),
						backgroundColor: comp.attributes.linksBackground,
						hasBorderRadius: Object.values(
							comp.attributes.linksBorderRadius
						).some(borderRadius => borderRadius !== '0px'),
						borderRadius: comp.attributes.linksBorderRadius,
						borderWidth: comp.attributes.linksBorderWidth,
						borderStyle: comp.attributes.linksBorderStyle,
						borderColor: comp.attributes.linksBorderColor,
						useVml: false
					}
				);
				break;

			default:
				console.warn('Invalid element name: ', name);
				return '';
				break;
		}
	}

	/**
	 * Generates an HTML table row with the provided content.
	 *
	 * @param {string} content - The content to be placed inside the table cell.
	 * @param {boolean} shouldRender - Determines whether the row should be rendered or not.
	 * @param {object} rowOptions - Additional options for the row.
	 * @return {string} - The generated HTML table row.
	 */
	function row(content, shouldRender, rowOptions = {}) {
		const defaults = {
			align: 'left',
			msoComments: false,
			hasMargins: false
		};

		rowOptions = merge(defaults, rowOptions);
		const mso = rowOptions.msoComments;

		if (shouldRender) {
			content = typeof content === 'function' ? content() : content;
			const marginStr = rowOptions.hasMargins
				? `margin-top: ${options.vSpace};`
				: '';
			return `
			${mso ? '<!--[if mso]>' : ''}
				<tr>
					<td ${debugStrEnd} align="${rowOptions.align}">
						${mso ? '<![endif]-->' : ''}
							<div style="display: block;${marginStr}">
								${content}
							</div>
						${mso ? '<!--[if mso]>' : ''}
					</td>
				</tr>
			${mso ? '<![endif]-->' : ''}`;
		} else return '';
	}

	/**
	 * Checks if an element should have a margin based on the elements that are displayed above.
	 * @param {string} elementName - The name of the element.
	 * @return {boolean} - Returns true if the element should have a margin, false otherwise.
	 */
	function elementShouldHaveMargin(elementName) {
		switch (elementName) {
			case 'description':
				return comp.get('displayTitle');
				break;
			case 'links':
				return (
					comp.get('displayDescription') || comp.get('displayTitle')
				);
				break;
			default:
				return false;
				break;
		}
	}

	const vSpace = `
		<tr>
			<td height="${parseFloat(options.vSpace)}" style="width: 100%;height:${
		options.vSpace
	}"></td>
		</tr>`;

	const listRow = row(
		getElement.bind(null, 'list'),
		comp.get('displaySocialList'),
		{ align: options.listAlignment }
	);
	const titleRow = row(
		getElement.bind(null, 'title'),
		comp.get('displayTitle'),
		{
			msoComments: isSideLayout,
			hasMargins: elementShouldHaveMargin('title')
		}
	);
	const descriptionRow = row(
		getElement.bind(null, 'description'),
		comp.get('displayDescription'),
		{
			msoComments: isSideLayout,
			hasMargins: elementShouldHaveMargin('description')
		}
	);
	const linksRow = row(
		getElement.bind(null, 'links'),
		comp.get('displayLinks'),
		{
			msoComments: isSideLayout,
			hasMargins: elementShouldHaveMargin('links'),
			align: comp.attributes.linksAlignment
		}
	);

	// TODO: display

	const bgString = options.style['background-color']
		? `bgcolor="${options.style['background-color']}"`
		: '';

	const columnPadding = isMobileOptimized
		? `padding:${parseFloat(options.hSpace) / 2}px;`
		: '';

	const va = options.verticalAlignment;
	const style = cssStyleObjectToString(options.style);

	if (isSideLayout) {
		const rows = insertAfterEveryItem(
			[titleRow, descriptionRow, linksRow].filter(row => !!row),
			`<!--[if mso]>${vSpace}<![endif]-->`
		).join(' ');
		const textPerWidth = 100 - parseFloat(options.linksListWidth) + '%';
		const listPerWidth = options.linksListWidth;
		innerHtml = `
			<div ${debugStrStart} class="ebr-2-col" style=";font-size:0;${style}">
				<!--[if mso]>
					<table role="presentation" width="100%" style="${style}" ${bgString}>
						<tr>
							<td style="width:${textPerWidth};padding:0;" valign="${va}">
								<![endif]-->
									<div class="ebr-column ${
										isMobileOptimized
											? `ebr-max-width-cover-${
													100 -
													Math.floor(listRatio * 10) *
														10
											  }`
											: ''
									}" style="width:100%;max-width:${textWidthPx};display:inline-block;vertical-align:${va};${columnPadding}">

										<div ${debugStrEnd} style="padding:0;">
												<!--[if mso]>
												<table role="presentation" width="100%">
												<![endif]-->
													${rows}
												<!--[if mso]>
												</table>
												<![endif]-->
										</div>

									</div>
								<!--[if mso]>
							</td>

							<td width="${options.hSpace}" style="width:${options.hSpace};min-width:${
			options.hSpace
		};"></td>

							<td style="width:${listPerWidth};padding:0;" valign="${va}">
								<![endif]-->
									<div class="ebr-column ${
										isMobileOptimized
											? `ebr-max-width-cover-${
													Math.floor(listRatio * 10) *
													10
											  }`
											: ''
									}" style="width:100%;max-width:${listWidthPx};display:inline-block;vertical-align:${va};margin-left:${
			isMobileOptimized ? '0px' : options.hSpace
		};${columnPadding}">

										<div ${debugStrEnd} style="padding:0;">
											${getElement('list')}
										</div>

									</div>
								<!--[if mso]>
							</td>

						</tr>
					</table>
				<![endif]-->
			</div>
	`;
	} else {
		const rows = insertAfterEveryItem(
			[titleRow, descriptionRow, listRow, linksRow].filter(row => !!row),
			vSpace
		).join(' ');
		innerHtml = `
			<table style="${style}" ${debugStrStart} ${bgString}>
				${rows}
			</table>
		`;
	}

	const html = margins(innerHtml, {
		topMargin: comp.get('topMargin') || 0,
		rightMargin: comp.get('rightMargin') || 0,
		bottomMargin: comp.get('bottomMargin') || 0,
		leftMargin: comp.get('leftMargin') || 0,
		hasMargins: comp.get('hasMargins'),
		backgroundColor: comp.get('backgroundColor')
	});

	if (options.debug)
		console.log('Footer HTML: ', comp.get('hasMargins') ? html : innerHtml);

	return comp.get('hasMargins') ? html : innerHtml;
};
