/* eslint-disable require-jsdoc */
import { DropdownButton } from 'datatalks-ui';

const swv = 'sw-visibility';
const expt = 'export-template';
const downTemp = 'download-template';
// const osm = 'open-sm';
// const otm = 'open-tm';
// const ola = 'open-layers';
// const obl = 'open-blocks';
// const ogn = 'open-general';
const ful = 'fullscreen';
const prv = 'preview';
const prvEmail = 'preview-email';
const expConfig = 'export-config';
const expHtml = 'export-html';
const exp = 'export-all';
const impConfig = 'import-config';
const clear = 'clear-storage';

function getActions(emailBuilder) {
	const opts = emailBuilder.getOptions();
	return [
		{
			id: impConfig,
			command: impConfig,
			label: 'Import Configuration',
			useAction: opts?.devMode
		},
		{
			id: expConfig,
			command: expConfig,
			label: opts.actionsLabels?.exportConfig || 'Export Configuration',
			useAction: typeof opts?.onExportConfig === 'function'
		},
		{
			id: expHtml,
			command: expHtml,
			label: opts.actionsLabels?.exportHtml || 'Export HTML',
			useAction: typeof opts?.onExportHtml === 'function'
		},
		{
			id: exp,
			command: exp,
			label: opts.actionsLabels?.exportAll || 'Export All',
			useAction: typeof opts?.onExport === 'function'
		},
		{
			id: downTemp,
			command: downTemp,
			label: opts.actionsLabels?.downloadTemplate || 'Download template',
			useAction: typeof opts?.onDownloadTemplate === 'function'
		},
		{
			active: false,
			id: swv,
			command: 'core:component-outline',
			label: 'Outline components',
			useAction: true
		},
		{
			id: prv,
			command: prv,
			label: 'Preview',
			useAction: false
		},
		{
			id: prvEmail,
			command: prvEmail,
			label: opts.actionsLabels?.previewEmail || 'Preview email',
			useAction: typeof opts?.onPreviewEmail === 'function'
		},
		{
			active: false,
			id: ful,
			command: ful,
			label: 'Fullscreen',
			useAction: false
		},
		{
			id: expt,
			command: expt,
			label: 'View code'
		},
		{
			id: clear,
			command: clear,
			label: opts.actionsLabels?.storageClear || 'Clear storage',
			useAction: typeof opts?.onStorageClear === 'function'
		}
	];
}

export default emailBuilder => {
	const { editor } = emailBuilder;
	return new DropdownButton({
		items: getActions(emailBuilder)
			.filter(
				action =>
					!action.hasOwnProperty('useAction') || action.useAction
			)
			.map(action => ({
				content: action.label,
				onClick: item => {
					if (
						action.hasOwnProperty('active') &&
						!item.getIsActive()
					) {
						editor.stopCommand(action.command);
					} else {
						editor.runCommand(action.command);
					}
				},
				selectable: action.hasOwnProperty('active'),
				active: action.active
			})),
		control: 'Actions',
		closeDropdownOnSelectableItemClick: false
	}).getEl();
};
