import { LinkedInputs } from 'datatalks-ui';

export default (obj, options) => {
	const localize = obj.component.localize;
	return new LinkedInputs({
		items: [
			{
				label: localize('misc.topLeft', true) || localize('Top Left'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').topLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'topLeft',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label: localize('misc.topRight', true) || localize('Top Right'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').topRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'topRight',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label:
					localize('misc.bottomRight', true) ||
					localize('Bottom Right'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').bottomRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'bottomRight',
							inputValue
						);
					},
					unit: 'px'
				}
			},
			{
				label:
					localize('misc.bottomLeft', true) ||
					localize('Bottom Left'),
				input: {
					defaultValue: parseFloat(
						obj.component.get('linksBorderRadius').bottomLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setLinksBorderRadius.call(
							obj.component,
							'bottomLeft',
							inputValue
						);
					},
					unit: 'px'
				}
			}
		]
	}).getEl();
};
