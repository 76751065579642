const percentages = [
	10,
	20,
	25,
	30,
	100 / 3,
	40,
	50,
	60,
	200 / 3,
	70,
	75,
	80,
	90,
	100
];
export default body => `
<!DOCTYPE html>
<html lang="en" xmlns="https://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width,initial-scale=1">
	<meta name="x-apple-disable-message-reformatting">
	<!--[if !mso]><!-->
	<meta http-equiv="X-UA-Compatible" content="IE=edge" />
	<!--<![endif]-->
	<title></title>
	<!--[if mso]>
	<style type="text/css">
	table {border-collapse:collapse;border:0;border-spacing:0;margin:0;}
	div, td {padding:0;}
	div {margin:0 !important;}
	</style>
	<noscript>
	<xml>
	<o:OfficeDocumentSettings>
	<o:PixelsPerInch>96</o:PixelsPerInch>
	</o:OfficeDocumentSettings>
	</xml>
	</noscript>
	<![endif]-->
	<style>
		${percentages.reduce((acc, percentage) => {
			return `${acc}
			.ebr-max-width-${Math.floor(percentage)} {
				max-width: ${percentage}% !important;
			}`;
		}, '')}

		@media screen and (min-width:600px) {
			${percentages.reduce((acc, percentage) => {
				return `${acc}
				.ebr-max-width-cover-${Math.floor(percentage)} {
					max-width: ${percentage}% !important;
				}`;
			}, '')}
		}

		@media screen and (max-width:600px) {
			.ebr-button {
				width: 100% !important;
			}

			${percentages.reduce((acc, percentage) => {
				return `${acc}
				.ebr-max-width-cover-${Math.floor(percentage)} {
					max-width: 100% !important;
				}`;
			}, '')}

			.ebr-top-col .ebr-column {
				max-width: 100% !important;
			}

			.ebr-column {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	</style>
</head>

${body}

</html>
`;
