import { capitalize } from 'lodash-es';
import { borderStyles, getUnit, setContent, merge } from 'datatalks-utils';
import sliderInput from '../../../commonAccordions/style/common/_sliderOrInput';
import imageAlignment from '../../../commonAccordions/style/common/_alignment';
import imageBorderRadius from '../../../commonAccordions/style/common/_borderRadius';
import {
	ColorPickerInput,
	InputNumber,
	Accordion,
	SectionItem,
	section,
	Dropdown,
	ToggleableSection
} from 'datatalks-ui';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		extendedClasses: '',
		toggleBtnClickTogglesAccordion: false,
		componentProperties: {
			imgBorderWidth: 'imageBorderWidth',
			imgBorderStyle: 'imageBorderStyle',
			imgBorderColor: 'imageBorderColor',
			imageWidth: 'imageWidth'
		},
		accordion: {
			title: 'Image',
			color: 'grey',
			type: 'extend'
		},
		imageAlignmentOptions: {
			onChange: (allSelected, changedItem) => {
				obj.component.setAndRerender(
					'imageAlignment',
					changedItem.getValue()
				);
			},
			value: obj.component.get('imageAlignment')
		},
		imageBorderRadiusOptions: {
			corners: {
				topLeft: {
					value: parseFloat(
						obj.component.get('imageBorderRadius')?.topLeft
					),
					onChange: value => {
						obj.component.setImageBorderRadius?.call(
							obj.component,
							'topLeft',
							value
						);
					}
				},
				topRight: {
					value: parseFloat(
						obj.component.get('imageBorderRadius')?.topRight
					),
					onChange: value => {
						obj.component.setImageBorderRadius?.call(
							obj.component,
							'topRight',
							value
						);
					}
				},
				bottomRight: {
					value: parseFloat(
						obj.component.get('imageBorderRadius')?.bottomRight
					),
					onChange: value => {
						obj.component.setImageBorderRadius?.call(
							obj.component,
							'bottomRight',
							value
						);
					}
				},
				bottomLeft: {
					value: parseFloat(
						obj.component.get('imageBorderRadius')?.bottomLeft
					),
					onChange: value => {
						obj.component.setImageBorderRadius?.call(
							obj.component,
							'bottomLeft',
							value
						);
					}
				}
			}
		},
		showBorderRadius: obj.component.get('imageUseBorderRadius'),
		onBorderRadiusToggle: isActive => {
			obj.component.setAndRerender('imageUseBorderRadius', isActive);
		},
		innerTraitsVisibility: {
			alignment: true,
			border: true,
			borderRadius: true,
			width: true
		}
	};

	defaults.imageSliderOptions = {
		sliderOptions: {
			extendedClasses: 'eb-mt-3'
		},
		label: 'Width',
		value: obj.component.get(
			options.componentProperties?.imageWidth ||
				defaults.componentProperties.imageWidth
		),
		onChange: value => {
			obj.component.setAndRerender.call(
				obj.component,
				options.componentProperties?.imageWidth ||
					defaults.componentProperties.imageWidth,
				value
			);
		},
		max: 100,
		min: 0,
		unit: '%'
	};

	options = merge(defaults, options);

	if (!Object.values(options.innerTraitsVisibility).some(value => value))
		return;

	if (options.innerTraitsVisibility.border && !customTrait.imageBorderCp)
		customTrait.imageBorderCp = new ColorPickerInput({
			color: obj.component.get(
				options.componentProperties.imgBorderColor
			),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender(
					options.componentProperties.imgBorderColor,
					cpi.getColor()
				);
			}
		});

	const imageAccordionContent = setContent(
		document.createElement('div'),
		[
			options.innerTraitsVisibility.width &&
				sliderInput(options.imageSliderOptions),
			options.innerTraitsVisibility.alignment &&
				new SectionItem({
					label: 'Alignment',
					content: imageAlignment(
						options.imageAlignmentOptions
					).getEl()
				}).getEl(),
			(options.innerTraitsVisibility.border ||
				options.innerTraitsVisibility.borderRadius) &&
				section({
					label: 'Border',
					content: [
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Width',
								content: new InputNumber({
									defaultValue: obj.component.get(
										options.componentProperties
											.imgBorderWidth
									),
									unit:
										getUnit(
											obj.component.get(
												options.componentProperties
													.imgBorderWidth
											)
										) || 'px',
									onChange: (value, unit, inputValue) => {
										obj.component.setAndRerender(
											options.componentProperties
												.imgBorderWidth,
											inputValue
										);
									}
								}).getEl()
							}).getEl(),
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Style',
								content: new Dropdown({
									items: borderStyles.map(style => {
										return {
											content: capitalize(style),
											value: style,
											active:
												obj.component.get(
													options.componentProperties
														.imgBorderStyle
												) === style
										};
									}),
									onChange: (dropdown, activeItem) => {
										obj.component.setAndRerender(
											options.componentProperties
												.imgBorderStyle,
											activeItem.getValue()
										);
									}
								}).getEl()
							}).getEl(),
						options.innerTraitsVisibility.border &&
							new SectionItem({
								label: 'Color',
								content: customTrait.imageBorderCp.getEl()
							}).getEl(),
						options.innerTraitsVisibility.borderRadius &&
							new ToggleableSection({
								label: 'Border radius',
								toggleableContent: true,
								startOpen: options.showBorderRadius,
								onToggle: options.onBorderRadiusToggle,
								content: imageBorderRadius(
									options.imageBorderRadiusOptions
								)
							}).getEl()
					]
				})
		].flat()
	);

	const ImageAccordion = new Accordion({
		title: options.accordion.title,
		content: imageAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		extendedClasses: options.extendedClasses || ''
	});

	return ImageAccordion;
};
