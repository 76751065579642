import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import { fileReader, merge } from 'datatalks-utils';
import heroTrait from './trait/_trait';
import render from './_render';
import baseMethods from '../common/methods/_baseMethods';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		attributes: {},
		spacingBetweenElements: '12px',
		cssClass: 'gjs-comp-hero',
		width: '100%',
		titlePh: 'Simple informing headline',
		headingStyle: {
			color: null
		},
		headingAlignment: 'left',
		textContentPh:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ultrices tempor porta. Maecenas eu libero at nunc pulvinar fermentum et convallis elit. In et nisi lacus. Morbi mauris metus, imperdiet pharetra efficitur sit amet, hendrerit et neque. Etiam nec vestibulum sem. Etiam condimentum eros in feugiat finibus. Quisque eget mollis velit. Maecenas tempus placerat arcu, id molestie mauris fermentum at. Phasellus vel tellus feugiat urna interdum posuere. Integer eu sodales urna. Nam metus leo, consectetur id neque imperdiet, vulputate dictum erat. Cras tristique, tortor a viverra ultricies, nulla enim faucibus tellus, non vestibulum diam eros ut orci.',
		textStyle: {
			color: null
		},
		textAlignment: 'left',
		name: 'Hero',
		displayTitle: true,
		displayText: true,
		displayButton: true,
		buttonPh: 'Button text',
		buttonBackgroundColor: null,
		buttonHeight: null,
		buttonWidth: '100%',
		buttonFontFamily: null,
		buttonFontSize: null,
		buttonFontStyle: 'normal',
		buttonColor: null,
		buttonFontWeight: null,
		buttonBorderWidth: '0px',
		buttonBorderStyle: 'solid',
		buttonBorderColor: 'transparent',
		buttonAlignment: 'center',
		buttonBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		buttonLineHeight: null,
		buttonUseBorderRadius: false,
		buttonLetterSpacing: 'normal',
		buttonNormalLetterSpacing: true,
		buttonDefaultLetterSpacingLength: '0.1em',
		buttonHasOutlookBorderRadius: false,
		buttonOutlookWidth: '150px',
		buttonTextDecorationLine: null,
		displayImage: true,
		imgPh: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+',
		imageWidth: '100%',
		imageAlignment: 'center',
		imageBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		imageUseBorderRadius: false,
		imageBorderColor: 'transparent',
		imageBorderStyle: 'solid',
		imageBorderWidth: 0,
		imageHasLink: false,
		imageHref: null,
		toHtmlOptions: {}
	};

	const options = merge(
		COMPONENTS_DEFAULTS,
		getComponentsDefaultsByType(),
		defaults,
		componentOptions
	);

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				...options,
				name: options.name,
				tagName: 'table',
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				style: {
					width: options.width
				},
				displayImage: options.displayImage,
				imgSrc: options.imgPh,
				imageAlignment: options.imageAlignment,
				imageWidth: options.imageWidth,
				buttonLetterSpacing: options.buttonLetterSpacing,
				buttonNormalLetterSpacing:
					options.buttonLetterSpacing === 'normal',
				buttonDefaultLetterSpacingLength:
					options.buttonDefaultLetterSpacingLength,
				imageBorderRadius: options.imageBorderRadius,
				imageBorderColor: options.imageBorderColor,
				imageBorderStyle: options.imageBorderStyle,
				imageBorderWidth: options.imageBorderWidth,
				displayTitle: options.displayTitle,
				title: `<h3>${options.titlePh}</h3>`,
				displayText: options.displayText,
				textContent: `<p>${options.textContentPh}</p>`,
				displayButton: options.displayButton,
				buttonText: options.buttonPh,
				components: comp => comp.componentRender(comp),
				traits(component) {
					const result = [
						heroTrait({
							hasImageWidthSlider: true
						})
					];

					return result;
				}
			}),

			init() {
				this.setStyle({
					...this.getStyle(),
					width: this.get('width')
				});
			},

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			setNormalLetterSpacing(isNormal) {
				this.set('buttonNormalLetterSpacing', isNormal);
			},

			setLetterSpacing(value) {
				this.setNormalLetterSpacing(value === 'normal');
				this.setAndRerender('buttonLetterSpacing', value);
				this.components(this.componentRender(this));
			},

			setAndRerender(prop, value) {
				this.set(prop, value);
				this.components(this.componentRender(this));
			},

			rerender() {
				this.components(this.componentRender(this));
			},

			changeImageWidth(width) {
				this.set('imageWidth', width);
				this.components(this.componentRender(this));
			},

			changeImage(file, callback) {
				const comp = this;
				if (file instanceof File || file instanceof Blob) {
					fileReader(file, {
						callbacks: {
							onload: result => {
								comp.set('imgSrc', result.e.target.result);
								comp.components(comp.componentRender(comp));
							},
							dataRead:
								typeof callback === 'function'
									? callback
									: undefined,
							onError: console.error,
							onabort: console.log
						}
					});
				} else {
					comp.set('imgSrc', file);
					comp.components(comp.componentRender(comp));
				}
			},

			removeImage() {
				const comp = this;
				comp.set('imgSrc', '');
				comp.components(comp.componentRender(comp));
			},

			toggleImage() {
				this.set('displayImage', !this.get('displayImage'));
				this.components(this.componentRender(this));
			},

			setImageBorderRadius(corner, value) {
				const aux = {};
				if (
					[
						'topLeft',
						'topRight',
						'bottomLeft',
						'bottomRight'
					].includes(corner)
				) {
					aux[corner] = value;
					this.set('imageBorderRadius', {
						...this.get('imageBorderRadius'),
						...aux
					});
					this.components(this.componentRender(this));
				} else {
					const br = { ...this.get('imageBorderRadius') };
					for (const key in br) {
						if (br.hasOwnProperty(key)) aux[key] = value;
					}
					this.set('imageBorderRadius', { ...aux });
					this.components(this.componentRender(this));
				}
			},

			componentRender: render,
			...baseMethods(componentOptions, editor)
		}
	};
};
