import { RadioButtonGroup } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import { toBoolean } from 'datatalks-utils';

const withMargins = getIcon('fullscreen-exit-line', { size: 'xl' });
const fullWidth = getIcon('fullscreen-line', { size: 'xl' });

export default obj => {
	const radioButtonGroupOptions = {
		onChange: (allSelected, changedItem, selectedItems, noneSelected) => {
			obj.component.setAndRerender(
				'hasMargins',
				toBoolean(changedItem.getValue())
			);
		},
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				label: 'Fit to container',
				value: 'false',
				selected: !obj.component.get('hasMargins'),
				icon: fullWidth
			},
			{
				label: 'With margins',
				value: 'true',
				selected: !!obj.component.get('hasMargins'),
				icon: withMargins
			}
		]
	};

	const marginsButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return marginsButtonGroup;
};
