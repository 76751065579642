import { merge, cssStyleObjectToString } from 'datatalks-utils';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default (innerHtml, options) => {
	const defaults = {
		debug: false,
		topMargin: '0px',
		rightMargin: '0px',
		bottomMargin: '0px',
		leftMargin: '0px',
		hasMargins: false,
		width: '100%',
		backgroundColor: null,
		hasBorderRadius: false,
		borderRadius: {
			topLeft: '0px',
			topRight: '0px',
			bottomRight: '0px',
			bottomLeft: '0px'
		},
		borderWidth: null,
		borderStyle: null,
		borderColor: null,
		useVml: false
	};

	options = merge(defaults, options);

	// Calculations
	// TODO: Add options and calculations for the outlook to render border radius depending margin values
	const hasBackground = options.backgroundColor != 'transparent';
	const hasBorder =
		options.borderColor != 'transparent' ||
		options.borderWidth != '0px' ||
		options.borderStyle != 'none';

	const tableStyle = {
		'max-width': options.width,
		width: '100%',
		'background-color': options.backgroundColor,
		'border-collapse': options.hasBorderRadius ? 'separate' : null,
		'border-radius': options.hasBorderRadius
			? Object.values(options.borderRadius).join(' ')
			: null,
		'border-width': options.borderWidth,
		'border-style': options.borderStyle,
		'border-color': options.borderColor
	};

	const cellStyle = {
		'padding-top': options.hasMargins ? options.topMargin || 0 : 0,
		'padding-right': options.hasMargins ? options.rightMargin || 0 : 0,
		'padding-bottom': options.hasMargins ? options.bottomMargin || 0 : 0,
		'padding-left': options.hasMargins ? options.leftMargin || 0 : 0
	};

	// TODO: Improve VML for better rendering in Outlook
	const vml =
		innerHtml => `<table style="margin: 0;padding: 0;border-spacing: 0; overflow: hidden;${
			(options.hasBorderRadius
				? `border-radius: ${Object.values(options.borderRadius).join(
						' '
				  )};`
				: '') +
			(options.backgroundColor
				? `background-color: ${options.backgroundColor};`
				: '') +
			`width: ${options.width};`
		}" cellspacing="0" cellpadding="0" border="0" width="${options.width}">
<tbody style="margin: 0;padding: 0;">
    <tr style="margin: 0;padding: 0;margin-top: 0">
        <!--[if mso]>
        <td style="margin:0;padding:0;font-size:0;width:${
			options.leftMargin
		}px;height:${options.topMargin}px;">
            ${
				hasBackground &&
				`<v:shape style="width:${options.leftMargin}px;height:${options.topMargin}px;position:relative;top:0.5px;left:0.5px;" coordorigin="0 0" coordsize="2 2" fillcolor="${options.backgroundColor}" stroke="f" fill="true" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                <v:path v="m 0,2 c 0,1,1,0,2,0 l 2,2 x"/>
            </v:shape>`
			}

            ${
				hasBorder &&
				`<v:shape style="width:${options.leftMargin}px;height:${options.topMargin}px;" coordorigin="0 0" coordsize="2 2" strokecolor="${options.borderColor}" strokeweight="${options.borderWidth}" stroke="true" fill="f" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
	              <v:path v="m 0,2 c 0,1,1,0,2,0"/>
            </v:shape>`
			}
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;width: ${
			options.leftMargin
		}px;height: ${options.topMargin}px;border-top-left-radius: ${
			options.borderRadius.topLeft
		}px;background-color: ${options.backgroundColor}; border-color:${
			options.borderColor
		}; border-style:${options.borderStyle}; border-top-width: ${
			options.borderWidth
		}; border-left-width: ${
			options.borderWidth
		}; border-right-width: 0; border-bottom-width: 0; ">
        </td>

        <!--<![endif]-->

        <!--[if mso]>
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		}; ${
			hasBorder
				? `border-top: ${options.borderWidth} solid ${options.borderColor};`
				: ''
		}">
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		}; border-color:${options.borderColor}; border-top: ${
			options.borderWidth
		} ${options.borderStyle} ${options.borderColor};">
        </td>
        <!--<![endif]-->

        <!--[if mso]>
        <td style="margin:0;padding:0;font-size:0;width:${
			options.rightMargin
		}px;height:${options.topMargin}px;">
            ${
				hasBackground &&
				`<v:shape style="width:${options.rightMargin}px;height:${options.topMargin}px;position:relative;top:0.5px;left:-0.5px;" coordorigin="0 0" coordsize="2 2" fillcolor="${options.backgroundColor}" stroke="f" fill="true" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                  <v:path v="m 0,0 c 1,0,2,1,2,2 l 0,2 x"/>
              </v:shape>`
			}

              ${
					hasBorder &&
					`<v:shape style="width:${options.rightMargin}px;height:${options.topMargin}px;" coordorigin="0 0" coordsize="2 2" strokecolor="${options.borderColor}" strokeweight="${options.borderWidth}" stroke="true" fill="f" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                    <v:path v="m 0,0 c 1,0,2,1,2,2"/>
                </v:shape>`
				}
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;width: ${
			options.rightMargin
		}px;height: ${options.topMargin}px;border-top-right-radius: ${
			options.borderRadius.topRight
		}px;background-color: ${options.backgroundColor};border-color:${
			options.borderColor
		}; border-style:${options.borderStyle}; border-top-width: ${
			options.borderWidth
		}; border-left-width: 0; border-right-width: ${
			options.borderWidth
		}; border-bottom-width: 0;">
        </td>
        <!--<![endif]-->
    </tr>

    <tr style="margin: 0;padding: 0">
        <!--[if mso]>
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		};${
			hasBorder
				? `border-left: ${options.borderWidth} solid ${options.borderColor};`
				: ''
		}">
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		}; border-left: ${options.borderWidth} ${options.borderStyle} ${
			options.borderColor
		};">
        </td>
        <!--<![endif]-->

        <td style="font-family: Helvetica, sans-serif;font-size: 16px;margin: 0;padding: 0;font-weight: normal;line-height: 24px;background-color: ${
			options.backgroundColor
		}">
            ${innerHtml}
        </td>

        <!--[if mso]>
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		};${
			hasBorder
				? `border-right: ${options.borderWidth} solid ${options.borderColor};`
				: ''
		}">
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		}; border-right: ${options.borderWidth} ${options.borderStyle} ${
			options.borderColor
		};">
        </td>
        <!--<![endif]-->
    </tr>

    <tr style="margin: 0;padding: 0">
        <!--[if mso]>
        <td style="margin:0;padding:0;font-size:0;width:${
			options.leftMargin
		}px;height:${options.bottomMargin}px;">
            ${
				hasBackground &&
				`<v:shape style="width:${options.leftMargin}px;height:${options.bottomMargin}px;position:relative;top:-0.5px;left:0.5px;" coordorigin="0 0" coordsize="2 2" fillcolor="${options.backgroundColor}" stroke="f" fill="true" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                <v:path v="m 2,2 c 1,2,0,1,0,0 l 2,0 x"/>
            </v:shape>`
			}
            ${
				hasBorder &&
				`<v:shape style="width:${options.leftMargin}px;height:${options.bottomMargin}px;" coordorigin="0 0" coordsize="2 2" strokecolor="${options.borderColor}" strokeweight="${options.borderWidth}" stroke="true" fill="f" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                  <v:path v="m 2,2 c 1,2,0,1,0,0"/>
              </v:shape>`
			}
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;width: ${
			options.leftMargin
		}px;height: ${options.bottomMargin}px;border-bottom-left-radius: ${
			options.borderRadius.bottomLeft
		}px;background-color: ${options.backgroundColor};border-color:${
			options.borderColor
		}; border-style:${
			options.borderStyle
		}; border-top-width:0; border-left-width: ${
			options.borderWidth
		}; border-right-width: 0; border-bottom-width: ${options.borderWidth};">
        </td>
        <!--<![endif]-->

        <!--[if mso]>
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		};${
			hasBorder
				? `border-bottom: ${options.borderWidth} solid ${options.borderColor};`
				: ''
		}">
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;background-color: ${
			options.backgroundColor
		}; border-bottom: ${options.borderWidth} ${options.borderStyle} ${
			options.borderColor
		};;">
        </td>
        <!--<![endif]-->

        <!--[if mso]>
        <td style="margin:0;padding:0;font-size:0;width:${
			options.rightMargin
		}px;height:${options.bottomMargin}px;">
            ${
				hasBackground &&
				`<v:shape style="width:${options.rightMargin}px;height:${options.bottomMargin}px;position:relative;top:-0.5px;left:-0.5px;" coordorigin="0 0" coordsize="2 2" fillcolor="${options.backgroundColor}" stroke="f" fill="true" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                <v:path v="m 2,0 c 2,1,1,2,0,2 l 0,0 x"/>
            </v:shape>`
			}
            ${
				hasBorder &&
				`<v:shape style="width:${options.rightMargin}px;height:${options.bottomMargin}px;" coordorigin="0 0" coordsize="2 2" strokecolor="${options.borderColor}" strokeweight="${options.borderWidth}" stroke="true" fill="f" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
                  <v:path v="m 2,0 c 2,1,1,2,0,2"/>
              </v:shape>`
			}
        </td>
        <![endif]-->
        <!--[if !mso]><!-->
        <td style="font-size: 0;margin: 0;padding: 0;width: ${
			options.rightMargin
		}px;height: ${options.bottomMargin}px;border-bottom-right-radius: ${
			options.borderRadius.bottomRight
		}px;background-color: ${options.backgroundColor};border-color:${
			options.borderColor
		}; border-style:${
			options.borderStyle
		}; border-top-width:0; border-left-width: 0; border-right-width: ${
			options.borderWidth
		}; border-bottom-width: ${options.borderWidth};">
        </td>
        <!--<![endif]-->
    </tr>
</tbody>
</table>`;

	const html = options.useVml
		? vml(innerHtml)
		: `<table data-eb-name="wrapper-first" style="${cssStyleObjectToString(
				validateStyleObj(tableStyle)
		  )}">
				<tr>
					<td data-eb-name="wrapper-last" style="${cssStyleObjectToString(
						validateStyleObj(cellStyle)
					)}">
						${innerHtml}
					</td>
				</tr>
			</table>
		`;

	if (options.debug) console.log('Margins common template HTML: ', html);

	return html;
};
