import {
	TextEditor,
	ToggleButton,
	Accordion,
	InputLabel,
	Input
} from 'datatalks-ui';
import { addClassesString, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';

export default (obj, options = {}, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const defaults = {
		hasButtonToggler: true,
		toggleBtnClickTogglesAccordion: false,
		textEditorProp: 'buttonEditor',
		fullButtonLink: true
	};

	options = merge(defaults, options);

	const buttonAccordionContent = document.createElement('div');

	if (!customTrait[options.textEditorProp]) {
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent: obj.component.get('buttonText'),
			initialState: obj.component.get('buttonState'),
			onChange: (html, editorState) => {
				obj.component.setAndRerender.call(
					obj.component,
					'buttonText',
					html
				);
				obj.component.set('buttonState', editorState);
			},
			...textEditorCommonOptions(editor, { isButton: true })
		});

		customTrait[options.textEditorProp].toolbar.on(
			'change:line-height',
			lineHeight => {
				obj.component.setAndRerender('buttonLineHeight', lineHeight);
			}
		);

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp],
			{
				isButton: true
			}
		);
	}

	if (options.fullButtonLink) {
		buttonAccordionContent.append(
			new InputLabel({
				input: new Input({
					initialValue: obj.component.get('buttonHref'),
					onChange: (e, input) => {
						obj.component.setAndRerender('buttonHref', input.value);
					},
					extendedClasses: 'eb-mb-4'
				}),
				label: 'Button Link'
			}).getEl()
		);
	}

	buttonAccordionContent.append(customTrait[options.textEditorProp].getEl());

	const title = options.hasButtonToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get('displayButton'),
						onChange: isActive => {
							obj.component.setAndRerender(
								'displayButton',
								isActive
							);
						}
					}).getEl(),
					`<span>Button</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: 'Button';

	const buttonAccordion = new Accordion({
		title: title,
		content: buttonAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return buttonAccordion;
};
