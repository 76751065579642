/* eslint-disable require-jsdoc */

import { $patchStyleText } from '@lexical/selection';
import { FORMAT_TEXT_COMMAND, $getSelection, $isRangeSelection } from 'lexical';
import { $isHeadingNode } from '@lexical/rich-text';
import { TOGGLE_LINK_COMMAND, $isLinkNode } from '@lexical/link';
import { $getNearestNodeOfType } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import getFormatters from './_formatters';
import getModifiers from './_modifiers';
import {
	Dropdown,
	DropdownItem,
	DropdownButtonItem,
	DropdownButton,
	ColorPickerInput,
	Floater
} from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import {
	webFonts,
	webSafeFonts,
	unquoteString,
	setContent,
	addClassesString,
	sanitizeUrl,
	EventEmitter,
	validateUrl,
	merge
} from 'datatalks-utils';
import clearFormattingPlugin from './_clearFormattingPlugin';
import TextEditorInputActions from './_linkEditor';
import TextEditorDropdown from './_TextEditorDropdown';
import { capitalize } from 'lodash-es';

export default class TextEditorToolbar {
	constructor(options = {}) {
		const toolbar = this;

		const defaults = {
			editor: null,
			textEditor: null,
			useRichText: true,
			useFormatters: {
				code: true,
				paragraph: true,
				h1: true,
				h2: true,
				h3: true,
				h4: true,
				h5: true,
				h6: true,
				numberedList: true,
				bulletList: true
			},
			startBold: {
				code: false,
				paragraph: false,
				h1: true,
				h2: true,
				h3: true,
				h4: true,
				h5: true,
				h6: true,
				bulletList: false,
				numberedList: false
			},
			useModifiers: {
				bold: true,
				italic: true,
				strikethrough: true,
				underline: true,
				superscript: false,
				code: false,
				alignLeft: true,
				alignCenter: true,
				alignRight: true,
				alignJustify: true
			},
			modifiersCb: {
				bold: null,
				italic: null,
				strikethrough: null,
				underline: null,
				superscript: null,
				code: null,
				alignLeft: null,
				alignCenter: null,
				alignRight: null,
				alignJustify: null
			},
			modifiersEval: {
				bold: null,
				italic: null,
				strikethrough: null,
				underline: null,
				superscript: null,
				code: null,
				alignLeft: null,
				alignCenter: null,
				alignRight: null,
				alignJustify: null
			},
			useLink: true,
			useLineHeight: true,
			clearFormattingIconSize: 'lg',
			linkIconSize: 'lg',
			getFormatters,
			getModifiers,
			classPrefix: 'eb-',
			className: 'prosetyper-toolbar',
			dropdownClassName: 'prosetyper-dropdown',
			dropdownItemClassName: 'prosetyper-dropdown-item',
			dropdownItemTemplate: ({ icon, label }) => {
				return [
					addClassesString(
						setContent(document.createElement('div'), icon),
						'eb-prosetyper__icon eb-prosetyper-dropdown-item__icon'
					),
					addClassesString(
						setContent(document.createElement('div'), label),
						'eb-prosetyper__label eb-prosetyper-dropdown-item__label'
					)
				];
			},
			dropdownsOptions: {
				arrowSize: 'lg'
			},
			lineHeightValues: [0.8, 1, 1.15, 1.5, 1.75, 2, 2.5, 3, 4, 5],
			lineHeightBySelection: true,
			lineHeightPlaceholder: 'Line height',
			fontSizeValues: [
				'8px',
				'10px',
				'12px',
				'14px',
				'16px',
				'24px',
				'32px',
				'48px',
				'64px',
				'72px'
			],
			extraDropdowns: [],
			evalAllowExtraDropdown: null,
			boldLinks: true,
			defaultColor: '#000'
		};

		defaults.clearFormattingIcon = getIcon('format-clear', {
			size:
				options.clearFormattingIconSize ||
				defaults.clearFormattingIconSize
		});

		defaults.linkIcon = getIcon('link', {
			size: options.linkIconSize || defaults.linkIconSize
		});

		this.options = merge(defaults, options);

		this.className = `${this.options.classPrefix}${this.options.className}`;
		this.dropdownClassName = `${this.options.classPrefix}${this.options.dropdownClassName}`;
		this.dropdownItemClassName = `${this.options.classPrefix}${this.options.dropdownItemClassName}`;

		if (!this.options.editor)
			throw new Error('TextEditorToolbar: editor is required');

		this.editor = this.options.editor;
		this.textEditor = this.options.textEditor;
		this.element = this.options.element || document.createElement('div');
		this.element.classList.add(this.className);
		this.element.addEventListener('click', e => {
			toolbar.editor.focus();
		});
		this.formatters = [];
		this.formattersDropdown = null;
		this.fonts = [];
		this.fontsDropdown = null;
		this.fontSizesDropdown = null;
		this.fontSizes = [];
		this.lineHeightsDropdown = null;
		this.lineHeights = [];
		this.modifiers = [];
		this.aligners = [];
		this.clearFormattingButton = null;
		this.linkButton = null;
		this.extraDropdowns = {
			bottom: [],
			top: []
		};
		this.eventEmitter = new EventEmitter();

		this.init();
	}

	init() {
		this.on('selectionChange', this.handleSelectionChange.bind(this));
		this.createModifiers();
		this.createAligners();
		if (this.options.useRichText) this.createFormatters();
		this.createFonts();
		if (this.options.useLineHeight) this.createLineHeights();
		this.createFontSizes();
		this.createColorPicker();
		this.createFormattingClearButton();
		if (this.options.useLink) {
			this.createLinkButton();
			this.createLinkEditor();
			this.createLinkExpandCollapse();
		}
		this.createExtraDropdowns();
		this.fillTopToolbar();
	}

	createFormatters() {
		const toolbar = this;
		const formatters = toolbar.options.getFormatters(
			toolbar,
			toolbar.editor,
			{
				formattersVisibility: toolbar.options.useFormatters,
				startBold: toolbar.options.startBold
			}
		);
		Object.values(formatters).forEach(formatter => {
			const { name, format, icon } = formatter;
			const item = new DropdownItem({
				content: toolbar.options.dropdownItemTemplate({
					icon,
					label: name
				}),
				onClick: format,
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});
			toolbar.formatters.push({
				...formatter,
				item
			});
		});
		toolbar.formattersDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.formatters.map(f => f.item)
		});
	}

	createExtraDropdowns() {
		this.options.extraDropdowns.forEach(dropdown => {
			if (!(dropdown instanceof TextEditorDropdown)) {
				dropdown = new TextEditorDropdown(dropdown);
			}
			if (
				(typeof this.options.evalAllowExtraDropdown === 'function' &&
					this.options.evalAllowExtraDropdown.call(this, dropdown)) ||
				!this.options.evalAllowExtraDropdown
			)
				this.createExtraDropdown(dropdown);
		});
	}

	createExtraDropdown(dropdown) {
		const toolbar = this;
		toolbar.extraDropdowns[dropdown.position].push(
			new DropdownButton({
				...toolbar.options.dropdownsOptions,
				...dropdown.dropdownOptions,
				items: dropdown.dropdownOptions.items.map(dropItem => {
					return new DropdownButtonItem({
						...(toolbar.options.dropdownsOptions.itemsOptions ||
							{}),
						...dropItem,
						extendedClasses: `${
							dropItem.extendedClasses
								? dropItem.extendedClasses + ' '
								: ''
						}${toolbar.dropdownItemClassName} ${
							toolbar.className
						}__dropdown-item`,
						onClick: (item, itemValue) => {
							if (typeof dropItem.onClick === 'function')
								dropItem.onClick.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						},
						onSelect: (item, itemValue) => {
							if (typeof dropItem.onSelect === 'function')
								dropItem.onSelect.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						},
						onDeselect: (item, itemValue) => {
							if (typeof dropItem.onDeselect === 'function')
								dropItem.onDeselect.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						}
					});
				}),
				extendedClasses: `${
					dropdown.dropdownOptions.extendedClasses
						? dropdown.dropdownOptions.extendedClasses + ' '
						: ''
				}${toolbar.dropdownClassName} ${toolbar.className}__dropdown`
			})
		);
	}

	createFonts() {
		const toolbar = this;
		[...webSafeFonts, ...webFonts].sort().forEach(font => {
			const item = new DropdownItem({
				content: webFonts.includes(font)
					? `${capitalize(font)} *`
					: capitalize(font),
				onClick: () => {
					this.editor.update(() => {
						const selection = $getSelection();
						if (!$isRangeSelection(selection)) {
							return false;
						}
						$patchStyleText(selection, {
							'font-family': font
						});
						return true;
					});
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.fonts.push({
				name: font,
				item
			});
		});
		toolbar.fontsDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.fonts.map(f => f.item),
			placeholder: 'Font'
		});
	}

	editorAddLink(href, setBold = this.options.boldLinks) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, href);
		if (setBold) {
			let isBold;
			this.editor.update(() => {
				isBold = $getSelection().hasFormat('bold');
				return isBold;
			});
			if (!isBold)
				this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
		}
	}

	editorAddText(text) {
		this.editor.update(() => {
			const selection = $getSelection();
			if (selection) {
				selection.insertText(text);
			}
		});
	}

	createFontSizes() {
		const toolbar = this;
		this.options.fontSizeValues.forEach(size => {
			const item = new DropdownItem({
				content: size,
				onClick: () => {
					this.editor.update(() => {
						const selection = $getSelection();
						if (!$isRangeSelection(selection)) {
							return false;
						}
						$patchStyleText(selection, {
							'font-size': size
						});
						return true;
					});
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.fontSizes.push({
				name: size,
				item
			});
		});
		toolbar.fontSizesDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.fontSizes.map(fs => fs.item),
			placeholder: 'Font size'
		});
	}

	createLineHeights() {
		const toolbar = this;
		toolbar.options.lineHeightValues.forEach(height => {
			const item = new DropdownItem({
				content: height,
				onClick: () => {
					this.editor.update(() => {
						const selection = $getSelection();
						if (!$isRangeSelection(selection)) {
							return false;
						}
						if (toolbar.options.lineHeightBySelection) {
							$patchStyleText(selection, {
								'line-height': height
							});
						} else {
							const lh = {
								'line-height': height
							};
							toolbar.textEditor.setOptions({
								iframeStyleOptions: {
									p: lh,
									h1: lh,
									h2: lh,
									h3: lh,
									h4: lh,
									h5: lh,
									h6: lh
								}
							});
							toolbar.textEditor.draw();
						}
						toolbar.eventEmitter.emit('change:line-height', height);
						return true;
					});
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.lineHeights.push({
				name: height,
				item
			});
		});
		toolbar.lineHeightsDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.lineHeights.map(lh => lh.item),
			placeholder: toolbar.options.lineHeightPlaceholder || 'Line height'
		});
	}

	createModifiers() {
		const toolbar = this;
		Object.values(toolbar.options.getModifiers(toolbar.editor))
			.filter(mod => toolbar.options.useModifiers[mod.name])
			.filter(({ category }) => category === 'text')
			.forEach(modifier => {
				toolbar.createModifier(modifier);
			});
	}

	createModifier(modifier) {
		const toolbar = this;
		const { icon, type, payload, label } = modifier;
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (icon) {
			setContent(button, icon);
		} else {
			button.textContent = label || payload;
		}

		button.addEventListener('click', () => {
			if (toolbar.options.useRichText) {
				this.editor.dispatchCommand(type, payload);
			}
			toolbar.eventEmitter.emit(`modify`, modifier);
			toolbar.eventEmitter.emit(`modify:${payload}`);
			if (typeof toolbar.options.modifiersCb[payload] === 'function') {
				toolbar.options.modifiersCb[payload](toolbar);
			}
		});
		this.modifiers.push({
			...modifier,
			button,
			active: false
		});
	}

	createAligners() {
		const toolbar = this;
		Object.values(toolbar.options.getModifiers(toolbar.editor))
			.filter(mod => toolbar.options.useModifiers[mod.name])
			.filter(({ category }) => category === 'alignment')
			.forEach(aligner => {
				toolbar.createAligner(aligner);
			});
	}

	createAligner(aligner) {
		const toolbar = this;
		const { icon, type, payload, label } = aligner;
		const button = document.createElement('button');
		button.className = `${toolbar.className}__button`;

		if (icon) {
			setContent(button, icon);
		} else {
			button.textContent = label || payload;
		}

		button.addEventListener('click', () => {
			if (toolbar.options.useRichText) {
				toolbar.editor.dispatchCommand(type, payload);
			}
			toolbar.eventEmitter.emit(`align`, aligner);
			toolbar.eventEmitter.emit(`align:${payload}`);
			if (typeof toolbar.options.modifiersCb[name] === 'function') {
				toolbar.options.modifiersCb[name](toolbar);
			}
		});
		toolbar.aligners.push({
			...aligner,
			button,
			active: false
		});
	}

	createColorPicker() {
		const toolbar = this;
		toolbar.textColorPicker = new ColorPickerInput({
			color: toolbar.options.defaultColor || '#000',
			emptyColor: () => null,
			noInput: true,
			extendedClasses: `${toolbar.className}__color-picker`,
			onChange: (cpi, ev) => {
				toolbar.editor.update(() => {
					const selection = $getSelection();
					if (!$isRangeSelection(selection)) {
						return false;
					}
					$patchStyleText(selection, {
						color: cpi.getColor()
					});
					return true;
				});
				toolbar.editor.read(() => {
					toolbar.eventEmitter.emit('change:color', cpi.getColor());
				});
			}
		});
	}

	createFormattingClearButton() {
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (this.options.clearFormattingIcon) {
			setContent(button, this.options.clearFormattingIcon);
		} else {
			button.textContent =
				this.options.clearFormattingLabel || 'Clear all formatting';
		}

		button.addEventListener('click', () => {
			this.clearFormatting();
		});
		this.clearFormattingButton = button;
	}

	createLinkButton() {
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (this.options.linkIcon) {
			setContent(button, this.options.linkIcon);
		} else {
			button.textContent = this.options.linkLabel || 'Insert link';
		}
		this.linkButton = button;
	}

	createLinkEditor() {
		const toolbar = this;
		toolbar.linkEditor = new TextEditorInputActions({
			context: toolbar,
			extendedClasses: `${toolbar.className}__link-editor`,
			onPrimaryButtonClick: toolbar.handleLinkAdd.bind(toolbar),
			onSecondaryButtonClick: toolbar.handleLinkRemove.bind(toolbar)
		});
		toolbar.linkEditorWrapper = toolbar.linkEditor.getEl();
	}

	createLinkExpandCollapse() {
		this.linkExpandCollapse = new Floater(
			this.linkButton,
			this.linkEditorWrapper,
			{
				stopTargetClickPropagation: true,
				margin: {
					top: 4
				}
			}
		);
	}

	fillTopToolbar() {
		this.modifiers
			.map(mod => mod.button)
			.forEach(button => {
				this.element.appendChild(button);
			});
		this.aligners
			.map(aligner => aligner.button)
			.forEach(button => {
				this.element.appendChild(button);
			});
		if (this.options.useRichText)
			this.element.appendChild(this.formattersDropdown.getEl());
		this.element.appendChild(this.fontsDropdown.getEl());
		this.element.appendChild(this.textColorPicker.getEl());
		this.element.appendChild(this.fontSizesDropdown.getEl());
		if (this.options.useLineHeight)
			this.element.appendChild(this.lineHeightsDropdown.getEl());
		this.element.appendChild(this.clearFormattingButton);
		if (this.options.useLink) this.element.appendChild(this.linkButton);
		if (this.extraDropdowns.top.length) {
			this.extraDropdowns.top.forEach(dropdown => {
				this.element.appendChild(dropdown.getEl());
			});
		}
	}

	handleSelectionChange(selection) {
		this.currentSelection = selection;
		if (this.options.useLink) this.closeLinkEditor();
		this.evaluateActiveButtons(selection);
	}

	evaluateActiveButtons(selection) {
		this.evaluateModifiers(selection);
		this.evaluateAligners(selection);
		if (this.options.useRichText) this.evaluateFormatters(selection);
		this.evaluateColor(selection);
		this.evaluateFont(selection);
		this.evaluateFontSize(selection);
		if (this.options.useLineHeight) this.evaluateLineHeight(selection);
		if (this.options.useLink) this.evaluateLink(selection);
		this.evaluateExtraDropdowns(selection);
	}

	evaluateModifiers(selection) {
		this.modifiers.forEach(modifier => {
			let isActive;
			if (this.options.useRichText && selection?.hasFormat) {
				isActive = selection.hasFormat(modifier.payload);
			} else if (
				typeof this.options.modifiersEval[modifier.payload] ===
				'function'
			) {
				isActive =
					this.options.modifiersEval[modifier.payload](toolbar);
			}

			this.setButtonActive(modifier.button, isActive || false);
		});
	}

	setButtonActive(button, isActive) {
		button.classList[isActive ? 'add' : 'remove'](
			`${this.className}__button--active`
		);
	}

	evaluateExtraDropdowns(selection) {
		const toolbar = this;
		for (const pos in toolbar.extraDropdowns) {
			if (toolbar.extraDropdowns.hasOwnProperty(pos)) {
				if (toolbar.extraDropdowns[pos].length) {
					toolbar.extraDropdowns[pos].forEach(dropdown => {
						dropdown.updateAllItemsSelection({
							textEditor: toolbar.textEditor,
							selection
						});
					});
				}
			}
		}
	}

	evaluateAligners(selection) {
		let selectionAlignment;
		if (this.options.useRichText && $isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = this.editor.getElementByKey(elementKey);
			if (elementDOM) {
				selectionAlignment = getComputedStyle(elementDOM)?.textAlign;
			}
		}

		this.aligners.forEach(aligner => {
			let isActive;
			if (selectionAlignment) {
				isActive = selectionAlignment === aligner.payload;
			} else if (
				typeof this.options.modifiersEval[aligner.name] === 'function'
			) {
				isActive = this.options.modifiersEval[aligner.name](toolbar);
			}

			this.setButtonActive(aligner.button, isActive || false);
		});
	}

	evaluateFormatters(selection) {
		let type;
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = this.editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(
						anchorNode,
						ListNode
					);
					type = parentList ? parentList.getTag() : element.getTag();
				} else {
					type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
				}
			}
		}
		const item = this.formatters.find(f => f.type === type)?.item;
		if (item)
			this.formattersDropdown.activateItem(
				this.formatters.find(f => f.type === type)?.item
			);
	}

	evaluateColor(selection) {
		if ($isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.color) {
				this.textColorPicker.changeColor(getComputedStyle(el).color);
			}
		}
	}

	evaluateFont(selection) {
		if ($isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.fontFamily) {
				const font = this.fonts.find(
					f =>
						f.name ===
						unquoteString(getComputedStyle(el).fontFamily)
				);
				if (font) this.fontsDropdown.activateItem(font.item);
			}
		}
	}

	evaluateFontSize(selection) {
		if ($isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.fontSize) {
				const font = this.fontSizes.find(
					fs => fs.name == getComputedStyle(el).fontSize
				);
				if (font) this.fontSizesDropdown.activateItem(font.item);
			}
		}
	}

	evaluateLineHeight(selection) {
		if ($isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.lineHeight) {
				const font = this.lineHeights.find(
					lh =>
						lh.name == getComputedStyle(el).lineHeight ||
						parseFloat(lh.name).toFixed(2) ==
							parseFloat(
								parseFloat(
									getComputedStyle(el).lineHeight
								).toFixed(2) /
									parseFloat(
										getComputedStyle(el).fontSize
									).toFixed(2)
							).toFixed(2)
				);
				if (font) this.lineHeightsDropdown.activateItem(font.item);
			}
		}
	}

	evaluateLink(selection) {
		if ($isRangeSelection(selection)) {
			const node = selection.anchor.getNode();

			if ($isLinkNode(node.getParent()) || $isLinkNode(node)) {
				this.linkButton.classList.add(
					`${this.className}__button--active`
				);

				if (this.linkEditor) {
					if ($isLinkNode(node.getParent())) {
						this.linkEditor.setInputValue(
							node.getParent().getURL()
						);
					} else if ($isLinkNode(node)) {
						this.linkEditor.setInputValue(node.getURL());
					}
				}
			} else {
				if (this.linkEditor) this.linkEditor.setInputValue('');
				this.linkButton.classList.remove(
					`${this.className}__button--active`
				);
			}
		}
	}

	clearFormatting() {
		clearFormattingPlugin(this.editor);
	}

	closeLinkEditor() {
		this.linkExpandCollapse.hideFloater();
	}

	invalidateUrl() {
		console.error('Invalid URL');
	}

	handleLinkAdd(inputValue, e) {
		const toolbar = this;
		if (!validateUrl(inputValue)) {
			toolbar.invalidateUrl();
			return;
		}

		toolbar.editor.dispatchCommand(
			TOGGLE_LINK_COMMAND,
			sanitizeUrl(inputValue)
		);

		if (toolbar.options.boldLinks)
			toolbar.ifSelectionNotBold(
				toolbar.editor.dispatchCommand.bind(
					toolbar.editor,
					FORMAT_TEXT_COMMAND,
					'bold'
				)
			);

		toolbar.closeLinkEditor();
	}

	ifSelectionNotBold(cb) {
		if (typeof cb == 'function')
			return this.editor.update(() => {
				return !$getSelection().hasFormat('bold') && cb();
			});
	}

	handleLinkRemove(inputValue, e) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);

		this.closeLinkEditor();
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		// TODO: add more destroy logic
		if (this.linkExpandCollapse instanceof Floater) {
			this.linkExpandCollapse.destroy();
		}
		this.textColorPicker.destroy();
	}
}
