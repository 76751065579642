import { TextDecorationHandler } from 'datatalks-utils';

export default (obj, item) => {
	const isList = !!item;
	const changeProp = isList
		? obj.component.changeItemProp.bind(obj.component, item)
		: obj.component.setAndRerender.bind(obj.component);
	const getProp = isList
		? obj.component.getItemProp.bind(obj.component, item)
		: obj.component.get.bind(obj.component);
	const toggleProp = (prop, trueValue, falseValue) => {
		changeProp(prop, getProp(prop) === trueValue ? falseValue : trueValue);
	};
	return {
		modifiersCb: {
			bold: toggleProp.bind(null, 'buttonFontWeight', '600', '400'),
			italic: toggleProp.bind(
				null,
				'buttonFontStyle',
				'italic',
				'normal'
			),
			strikethrough: () => {
				const tdh = new TextDecorationHandler(
					getProp('buttonTextDecorationLine') || ''
				);
				changeProp(
					'buttonTextDecorationLine',
					tdh.toggleDecoration('line-through')
				);
			},
			underline: () => {
				const tdh = new TextDecorationHandler(
					getProp('buttonTextDecorationLine') || ''
				);
				changeProp(
					'buttonTextDecorationLine',
					tdh.toggleDecoration('underline')
				);
			},
			alignLeft: () => {
				changeProp('buttonTextAlignment', 'left');
			},
			alignCenter: () => {
				changeProp('buttonTextAlignment', 'center');
			},
			alignRight: () => {
				changeProp('buttonTextAlignment', 'right');
			},
			alignJustify: () => {
				changeProp('buttonTextAlignment', 'justify');
			}
		},
		modifiersEval: {
			bold: () => getProp('buttonFontWeight') === '600',
			italic: () => getProp('buttonFontStyle') === 'italic',
			strikethrough: () =>
				getProp('buttonTextDecorationLine') &&
				getProp('buttonTextDecorationLine').indexOf('line-through') >
					-1,
			underline: () =>
				getProp('buttonTextDecorationLine') &&
				getProp('buttonTextDecorationLine').indexOf('underline') > -1,
			alignLeft: () => getProp('buttonTextAlignment') === 'left',
			alignCenter: () => getProp('buttonTextAlignment') === 'center',
			alignRight: () => getProp('buttonTextAlignment') === 'right',
			alignJustify: () => getProp('buttonTextAlignment') === 'justify'
		},
		plainTextOptions: {
			font: getProp('buttonFontFamily'),
			color: getProp('buttonColor'),
			fontSize: getProp('buttonFontSize')
		},
		PlainTextCallbacks: {
			font: font => changeProp('buttonFontFamily', font),
			color: color => changeProp('buttonColor', color),
			fontSize: fontSize => changeProp('buttonFontSize', fontSize),
			clearFormatting: () => {
				// TODO: replace with getComponentDefaults
				changeProp('buttonFontFamily', null);
				changeProp('buttonColor', null);
				changeProp('buttonFontSize', null);
				changeProp('buttonTextAlignment', 'left');
				changeProp('buttonFontWeight', null);
				changeProp('buttonFontStyle', 'normal');
				changeProp('buttonTextDecorationLine', null);
			}
		}
	};
};
