import { validateStyleObj } from '../../common/functions/_utilFunctions';

export default comp => {
	const getGeneralStyle = comp
		.getEditor()
		.getStyleRules.bind(comp.getEditor(), 'component');

	const borderRadius = comp.get('useBorderRadius')
		? Object.values(comp.get('borderRadius')).join(' ')
		: [
				getGeneralStyle('border-top-left-radius') || 0,
				getGeneralStyle('border-top-right-radius') || 0,
				getGeneralStyle('border-bottom-right-radius') || 0,
				getGeneralStyle('border-bottom-left-radius') || 0
		  ].join(' ');

	return validateStyleObj({
		'background-color':
			comp.get('backgroundColor') || getGeneralStyle('background-color'),
		width: comp.get('width'),
		'border-radius': borderRadius
	});
};
