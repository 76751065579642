export default comp => {
	const style = {
		...comp.getStyle(),
		'background-color': comp.get('color'),
		['mso-line-height-rule']: 'exactly'
	};

	if (['td', 'tr'].includes(comp.get('tagName'))) {
		if (comp.get('isVertical')) {
			comp.set('tagName', 'tr');
			comp.set('style', {
				...style,
				['line-height']: comp.get('dimension'),
				height: comp.get('dimension'),
				width: '100%'
			});
		} else {
			comp.set('tagName', 'td');
			delete style['height'];
			comp.set('style', {
				...style,
				width: comp.get('dimension'),
				['min-width']: comp.get('dimension')
			});
		}
	}

	if (comp.get('tagName') === 'table') {
		return {
			type: 'row',
			components: {
				type: 'cell',
				style: comp.get('isVertical')
					? {
							...style,
							height: comp.get('dimension'),
							['line-height']: comp.get('dimension'),
							width: '100%'
					  }
					: {
							...style,
							width: comp.get('dimension'),
							['min-width']: comp.get('dimension')
					  },
				components: []
			}
		};
	}

	return [];
};
