import { merge } from 'datatalks-utils';
import listTrait from './trait/_trait';

export default comp => {
	const traitOptions = merge(
		comp.get('traitDefaultOptions') || {},
		comp.get('traitOptions') || {}
	);

	const result = [listTrait(traitOptions)];

	return result;
};
