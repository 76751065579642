import { deleteProps, merge } from 'datatalks-utils';

/**
 * Validates a style object by removing any properties with null values.
 * @param {Object} style - The style object to validate.
 * @return {Object} - The validated style object.
 */
export function validateStyleObj(style) {
	var result = {};
	for (var prop in style) {
		if (style[prop] != null) {
			result[prop] = style[prop];
		}
	}
	return result;
}

/**
 * Validates the border properties of a style object. If the border properties would make it invisible,
 *  they are removed and `border:none` is set.
 * @param {Object} styleObject - The style object to validate.
 * @param {boolean} [mutate=false] - Indicates whether to mutate the style object or not.
 * @return {Object} - The updated style object if mutate is true, otherwise a copy of it.
 */
export function validateBorder(styleObject, mutate = false) {
	if (
		(styleObject['border-width'] &&
			parseFloat(styleObject['border-width']) < 0.1) ||
		styleObject['border-color'] === 'transparent' ||
		styleObject['border-style'] === 'none' ||
		styleObject['border-style'] === 'hidden'
	) {
		if (mutate) {
			deleteProps(
				styleObject,
				['border-width', 'border-color', 'border-style'],
				mutate
			);
			styleObject.border = 'none';
			return styleObject;
		} else {
			return deleteProps(
				styleObject,
				['border-width', 'border-color', 'border-style'],
				mutate
			);
		}
	}

	return mutate ? styleObject : merge({}, styleObject);
}

export default {
	validateStyleObj,
	validateBorder
};
