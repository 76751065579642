import { toLower, capitalize } from 'lodash-es';
import { cssFontWeights, merge } from 'datatalks-utils';
import { Dropdown } from 'datatalks-ui';

export default options => {
	const defaults = {
		extendedClasses: '',
		value: 'normal',
		onChange: null // (value)
	};

	options = merge(defaults, options);

	return new Dropdown({
		extendedClasses: options.extendedClasses,
		items: cssFontWeights.map(weight => {
			return {
				content:
					typeof weight === 'string' ? capitalize(weight) : weight,
				value: weight,
				active: toLower(options.value) === toLower(weight)
			};
		}),
		onChange: (dropdown, activeItem) => {
			if (typeof options.onChange === 'function') {
				options.onChange(activeItem.getValue());
			}
		}
	});
};
