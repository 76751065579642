import { $getRoot, $getSelection } from 'lexical';
import { parseStyleString, cssStyleObjectToString } from 'datatalks-utils';

/**
 * Retrieves the text colors from the provided editor state.
 *
 * @param {EditorState} editorState - The editor state object.
 * @return {String[] | null} The text colors array or null if no text color is found.
 */
function getTextColorsFromEditorState(editorState) {
	const textColors = [];

	editorState.read(() => {
		const root = $getRoot();
		const selection = $getSelection();
		if (selection || root) {
			const nodes = selection ? selection.getNodes() : getAllNodes(root);

			if (nodes.length) {
				nodes.forEach(node => {
					if (node.getStyle) {
						let style = node.getStyle();
						if (style) {
							if (typeof style === 'string') {
								style = parseStyleString(style);
							}
							if (style.color) {
								textColors.push(style.color);
							}
						}
					}
				});
			}
		}
	});

	return textColors;
}

/**
 * Returns the style options' attribute to look for based on the given node.
 *
 * @param {Object} node - The node to check.
 * @return {string|null} - The style attribute to look for, or null if none found.
 */
function getNodeStyleAttributeToLookFor(node) {
	if (node.getType) {
		if (node.getType() === 'link') return 'link';
		if (node.getType() === 'paragraph') return 'p';
	}

	if (node.getTag) {
		if (['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.getTag())) {
			return node.getTag();
		} else {
			return null;
		}
	}

	return null;
}

/**
 * Retrieves the default colors for the node types that are being used in the provided text editor.
 *
 * @param {TextEditor} textEditor - The text editor object.
 * @return {Array<string>} - An array of the default colors used in the text editor.
 */
function getUsedTypesColors(textEditor) {
	const colors = [];
	textEditor.editor.read(() => {
		const root = $getRoot();
		if (root) {
			const nodes = getAllNodes(root);
			nodes.forEach(node => {
				const styleAttribute = getNodeStyleAttributeToLookFor(node);
				if (
					styleAttribute &&
					textEditor.options.iframeStyleOptions[styleAttribute]?.color
				) {
					colors.push(
						textEditor.options.iframeStyleOptions[styleAttribute]
							.color
					);
				}
			});
		}
	});
	return colors;
}

/**
 * Recursively retrieves all nodes from the root node.
 *
 * @param {LexicalNode} node - The root node.
 * @return {Array<LexicalNode>} An array of all nodes.
 */
function getAllNodes(node) {
	let nodes = [node];
	if (node.getChildren) {
		node.getChildren().forEach(child => {
			nodes = nodes.concat(getAllNodes(child));
		});
	}
	return nodes;
}
/**
 * Applies the provided styles to the ObjectNodes in the provided Selection.
 * Will update partially selected TextNodes by applying the styles to the whole ObjectNode's text.
 * @param {RangeSelection} selection - The selected node(s) to update.
 * @param {Object} patch - The patch to apply, which can include multiple styles.
 */
function patchStyleObjectNode(selection, patch) {
	selection.getNodes().forEach(node => {
		if (node.getType() === 'object') {
			objectPatchStyle(node, patch);
		}
	});
}

/**
 * Applies a patch of style changes to a given node.
 *
 * @param {Object} node - The target node to apply styles to.
 * @param {Object} patch - An object representing the style changes. The keys are CSS property names and the values are either the new style values, functions to compute the new values, or null to remove the styles.
 */
function objectPatchStyle(node, patch) {
	const prevStyles =
		parseStyleString(
			'getStyle' in node ? node.getStyle() : node.style || ''
		) || {};

	const newStyles = Object.entries(patch).reduce((styles, [key, value]) => {
		if (typeof value === 'function') {
			styles[key] = value(prevStyles[key], node);
		} else if (value === null) {
			delete styles[key];
		} else {
			styles[key] = value;
		}
		return styles;
	}, { ...prevStyles } || {});
	const newCSSText = cssStyleObjectToString(newStyles);
	node.setStyle(newCSSText);
}

export {
	getTextColorsFromEditorState,
	getUsedTypesColors,
	getNodeStyleAttributeToLookFor,
	getAllNodes,
	patchStyleObjectNode,
	objectPatchStyle
};
