export const COMPONENTS_DEFAULTS = {
	removable: true,
	draggable: true,
	droppable: false,
	badgable: true,
	highlightable: true,
	copyable: true,
	resizable: false,
	editable: false,
	layerable: true,
	selectable: true,
	hoverable: true,
	locked: false
};
