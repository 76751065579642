import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import trait from './trait/_trait';
import render from './_render';
import baseMethods from '../common/methods/_baseMethods';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import { merge } from 'datatalks-utils';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		attributes: {},
		spacingBetweenElements: '12px',
		cssClass: 'gjs-comp-call-to-action',
		width: '100%',
		textContentPh: 'This should be a call to action heading.',
		buttonPh: 'Button text',
		textAlignment: 'left',
		textStyle: {
			color: null
		},
		name: 'Call To Action',
		displayText: true,
		displayButton: true,
		buttonPh: 'Button text',
		buttonBackgroundColor: null,
		buttonHeight: null,
		buttonWidth: '100%',
		buttonFontFamily: null,
		buttonColor: null,
		buttonFontWeight: null,
		buttonBorderWidth: '0px',
		buttonBorderStyle: 'solid',
		buttonBorderColor: 'transparent',
		buttonAlignment: 'right',
		buttonBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		buttonUseBorderRadius: false,
		buttonLetterSpacing: 'normal',
		buttonNormalLetterSpacing: true,
		buttonDefaultLetterSpacingLength: '0.1em',
		buttonLineHeight: null,
		buttonHasOutlookBorderRadius: false,
		buttonOutlookWidth: '150px',
		toHtmlOptions: {},
		buttonContentRatio: '0.3'
	};

	const options = merge(
		COMPONENTS_DEFAULTS,
		getComponentsDefaultsByType(),
		defaults,
		componentOptions
	);

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				name: options.name,
				...options,
				tagName: 'table',
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				style: {
					width: options.width
				},
				textContent: `<h4>${options.textContentPh}</h4>`,
				buttonText: options.buttonPh,
				buttonNormalLetterSpacing:
					options.buttonLetterSpacing === 'normal',
				components: comp => comp.componentRender(comp),
				traits(component) {
					const result = [trait()];

					return result;
				}
			}),

			init() {
				this.setStyle({
					...this.getStyle(),
					width: this.get('width')
				});
			},

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			componentRender: render,

			setNormalLetterSpacing(isNormal) {
				this.set('buttonNormalLetterSpacing', isNormal);
			},

			setLetterSpacing(value) {
				this.setNormalLetterSpacing(value === 'normal');
				this.setAndRerender('buttonLetterSpacing', value);
				this.components(this.componentRender(this));
			},

			setAndRerender(prop, value) {
				this.set(prop, value);
				this.components(this.componentRender(this));
			},

			rerender() {
				this.components(this.componentRender(this));
			},
			...baseMethods(componentOptions, editor)
		}
	};
};
