import {
	autoUpdate,
	computePosition,
	offset,
	shift,
	flip
} from '@floating-ui/dom';
import { merge } from 'datatalks-utils';

/* eslint-disable require-jsdoc */
export default class Modal {
	constructor(options) {
		const defaults = {
			triggerElement: null,
			targetElement: null,
			content: null,
			isStartOpen: false,
			classPrefix: 'eb-',
			modalClass: 'modal',
			modalExtendedClasses: null,
			modalDialogClass: 'modal-dialog',
			modalDialogExtendedClasses: null,
			placement: null
		};

		this.options = merge(defaults, options);

		this.modal = null;
		this.modalDialog = null;
		this.triggerElement = this.options.triggerElement;
		this.targetElement =
			this.options.targetElement ||
			document.querySelector('#modalContainer');
		if (!this.targetElement) {
			this.targetElement = document.createElement('div');
			this.targetElement.id = 'modalContainer';
			document.body.appendChild(this.targetElement);
		}
		this.content = this.options.content;

		this.init();
	}

	/**
	 * Initialize the modal.
	 */
	init() {
		if (!this.content) {
			console.warn('Modal content is missing');
			return;
		}

		this.draw();

		if (this.triggerElement && this.options.placement) {
			this.position();
		}

		if (this.triggerElement) {
			this.triggerElement.addEventListener('click', () => {
				this.show();
			});
		}
		if (this.options.isStartOpen) {
			this.open();
		}
	}

	draw() {
		this.modal = document.createElement('div');
		this.modal.classList.add(
			this.options.classPrefix + this.options.modalClass
		);
		if (this.options.modalExtendedClasses) {
			this.modal.classList.add(...this.options.modalExtendedClasses);
		}
		this.modal.addEventListener('click', e => {
			this.close();
		});

		this.modalDialog = document.createElement('div');
		this.modalDialog.classList.add(
			this.options.classPrefix + this.options.modalDialogClass
		);
		if (this.options.modalDialogExtendedClasses) {
			this.modalDialog.classList.add(
				...this.options.modalDialogExtendedClasses
			);
		}
		this.modalDialog.addEventListener('click', e => {
			e.stopPropagation();
		});

		this.modalDialog.appendChild(this.content);
		this.modal.appendChild(this.modalDialog);
	}

	position() {
		autoUpdate(this.triggerElement, this.modal, () => {
			computePosition(this.triggerElement, this.modal, {
				placement: this.options.placement,
				middleware: [offset(10), flip(), shift({ padding: 5 })]
			}).then(({ x, y }) => {
				Object.assign(this.modal.style, {
					left: `${x}px`,
					top: `${y}px`
				});
			});
		});
	}

	open() {
		if (this.targetElement.hasChildNodes) {
			this.targetElement.innerHTML = '';
		}

		this.targetElement.appendChild(this.modal);

		this.modal.classList.add(
			this.options.classPrefix + this.options.modalClass + '--open'
		);
	}

	/**
	 * Close the modal.
	 */
	close() {
		this.modal.classList.remove(
			this.options.classPrefix + this.options.modalClass + '--open'
		);
	}
}
