/* eslint-disable require-jsdoc */
import { Accordion } from 'datatalks-ui';
import { htmlToPlainText, setContent } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { merge } from 'datatalks-utils';

function getAccordionTitle(obj, item, options) {
	if (typeof options.accordionTitleTemplate === 'function') {
		return options.accordionTitleTemplate.call(null, obj, item, options);
	} else {
		const accordionDeleteBtn = getIcon('delete-bin-line');
		accordionDeleteBtn.addEventListener('click', () => {
			obj.component.deleteLink.call(obj.component, item);
			obj.trait.removeItem(item);
		});
		accordionDeleteBtn.setAttribute('eb-data-action', 'delete');

		return setContent(document.createElement('div'), [
			`
				<i eb-data-action="drag" class="eb-i eb-i-draggable"></i>
				<span>${htmlToPlainText(item.content)}</span>
			`,
			accordionDeleteBtn
		]);
	}
}

export default (obj, item, options, customTrait = {}) => {
	const defaults = {
		accordionContentTemplate: null,
		accordionTitleTemplate: null,
		accordionColor: 'grey-outline'
	};

	options = merge(defaults, options);

	let accordionContent;

	if (typeof options.accordionContentTemplate === 'function') {
		accordionContent = options.accordionContentTemplate.call(
			null,
			obj,
			item,
			options,
			customTrait
		);
	}

	const accordion = new Accordion({
		title: getAccordionTitle(obj, item, options),
		content: accordionContent,
		accordionColor: options.accordionColor
	});

	obj.component.on('component:update:item', updatedItem => {
		if (updatedItem === item)
			accordion.setTitle(getAccordionTitle(obj, item, options));
	});

	return accordion.getEl();
};
