import { addClassesString, debounce, setContent, merge } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { Button, InputNumber, InputLabel } from 'datatalks-ui';
/* eslint-disable require-jsdoc */
export default class LinkedInputs {
	constructor(options = {}) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'linked-inputs',
			extendedClasses: '',
			items: [],
			linkIconSize: 'md',
			startLinked: false,
			onLink: null,
			autoAlphaInput: true,
			useLabels: true
		};

		defaults.linkIcon = getIcon('link', {
			size: options.linkIconSize || defaults.linkIconSize
		});

		this.options = merge(defaults, options);

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.autoAlphaInput = this.options.autoAlphaInput;
		this.alphaInput = null;
		this.isLinked = this.options.startLinked;
		this.element = null;
		this.items = this.options.items;
		this.init();
	}

	init() {
		this.convertItems();
		this.createWrapper();
		this.createItemsContainer();
		this.createLinkButton();
		this.drawItems();
		this.draw();
	}

	createWrapper() {
		this.element = document.createElement('div');
		this.element.className = this.className;
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
	}

	createItemsContainer() {
		this.itemsContainer = document.createElement('div');
		this.itemsContainer.className = `${this.className}__items`;
	}

	createLinkButton() {
		this.linkButton = new Button({
			content: this.options.linkIcon,
			extendedClasses: `${this.className}__link`,
			buttonVariant: 'toggle',
			active: this.isLinked
		});

		this.linkButton.getEl().addEventListener('click', () => {
			this.handleLinkButtonClick();
		});
	}

	handleLinkButtonClick() {
		this.isLinked = !this.isLinked;
		this.linkButton.setActive(this.isLinked);
		if (this.isLinked) this.syncInputs();
		if (typeof this.options.onLink === 'function')
			this.options.onLink.call(this, this, this.alphaInput?.getValue());
	}

	syncInputs() {
		if (this.alphaInput) {
			this.items
				.map(item => item.input)
				.forEach(input => {
					if (input != this.alphaInput)
						input.setValue(this.alphaInput?.getValue());
				});
		}
	}

	draw() {
		this.element.append(this.itemsContainer, this.linkButton.getEl());
	}

	drawItems() {
		setContent(
			this.itemsContainer,
			this.items.map(item =>
				this.options.useLabels ? item.getEl() : item.input.getEl()
			)
		);
	}

	convertItems() {
		const linkedInputs = this;
		linkedInputs.items = linkedInputs.items.map(obj => {
			const extendedClasses = obj.input.extendedClasses
				? `${obj.input.extendedClasses} ${linkedInputs.className}__item`
				: `${linkedInputs.className}__item`;

			const inputExtendedClasses = obj.input.inputExtendedClasses
				? `${obj.input.inputExtendedClasses} ${linkedInputs.className}__input`
				: `${linkedInputs.className}__input`;

			const item = {
				label: obj.label || null,
				input:
					obj.input instanceof InputNumber
						? obj
						: new InputNumber({
								...obj.input,
								extendedClasses,
								inputExtendedClasses
						  })
			};

			if (obj.alphaInput) {
				linkedInputs.alphaInput = item.input;
				linkedInputs.autoAlphaInput = false;
			}

			item.input.on(
				'change',
				linkedInputs.handleItemClick.bind(linkedInputs, item.input)
			);

			return linkedInputs.options.useLabels
				? new InputLabel({
						...item,
						labelExtendedClasses: `${linkedInputs.className}__label`
				  })
				: item;
		});

		linkedInputs.alphaInput =
			linkedInputs.alphaInput || linkedInputs.items[0].input;
	}

	handleItemClick(item, itemValue, itemUnit, itemInputValue, userInput) {
		if (this.autoAlphaInput) this.alphaInput = item;
		if (this.isLinked && userInput) {
			const sync = debounce(this.syncInputs.bind(this), 500);
			sync();
		}
	}

	getEl() {
		return this.element;
	}
}
