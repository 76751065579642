/* eslint-disable require-jsdoc */
import { addClassesString } from 'datatalks-utils';

export default class ToggleButton {
	constructor(options) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'toggle-btn',
			extendedClasses: '',
			disabled: false,
			onChange: null,
			startActive: false,
			clickStopPropagation: false
		};

		this.options = {
			...defaults,
			...options
		};

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.isDisable = this.options.disabled;
		this.isActive = this.options.startActive;
		this.element = null;
		this.init();
	}

	init() {
		this.createElement();
		this.setActive(this.isActive);
	}

	createElement() {
		const toggle = this;
		toggle.element = document.createElement('div');
		toggle.element.className = toggle.className;
		if (toggle.options.extendedClasses)
			addClassesString(toggle.element, toggle.options.extendedClasses);
		toggle.element.addEventListener('click', e => {
			if (toggle.options.clickStopPropagation) e.stopPropagation();
			toggle.handleOnClick.call(toggle);
		});
	}

	handleOnClick() {
		this.toggle();
		if (typeof this.options.onChange === 'function')
			this.options.onChange.call(this, this.isActive);
	}

	toggle() {
		this.setActive(!this.isActive);
	}

	setActive(isActive = true) {
		this.isActive = isActive;
		this.element.setAttribute('data-toggled', this.isActive);
	}

	getActive() {
		return this.isActive;
	}

	setDisable(isDisable = true) {
		this.isDisable = isDisable;
		this.element.setAttribute('data-toggle-disabled', this.isDisable);
	}

	getDisable() {
		return this.isDisable;
	}

	getEl() {
		return this.element;
	}
}
