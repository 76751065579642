import { setContent } from 'datatalks-utils';
import { SectionItem } from 'datatalks-ui';
import { Dropdown, TextEditor } from 'email-builder-ui';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../../common/functions/_textEditorCommonActions';
import { merge } from 'datatalks-utils';

export default (obj, item, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const editor = obj.component.getEditor();
	const defaults = {
		traitsVisibility: {
			text: true,
			url: true,
			target: true
		},
		textEditorProp: `link${item.id != null ? `_${item.id}_` : ''}Editor`
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.accordionContentOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.accordionContentOptions
		);
	}

	// URL input
	const urlInput = document.createElement('input');
	urlInput.addEventListener('input', e => {
		obj.component.changeLinkUrl.call(obj.component, item, e.target.value);
	});
	urlInput.className = 'eb-input';
	if (item.href) {
		urlInput.value = item.href;
	} else {
		urlInput.placeholder = item.url || 'URL';
	}

	if (options.traitsVisibility.text && !customTrait[options.textEditorProp]) {
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent: item.content,
			initialState: obj.component.getItemProp(item, 'editorState'),
			onChange: (html, editorState) => {
				obj.component.changeLinkText.call(
					obj.component,
					item,
					html,
					false
				);
				obj.component.setItemProp(item, 'editorState', editorState);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp]
		);
	}

	return setContent(document.createElement('div'), [
		options.traitsVisibility.text &&
			new SectionItem({
				label:
					localize('components.names.text', true) || localize('Text'),
				content: customTrait[options.textEditorProp].getEl()
			}).getEl(),
		options.traitsVisibility.url &&
			new SectionItem({
				label: localize('Add a URL'),
				content: urlInput
			}).getEl(),
		options.traitsVisibility.target &&
			new SectionItem({
				label: localize('Open Mode'),
				content: new Dropdown({
					items: [
						{
							content: localize('Open in a new tab/window'),
							value: '_blank',
							active: item.target === '_blank'
						},
						{
							content: localize('Open in the current tab'),
							value: '_self',
							active: item.target === '_self'
						}
					],
					onChange: (dropdown, activeItem) => {
						obj.component.changeLinkOpenMode.call(
							obj.component,
							item,
							activeItem.getValue()
						);
					}
				}).getEl()
			}).getEl()
	]);
};
