import { merge, cssStyleObjectToString } from 'datatalks-utils';

import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default (innerHtml, options) => {
	const defaults = {
		debug: false,
		topMargin: '0px',
		rightMargin: '0px',
		bottomMargin: '0px',
		leftMargin: '0px',
		hasMargins: false,
		width: '100%',
		backgroundColor: null
	};

	options = merge(defaults, options);

	const tableStyle = {
		width: options.width
	};

	const cellStyle = {
		'background-color': options.backgroundColor,
		'padding-top': options.hasMargins ? options.topMargin || 0 : 0,
		'padding-right': options.hasMargins ? options.rightMargin || 0 : 0,
		'padding-bottom': options.hasMargins ? options.bottomMargin || 0 : 0,
		'padding-left': options.hasMargins ? options.leftMargin || 0 : 0
	};

	const html = `
		<table data-eb-name="margins-first" style="${cssStyleObjectToString(
			validateStyleObj(tableStyle)
		)}">
			<tr>
				<td data-eb-name="margins-last" style="${cssStyleObjectToString(
					validateStyleObj(cellStyle)
				)}">
					${innerHtml}
				</td>
			</tr>
		</table>
	`;

	if (options.debug) console.log('Margins common template HTML: ', html);

	return html;
};
