/* eslint-disable require-jsdoc */
import { camelCase } from 'lodash-es';
import traitsTabs from './_exportComponentTabs';
import itemsListMutation from './_itemsListMutation';
import { setContent, merge, endsWith } from 'datatalks-utils';
import accordionContentTemplate from './List/components/contentTab/_accordionContent';
import accordionTitleTemplate from './List/components/contentTab/_itemAccordionTitle';

class CustomTrait {
	constructor(obj, options, type) {
		const defaults = {
			cssClass: 'gjs-trait',
			extendedClasses: '',
			onInit: null,
			startWithContentTabActive: true,
			mutateOptions: {
				styleTabContent: traitsTabs[camelCase(type)].style,
				contentTabContent: traitsTabs[camelCase(type)].content,
				contentTabOptions: {},
				styleTabOptions: {}
			},
			hasList: false,
			itemsListMutationOptions: {
				listItemElementTemplateOptions: {
					accordionContentTemplate,
					// TODO: needs to be dynamic depending on list item type
					accordionTitleTemplate,
					hasImageUploader: true,
					hasTitleEditor: true,
					hasDescriptionEditor: true,
					hasButtonEditor: true,
					buttonProp: 'buttonText',
					titleProp: 'title'
				}
			}
		};

		this.options = merge(
			defaults,
			options,
			obj.trait.model?.attributes?.options,
			obj.trait.attributes?.options
		);

		this.obj = obj;

		this.init();
	}

	init() {
		this.wrapper = document.createElement('div');

		if (this.options.cssClass) {
			this.wrapper.classList.add(this.options.cssClass);
		}

		if (this.options.extendedClasses) {
			this.wrapper.classList.add(this.options.extendedClasses);
		}

		this.mutate();

		if (this.options.hasList) {
			itemsListMutation(
				this.obj,
				this.options.itemsListMutationOptions,
				this
			);
		}

		if (typeof this.options.onInit === 'function')
			this.options.onInit.call(this.obj.trait, this.obj);

		this.draw();
	}

	mutate() {
		this.obj.trait.set('wrapper', this.wrapper);

		if (
			typeof this.options.mutateOptions.styleTabContent === 'function' &&
			typeof this.options.mutateOptions.contentTabContent === 'function'
		) {
			this.obj.trait.set('view', 'content');
			this.obj.trait.set('setView', view => {
				this.obj.trait.set('view', view);
				this.obj.trait.get('updateView')();
			});
			this.obj.trait.set('updateView', () => {
				if (this.obj.trait.get('view') === 'content') {
					setContent(
						this.obj.trait.get('wrapper'),
						this.options.mutateOptions.contentTabContent.call(
							null,
							this.obj,
							this.options.mutateOptions.contentTabOptions,
							this
						)
					);
				} else {
					setContent(
						this.obj.trait.get('wrapper'),
						this.options.mutateOptions.styleTabContent.call(
							null,
							this.obj,
							this.options.mutateOptions.styleTabOptions,
							this
						)
					);
				}
			});
		} else {
			console.error(
				'You need to provide the content for both style and content tabs'
			);
		}
	}

	draw() {
		this.obj.trait.get('setView')(
			Object.keys(
				this.obj.component.getEditor().Commands.getActive()
			).includes('open-style-traits')
				? 'style'
				: 'content'
		);
	}

	dispose() {
		this.destroyTextEditors();
		this.destroyColorPickers();
	}

	destroyColorPickers() {
		const ct = this;
		const colorPickerProps = Object.getOwnPropertyNames(ct).filter(prop =>
			endsWith(prop, 'Cp', true)
		);

		colorPickerProps
			.map(prop => ct[prop])
			.forEach(cp => {
				cp.destroy();
			});

		colorPickerProps.forEach(prop => delete ct[prop]);
	}

	destroyTextEditors() {
		const ct = this;
		const editorProps = Object.getOwnPropertyNames(ct).filter(prop =>
			endsWith(prop, 'Editor', true)
		);

		editorProps
			.map(prop => ct[prop])
			.forEach(rte => {
				rte.destroy();
			});

		editorProps.forEach(prop => delete ct[prop]);
	}
}

export default (options = {}, type, obj) => {
	if (obj.component.get('hasTraits') !== false) {
		const trait = new CustomTrait(obj, options, type);
		obj.component.set('getCustomTrait', () => trait);
		obj.component
			.getEditor()
			.on('component:deselected', function (component) {
				trait.dispose();
			});
		return trait.wrapper;
	} else {
		console.warn(
			`Component ${
				obj.component.get('name') ||
				obj.component.get('type') ||
				obj.component.get('tagName')
			} has no traits`
		);
		const el = document.createElement('div');
		el.innerHTML = 'No traits available';
		return el;
	}
};

// export default (options = {}, type, obj) => {
// 	const defaults = {
// 		cssClass: 'gjs-trait',
// 		extendedClasses: '',
// 		onInit: null,
// 		startWithContentTabActive: true,
// 		mutateOptions: {
// 			styleTabContent: traitsTabs[camelCase(type)].style,
// 			contentTabContent: traitsTabs[camelCase(type)].content,
// 			contentTabOptions: {},
// 			styleTabOptions: {}
// 		},
// 		hasList: false,
// 		itemsListMutationOptions: {}
// 	};

// 	options = merge(
// 		defaults,
// 		options,
// 		obj.trait.model?.attributes?.options,
// 		obj.trait.attributes?.options
// 	);

// 	mutate(obj, options.mutateOptions);

// 	if (options.hasList) {
// 		itemsListMutation(obj, options.itemsListMutationOptions);
// 	}

// 	// wrapper
// 	const wrapper = document.createElement('div');

// 	if (options.cssClass) {
// 		wrapper.classList.add(options.cssClass);
// 	}

// 	if (options.extendedClasses) {
// 		wrapper.classList.add(options.extendedClasses);
// 	}

// 	if (typeof options.onInit === 'function')
// 		options.onInit.call(obj.trait, obj);

// 	obj.trait.set('wrapper', wrapper);
// 	obj.trait.get('setView')(
// 		Object.keys(obj.component.getEditor().Commands.getActive()).includes(
// 			'open-style-traits'
// 		)
// 			? 'style'
// 			: 'content'
// 	);
// 	return wrapper;
// };
