import { COMPONENTS_DEFAULTS } from '../../../../config/_config';
import render from './_render';
import baseMethods from '../../common/methods/_baseMethods';
import { getComponentsDefaultsByType } from '../../../componentsDefaults/_componentsDefaults';
import { merge } from 'datatalks-utils';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = getComponentsDefaultsByType('space');

	const options = {
		...COMPONENTS_DEFAULTS,
		...defaults,
		...componentOptions
	};
	const style = options.isVertical
		? {
				width: '100%'
		  }
		: {
				width: options.dimension
		  };

	return {
		model: {
			defaults: () => ({
				name: options.name,
				...options,
				tagName: options.isVertical ? 'tr' : 'td',
				attributes: {
					class: options.cssClass
				},
				style,
				components: comp => comp.componentRender(comp)
			}),

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			componentRender: render,
			...baseMethods(componentOptions, editor)
		}
	};
};
