import {
	merge,
	addClassesString,
	setContent,
	capitalize
} from 'datatalks-utils';
import { Accordion, RadioButtonGroup, SectionItem } from 'datatalks-ui';

import getStyleTabOptions from '../../common/_styleTabOptions';

import backgroundAccordion from './styleTab/_backgroundAccordion';
import imageAccordion from './styleTab/_imageAccordion';
import linksAccordion from './styleTab/_linksAccordion';
import marginsAccordion from './styleTab/_marginsAccordion';
import spacingAccordion from './styleTab/_spacingAccordion';
import borderAccordion from '../../commonAccordions/style/_border';

export default (obj, options, customTrait) => {
	const localize = obj.component.localize;
	const defaults = {
		accordionsVisibility: {
			layout: true,
			background: true,
			border: true,
			margins: true,
			spacing: true,
			image: true,
			links: true
		},
		extendedClasses: '',
		backgroundAccordionOptions: {},
		marginsAccordionOptions: {},
		spacingAccordionOptions: {
			linksWidthSliderOptions: {
				storeOptions: {
					attrNames: {
						slider: 'linksWidthSlider'
					}
				}
			}
		},
		imageAccordionOptions: {
			imageSliderOptions: {
				onChange: value => {
					obj.component.setAndRerender.call(
						obj.component,
						'imageWidth',
						value
					);

					if (
						obj.component.get('layoutVariant') === 0 &&
						customTrait?.linksWidthSlider?.noUiSlider
					) {
						const slider = customTrait.linksWidthSlider.noUiSlider;
						if (slider.get() > 100 - parseFloat(value)) {
							slider.set(100 - parseFloat(value));
						}
						slider.updateOptions({
							range: {
								min: slider.options.range.min,
								max: 100 - parseFloat(value)
							}
						});
					}
				}
			}
		},
		linksAccordionOptions: {
			traitsVisibility: {
				width: false,
				align: false
			}
		},
		layoutAccordionOptions: {
			accordion: {
				title:
					localize('traits.styles.sections.layout', true) ||
					localize('Layout'),
				color: 'grey',
				type: 'extend',
				startOpen: true
			},
			layout: {
				label: localize("Choose the header's image position"),
				value: obj.component.get('layoutVariant'),
				onChange: (allSelected, changedItem) => {
					obj.component.setAndRerender(
						'layoutVariant',
						changedItem.getValue()
					);

					if (customTrait?.linksWidthSlider?.noUiSlider) {
						const slider = customTrait.linksWidthSlider.noUiSlider;
						if (obj.component.get('layoutVariant') === 0) {
							const maxWidth =
								100 -
								parseFloat(obj.component.get('imageWidth'));
							if (slider.get() > maxWidth) {
								slider.set(maxWidth);
							}
							slider.updateOptions({
								range: {
									min: slider.options.range.min,
									max: maxWidth
								}
							});
						} else {
							slider.updateOptions({
								range: {
									min: slider.options.range.min,
									max: 100
								}
							});
						}
					}
				}
			}
		}
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	return addClassesString(
		setContent(document.createElement('div'), [
			options.accordionsVisibility.layout
				? new Accordion({
						title: options.layoutAccordionOptions.accordion.title,
						accordionColor:
							options.layoutAccordionOptions.accordion.color,
						accordionType:
							options.layoutAccordionOptions.accordion.type,
						startOpen:
							options.layoutAccordionOptions.accordion.startOpen,
						content: new SectionItem({
							label: options.layoutAccordionOptions.layout.label,
							content: new RadioButtonGroup({
								singleSelection: true,
								allowNoSelection: false,
								items: [
									{
										value: 0,
										label: capitalize(
											localize('misc.leftSide', true) ||
												localize('Left Side')
										),
										selected:
											options.layoutAccordionOptions
												.layout.value === 0
									},
									{
										value: 1,
										label: capitalize(
											localize('misc.top', true) ||
												localize('Top')
										),
										selected:
											options.layoutAccordionOptions
												.layout.value === 1
									}
								],
								onChange:
									options.layoutAccordionOptions.layout
										.onChange
							}).getEl()
						}).getEl()
				  }).getEl()
				: null,
			options.accordionsVisibility.background
				? backgroundAccordion
						.call(
							null,
							obj,
							options.backgroundAccordionOptions,
							customTrait
						)
						?.getEl()
				: null,
			options.accordionsVisibility.margins
				? marginsAccordion
						.call(
							null,
							obj,
							options.marginsAccordionOptions,
							customTrait
						)
						?.getEl()
				: null,
			options.accordionsVisibility.border
				? borderAccordion(getStyleTabOptions(obj, 'border')).getEl()
				: null,
			options.accordionsVisibility.spacing
				? spacingAccordion
						.call(
							null,
							obj,
							options.spacingAccordionOptions,
							customTrait
						)
						?.getEl()
				: null,
			options.accordionsVisibility.image
				? imageAccordion
						.call(
							null,
							obj,
							options.imageAccordionOptions,
							customTrait
						)
						?.getEl()
				: null,
			options.accordionsVisibility.links
				? linksAccordion
						.call(
							null,
							obj,
							options.linksAccordionOptions,
							customTrait
						)
						?.getEl()
				: null
		]),
		options.extendedClasses
	);
};
