/* eslint-disable require-jsdoc */
import { merge } from 'lodash-es';
import { addClassesString, setContent, EventEmitter } from 'datatalks-utils';
import { Dropdown } from 'datatalks-ui';
import DropdownItem from './Dropdown/_dropdownItem';

export default class ChoosableSection {
	constructor(options) {
		const defaults = {
			className: 'choosable-section',
			classPrefix: 'eb-',
			extendedClasses: '',
			tagName: 'div',
			label: null,
			items: [
				{
					active: false,
					label: '',
					content: '',
					onSelect: null,
					onDeselect: null
				}
			],
			labelExtendedClasses: '',
			contentExtendedClasses: '',
			onChange: null,
			dropdownLabel: null,
			dropdownLabelExtendedClasses: ''
		};

		this.options = merge(defaults, options);

		this.element = null;
		this.content = null;
		this.label = null;
		this.cssClass = `${this.options.classPrefix}${this.options.className}`;

		this.eventEmitter = new EventEmitter();

		this.init();
	}

	init() {
		this.createElements();

		if (typeof this.options.onChange === 'function')
			this.on('change', this.options.onChange);

		this.draw();
	}

	createElements() {
		this.element = addClassesString(
			document.createElement(this.options.tagName),
			`${this.cssClass}${
				this.options.extendedClasses
					? ` ${this.options.extendedClasses}`
					: ''
			}`
		);

		this.label = setContent(
			addClassesString(
				document.createElement('label'),
				`eb-label ${this.cssClass}__label${
					this.options.labelExtendedClasses
						? ` ${this.options.labelExtendedClasses}`
						: ''
				}`
			),
			this.options.label || ''
		);

		if (this.options.dropdownLabel) {
			this.dropdownLabel = setContent(
				addClassesString(
					document.createElement('label'),
					`eb-label ${this.cssClass}__toggle-label${
						this.options.dropdownLabelExtendedClasses
							? ` ${this.options.dropdownLabelExtendedClasses}`
							: ''
					}`
				),
				this.options.dropdownLabel || ''
			);
		}

		this.content = addClassesString(
			document.createElement('div'),
			`${this.cssClass}__content${
				this.options.contentExtendedClasses
					? ` ${this.options.contentExtendedClasses}`
					: ''
			}`
		);

		this.createDropdown();
		if (typeof this.dropdown?.activeItem?.onItemClick === 'function') {
			this.dropdown.activeItem.onItemClick();
		}
	}

	setContent(content) {
		setContent(this.content, content);
	}

	createDropdown() {
		const ts = this;
		ts.dropdown = new Dropdown({
			size: 'sm',
			arrowSize: 'sm',
			items: ts.options.items.map(
				item =>
					new DropdownItem({
						active: item.active,
						content: item.label,
						onSelect: item.onSelect,
						onDeselect: item.onDeselect,
						onClick: () => {
							ts.setContent(
								typeof item.content === 'function'
									? item.content()
									: item.content
							);
							ts.eventEmitter.emit('change', item);
						}
					})
			)
		});
	}

	draw() {
		setContent(this.element, [
			addClassesString(
				setContent(document.createElement('div'), [
					this.label,
					this.options.dropdownLabel
						? addClassesString(
								setContent(document.createElement('div'), [
									this.dropdownLabel,
									this.dropdown.getEl()
								]),
								`${this.cssClass}__dropdown-label-wrapper`
						  )
						: this.dropdown.getEl()
				]),
				`${this.cssClass}__label-wrapper`
			),
			this.content
		]);
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
