import { LinkedInputs } from 'datatalks-ui';
import { allEqual } from 'datatalks-utils';

export default (obj, options) => {
	return new LinkedInputs({
		startLinked: allEqual(
			['topLeft', 'topRight', 'bottomRight', 'bottomLeft'].map(
				corner => obj.component.get('buttonBorderRadius')[corner]
			)
		),
		items: [
			{
				label: 'Top left',
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').topLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topLeft: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label: 'Top right',
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').topRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							topRight: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label: 'Bottom right',
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').bottomRight
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomRight: inputValue
						});
					},
					unit: 'px'
				}
			},
			{
				label: 'Bottom left',
				input: {
					defaultValue: parseFloat(
						obj.component.get('buttonBorderRadius').bottomLeft
					),
					onChange: (value, unit, inputValue) => {
						obj.component.setAndRerender('buttonBorderRadius', {
							...obj.component.get('buttonBorderRadius'),
							bottomLeft: inputValue
						});
					},
					unit: 'px'
				}
			}
		]
	}).getEl();
};
