import { merge } from 'datatalks-utils';
import getEditorHtml from '../../common/functions/_getEditorHtmlInlined';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		juiceOptions: {}
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, opts = {}) => {
			if (typeof emailBuilder.getOptions().onExportHtml === 'function') {
				emailBuilder
					.getOptions()
					.onExportHtml(getEditorHtml(emailBuilder), emailBuilder);
			}
		}
	};
};
