import { merge } from 'datatalks-utils';
import { InputNumber, Accordion, SectionItem } from 'datatalks-ui';
import { getUnit, setContent } from 'datatalks-utils';
import sliderInput from '../../../commonAccordions/style/common/_sliderOrInput';

export default (obj, options) => {
	const localize = obj.component.localize;
	const defaults = {
		traitsVisibility: {
			space: true,
			ratio: false
		},
		componentProperty: 'spacingBetweenElements',
		accordion: {
			title:
				localize('traits.styles.sections.spacing', true) ||
				localize('Spacing'),
			color: 'grey',
			type: 'extend'
		},
		sectionsLabel: {
			space: localize('Spacing between elements'),
			ratio: localize('Button to content ratio')
		},
		ratio: {
			value: obj.component.get('buttonContentRatio') * 100,
			onChange: value => {
				obj.component.setAndRerender(
					'buttonContentRatio',
					parseFloat(value) / 100
				);
			}
		}
	};

	options = merge(defaults, options);

	const spacingInput = new InputNumber({
		defaultValue: obj.component.get(options.componentProperty),
		unit: getUnit(obj.component.get(options.componentProperty)),
		onChange: (value, unit, inputValue) => {
			obj.component.setAndRerender(options.componentProperty, inputValue);
		}
	}).getEl();

	let ratioSection;

	if (options.traitsVisibility.ratio) {
		ratioSection = new SectionItem({
			label: options.sectionsLabel.ratio,
			content: sliderInput(options.ratio)
		}).getEl();
	}

	const spacingAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.sectionsLabel.space,
			content: spacingInput
		}).getEl(),
		ratioSection
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: spacingAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
