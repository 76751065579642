/* eslint-disable require-jsdoc */
import { merge } from 'datatalks-utils';
import { capitalize, camelCase } from 'lodash-es';
import { Accordion } from 'datatalks-ui';
import { addClassesString, setContent } from 'datatalks-utils';
import textAccordionContent from './_textAccordionContent';

export default (options, emailBuilder) => {
	const { editor } = emailBuilder;
	const localize = emailBuilder.getTranslation.bind(emailBuilder);
	const defaults = {
		accordion: {
			title:
				localize('traits.styles.sections.textStyles') || 'Text styles',
			type: 'extend',
			color: 'grey',
			extendedClasses: null,
			contentExtendedClasses: 'eb-p-0'
		},
		wrapper: {
			useAccordion: true,
			color: {
				active: true
			},
			fontFamily: {
				active: true
			},
			fontFallback: {
				active: true
			},
			size: {
				active: true
			}
		},
		headings: {
			accordion: {
				type: 'extend',
				color: 'grey-outline'
			},
			color: {
				active: true
			},
			lineHeight: {
				active: true
			},
			fontFamily: {
				active: true
			},
			fontFallback: {
				active: true
			},
			size: {
				active: true
			}
		},
		paragraph: {
			color: {
				active: true
			},
			lineHeight: {
				active: true
			},
			fontFamily: {
				active: true
			},
			fontFallback: {
				active: true
			},
			size: {
				active: true
			}
		}
	};

	options = merge(defaults, options);

	function getAccordionTitle(tag) {
		if (tag === 'wrapper')
			return localize('textTypes.default', true) || localize('Email');
		if (tag === 'paragraph')
			return capitalize(
				localize(`textTypes.${tag}`, true) || localize(tag)
			);
		const headingLabel = `Heading ${parseInt(tag.replace('h', ''))}`;
		return capitalize(
			localize(`textTypes.${camelCase(headingLabel)}`, true) ||
				localize(headingLabel)
		);
	}

	function updateFontFamily({
		tag,
		value = null,
		fontType = 'font',
		isInheriting = false
	}) {
		if (!isInheriting)
			emailBuilder.setStyleProp(
				'fonts',
				merge(emailBuilder.styles.fonts, {
					[tag]: { [fontType]: value }
				})
			);

		editor.runCommand('change-general-style-prop', {
			changedProp: 'fontFamily',
			oldValue: emailBuilder.getStyleProp('fonts')[tag]?.font,
			newValue: value
				? emailBuilder.getStyleProp('fonts')[tag].font
				: emailBuilder.getStyleProp('fonts').wrapper.font,
			selector: tag
		});
		emailBuilder.applyGeneralStyles('fonts', tag);
	}

	function handleFontChange({
		tag,
		value = null,
		fontType = 'font',
		isWrapper = false
	}) {
		updateFontFamily({ tag, value, fontType });

		if (isWrapper) {
			types.forEach(inheritedTag => {
				if (
					inheritedTag !== 'wrapper' &&
					(!emailBuilder.styles.fonts[inheritedTag].font ||
						!emailBuilder.styles.fonts[inheritedTag][fontType])
				) {
					updateFontFamily({
						tag: inheritedTag,
						value,
						fontType,
						isInheriting: true
					});
				}
			});
		}
	}

	const types = ['paragraph', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

	function getAccordionContent(tag) {
		const isWrapper = tag === 'wrapper';

		return textAccordionContent(
			{
				fontFamily: {
					value: emailBuilder.styles.fonts[tag].font,
					onChange: value =>
						handleFontChange({ tag, value, isWrapper }),
					onReset:
						!isWrapper &&
						(() => handleFontChange({ tag, isWrapper }))
				},
				fontFallback: {
					value: emailBuilder.styles.fonts[tag].fallback,
					isHidden: !emailBuilder.styles.fonts[tag].font,
					onChange: value =>
						handleFontChange({
							tag,
							value,
							fontType: 'fallback',
							isWrapper
						}),
					onReset:
						!isWrapper &&
						(() =>
							handleFontChange({
								tag,
								fontType: 'fallback',
								isWrapper
							}))
				},
				fontSize: {
					value: emailBuilder.getStyleProp('fontSizes')[tag],
					onChange: value => {
						editor.runCommand('change-general-style-prop', {
							changedProp: 'fontSize',
							oldValue:
								emailBuilder.getStyleProp('fontSizes')[tag],
							newValue: value,
							selector: tag
						});
						emailBuilder.setStyleProp(
							'fontSizes',
							merge(emailBuilder.styles.fontSizes, {
								[tag]: value
							})
						);
						emailBuilder.applyGeneralStyles('fontSizes', tag);
					}
				},
				color: {
					value: emailBuilder.getStyleProp('fontColors')[tag],
					onChange: value => {
						editor.runCommand('change-general-style-prop', {
							changedProp: 'color',
							oldValue:
								emailBuilder.getStyleProp('fontColors')[tag],
							newValue: value,
							selector: tag
						});
						emailBuilder.setStyleProp(
							'fontColors',
							merge(emailBuilder.styles.fontColors, {
								[tag]: value
							})
						);
						emailBuilder.applyGeneralStyles('fontColors', tag);
					},
					emptyValue: isWrapper
						? '#000000'
						: () => emailBuilder.getStyleProp('fontColors').wrapper,
					// TODO: add this dynamically through email options
					emptyValueLabel: isWrapper
						? capitalize(
								emailBuilder.getTranslation(
									'misc.black',
									true
								) || emailBuilder.getTranslation('Black')
						  )
						: emailBuilder.getTranslation("Email's text color"),
					onReset: emptyValue => {
						if (isWrapper) {
							editor.setStyleRules(
								'wrapper',
								{ color: emptyValue },
								{ addStyles: true }
							);
						} else {
							emailBuilder.setStyleProp(
								'fontColors',
								merge(emailBuilder.styles.fontColors, {
									[tag]: null
								})
							);
							editor.removeStyleRules(tag, 'color');
						}
					}
				},
				lineHeight: {
					value: emailBuilder.getStyleProp('lineHeights')[tag],
					onChange: value => {
						editor.runCommand('change-general-style-prop', {
							changedProp: 'lineHeight',
							oldValue:
								emailBuilder.getStyleProp('lineHeights')[tag],
							newValue: value,
							selector: tag
						});
						if (value != null) {
							emailBuilder.setStyleProp(
								'lineHeights',
								merge(emailBuilder.styles.lineHeights, {
									[tag]: value
								})
							);
							emailBuilder.applyGeneralStyles('lineHeights', tag);
						}
					},
					onReset: () => {
						editor.runCommand('change-general-style-prop', {
							changedProp: 'lineHeight',
							oldValue:
								emailBuilder.getStyleProp('lineHeights')[tag],
							newValue: null,
							selector: tag
						});
						editor.removeStyleRules(tag, 'line-height');
						emailBuilder.setStyleProp(
							'lineHeights',
							merge(emailBuilder.styles.lineHeights, {
								[tag]: null
							})
						);
					}
				}
			},
			emailBuilder
		);
	}

	const accordionContent = [];

	if (options.wrapper.useAccordion) {
		types.unshift('wrapper');
	} else {
		accordionContent.push(
			addClassesString(
				setContent(
					document.createElement('div'),
					getAccordionContent('wrapper')
				),
				'eb-p-3'
			)
		);
	}

	accordionContent.push(
		...types
			.map(tag =>
				new Accordion({
					accordionColor: options.headings.accordion.color,
					accordionType: options.headings.accordion.type,
					title: getAccordionTitle(tag),
					content: getAccordionContent(tag)
				}).getEl()
			)
			.filter(accordion => !!accordion)
	);

	return new Accordion({
		title: options.accordion.title,
		content: accordionContent,
		accordionType: options.accordion.type,
		accordionColor: options.accordion.color,
		extendedClasses: options.accordion.extendedClasses,
		contentExtendedClasses: options.accordion.contentExtendedClasses
	});
};
