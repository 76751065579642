import { FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND } from 'lexical';
import { getIcon } from 'datatalks-icons';

export default editor => {
	return {
		bold: {
			name: 'bold',
			payload: 'bold',
			type: FORMAT_TEXT_COMMAND,
			icon: getModifierIcon('bold'),
			category: 'text'
		},
		italic: {
			name: 'italic',
			payload: 'italic',
			type: FORMAT_TEXT_COMMAND,
			icon: getIcon('italic'),
			category: 'text'
		},
		strikethrough: {
			name: 'strikethrough',
			payload: 'strikethrough',
			type: FORMAT_TEXT_COMMAND,
			icon: getModifierIcon('strikethrough'),
			category: 'text'
		},
		underline: {
			name: 'underline',
			payload: 'underline',
			type: FORMAT_TEXT_COMMAND,
			icon: getModifierIcon('underline'),
			category: 'text'
		},
		code: {
			name: 'code',
			payload: 'code',
			type: FORMAT_TEXT_COMMAND,
			icon: getModifierIcon('code-view'),
			category: 'text'
		},
		superscript: {
			name: 'superscript',
			payload: 'superscript',
			type: FORMAT_TEXT_COMMAND,
			icon: getModifierIcon('superscript'),
			category: 'text'
		},
		alignLeft: {
			name: 'alignLeft',
			payload: 'left',
			type: FORMAT_ELEMENT_COMMAND,
			icon: getModifierIcon('align-left'),
			category: 'alignment'
		},
		alignCenter: {
			name: 'alignCenter',
			payload: 'center',
			type: FORMAT_ELEMENT_COMMAND,
			icon: getModifierIcon('align-center'),
			category: 'alignment'
		},
		alignRight: {
			name: 'alignRight',
			payload: 'right',
			type: FORMAT_ELEMENT_COMMAND,
			icon: getModifierIcon('align-right'),
			category: 'alignment'
		},
		alignJustify: {
			name: 'alignJustify',
			payload: 'justify',
			type: FORMAT_ELEMENT_COMMAND,
			icon: getModifierIcon('align-justify'),
			category: 'alignment'
		}
	};
};

/**
 * Get the icon for the modifier.
 * @param {string} name - The name of the modifier.
 * @return {string} The icon for the modifier.
 */
function getModifierIcon(name) {
	return getIcon(name, { size: 'md' });
}
