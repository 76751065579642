import { COMPONENTS_DEFAULTS } from '../../../../config/_config';
import render from './_render';
import { getComponentsDefaultsByType } from '../../../componentsDefaults/_componentsDefaults';
import baseMethods from '../../common/methods/_baseMethods';
import toHtml from './_toHtml';
import { merge } from 'datatalks-utils';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = getComponentsDefaultsByType('button');

	const options = {
		...COMPONENTS_DEFAULTS,
		...defaults,
		...componentOptions,
		isListeningGeneralStyles: false
	};

	return {
		model: {
			defaults: () => ({
				...options,
				name: options.name,
				tagName: options.tagName,
				attributes: {
					...options.attributes,
					class: options.cssClass
				},
				cssClass: options.cssClass,
				content: options.text,
				components: comp => comp.componentRender(comp)
			}),

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			componentRender: render,
			...baseMethods(componentOptions, editor)
		}
	};
};
