import callToAction from './call-to-action.svg';
import footer from './footer.svg';
import header from './header.svg';
import hero from './hero.svg';
import horizontalList from './horizontal-list.svg';
import imageHorizontalList from './image-horizontal-list.svg';
import imageVerticalList from './image-vertical-list.svg';
import verticalList from './vertical-list.svg';
import image from './image.svg';
import button from './button.svg';
import text from './text.svg';
import space from './space.svg';
import separator from './separator.svg';
import linksList from './links-list.svg';
import buttonHorizontalList from './button-horizontal-list.svg';

export default {
	callToAction,
	footer,
	header,
	hero,
	horizontalList,
	imageHorizontalList,
	imageVerticalList,
	verticalList,
	image,
	button,
	text,
	linksList,
	space,
	separator,
	buttonHorizontalList
};
