import { merge, cssStyleObjectToString } from 'datatalks-utils';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default (comp, options) => {
	const defaults = {
		debug: false,
		href: comp.get('href') || comp.get('attributes')?.href,
		parentWidth: null,
		style: {
			width: options.parentWidth || null,
			display: 'inline-block'
		}
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const innerComponents = comp.components().map(c => c);

	const isImage =
		innerComponents.length === 1 &&
		innerComponents[0].get('type') === 'image-wrapper';

	let html;

	if (isImage) {
		html = innerComponents[0].toHTML({
			parentWidth: options.parentWidth,
			href: options.href,
			hasLink: true
		});
	} else {
		const inner =
			comp.get('content') ||
			innerComponents
				.map(child =>
					child.toHTML({ parentWidth: options.parentWidth })
				)
				.join('\n');

		html = `
			<a href="${options.href}" style="${cssStyleObjectToString(
			validateStyleObj(options.style)
		)}">
				${inner}
			</a>
		`;
	}

	if (options.debug) console.log('Link HTML: ', html);

	return html;
};
