import marginsButtonGroup from './marginsAccordion/_marginButtonsGroup';
import marginsColor from '../../../commonAccordions/style/common/_marginsController';
import { Accordion, SectionItem, LinkedInputs } from 'datatalks-ui';
import { capitalize } from 'lodash-es';
import { setContent, getUnit, merge } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		traitsVisibility: {
			hasMargins: true,
			margins: true,
			// TODO: set default to true when the feature is ready
			marginsColor: false
		}
	};

	options = merge(defaults, options);

	const marginsAccordionContent = document.createElement('div');
	setContent(
		marginsAccordionContent,
		[
			options.traitsVisibility.hasMargins &&
				new SectionItem({
					label: 'Choose one option',
					content: marginsButtonGroup(obj).getEl()
				}).getEl(),
			options.traitsVisibility.margins &&
				new SectionItem({
					label: 'Margins',
					content: new LinkedInputs({
						items: ['left', 'right', 'top', 'bottom'].map(side => {
							return {
								label: capitalize(side),
								input: {
									defaultValue: obj.component.get(
										`${side}Margin`
									),
									unit: getUnit(
										obj.component.get(`${side}Margin`)
									),
									changeableUnit: true,
									onChange: (value, unit, inputValue) => {
										obj.component.setAndRerender(
											`${side}Margin`,
											inputValue
										);
									}
								}
							};
						})
					}).getEl()
				}).getEl(),
			options.traitsVisibility.marginsColor &&
				marginsColor(
					{
						label: 'Margins color',
						left: obj.component.get.bind(
							obj.component,
							'leftMarginColor'
						),
						right: obj.component.get.bind(
							obj.component,
							'rightMarginColor'
						),
						top: obj.component.get.bind(
							obj.component,
							'topMarginColor'
						),
						bottom: obj.component.get.bind(
							obj.component,
							'bottomMarginColor'
						),
						onLeftChange: color =>
							obj.component.setAndRerender(
								'leftMarginColor',
								color
							),
						onRightChange: color =>
							obj.component.setAndRerender(
								'rightMarginColor',
								color
							),
						onTopChange: color =>
							obj.component.setAndRerender(
								'topMarginColor',
								color
							),
						onBottomChange: color =>
							obj.component.setAndRerender(
								'bottomMarginColor',
								color
							)
					},
					customTrait
				)
		],
		true
	);

	const marginsAccordion = new Accordion({
		title: 'Margins',
		content: marginsAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return marginsAccordion;
};
