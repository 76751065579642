import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import render from './_render';
import methods from './_methods';
import buildTrait from './_buildTrait';
import { merge } from 'datatalks-utils';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import toHtml from './_toHtml';

/**
 * @typedef {'left' | 'center' | 'right'} ComponentAlignment
 */

/**
 * The object containing the options to configure the list component build
 * @typedef { Object } ListComponentBuildOptions
 * @property {ListItemComponent } defaultItem - The default ListItem component to add when adding a new item.
 * @property { ListItemComponent[] } startItemsList - The list of ListItem components to display when using the list component.
 * @property { string } width - The width of the list component (in CSS notation).
 * @property { ComponentAlignment } align - The alignment of the component. See ComponentAlignment type definition.
 * @property { boolean } isVertical - If set to true the list is displayed in the vertical, else it would be displayed horizontally.
 */

/**
 * Returns an List component configured with the passed options
 * @param { ListComponentBuildOptions } componentOptions - The options to configure the list component.
 * @param { EmailBuilder } emailBuilder - The Email Builder class instance.
 * @return { ListComponent } The List component
 */
export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		attributes: {},
		cssClass: 'gjs-comp-list',
		width: '100%',
		align: 'center',
		isVertical: false,
		style: {
			'table-layout': 'fixed'
		},
		numberOfStartingItems: 2,
		maxItems: 3,
		name: 'List',
		itemType: 'list-item',
		itemOptions: {
			description: '<p>This is part of a list</p>',
			imgWidth: '100%'
		},
		traitDefaultOptions: {
			mutateOptions: {
				listItemElementTemplateOptions: {
					hasImageUploader: true,
					hasTitleEditor: true,
					hasDescriptionEditor: true,
					hasButtonEditor: true,
					buttonProp: 'buttonText',
					titleProp: 'title'
				}
			}
		},
		spacingBetweenElements: '24px',
		itemPropsMap: null,
		toHtmlOptions: {}
	};

	const options = merge(
		COMPONENTS_DEFAULTS,
		getComponentsDefaultsByType(),
		defaults,
		componentOptions
	);

	options.itemOptions = merge(
		getComponentsDefaultsByType(options.itemType) || {},
		options.itemOptions
	);

	if (options.numberOfStartingItems > options.maxItems)
		options.numberOfStartingItems = options.maxItems;

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				...options,
				name: options.name,
				itemDefaultOptions: options.itemOptions,
				attributes: {
					...options.attributes,
					align: options.align,
					class: options.cssClass
				},
				style: merge(options.style, { width: options.width }),
				tagName: 'table',
				components: comp => comp.componentRender(comp),
				items: [],
				defaultItem: options.defaultItem,
				isVertical: options.isVertical,
				traits: comp => comp.buildTrait(comp)
			}),

			toHTML(toHtmlOpts = {}) {
				return toHtml(this, merge(options.toHtmlOptions, toHtmlOpts));
			},

			componentRender: render,
			buildTrait,
			...methods(options, editor)
		}
	};
};
