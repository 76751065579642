import {
	merge,
	isHTML,
	setNodeStyle,
	setContent,
	cssStyleDeclarationToObject as styleToObj
} from 'datatalks-utils';
import getComputedStyle from './_getComputedStyle';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		text: comp.get('text'),
		parentWidth: null
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	if (!isHTML(options.text)) options.text = `<p>${options.text}</p>`;

	const aux = setContent(document.createElement('div'), options.text);
	setNodeStyle(
		aux.childNodes[0],
		merge(
			validateStyleObj(options.style),
			styleToObj(aux.childNodes[0].style)
		)
	);
	const html = aux.innerHTML.replace(/&quot;/g, "'"); // TODO: find a better way to handle this

	if (options.debug) console.log('Text HTML: ', html);

	return html;
};
