/* eslint-disable require-jsdoc */
import { addClassesString, setContent, EventEmitter } from 'datatalks-utils';

/**
 * The options to configure the Dropdown Button Item
 * @typedef { Object } DropdownButtonItemOptions
 * @property { boolean= } active - (If options.selectable is true) Whether or not the item should start active. @default false
 * @property { DropdownButtonItemCallback= } onSelect - A callback function to call when the item is selected. @default null
 * @property { DropdownButtonItemCallback= } onDeselect - A callback function to call when the item is deselected. @default null
 * @property { DropdownButtonItemCallback= } onClick - A callback function to call when the item is clicked. @default null
 * @property { boolean= } selectable - Whether or not the item should be selectable or just an action. @default false
 * @property { HTMLString | string= } content - The HTML content to render on the item. @default ''
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string= } cssClass - The base CSS class to add to the item. @default 'dropdown-button-item'
 * @property { string= } extendedClasses - The CSS classes to extend the item's style. @default ''
 */

/**
 * The callbacks functions called on the onSelect, onDeselect and OnClick events of the DropdownButton Item.
 * @callback DropdownButtonItemCallback
 * @param {DropdownButtonItem} item - The Item itself
 */

/**
 * Class representing a DropdownButton Item to be used as an option inside the DropdownButton.
 * @class
 * @property { DropdownButtonItemOptions }  options - The passed options merged with the default
 * values to configure the DropdownButton Item.
 * @property { HTMLElement }  el - The element that wraps the DropdownButton Item.
 * @property { boolean }  isActive - Whether or not the item is in the active state.
 */
export default class DropdownButtonItem {
	/**
	 * Creates the needed properties to build the DropdownButton Item and calls init method.
	 * @constructor
	 * @param { DropdownButtonItemOptions } options - The options to configure the DropdownButton Item.
	 */
	constructor(options = {}) {
		const defaults = {
			active: false,
			classPrefix: 'eb-',
			cssClass: 'dropdown-button-item',
			extendedClasses: '',
			content: '',
			onSelect: null,
			onDeselect: null,
			selectable: false,
			onClick: null,
			data: null
		};

		this.options = {
			...defaults,
			...options
		};

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.el = null;
		this.isSelectable = this.options.selectable;
		this.isActive = this.options.active && this.isSelectable;
		this.content = this.options.content;
		this.eventEmitter = new EventEmitter();

		this.init();
	}

	/**
	 * Creates the wrapper element, assigns it to the 'el' property and adds the attributes.
	 * Adds a click event listener to call onItemClick method. Sets the inner HTML of the element
	 * provided through the content option
	 * @method
	 */
	init() {
		const item = this;
		item.el = document.createElement('div');
		item.el.classList.add(item.className);
		this.setContent(this.options.content);
		if (item.options.extendedClasses)
			addClassesString(item.el, item.options.extendedClasses);
		item.el.addEventListener('click', e => {
			item.onItemClick.call(item);
		});
	}

	/**
	 * Sets the content of the Item by replacing it's inner HTML with the given content
	 * @param { (HTMLString | string) } content - The new HTML content of the item
	 * @method
	 */
	setContent(content) {
		setContent(this.el, content);
	}

	/**
	 * Calls onClick function passed through options and calls the select method
	 * @method
	 */
	onItemClick() {
		const item = this;
		item.eventEmitter.emit('click', item, this.isActive);
		if (item.isSelectable) item.toggleSelection.call(item);
		if (typeof item.options.onClick === 'function')
			item.options.onClick.call(null, item, this.isActive);
	}

	/**
	 * Selects the item by setting 'isActive' property to 'true'.
	 * Calls options.onSelect if it's valid function
	 * @method
	 * @param { boolean } preventCallback - Prevents the onSelect callback from
	 * being called and 'select' event from being emitted
	 */
	select(preventCallback) {
		this.isActive = true;
		this.el.classList.add(`${this.className}--active`);
		if (!preventCallback) {
			this.eventEmitter.emit('select', this);
			if (typeof this.options.onSelect === 'function')
				this.options.onSelect.call(null, this);
		}
	}

	/**
	 * Deselects the item by setting 'isActive' property to 'false'.
	 * Calls options.onDeselect if it's valid function
	 * @method
	 * @param { boolean } preventCallback - Prevents the onDeselect callback from
	 * being called and 'deselect' event from being emitted
	 */
	deselect(preventCallback) {
		this.isActive = false;
		this.el.classList.remove(`${this.className}--active`);
		if (!preventCallback) {
			if (typeof this.options.onDeselect === 'function')
				this.options.onDeselect.call(null, this);
			this.eventEmitter.emit('deselect', this);
		}
	}

	/**
	 * Calls Select or Deselect based on the current value of isActive prop
	 * @method
	 */
	toggleSelection() {
		this.eventEmitter.emit('toggle', this);
		if (!this.isActive) {
			this.select.call(this);
		} else {
			this.deselect.call(this);
		}
	}

	/**
	 * Gets the wrapper element (Item.el)
	 * @method
	 * @return {HTMLElement} The wrapper element
	 */
	getEl() {
		return this.el;
	}

	/**
	 * Returns whether or not the item is active;
	 * @method
	 * @return {boolean} whether or not the item is active
	 */
	getIsActive() {
		return this.isActive;
	}

	on(eventName, callback) {
		this.eventEmitter.on(eventName, callback);
	}

	off(eventName, callback) {
		this.eventEmitter.off(eventName, callback);
	}
}
