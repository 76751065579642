/* eslint-disable require-jsdoc */
import { merge } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import {
	addClassesString,
	setContent,
	fileReader,
	EventEmitter
} from 'datatalks-utils';

export default class FileInputPreview {
	constructor(options = {}) {
		const defaults = {
			callbacks: {
				change: null,
				input: null,
				click: null,
				delete: null,
				wrapperClick: null
			},
			useInput: true,
			uploadIcon: getIcon('upload-cloud-fill', { size: 'xxl' }),
			editIcon: getIcon('pencil-fill', { size: 'lg' }),
			deleteIcon: getIcon('delete-bin-line', { size: 'lg' }),
			btnText: 'Click to upload image',
			btnClass: '',
			classPrefix: 'eb-',
			cssClass: 'img-input',
			extendedClasses: '',
			image: null,
			name: '',
			description:
				'or drop files here. Make sure your image is a <strong>JPG</strong> or <strong>PNG</strong> at least <strong>600px</strong> wide.'
		};

		this.eventEmitter = new EventEmitter();
		this.options = merge(defaults, options);

		this.className = `${this.options.classPrefix}${this.options.cssClass}`;
		this.file = null;
		this.image = this.options.image;
		this.name = this.options.name;
		this.displayingImage = this.image != null;
		this.options = merge(defaults, options);
		this.init();
	}

	init() {
		for (const cb in this.options.callbacks) {
			if (typeof this.options.callbacks[cb] === 'function') {
				this.eventEmitter.on(cb, this.options.callbacks[cb]);
			}
		}
		this.createWrapper();
		this.createPlaceholder();
		this.createInput();
		this.createActionsContainer();
		this.createDeleteAction();
		this.createEditAction();
		this.drawActionsContainer();
		this.createImage();
		this.createOverlay();
		this.createPreview();
		this.drawPreview();
		this.draw();
		if (this.image) this.drawImage();
	}

	createWrapper() {
		const fileInput = this;
		fileInput.wrapper = document.createElement('div');
		fileInput.wrapper.className = fileInput.className;
		if (fileInput.options.extendedClasses)
			addClassesString(
				fileInput.wrapper,
				fileInput.options.extendedClasses
			);
		fileInput.wrapper.addEventListener('click', e => {
			fileInput.eventEmitter.emit('wrapperClick', fileInput, e);
		});
	}

	createPlaceholder() {
		this.placeholder = document.createElement('div');
		this.placeholder.classList.add(this.className + '__placeholder');
		setContent(this.placeholder, [
			addClassesString(
				setContent(
					document.createElement('i'),
					this.options.uploadIcon
				),
				this.className + '__icon'
			),
			addClassesString(
				setContent(
					document.createElement('button'),
					this.options.btnText
				),
				this.className + '__btn'
			),
			addClassesString(
				setContent(
					document.createElement('p'),
					this.options.description
				),
				this.className + '__description'
			)
		]);
	}

	createInput() {
		if (this.options.useInput) {
			this.input = document.createElement('input');
			this.input.classList.add('eb-input');
			this.input.classList.add(this.className + '__input');
			this.input.setAttribute('type', 'file');
			this.input.setAttribute('accept', 'image/*');
			this.input.addEventListener('change', e => {
				this.handleChange(e.target.files[0]);
			});
			// for (const cb in this.options.callbacks) {
			// 	if (typeof this.options.callbacks[cb] === 'function') {
			// 		this.input.addEventListener(cb, this.options.callbacks[cb]);
			// 	}
			// }
		}
	}

	createActionsContainer() {
		this.actionsContainer = document.createElement('div');
		this.actionsContainer.classList.add(`${this.className}__actions`);
	}

	createDeleteAction() {
		this.deleteAction = document.createElement('div');
		this.deleteAction.classList.add(`${this.className}__action`);
		this.deleteAction.addEventListener('click', e => {
			this.removeImage();
			this.setName('');
			e.stopPropagation();
		});
		setContent(this.deleteAction, this.options.deleteIcon);
	}

	createEditAction() {
		this.editAction = document.createElement('div');
		this.editAction.classList.add(`${this.className}__action`);
		if (this.options.useInput) {
			this.editAction.addEventListener('click', () => this.input.click());
		}
		setContent(this.editAction, this.options.editIcon);
	}

	drawActionsContainer() {
		setContent(this.actionsContainer, [this.deleteAction, this.editAction]);
	}

	createPreview() {
		this.preview = document.createElement('div');
		this.preview.classList.add(`${this.className}__preview`);
	}

	createImage() {
		this.imageElement = document.createElement('img');
		this.imageElement.classList.add(`${this.className}__img`);
	}

	createOverlay() {
		this.overlay = document.createElement('div');
		this.overlay.classList.add(`${this.className}__overlay`);
	}

	drawPreview() {
		setContent(this.preview, [
			this.imageElement,
			this.overlay,
			this.actionsContainer
		]);
	}

	handleChange(file) {
		const fileInput = this;
		fileReader(file, {
			callbacks: {
				onload: result => {
					fileInput.file = file;
					fileInput.setImage(result.e.target.result);
					fileInput.displayImage();
					fileInput.eventEmitter.emit(
						'change',
						fileInput,
						result.e.target.result
					);
				},
				onError: console.error,
				onabort: console.log
			}
		});
	}

	displayImage() {
		this.displayingImage = true;
		this.drawImage();
		this.draw();
	}

	removeImage() {
		this.displayingImage = false;
		this.draw();
		this.eventEmitter.emit('delete', this);
	}

	set(image, name) {
		const fileInput = this;
		fileInput.setImage(image);
		fileInput.displayImage();
		this.setName(name);

		fileInput.eventEmitter.emit('change', fileInput, image);
	}

	setImage(file) {
		this.image = file;
	}

	setName(name) {
		this.name = name || 'Untitled image';
	}

	getImage() {
		return this.image;
	}

	getName() {
		return this.name || this.image?.name || this.file?.name;
	}

	drawImage() {
		this.imageElement.src = this.image;
	}

	draw() {
		if (this.displayingImage) {
			setContent(this.wrapper, [this.preview, this.input]);
			this.wrapper.classList.add(`${this.className}--preview`);
		} else {
			setContent(this.wrapper, [this.placeholder, this.input]);
			this.wrapper.classList.remove(`${this.className}--preview`);
		}
	}

	getEl() {
		return this.wrapper;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
