import { TextEditor, ToggleButton, Accordion } from 'datatalks-ui';
import { addClassesString, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../common/functions/_textEditorCommonActions';

export default (options = {}, obj, customTrait = {}) => {
	const editor = obj.component.getEditor();

	const defaults = {
		textToggler: {
			showToggler: true,
			startActive: true,
			clickTogglesAccordion: false,
			onChange: null // (isActive)
		},
		initialText: null,
		onUpdate: null, // (content)
		accordion: {
			title: 'Text',
			color: 'grey',
			type: 'extend'
		},
		textEditorOptions: {},
		textEditorProp: 'textEditor'
	};

	options = merge(defaults, options);

	const textAccordionContent = document.createElement('div');

	if (!customTrait[options.textEditorProp]) {
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent: options.initialText,
			initialState: options.initialState,
			onChange: (html, editorState) => {
				if (typeof options.onUpdate === 'function')
					options.onUpdate(html, editorState);
			},
			...textEditorCommonOptions(editor),
			...options.textEditorOptions
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp]
		);
	}

	textAccordionContent.append(customTrait[options.textEditorProp].getEl());

	const title = options.textToggler.showToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.textToggler.clickTogglesAccordion,
						startActive: options.textToggler.startActive,
						onChange: isActive => {
							if (
								typeof options.textToggler.onChange ===
								'function'
							)
								options.textToggler.onChange(isActive);
						}
					}).getEl(),
					`<span>${options.accordion.title}</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: options.accordion.title;

	const textAccordion = new Accordion({
		title: title,
		content: textAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return textAccordion;
};
