import {
	validateStyleObj,
	validateBorder
} from '../common/functions/_utilFunctions';

export default comp => {
	const getGeneralStyle = comp
		.getEditor()
		.getStyleRules.bind(comp.getEditor(), 'component');

	const borderRadius = comp.get('useBorderRadius')
		? Object.values(comp.get('borderRadius')).join(' ')
		: [
				getGeneralStyle('border-top-left-radius') || 0,
				getGeneralStyle('border-top-right-radius') || 0,
				getGeneralStyle('border-bottom-right-radius') || 0,
				getGeneralStyle('border-bottom-left-radius') || 0
		  ].join(' ');

	const style = {
		'background-color': comp.get('backgroundColor'),
		'border-color': comp.get('borderColor'),
		'border-style': comp.get('borderStyle'),
		'border-width': comp.get('borderWidth'),
		'border-radius': borderRadius,
		width: '100%'
	};

	return validateStyleObj(validateBorder(style));
};
