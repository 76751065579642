import { capitalize, camelCase } from 'lodash-es';
import { getUnit, setContent, merge, addClassesString } from 'datatalks-utils';
import {
	Accordion,
	SectionItem,
	section,
	LinkedInputs,
	ToggleButton,
	InputNumber,
	ToggleableSection
} from 'datatalks-ui';
import alignGroup from '../../../commonAccordions/style/common/_alignment';
import sliderInput from '../../../commonAccordions/style/common/_sliderOrInput';
import spacingInput from '../../../Header/components/styleTab/spacingAccordion/_spacingInput';

export default (obj, options = {}, customTrait = {}) => {
	let defaults = {
		traitsVisibility: {
			wrapable: true,
			spaceBetweenListItems: true,
			alignment: true,
			valignment: true,
			width: true,
			margins: true
		},
		accordion: {
			title: '',
			color: '',
			type: ''
		},
		alignmentOptions: {
			label: '',
			value: null,
			onChange: null,
			extendedClasses: '',
			left: {},
			center: {},
			right: {}
		},
		vAlignmentOptions: {
			label: '',
			value: null,
			onChange: null,
			extendedClasses: '',
			left: {},
			center: {},
			right: {}
		},
		margins: {
			label: '',
			left: null,
			right: null,
			top: null,
			bottom: null,
			onLeftChange: null,
			onRightChange: null,
			onTopChange: null,
			onBottomChange: null,
			changeableUnit: true,
			inputsStartLinked: true
		},
		activeSides: {
			left: true,
			right: true,
			top: true,
			bottom: true
		},
		listItemWidth: {
			label: '',
			value: null,
			unit: '',
			changeableUnit: true,
			onChange: null
		},
		isWrapableOptions: {
			label: 'Wrapable',
			startOpen: false,
			onToggle: null,
			content: null,
			toggleableContent: true
		},
		componentProperties: {
			spaceBetweenInnerComponents: 'spacingBetweenElements',
			horizontalSpaceBetweenInnerComponents:
				'horizontalSpacingBetweenElements',
			spaceBetweenListItems: 'linksGap'
		},
		linksWidthSliderOptions: {
			label: 'Icons list width',
			value:
				obj.component.attributes.linksListWidth === 'auto'
					? 100
					: parseInt(obj.component.attributes.linksListWidth),
			onChange: value =>
				obj.component.changeLinksListWidth.call(
					obj.component,
					parseInt(value) + '%'
				),
			sliderOptions: {
				extendedClasses: 'eb-mt-3'
			}
		}
	};
	defaults = {
		...defaults,
		spacingInputOptions: {
			label: 'Spacing between icons',
			getValue: obj.component.get.bind(
				obj.component,
				options?.componentProperties?.spaceBetweenListItems ||
					defaults.componentProperties.spaceBetweenListItems
			)
		}
	};

	options = merge(defaults, options);

	let showSlider = !obj.component.get('useLinksGap');
	const dynamicContent = document.createElement('div');
	let setDynamicContent = null;

	setDynamicContent = renderSlider => {
		if (renderSlider) {
			if (getUnit(obj.component.get('linksListWidth')) != '%')
				obj.component.changeLinksWidthToPercentage.call(obj.component);
			setContent(
				dynamicContent,
				sliderInput(
					{
						...options.linksWidthSliderOptions,
						label: options.linksWidthSliderOptions.label
					},
					customTrait
				)
			);
		} else {
			obj.component.changeLinksListWidth.call(obj.component, 'auto');
			setContent(
				dynamicContent,
				new SectionItem({
					label: options.spacingInputOptions.label,
					content: spacingInput.call(
						null,
						obj,
						options.spacingInputOptions
					)
				}).getEl()
			);
		}
	};
	setDynamicContent(showSlider);

	const listItemWidth = new InputNumber({
		defaultValue:
			options.listItemWidth.value === 'auto'
				? '36px'
				: options.listItemWidth.value,
		unit: options.listItemWidth.unit,
		changeableUnit: options.listItemWidth.changeableUnit,
		onChange: (value, unit, inputValue) => {
			if (typeof options.listItemWidth.onChange === 'function')
				options.listItemWidth.onChange(inputValue);
		}
	});

	const listAccordionContent = setContent(document.createElement('div'), [
		section({
			label: 'List Width',
			content: [
				options.traitsVisibility.spaceBetweenListItems &&
					addClassesString(
						setContent(document.createElement('div'), [
							`<p>${options.spacingInputOptions.label}</p>`,
							new ToggleButton({
								startActive: !obj.component.get('useLinksGap'),
								onChange: value => {
									showSlider = value;
									setDynamicContent(showSlider);
									obj.component.setAndRerender(
										'useLinksGap',
										!value
									);
								}
							}).getEl(),
							`<p>${options.linksWidthSliderOptions.label}</p>`
						]),
						'eb-flex eb-items-center eb-gap-3 eb-justify-between eb-mt-5'
					),
				options.traitsVisibility.spaceBetweenListItems &&
					addClassesString(dynamicContent, 'eb-mt-3'),
				options.traitsVisibility.wrapable &&
					new ToggleableSection({
						label: options.isWrapableOptions.label,
						startOpen: options.isWrapableOptions.startOpen,
						onToggle: options.isWrapableOptions.onToggle,
						content: options.isWrapableOptions.content,
						toggleableContent:
							options.isWrapableOptions.toggleableContent
					}).getEl()
			]
		}),
		(options.traitsVisibility.alignment ||
			options.traitsVisibility.valignment) &&
			section({
				label: 'Alignment',
				content: [
					options.traitsVisibility.alignment &&
						new SectionItem({
							label: options.alignmentOptions.label,
							content: alignGroup(
								options.alignmentOptions
							).getEl()
						}).getEl(),
					options.traitsVisibility.valignment &&
						new SectionItem({
							label: options.vAlignmentOptions.label,
							content: alignGroup(
								options.vAlignmentOptions
							).getEl()
						}).getEl()
				]
			}),
		(options.traitsVisibility.width || options.traitsVisibility.margins) &&
			section({
				label: 'Icon',
				content: [
					options.traitsVisibility.width &&
						new SectionItem({
							label: options.listItemWidth.label,
							content: listItemWidth.getEl()
						}).getEl(),
					options.traitsVisibility.margins &&
						new SectionItem({
							label: options.margins.label,
							content: new LinkedInputs({
								startLinked: options.margins.inputsStartLinked,
								items: Object.keys(options.activeSides)
									.filter(side => options.activeSides[side])
									.map(side => {
										return {
											label: capitalize(side),
											input: {
												defaultValue:
													options.margins[side],
												unit: getUnit(
													options.margins[side]
												),
												changeableUnit:
													options.margins
														.changeableUnit,
												onChange: (
													value,
													unit,
													inputValue
												) => {
													if (
														typeof options.margins[
															camelCase(
																`on ${side} change`
															)
														] === 'function'
													)
														options.margins[
															camelCase(
																`on ${side} change`
															)
														](inputValue);
												}
											}
										};
									})
							}).getEl()
						}).getEl()
				]
			})
	]);

	const listAccordion = new Accordion({
		title: options.accordion.title,
		content: listAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return listAccordion;
};
