export default {
	tagName: 'tr',
	cssClass: 'gjs-comp-margins',
	name: 'Component Margins',
	backgroundColor: null,
	innerComponents: [],
	innerRenderType: null,
	hasMargins: false,
	margins: {
		left: '0px',
		right: '0px',
		top: '0px',
		bottom: '0px'
	},
	marginsColor: {
		left: 'transparent',
		right: 'transparent',
		top: 'transparent',
		bottom: 'transparent'
	},
	wrapInTable: false,
	width: '100%',
	toHtmlOptions: {}
};
