/**
 * The object containing the options to configure the hero trait
 * @typedef { Object } HeroTraitOptions
 * @property { string } cssClass - The base class to apply to the wrapper container of the hero trait.
 * @property { string } extendedClasses - Other classes to apply to the wrapper container of the hero trait (i.e. utility classes).
 * @property { boolean } hasImageWidthSlider - Wether or not to have a range slider that changes image's width.
 */

/**
 * Creates a trait of the type 'hero' configured with the passed options
 * @param {HeroTraitOptions} options - The options to configure the hero trait.
 */

export default (options = {}) => {
	return {
		type: 'hero',
		options
	};
};
