/**
 * The object containing the options to configure the list trait
 * @typedef { Object } ListTraitOptions
 * @property { string } cssClass - The base class to apply to the wrapper container of the list trait.
 * @property { string } extendedClasses - Other classes to apply to the wrapper container of the list trait (i.e. utility classes).
 */

/**
 * Creates a trait of the type 'list' configured with the passed options
 * @param {ListTraitOptions} options - The options to configure the list trait.
 */

export default (options = {}) => {
	return {
		type: 'list',
		options
	};
};
