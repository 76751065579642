/**
 * The object containing the options to configure the Call To Action trait
 * @typedef { Object } CallToActionTraitOptions
 * @property { string } cssClass - The base class to apply to the wrapper container of the trait.
 * @property { string } extendedClasses - Other classes to apply to the wrapper container of the trait (i.e. utility classes).
 */

/**
 * Creates a trait of the type 'call-to-action' configured with the passed options
 * @param {CallToActionTraitOptions} options - The options to configure the call to action trait.
 */

export default (options = {}) => {
	return {
		type: 'call-to-action',
		options
	};
};
