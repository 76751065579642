import { setContent } from 'datatalks-utils';
import ImageInput from '../../../common/imgFileInput/_imgFileInputPreview';
import {
	TextEditor,
	ToggleableSection,
	Input,
	InputLabel,
	SectionItem
} from 'datatalks-ui';
import { merge } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';

export default (obj, item, options, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const defaults = {
		accordionContentTemplate: null,
		accordionTitleTemplate: null,
		hasImageUploader: true,
		hasTitleEditor: true,
		hasDescriptionEditor: true,
		hasButtonEditor: true,
		buttonProp: 'buttonText',
		buttonHrefProp: 'buttonHref',
		imageHasLinkProp: 'imageHasLink',
		imageHrefProp: 'imageHref',
		onImageChange: null,
		onImageDelete: null,
		useInput: false,
		descriptionEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}descriptionEditor`,
		titleEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}titleEditor`,
		buttonEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}buttonEditor`
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (
		obj.component?.attributes?.traitOptions?.itemsListMutationOptions
			?.listItemElementTemplateOptions
	) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.itemsListMutationOptions
				.listItemElementTemplateOptions
		);
	}

	const urlInput = document.createElement('input');
	urlInput.addEventListener('input', e => {
		obj.component.changeItemProp.call(
			obj.component,
			item,
			'href',
			e.target.value
		);
	});
	urlInput.className = 'eb-input';
	if (item.href) {
		urlInput.value = item.href;
	} else {
		urlInput.placeholder = item.url || 'URL';
	}

	// TODO: add validation to prevent accordion from being empty

	if (
		options.hasDescriptionEditor &&
		!customTrait[options.descriptionEditorProp]
	) {
		customTrait[options.descriptionEditorProp] = new TextEditor({
			initialContent: obj.component.getItemProp(item, 'description'),
			initialState: obj.component.getItemProp(item, 'descriptionState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'description',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'descriptionState',
					editorState,
					false
				);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.descriptionEditorProp]
		);
	}

	if (options.hasTitleEditor && !customTrait[options.titleEditorProp]) {
		customTrait[options.titleEditorProp] = new TextEditor({
			initialContent: obj.component.getItemProp(item, 'title'),
			initialState: obj.component.getItemProp(item, 'titleState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'title',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'titleState',
					editorState,
					false
				);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.titleEditorProp]
		);
	}

	if (options.hasButtonEditor && !customTrait[options.buttonEditorProp]) {
		customTrait[options.buttonEditorProp] = new TextEditor({
			initialContent: obj.component.getItemProp(
				item,
				options.buttonProp || 'buttonText'
			),
			initialState: obj.component.getItemProp(item, 'buttonState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					options.buttonProp || 'buttonText',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'buttonState',
					editorState,
					false
				);
			},
			...textEditorCommonOptions(editor, { isButton: true })
		});

		customTrait[options.buttonEditorProp].toolbar.on(
			'change:line-height',
			lineHeight => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'buttonLineHeight',
					lineHeight
				);
			}
		);

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.buttonEditorProp],
			{
				isButton: true
			}
		);
	}

	const imageUploader = new ImageInput({
		image: obj.component.getItemProp.call(obj.component, item, 'imgSrc'),
		useInput: options.useInput,
		callbacks: {
			wrapperClick: (fileInput, e) => {
				const editor = obj.component.getEditor();
				editor.waitForAssets((opts, sender, a) => {
					fileInput.set(sender.imgSrc, sender.imgName);
				});
			},
			change: (fileInput, imgSrc) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'imgSrc',
					imgSrc
				);
				if (typeof options.onImageChange === 'function')
					options.onImageChange.call(
						null,
						obj.component,
						item,
						fileInput
					);
			},
			delete: () => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'imgSrc',
					null
				);
				if (typeof options.onImageDelete === 'function')
					options.onImageDelete.call(null, obj.component, item);
			}
		}
	});

	const imageLinkSection = new ToggleableSection({
		label: 'Add a link to your image',
		content: new SectionItem({
			label: 'Add a URL',
			content: new Input({
				onChange: (e, input) =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						options.imageHrefProp,
						input.value
					),
				initialValue: obj.component.getItemProp(
					item,
					options.imageHrefProp
				)
			}).getEl()
		}).getEl(),
		toggleableContent: true,
		startOpen: obj.component.getItemProp(item, options.imageHasLinkProp),
		onToggle: isActive =>
			obj.component.changeItemProp.call(
				obj.component,
				item,
				options.imageHasLinkProp,
				isActive
			)
	}).getEl();

	return setContent(document.createElement('div'), [
		options.hasImageUploader &&
			new ToggleableSection({
				label: 'Upload your image',
				content: [imageUploader.getEl(), imageLinkSection],
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayImage'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayImage',
						isActive
					)
			}).getEl(),
		options.hasTitleEditor &&
			new ToggleableSection({
				label: 'Title',
				content: customTrait[options.titleEditorProp].getEl(),
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayTitle'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayTitle',
						isActive
					)
			}).getEl(),
		options.hasDescriptionEditor &&
			new ToggleableSection({
				label: 'Description',
				content: customTrait[options.descriptionEditorProp].getEl(),
				toggleableContent: true,
				startOpen: obj.component.getItemProp(
					item,
					'displayDescription'
				),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayDescription',
						isActive
					)
			}).getEl(),
		options.hasButtonEditor &&
			new ToggleableSection({
				label: 'Button',
				content: [
					new InputLabel({
						input: new Input({
							initialValue: obj.component.getItemProp(
								item,
								options.buttonHrefProp || 'buttonHref'
							),
							onChange: (e, input) => {
								obj.component.changeItemProp.call(
									obj.component,
									item,
									options.buttonHrefProp || 'buttonHref',
									input.value
								);
							},
							extendedClasses: 'eb-mb-4'
						}),
						label: 'Button Link'
					}).getEl(),
					customTrait[options.buttonEditorProp].getEl()
				],
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayButton'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayButton',
						isActive
					)
			}).getEl()
	]);
};
