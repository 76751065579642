import { merge, isValidColor } from 'datatalks-utils';
import { toHex } from 'color2k';

export default (comp, options) => {
	const defaults = {
		debug: false,
		color: comp.get('color'),
		size: comp.get('dimension'),
		parentWidth: null,
		convertColorsToHex: true,
		isVertical: comp.get('isVertical')
	};

	options = merge({}, defaults, options);

	if (options.convertColorsToHex && isValidColor(options.color))
		options.color = toHex(options.color);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	const html = options.isVertical
		? `
		<div style="line-height:${options.size}; height:${options.size}; font-size:${options.size}; background-color: ${options.color}; color: ${options.color};">&#8202;</div>
	`
		: `
	<i style="letter-spacing:${options.size};mso-font-width:-100%;display:inline-block;width:${options.size};background-color:${options.color};color: ${options.color};">&#8202;</i>`;

	if (options.debug) console.log('Space HTML: ', html);

	return html;
};
