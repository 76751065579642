import { camelCase, capitalize } from 'lodash-es';
import { getUnit, merge, allEqual } from 'datatalks-utils';
import marginsButtonGroup from './common/_margins';
import marginsColor from './common/_marginsController';
import { Accordion, LinkedInputs, SectionItem } from 'datatalks-ui';
import localize from '../../common/_localize';

export default (options = {}, customTrait = {}) => {
	const defaults = {
		traitsVisibility: {
			hasMargins: true,
			margins: true,
			// TODO: set default to true when the feature is ready
			marginsColor: false
		},
		hasMargins: {
			label: localize('Choose an option'),
			value: null,
			onChange: null
		},
		activeSides: {
			left: true,
			right: true,
			top: true,
			bottom: true
		},
		margins: {
			label:
				localize('traits.styles.properties.margin', true) ||
				localize('Margins'),
			left: null,
			right: null,
			top: null,
			bottom: null,
			onLeftChange: null,
			onRightChange: null,
			onTopChange: null,
			onBottomChange: null,
			changeableUnit: true,
			inputsStartLinked: true
		},
		marginsColor: {
			label: localize('Margins color'),
			left: null,
			right: null,
			top: null,
			bottom: null,
			onLeftChange: null,
			onRightChange: null,
			onTopChange: null,
			onBottomChange: null
		},
		accordion: {
			title:
				localize('traits.styles.sections.margins', true) ||
				localize('Margins'),
			color: 'grey',
			type: 'extend',
			onlyContent: false
		}
	};

	const om = options.margins;
	defaults.margins.inputsStartLinked = allEqual([
		om.left,
		om.right,
		om.top,
		om.bottom
	]);

	options = merge(defaults, options);

	const marginsAccordionContent = [
		options.traitsVisibility.hasMargins &&
			new SectionItem({
				label: options.hasMargins.label,
				content: marginsButtonGroup(options.hasMargins).getEl()
			}).getEl(),
		options.traitsVisibility.margins &&
			new SectionItem({
				label: options.margins.label,
				content: new LinkedInputs({
					startLinked: options.margins.inputsStartLinked,
					items: Object.keys(options.activeSides)
						.filter(side => options.activeSides[side])
						.map(side => {
							return {
								label: capitalize(
									localize(`misc.${side}`, true) ||
										localize(side)
								),
								input: {
									defaultValue: options.margins[side],
									unit: getUnit(options.margins[side]),
									changeableUnit:
										options.margins.changeableUnit,
									onChange: (value, unit, inputValue) => {
										if (
											typeof options.margins[
												camelCase(`on ${side} change`)
											] === 'function'
										)
											options.margins[
												camelCase(`on ${side} change`)
											](inputValue);
									}
								}
							};
						})
				}).getEl()
			}).getEl(),
		options.traitsVisibility.marginsColor &&
			marginsColor(
				{
					label: options.marginsColor.label,
					...options.marginsColor
				},
				customTrait
			)
	];

	const marginsAccordion = new Accordion({
		title: options.accordion.title,
		content: marginsAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return options.accordion.onlyContent
		? marginsAccordion.getContent()
		: marginsAccordion;
};
