import { merge } from 'datatalks-utils';

/**
 * Represents a text editor dropdown.
 */
export default class TextEditorDropdown {
	/**
	 * Creates a new instance of TextEditorDropdown.
	 * @param {Object} options - The dropdown options.
	 * @param {string} options.position - The position of the dropdown in the Toolbar. @default 'top'
	 * @param {Object} options.dropdownOptions - The options for the dropdown creation.
	 * @param {string} options.type - The type of the dropdown. @default 'link'
	 */
	constructor(options = {}) {
		const defaults = {
			position: 'top',
			dropdownOptions: {},
			type: 'link'
		};

		this.options = merge(defaults, options);

		this.type = this.options.type;
		this.position = this.options.position;
		this.dropdownOptions = this.options.dropdownOptions;
	}
}
