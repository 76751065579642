import { merge, addClassesString, setContent } from 'datatalks-utils';

import getStyleTabOptions from '../../common/_styleTabOptions';

import backgroundAccordion from '../../Header/components/styleTab/_backgroundAccordion';
import marginsAccordion from '../../Header/components/styleTab/_marginsAccordion';
import textAccordion from '../../Hero/components/styleTab/_textAccordion';
import linksAccordion from '../../Header/components/styleTab/_linksAccordion';
import spacingAccordion from '../../Header/components/styleTab/_spacingAccordion';
import layoutAccordion from './styleTab/_layoutAccordion';
import listAccordion from './styleTab/_listAccordion';
import borderAccordion from '../../commonAccordions/style/_border';
import { getIcon } from 'datatalks-icons';

export default (obj, options, customTrait = {}) => {
	const defaults = {
		accordionsVisibility: {
			layout: true,
			background: true,
			border: true,
			margins: true,
			spacing: true,
			title: true,
			description: true,
			links: true,
			socialList: true
		},
		extendedClasses: '',
		layoutAccordionOptions: {},
		backgroundAccordionOptions: {},
		marginsAccordionOptions: {},
		spacingAccordionOptions: {
			traitsVisibility: {
				horizontalSpace: true,
				spaceBetweenListItems: false
			}
		},
		titleAccordionOptions: {
			accordion: {
				title: 'Title'
			},
			textAlignmentOptions: {
				componentProperty: 'titleAlignment'
			},
			styleObjPropName: 'titleStyle',
			colorPickerProp: 'titleCp'
		},
		descriptionAccordionOptions: {
			accordion: {
				title: 'Description'
			},
			textAlignmentOptions: {
				componentProperty: 'descriptionAlignment'
			},
			styleObjPropName: 'descriptionStyle',
			colorPickerProp: 'descriptionCp'
		},
		listAccordionOptions: {
			accordion: {
				title: 'Social Network List',
				color: 'grey',
				type: 'extend'
			},
			traitsVisibility: {
				alignment: !obj.component.get('socialListSideways'),
				valignment: obj.component.get('socialListSideways'),
				spaceBetweenListItems: true,
				margins: false
			},
			componentProperties: {
				spaceBetweenListItems: 'socialListGap'
			},
			alignmentOptions: {
				label: 'Align list',
				onChange: (
					allSelected,
					changedItem,
					selectedItems,
					noneSelected
				) => {
					obj.component.setAndRerender.call(
						obj.component,
						'socialListAlignment',
						changedItem.getValue()
					);
				},
				value: obj.component.get('socialListAlignment') || 'center',
				left: {
					value: 'left',
					selected: value => value === 'left',
					icon: getIcon('align-item-left-line', { size: 'xl' })
				},
				center: {
					value: 'center',
					selected: value => value === 'center',
					icon: getIcon('align-item-horizontal-center-line', {
						size: 'xl'
					})
				},
				right: {
					value: 'right',
					selected: value => value === 'right',
					icon: getIcon('align-item-right-line', { size: 'xl' })
				}
			},
			vAlignmentOptions: {
				label: 'Social network list vertical align',
				onChange: (
					allSelected,
					changedItem,
					selectedItems,
					noneSelected
				) => {
					obj.component.setAndRerender(
						'socialListVAlignment',
						changedItem.getValue()
					);
				},
				value: obj.component.get('socialListVAlignment'),
				left: {
					value: 'top',
					selected: value => value === 'top',
					icon: getIcon('align-item-top-line', { size: 'xl' })
				},
				center: {
					value: 'middle',
					selected: value => value === 'middle',
					icon: getIcon('align-item-vertical-center-line', {
						size: 'xl'
					})
				},
				right: {
					value: 'bottom',
					selected: value => value === 'bottom',
					icon: getIcon('align-item-bottom-line', { size: 'xl' })
				}
			},
			margins: {
				label: 'Margins',
				left: obj.component.get('socialListLeftMargin'),
				right: obj.component.get('socialListRightMargin'),
				top: obj.component.get('socialListTopMargin'),
				bottom: obj.component.get('socialListBottomMargin'),
				changeableUnit: false,
				inputsStartLinked: false,
				onLeftChange: inputValue =>
					obj.component.setAndRerender(
						'socialListLeftMargin',
						inputValue
					),
				onRightChange: inputValue =>
					obj.component.setAndRerender(
						'socialListRightMargin',
						inputValue
					),
				onTopChange: inputValue =>
					obj.component.setAndRerender(
						'socialListTopMargin',
						inputValue
					),
				onBottomChange: inputValue =>
					obj.component.setAndRerender(
						'socialListBottomMargin',
						inputValue
					)
			},
			listItemWidth: {
				label: 'Size',
				value: obj.component.get('iconWidth') || 'auto',
				unit: 'px',
				changeableUnit: false,
				onChange: inputValue =>
					obj.component.changeAllItemWidth(inputValue)
			},
			isWrapableOptions: {
				label: 'Long Icons List on Mobile Support (>7 icons)',
				toggleableContent: true,
				startOpen: obj.component.get('socialListIsWrapable'),
				onToggle: value => {
					obj.component.setAndRerender('socialListIsWrapable', value);
				},
				content:
					'<p>Breaking large number of icons into new line on mobile devices to prevent overflow. Recommended use for lists with more than 7 icons or even less if using icons bigger than 40px!</p>'
			}
		},
		linksAccordionOptions: {
			accordion: {
				title: 'Links'
			},
			textAlignmentOptions: {
				componentProperty: 'linksAlignment'
			},
			styleObjPropName: 'linksStyle',
			colorPickerProp: 'linksCp',
			traitsVisibility: {
				font: false,
				weight: false,
				letterSpacing: false,
				color: false
			},
			alignmentOptions: {
				onChange: (allSelected, changedItem) => {
					obj.component.setAndRerender(
						'linksAlignment',
						changedItem.getValue()
					);
				},
				value: obj.component.get('linksAlignment')
			}
		}
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (obj.component?.attributes?.traitOptions?.styleTabOptions) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.styleTabOptions
		);
	}

	const styleTab = addClassesString(
		setContent(document.createElement('div'), [
			options.accordionsVisibility.layout &&
				layoutAccordion(
					obj,
					options.layoutAccordionOptions,
					customTrait
				).getEl(),
			options.accordionsVisibility.background &&
				backgroundAccordion
					.call(
						null,
						obj,
						options.backgroundAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.margins &&
				marginsAccordion
					.call(
						null,
						obj,
						options.marginsAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.border
				? borderAccordion(
						getStyleTabOptions(obj, 'border'),
						customTrait
				  ).getEl()
				: null,
			options.accordionsVisibility.spacing &&
				spacingAccordion
					.call(
						null,
						obj,
						options.spacingAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.title &&
				textAccordion
					.call(null, obj, options.titleAccordionOptions, customTrait)
					.getEl(),
			options.accordionsVisibility.description &&
				textAccordion
					.call(
						null,
						obj,
						options.descriptionAccordionOptions,
						customTrait
					)
					.getEl(),
			options.accordionsVisibility.socialList &&
				listAccordion(
					obj,
					options.listAccordionOptions,
					customTrait
				).getEl(),
			options.accordionsVisibility.links &&
				linksAccordion
					.call(null, obj, options.linksAccordionOptions, customTrait)
					.getEl()
		]),
		options.extendedClasses
	);

	return styleTab;
};
