import { merge } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {
		debug: !!emailBuilder.getOptions().devMode
	};

	commandOptions = merge(defaults, commandOptions);

	return {
		run: (editor, sender, options) => {
			if (commandOptions.debug)
				console.log(
					`Assets set: ${Object.keys(options)
						.filter(key => options[key] != null)
						.join(', ')}.`
				);
		}
	};
};
