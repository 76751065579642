import { merge } from 'datatalks-utils';
import linksAccordion from '../../Header/components/styleTab/_linksAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}) => {
	const defaults = {
		extendedClasses: '',
		skipAccordionTitle: true
	};

	options = merge(defaults, options);

	const styleTab = addClassesString(
		setContent(
			document.createElement('div'),
			options.skipAccordionTitle
				? linksAccordion().getContent()
				: linksAccordion().getEl()
		),
		options.extendedClasses
	);

	return styleTab;
};
