import { merge } from 'datatalks-utils';

/* eslint-disable require-jsdoc */
export default class Alert {
	constructor(options) {
		const defaults = {
			classPrefix: 'eb-',
			alertClass: 'alert',
			type: 'info',
			title: '',
			description: '',
			icon: null,
			isHidden: true
		};

		this.options = merge(defaults, options);

		this.type = this.options.type;
		this.icon = this.options.icon;
		this.title = this.options.title;
		this.description = this.options.description;
		this.isHidden = this.options.isHidden;

		this.alert = null;
		this.iconWrapper = null;
		this.titleWrapper = null;
		this.descriptionWrapper = null;

		this.init();
	}

	init() {
		this.createIcon();
		this.createTitle();
		this.createDescription();
		this.createAlert();

		if (this.options.isHidden) {
			this.hide();
		}
	}

	createIcon() {
		if (!this.options.icon) {
			return;
		}

		this.iconWrapper = document.createElement('div');
		this.iconWrapper.className = `${this.options.classPrefix}${this.options.alertClass}__icon`;

		this.setIcon(this.options.icon);
	}

	createTitle() {
		if (!this.options.title) {
			return;
		}

		this.titleWrapper = document.createElement('div');
		this.titleWrapper.className = `${this.options.classPrefix}${this.options.alertClass}__title`;

		this.setTitle(this.options.title);
	}

	createDescription() {
		if (!this.options.description) {
			return;
		}

		this.descriptionWrapper = document.createElement('div');
		this.descriptionWrapper.className = `${this.options.classPrefix}${this.options.alertClass}__description`;

		this.setDescription(this.options.description);
	}

	createAlert() {
		const alertWrapper = document.createElement('div');
		alertWrapper.className = `${this.options.classPrefix}${this.options.alertClass} ${this.options.classPrefix}${this.options.alertClass}--${this.type}`;

		const alertLeft = document.createElement('div');
		alertLeft.className = `${this.options.classPrefix}${this.options.alertClass}__left`;

		if (this.iconWrapper) {
			alertLeft.appendChild(this.iconWrapper);
		}

		const alertRight = document.createElement('div');
		alertRight.className = `${this.options.classPrefix}${this.options.alertClass}__right`;

		if (this.titleWrapper) {
			alertRight.appendChild(this.titleWrapper);
		}

		if (this.descriptionWrapper) {
			alertRight.appendChild(this.descriptionWrapper);
		}

		alertWrapper.appendChild(alertLeft);
		alertWrapper.appendChild(alertRight);

		this.alert = alertWrapper;
	}

	setIcon(icon) {
		this.icon = icon;

		if (icon instanceof HTMLElement) {
			this.iconWrapper.innerHTML = '';
			this.iconWrapper.appendChild(icon);
		}
	}

	setTitle(title) {
		this.title = title;

		if (typeof title === 'string') {
			this.titleWrapper.textContent = title;
		} else if (title instanceof HTMLElement) {
			this.titleWrapper.innerHTML = '';
			this.titleWrapper.appendChild(title);
		}
	}

	setDescription(description) {
		this.description = description;

		if (typeof description === 'string') {
			this.descriptionWrapper.textContent = description;
		} else if (description instanceof HTMLElement) {
			this.descriptionWrapper.innerHTML = '';
			this.descriptionWrapper.appendChild(description);
		}
	}

	show() {
		this.isHidden = false;
		this.alert.classList.remove(
			`${this.options.classPrefix}${this.options.alertClass}--hidden`
		);
	}

	hide() {
		this.isHidden = true;
		this.alert.classList.add(
			`${this.options.classPrefix}${this.options.alertClass}--hidden`
		);
	}

	getEl() {
		return this.alert;
	}
}
