/* eslint-disable require-jsdoc */
import { addClassesString, EventEmitter, merge } from 'datatalks-utils';

export default class Input {
	constructor(options = {}) {
		const defaults = {
			height: null,
			classPrefix: 'eb-',
			className: 'input',
			extendedClasses: '',
			type: 'text',
			onChange: null,
			onInput: null,
			initialValue: null,
			placeholder: null
		};
		this.options = merge(defaults, options);
		this.eventEmitter = new EventEmitter();
		this.placeholder = this.options.placeholder;
		this.init();
	}
	init() {
		this.input = addClassesString(
			document.createElement('input'),
			`${this.options.classPrefix}${this.options.className} ${this.options.extendedClasses}`
		);

		if (this.placeholder) this.input.placeholder = this.placeholder;

		this.input.addEventListener('input', e => {
			this.eventEmitter.emit('input', e, this.input);
		});

		this.input.addEventListener('change', e => {
			this.eventEmitter.emit('change', e, this.input);
		});

		if (typeof this.options.onChange === 'function')
			this.on('change', this.options.onChange);

		if (typeof this.options.onInput === 'function')
			this.on('input', this.options.onInput);

		if (this.options.initialValue)
			this.input.value = this.options.initialValue;

		if (this.options.type) this.input.type = this.options.type;

		if (this.options.height)
			this.input.style.setProperty('--input-height', this.options.height);
	}

	getEl() {
		return this.input;
	}

	getValue() {
		return this.input.value;
	}

	setValue(value) {
		this.input.value = value;
	}

	setType(type) {
		this.input.type = type;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
