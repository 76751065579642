import buttonStyleTab from './atoms/Button/components/_styleTab';
import buttonContentTab from './atoms/Button/components/_contentTab';
import callToActionStyleTab from './CallToAction/components/_styleTab';
import callToActionContentTab from './CallToAction/components/_contentTab';
import footerStyleTab from './Footer/components/_styleTab';
import footerContentTab from './Footer/components/_contentTab';
import headerStyleTab from './Header/components/_styleTab';
import headerContentTab from './Header/components/_contentTab';
import heroStyleTab from './Hero/components/_styleTab';
import heroContentTab from './Hero/components/_contentTab';
import listStyleTab from './List/components/_styleTab';
import listContentTab from './List/components/_contentTab';
import linksListStyleTab from './linksList/components/_styleTab';
import linksListContentTab from './linksList/components/_contentTab';
import imageWrapperStyleTab from './atoms/ImageWrapper/components/_styleTab';
import imageWrapperContentTab from './atoms/ImageWrapper/components/_contentTab';
import textStyleTab from './atoms/Text/components/_styleTab';
import textContentTab from './atoms/Text/components/_contentTab';
import baseStyleTab from './base/components/_styleTab';
import baseContentTab from './base/components/_contentTab';
import spaceStyleTab from './atoms/Space/components/_styleTab';
import spaceContentTab from './atoms/Space/components/_contentTab';

export default {
	button: {
		style: buttonStyleTab,
		content: buttonContentTab
	},
	callToAction: {
		style: callToActionStyleTab,
		content: callToActionContentTab
	},
	footer: {
		style: footerStyleTab,
		content: footerContentTab
	},
	header: {
		style: headerStyleTab,
		content: headerContentTab
	},
	hero: {
		style: heroStyleTab,
		content: heroContentTab
	},
	list: {
		style: listStyleTab,
		content: listContentTab
	},
	linksList: {
		style: linksListStyleTab,
		content: linksListContentTab
	},
	imageWrapper: {
		style: imageWrapperStyleTab,
		content: imageWrapperContentTab
	},
	text: {
		style: textStyleTab,
		content: textContentTab
	},
	base: {
		style: baseStyleTab,
		content: baseContentTab
	},
	space: {
		style: spaceStyleTab,
		content: spaceContentTab
	}
};
