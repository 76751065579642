import { merge } from 'datatalks-utils';
import { setContent, htmlToPlainText } from 'datatalks-utils';
import { getIcon } from '../../../../../../icons/_icons';

export default (obj, item, options) => {
	const defaults = {
		titleProp: 'title'
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (
		obj.component?.attributes?.traitOptions?.itemsListMutationOptions
			?.listItemElementTemplateOptions
	) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.itemsListMutationOptions
				.listItemElementTemplateOptions
		);
	}

	const accordionDeleteBtn = getIcon('delete-bin-line');
	accordionDeleteBtn.addEventListener('click', () => {
		obj.component.deleteItem.call(obj.component, item);
		// obj.trait.removeItem(item);
	});
	accordionDeleteBtn.setAttribute('eb-data-action', 'delete');

	return setContent(document.createElement('div'), [
		`
			<i eb-data-action="drag" class="eb-i eb-i-draggable"></i>
			<span>${htmlToPlainText(
				obj.component.getItemProp(item, options.titleProp)
			)}</span>
		`,
		accordionDeleteBtn
	]);
};
