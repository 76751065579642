import { merge } from 'datatalks-utils';
import {
	InputNumber,
	Accordion,
	SectionItem,
	ToggleableSection
} from 'datatalks-ui';
import { getUnit, setContent } from 'datatalks-utils';
import sizeSliderInput from '../../../commonAccordions/style/common/_sliderOrInput';

export default (obj, options) => {
	const defaults = {
		space: {
			visible: true,
			label: 'Spacing between items',
			value: null,
			onChange: null,
			changeableUnit: true
		},
		vertical: {
			visible: true,
			label: 'Vertical spacing between elements',
			value: null,
			onChange: null,
			changeableUnit: true
		},
		horizontal: {
			visible: true,
			label: 'Horizontal spacing between elements',
			value: null,
			onChange: null,
			changeableUnit: true
		},
		size: {
			visible: true,
			toggle: {
				startOpen: false,
				label: 'Use custom size for items',
				onToggle: null
			},
			slider: {
				label: 'Items size',
				value: null,
				min: null,
				max: null,
				interval: null,
				onChange: null
			}
		},
		accordion: {
			title: 'Spacing',
			color: 'grey',
			type: 'extend'
		}
	};

	options = merge(defaults, options);

	const spacingInput = new InputNumber({
		defaultValue: options.space.value,
		unit: getUnit(options.space.value),
		changeableUnit: options.space.changeableUnit,
		onChange: (value, unit, inputValue) => {
			if (typeof options.space.onChange === 'function')
				options.space.onChange(inputValue);
		}
	}).getEl();

	const verticalInput = new InputNumber({
		defaultValue: options.vertical.value,
		unit: getUnit(options.vertical.value),
		changeableUnit: options.vertical.changeableUnit,
		onChange: (value, unit, inputValue) => {
			if (typeof options.vertical.onChange === 'function')
				options.vertical.onChange(inputValue);
		}
	}).getEl();

	const horizontalInput = new InputNumber({
		defaultValue: options.horizontal.value,
		unit: getUnit(options.horizontal.value),
		changeableUnit: options.horizontal.changeableUnit,
		onChange: (value, unit, inputValue) => {
			if (typeof options.horizontal.onChange === 'function')
				options.horizontal.onChange(inputValue);
		}
	}).getEl();

	const sizeSliderOptions = {
		label: options.size.slider.label,
		value: options.size.slider.value,
		min: options.size.slider.min,
		max: options.size.slider.max,
		interval: options.size.slider.interval,
		unit: options.size.slider.unit,
		onChange: options.size.slider.onChange
	};

	const spacingAccordionContent = setContent(document.createElement('div'), [
		options.space.visible &&
			new SectionItem({
				label: options.space.label,
				content: spacingInput
			}).getEl(),
		options.vertical.visible &&
			new SectionItem({
				label: options.vertical.label,
				content: verticalInput
			}).getEl(),
		options.horizontal.visible &&
			new SectionItem({
				label: options.horizontal.label,
				content: horizontalInput
			}).getEl(),
		options.size.visible &&
			new ToggleableSection({
				startOpen: options.size.toggle.startOpen,
				toggleableContent: true,
				label: options.size.toggle.label,
				content: sizeSliderInput(sizeSliderOptions),
				onToggle: isActive => {
					if (typeof options.size.toggle.onToggle === 'function')
						options.size.toggle.onToggle(isActive);
				}
			}).getEl()
	]);

	const buttonAccordion = new Accordion({
		title: options.accordion.title,
		content: spacingAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return buttonAccordion;
};
