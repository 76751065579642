import ImageInput from '../../common/imgFileInput/_imgFileInputPreview';
import { addClassesString, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import {
	ToggleableSection,
	ToggleButton,
	Input,
	Accordion,
	SectionItem
} from 'datatalks-ui';
import localize from '../../common/_localize';

export default (options = {}) => {
	const defaults = {
		accordion: {
			title:
				localize('components.names.image', true) || localize('Image'),
			color: 'grey',
			type: 'extend'
		},
		imageToggler: {
			showToggler: true,
			startActive: true,
			clickTogglesAccordion: false,
			onChange: null // (isActive)
		},
		imgSrc: null,
		useInput: false,
		wrapperClick: null,
		onChange: null, // (fileInput, imgSrc)
		onDelete: null,
		onInputChange: null,
		inputValue: null,
		sectionContentExtendedClasses: 'eb-mt-2',
		sectionLabel: localize('Upload your image'),
		linkSectionLabel: localize('Add a link to your image'),
		urlSectionLabel: localize('Add a URL'),
		linkSectionStartsOpen: false,
		onLinkSectionToggle: null
	};

	options = merge(defaults, options);

	const callbacks = {};

	if (typeof options.onChange === 'function')
		callbacks.change = options.onChange;

	if (typeof options.wrapperClick === 'function')
		callbacks.wrapperClick = options.wrapperClick;

	if (typeof options.onDelete === 'function')
		callbacks.delete = options.onDelete;

	const ImageAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.sectionLabel,
			content: new ImageInput({
				image: options.imgSrc,
				useInput: options.useInput,
				callbacks
			}).getEl(),
			contentExtendedClasses: options.sectionContentExtendedClasses
		}).getEl(),
		new ToggleableSection({
			label: options.linkSectionLabel,
			content: new SectionItem({
				label: options.urlSectionLabel,
				content: new Input({
					onChange: options.onInputChange,
					initialValue: options.inputValue
				}).getEl()
			}).getEl(),
			contentExtendedClasses: options.sectionContentExtendedClasses,
			toggleableContent: true,
			startOpen: options.linkSectionStartsOpen,
			onToggle: options.onLinkSectionToggle
		}).getEl()
	]);

	const title = options.imageToggler.showToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.imageToggler.clickTogglesAccordion,
						startActive: options.imageToggler.startActive,
						onChange: isActive => {
							if (
								typeof options.imageToggler.onChange ===
								'function'
							)
								options.imageToggler.onChange(isActive);
						}
					}).getEl(),
					`<span>${options.accordion.title}</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: localize('components.names.image', true) || localize('Image');

	const ImageAccordion = new Accordion({
		title: title,
		content: ImageAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return ImageAccordion;
};
