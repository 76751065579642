/* eslint-disable require-jsdoc */

import { addClassesString, setContent } from 'datatalks-utils';

/**
 * The callback function to be called when Radio Button Group Item selection has changed.
 * @callback RadioButtonGroupItem~onChangeCallback
 * @param { boolean } isSelected - Wether or not all items became selected.
 * @param { RadioButtonGroupItem } item - The item changed.
 */

/**
 * The callback function to be called when Radio Button Group Item is clicked. Is triggered before the item value is changed (if changeOnClick is set to true).
 * @callback RadioButtonGroupItem~onClickCallback
 * @param { RadioButtonGroupItem } item - The item clicked.
 */

/**
 * Options to configure Radio Button Group Items
 * @typedef { Object } RadioButtonGroupItemOptions
 * @property { bool } selected - wether the item is in active state or not.
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string } cssClass - Class to use as the block class of the item (BEM).
 * @property { string } extendedClasses - Other classes to use in the root element of the item.
 * @property { string | HTMLElement } icon - The html element or html string containing the icon for the item.
 * @property { string | HTMLElement } label - The label of the item. It can be HTML.
 * @property { string } value - The value of the item.
 * @property { RadioButtonGroupItem~onChangeCallback } onChange - Triggered when the item's value is changed.
 * @property { RadioButtonGroupItem~onClickCallback } onClick - Triggered when the item is clicked. Triggered before selection change.
 */

/**
 * Returns a Radio Button Group Item.
 * @param { RadioButtonGroupItemOptions } opts - The options to configure the item.
 * @return { HTMLElement } - The item HTML element.
 */
export default class RadioButtonGroupItem {
	constructor(opts = {}) {
		const defaults = {
			value: null,
			selected: false,
			classPrefix: 'eb-',
			cssClass: 'radio-btn-group-item',
			extendedClasses: '',
			icon: '',
			label: '',
			changeOnClick: true,
			onClick: null,
			onChange: null,
			useActiveBorder: true
		};

		this.opts = {
			...defaults,
			...opts
		};

		this.className = `${this.opts.classPrefix}${this.opts.cssClass}`;

		this.onchangeCbs = [];
		this.onclickCbs = [];

		if ((this.opts.label || this.opts.icon) && this.opts.value != null) {
			this.init();
		} else {
			throw new Error(
				"You need to provide at least a label or an icon and the item's value to create a RadioButtonGroupItem"
			);
		}
	}

	init() {
		this.element = document.createElement('div');
		this.element.className = `${this.className}${
			this.opts.selected ? ' ' + this.className + '--active' : ''
		}${
			this.opts.useActiveBorder
				? ' ' + this.className + '--active-border'
				: ''
		}${this.opts.extendedClasses ? ' ' + this.opts.extendedClasses : ''}`;
		this.element.setAttribute('data-value', this.opts.value);
		this.element.addEventListener('click', () => {
			this.onclick.call(this, this);
		});

		if (typeof this.opts.onClick === 'function')
			this.onclickCbs.push(this.opts.onClick);

		if (typeof this.opts.onChange === 'function')
			this.onchangeCbs.push(this.opts.onChange);

		if (this.opts.changeOnClick) this.onclickCbs.push(this.toggleSelection);

		setContent(this.element, [
			addClassesString(
				setContent(document.createElement('div'), this.opts.icon),
				`${this.className}__icon`
			),
			addClassesString(
				setContent(document.createElement('div'), this.opts.label),
				`${this.className}__label`
			)
		]);

		setContent(
			this.element.querySelector(`.${this.className}__icon`),
			this.opts.icon
		);
	}

	addSelectedClass() {
		this.element.classList.add(this.className + '--active');
	}

	removeSelectedClass() {
		this.element.classList.remove(this.className + '--active');
	}

	getEl() {
		return this.element;
	}

	getValue() {
		return this.opts.value;
	}

	selectItem() {
		const hasChanged = !this.isSelected();
		this.opts.selected = true;
		this.addSelectedClass();
		if (hasChanged) {
			this.onchange.call(this);
		}
	}

	onclick() {
		this.onclickCbs.forEach(cb => {
			if (typeof cb === 'function') cb.call(this, this);
		});
	}

	onchange() {
		this.onchangeCbs.forEach(cb => {
			if (typeof cb === 'function')
				cb.call(this, this.isSelected(), this);
		});
	}

	unselectItem() {
		const hasChanged = this.isSelected();
		this.opts.selected = false;
		this.removeSelectedClass();
		if (hasChanged) {
			this.onchange.call(this);
		}
	}

	isSelected() {
		return this.opts.selected;
	}

	toggleSelection() {
		if (this.isSelected()) {
			this.unselectItem();
		} else {
			this.selectItem();
		}
		return this.isSelected();
	}

	on(eventName, callback, prioritize) {
		eventName = eventName.trim().toLowerCase();
		if (
			typeof this['on' + eventName] === 'function' &&
			Array.isArray(this['on' + eventName + 'Cbs']) &&
			typeof callback === 'function'
		) {
			if (prioritize === true) {
				this['on' + eventName + 'Cbs'].unshift(callback);
			} else if (typeof prioritize === 'number') {
				this['on' + eventName + 'Cbs'].splice(prioritize, 0, callback);
			} else {
				this['on' + eventName + 'Cbs'].push(callback);
			}
		} else {
			console.error(
				`${eventName} is not a valid RadioButtonGroupItem event name.`
			);
		}
	}
}
