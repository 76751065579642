import { merge } from 'datatalks-utils';
import { addClassesString, setContent } from 'datatalks-utils';

export default (commandOptions, emailBuilder) => {
	const defaults = {};

	commandOptions = merge(defaults, commandOptions);

	return {
		run(editor, sender) {
			this.sender = sender;
			const em = editor.getModel();

			const config = editor.Config;
			const pfx = config.stylePrefix;
			const tm = editor.TraitManager;
			const confTm = tm.getConfig();
			let panelC;

			if (confTm.appendTo) return;

			if (!this.$cn) {
				this.$cn = document.createElement('div');
				this.$cn2 = document.createElement('div');
				this.$cn.append(this.$cn2);
				this.$header = document.createElement('div');
				setContent(
					this.$header,
					addClassesString(
						setContent(
							document.createElement('div'),
							em.t('traitManager.empty')
						),
						`${confTm.stylePrefix}header`
					)
				);
				this.$cn.append(this.$header);

				if (confTm.custom) {
					tm.__trgCustom({ container: this.$cn2.childNodes[0] });
				} else {
					setContent(
						this.$cn2,
						addClassesString(
							setContent(
								document.createElement('div'),
								em.t('traitManager.label')
							),
							`${pfx}traits-label`
						)
					);
					this.$cn2.append(tm.render());

					editor.trigger(
						'render:tm',
						editor.getSelected().getTraits()
					);
				}

				var panels = editor.Panels;

				if (!panels.getPanel('views-container')) {
					panelC = panels.addPanel({ id: 'traits-view' });
				} else {
					panelC = panels.getPanel('traits-view');
				}

				panelC
					?.set('appendContent', this.$cn.childNodes[0])
					.trigger('change:appendContent');

				this.target = editor.getModel();
				this.listenTo(this.target, 'component:toggled', this.toggleTm);
			}

			this.toggleTm();
		},

		/**
		 * Toggle Trait Manager visibility
		 * @private
		 */
		toggleTm() {
			const sender = this.sender;
			if (sender && sender.get && !sender.get('active')) return;

			if (this.target.getSelectedAll().length === 1) {
				this.$cn2.removeAttribute('eb-hidden');
				this.$header.setAttribute('eb-hidden', true);
			} else {
				this.$cn2.setAttribute('eb-hidden', true);
				this.$header.removeAttribute('eb-hidden');
			}
		},

		stop() {
			this.$cn2 && this.$cn2.setAttribute('eb-hidden', true);
			this.$header && this.$header.setAttribute('eb-hidden', true);
		}
	};
};
