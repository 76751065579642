import { ToggleButton, Accordion, InputLabel, UrlEditor } from 'datatalks-ui';
import { TextEditor } from 'email-builder-ui';
import {
	addClassesString,
	setContent,
	merge,
	extractPlainText,
	isHTML
} from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';
import modifiersCbs from '../../../common/_buttonEditorCbs';

export default (obj, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const editor = obj.component.getEditor();
	const defaults = {
		hasButtonToggler: true,
		toggleBtnClickTogglesAccordion: false,
		textEditorProp: 'buttonEditor',
		fullButtonLink: true
	};

	options = merge(defaults, options);

	const buttonAccordionContent = document.createElement('div');

	const toolbarOptions = modifiersCbs(obj);

	if (!customTrait[options.textEditorProp]) {
		const buttonText = obj.component.get('buttonText');
		const initialContent = isHTML(buttonText)
			? extractPlainText(buttonText)
			: buttonText;
		if (initialContent !== buttonText)
			obj.component.setAndRerender('buttonText', initialContent);
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent,
			// initialState: obj.component.get('buttonState'),
			onChange: (html, editorState) => {
				obj.component.setAndRerender.call(
					obj.component,
					'buttonText',
					html
				);
				obj.component.set('buttonState', editorState);
			},
			...merge(
				{
					toolbarOptions
				},
				textEditorCommonOptions(editor, { isButton: true })
			)
		});

		customTrait[options.textEditorProp].toolbar.on(
			'change:line-height',
			lineHeight => {
				obj.component.setAndRerender('buttonLineHeight', lineHeight);
			}
		);

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp],
			{
				isButton: true
			}
		);
	}

	let objects = [];

	if (
		editor.getEmailBuilderOptions()?.textEditorOptions?.toolbarOptions
			?.objects.length
	)
		objects = editor
			.getEmailBuilderOptions()
			?.textEditorOptions?.toolbarOptions?.objects.map(obj => ({
				...obj,
				urlEditorLocationType: 'newPopup'
			}));

	const urlEditorToolbarOptions = {
		...(editor.getEmailBuilderOptions()?.textEditorOptions
			?.toolbarOptions || {}),
		objects
	};

	if (options.fullButtonLink) {
		buttonAccordionContent.append(
			new InputLabel({
				input: new UrlEditor({
					initialContent: obj.component.get('buttonHref'),
					initialState: obj.component.get('buttonHrefState') || null,
					toolbarOptions: urlEditorToolbarOptions,
					onChange: (url, state) => {
						obj.component.setAndRerender('buttonHref', url);
						obj.component.setAndRerender('buttonHrefState', state);
					}
				}).getEl(),
				label: localize('Button Link')
			}).getEl()
		);
	}

	buttonAccordionContent.append(
		new InputLabel({
			input: customTrait[options.textEditorProp].getEl(),
			label: localize('Button Text'),
			extendedClasses: 'eb-mt-4'
		}).getEl()
	);
	buttonAccordionContent.append(
		setContent(
			document.createElement('figcaption'),
			localize('Please check the template editor for the style preview')
		) // TODO: delete this when plain text mode can preview styles
	);

	const title = options.hasButtonToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get('displayButton'),
						onChange: isActive => {
							obj.component.setAndRerender(
								'displayButton',
								isActive
							);
						}
					}).getEl(),
					`<span>Button</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: 'Button';

	const buttonAccordion = new Accordion({
		title: title,
		content: buttonAccordionContent,
		accordionColor: 'grey',
		accordionType: 'extend'
	});

	return buttonAccordion;
};
