// import { camelCase } from 'lodash-es';
import Icon from './_component';
// import icons from './_iconsList';

// const obj = {};

// icons.forEach(iconName => {
// 	obj[camelCase(iconName)] = getIcon.bind(null, iconName);
// });

/**
 * Creates an icon with the icon class and returns the icon element.
 * @param { string } iconName - The name of the icon to get.
 * @param { object } iconOptions - The options to configure the icon.
 * @return { HTMLElement } The icon element created with the Icon class
 * @function
 */
export function getIcon(iconName, iconOptions = {}) {
	return new Icon({ iconName, ...iconOptions }).getEl();
}

// export default obj;
