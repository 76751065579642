import { merge } from 'datatalks-utils';
import textAccordion from '../../Hero/components/contentTab/_textAccordion';
import buttonAccordion from '../../Hero/components/contentTab/_buttonAccordion';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const defaults = {
		extendedClasses: '',
		textAccordionOptions: {},
		buttonAccordionOptions: {}
	};

	options = merge(defaults, options);

	const contentTab = addClassesString(
		setContent(document.createElement('div'), [
			textAccordion
				.call(null, obj, options.textAccordionOptions, customTrait)
				.getEl(),
			buttonAccordion
				.call(null, obj, options.buttonAccordionOptions, customTrait)
				.getEl()
		]),
		options.extendedClasses
	);

	return contentTab;
};
