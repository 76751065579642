/* eslint-disable require-jsdoc */
import { EventEmitter } from 'datatalks-utils';
import { merge } from 'lodash-es';

export default class Floater {
	constructor(element, floater, options = {}) {
		if (this.isElement(element)) {
			this.element = element;
		} else {
			throw new Error(
				'element is not an instance of an element in Floater constructor'
			);
		}

		this.intObservers = [];

		this.floater = floater;

		this.parentScrollers = [];

		this.defaults = {
			targetAnchorPoint: {
				x: 'left',
				y: 'bottom'
			},
			floaterAnchorPoint: {
				x: 'left',
				y: 'top'
			},
			margin: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			},
			extendedClasses: '',
			classPrefix: 'eb-',
			className: 'floater',
			startHidden: true,
			targetOnClick: null,
			preventDefaultTargetClickBehavior: false,
			stopTargetClickPropagation: false,
			hideFloaterOn: {
				windowResize: false,
				targetClick: false,
				windowClick: true,
				targetIntersection: true,
				floaterClick: true
			},
			fixedPosition: false,
			debug: false
		};

		if (options) {
			this.options = merge(this.defaults, options);
		} else {
			this.options = { ...this.defaults };
		}

		this.eventEmitter = new EventEmitter();
		this.needsDraw = true;
		this.isVisible = false;

		this.cssClass = `${this.options.classPrefix}${this.options.className}`;

		this.throttle('resize', 'optimizedResize');
		this.throttle(
			'scroll',
			'optimizedScroll',
			{ capture: true, passive: true },
			document
		);

		if (this.element) {
			this.init();
		}
	}

	isValidXValue(xValue) {
		return xValue == 'left' || xValue == 'center' || xValue == 'right';
	}

	isValidYValue(yValue) {
		return yValue == 'top' || yValue == 'middle' || yValue == 'bottom';
	}

	correctPoitnts() {
		if (!this.isValidYValue(this.options.targetAnchorPoint.y)) {
			this.options = {
				...this.options,
				targetAnchorPoint: {
					...this.options.targetAnchorPoint,
					y: 'bottom'
				}
			};
		}

		if (!this.isValidXValue(this.options.targetAnchorPoint.x)) {
			this.options = {
				...this.options,
				targetAnchorPoint: {
					...this.options.targetAnchorPoint,
					x: 'left'
				}
			};
		}

		if (!this.isValidYValue(this.options.floaterAnchorPoint.y)) {
			this.options = {
				...this.options,
				floaterAnchorPoint: {
					...this.options.floaterAnchorPoint,
					y: 'top'
				}
			};
		}

		if (!this.isValidXValue(this.options.floaterAnchorPoint.x)) {
			this.options = {
				...this.options,
				floaterAnchorPoint: {
					...this.options.floaterAnchorPoint,
					x: 'left'
				}
			};
		}
	}

	init() {
		this.alignChangedX = false;
		this.posChangedX = false;
		this.marginChangedX = false;
		this.alignChangedY = false;
		this.posChangedY = false;
		this.marginChangedY = false;

		this.hasBeenDrawn = false;

		this.correctPoitnts();

		document.body.append(this.floater);

		window.addEventListener('optimizedResize', () => {
			if (this.options.hideFloaterOn.windowResize) {
				this.hideFloater();
			} else {
				this.handleWindowResize();
			}
		});

		if (this.options.hideFloaterOn.windowClick)
			document.body.addEventListener('click', e => {
				if (
					!(
						e.target == this.floater ||
						this.floater.contains(e.target)
					) &&
					!(
						e.target == this.element ||
						this.element.contains(e.target)
					)
				)
					this.hideFloater();
			});

		this.element.addEventListener('click', e => {
			if (!this.options.preventDefaultTargetClickBehavior) {
				if (this.isVisible && this.options.hideFloaterOn.targetClick) {
					this.hideFloater();
				} else {
					this.draw();
				}
			}
			if (typeof this.options.targetOnClick == 'function')
				this.options.targetOnClick.call(this, this);
			if (this.options.stopTargetClickPropagation) e.stopPropagation();
		});

		if (this.options.hideFloaterOn.floaterClick)
			this.floater.addEventListener('click', e => {
				this.hideFloater();
			});

		document.addEventListener(
			'optimizedScroll',
			e => {
				if (e.target.contains(this.element) && this.isVisible) {
					this.draw();
				} else {
					this.needsDraw = true;
				}
				if (this.parentScrollers.indexOf(e.target) < 0) {
					this.parentScrollers.push(e.target);
					const observer = new IntersectionObserver(
						this.handleIntersection.bind(this),
						{
							root: e.target
						}
					);
					observer.observe(this.element);
					this.intObservers.push(observer);
				}
			},
			{ capture: true, passive: true }
		);

		this.targetResizeObserver = new ResizeObserver(entries => {
			this.handleTargetResize();
		});

		this.targetResizeObserver.observe(this.element);

		this.floaterResizeObserver = new ResizeObserver(entries => {
			this.handleFloaterResize();
		});

		this.floaterResizeObserver.observe(this.floater);

		this.floater.classList.add(this.cssClass);
		this.element.classList.add(`${this.cssClass}__trigger`);

		if (this.options.fixedPosition)
			this.floater.classList.add(`${this.cssClass}--fixed`);

		if (this.options.extendedClasses)
			this.floater.className += ' ' + this.options.extendedClasses;

		if (!this.options.startHidden) this.draw();
	}

	throttle(type, name, options, obj) {
		obj = obj || window;
		let running = false;
		const func = function () {
			if (running) {
				return;
			}
			running = true;
			requestAnimationFrame(function () {
				obj.dispatchEvent(new CustomEvent(name));
				running = false;
			});
		};
		obj.addEventListener(type, func, options);
	}

	handleWindowResize() {
		if (this.hasBeenDrawn) {
			this.doc = {
				width:
					document.documentElement.clientWidth ||
					document.body.clientWidth ||
					window.innerWidth,
				height:
					document.documentElement.clientHeight ||
					document.body.clientHeight ||
					window.innerHeight
			};

			const newBounds = this.rectToObj(
				this.element.getBoundingClientRect()
			);

			this.eBounds = {
				...newBounds,
				right: this.doc.width - newBounds.left - newBounds.width,
				bottom: this.doc.height - newBounds.top - newBounds.height
			};

			this.leftArgs = {
				...this.leftArgs,
				before: this.eBounds.left,
				eSize: this.eBounds.width,
				after: this.eBounds.right
			};

			this.topArgs = {
				...this.topArgs,
				before: this.eBounds.top,
				eSize: this.eBounds.height,
				after: this.eBounds.bottom
			};

			this.checkArgsForChangesAndUndo();
			this.placeFloater();
		}
	}

	handleIntersection(entries) {
		if (
			this.options.hideFloaterOn.targetIntersection &&
			this.isVisible &&
			entries[0].intersectionRatio < 1
		)
			this.hideFloater();
	}

	handleTargetResize() {
		if (this.hasBeenDrawn) {
			const newBounds = this.rectToObj(
				this.element.getBoundingClientRect()
			);

			this.eBounds = {
				...this.eBounds,
				...newBounds,
				right: this.doc.width - newBounds.left - newBounds.width,
				bottom: this.doc.height - newBounds.top - newBounds.height
			};

			this.leftArgs = {
				...this.leftArgs,
				before: this.eBounds.left,
				eSize: this.eBounds.width,
				after: this.eBounds.right
			};

			this.topArgs = {
				...this.topArgs,
				before: this.eBounds.top,
				eSize: this.eBounds.height,
				after: this.eBounds.bottom
			};

			this.checkArgsForChangesAndUndo();

			this.placeFloater();
		}
	}

	handleFloaterResize() {
		if (this.hasBeenDrawn) {
			this.fBounds = this.floater.getBoundingClientRect();

			this.leftArgs = {
				...this.leftArgs,
				fSize: this.fBounds.width
			};

			this.topArgs = {
				...this.topArgs,
				fSize: this.fBounds.height
			};

			this.checkArgsForChangesAndUndo();

			this.placeFloater();
		}
	}

	checkArgsForChangesAndUndo() {
		if (this.alignChangedX)
			this.leftArgs = {
				...this.leftArgs,
				align: this.convertPointToValues(
					this.options.floaterAnchorPoint
				).x
			};

		if (this.alignChangedY)
			this.topArgs = {
				...this.topArgs,
				align: this.convertPointToValues(
					this.options.floaterAnchorPoint
				).y
			};

		if (this.posChangedX)
			this.leftArgs = {
				...this.leftArgs,
				pos: this.convertPointToValues(this.options.targetAnchorPoint).x
			};

		if (this.posChangedY)
			this.topArgs = {
				...this.topArgs,
				pos: this.convertPointToValues(this.options.targetAnchorPoint).y
			};

		if (this.marginChangedX)
			this.leftArgs = {
				...this.leftArgs,
				marginBefore: this.options.margin.left,
				marginAfter: this.options.margin.right
			};

		if (this.marginChangedY)
			this.topArgs = {
				...this.topArgs,
				marginBefore: this.options.margin.top,
				marginAfter: this.options.margin.bottom
			};

		this.alignChangedX = false;
		this.posChangedX = false;
		this.marginChangedX = false;
		this.alignChangedY = false;
		this.posChangedY = false;
		this.marginChangedY = false;
	}

	draw() {
		this.hasBeenDrawn = true;
		this.needsDraw = false;
		this.eBounds = this.rectToObj(this.element.getBoundingClientRect());

		this.fBounds = this.floater.getBoundingClientRect();

		this.doc = {
			width:
				document.documentElement.clientWidth ||
				document.body.clientWidth ||
				window.innerWidth,
			height:
				document.documentElement.clientHeight ||
				document.body.clientHeight ||
				window.innerHeight
		};

		this.eBounds = Object.assign(
			{},
			{ ...this.eBounds },
			{
				right: this.doc.width - this.eBounds.left - this.eBounds.width,
				bottom: this.doc.height - this.eBounds.top - this.eBounds.height
			}
		);

		this.leftArgs = {
			before: this.eBounds.left,
			eSize: this.eBounds.width,
			pos: this.convertPointToValues(this.options.targetAnchorPoint).x,
			fSize: this.fBounds.width,
			align: this.convertPointToValues(this.options.floaterAnchorPoint).x,
			marginBefore: this.options.margin.left,
			marginAfter: this.options.margin.right,
			after: this.eBounds.right
		};

		this.topArgs = {
			before: this.eBounds.top,
			eSize: this.eBounds.height,
			pos: this.convertPointToValues(this.options.targetAnchorPoint).y,
			fSize: this.fBounds.height,
			align: this.convertPointToValues(this.options.floaterAnchorPoint).y,
			marginBefore: this.options.margin.top,
			marginAfter: this.options.margin.bottom,
			after: this.eBounds.bottom
		};

		this.placeFloater();
		this.showFloater();
	}

	isElement(el) {
		return typeof HTMLElement === 'object'
			? el instanceof HTMLElement
			: el &&
					typeof el === 'object' &&
					el !== null &&
					el.nodeType === 1 &&
					typeof el.nodeName === 'string';
	}

	rectToObj(bounds) {
		const { top, right, bottom, left, width, height, x, y } = bounds;
		return { top, right, bottom, left, width, height, x, y };
	}

	convertPointToValues(point) {
		let x;
		let y;

		if (point.x == 'left') x = 0;
		else if (point.x == 'center') x = 0.5;
		else x = 1;

		if (point.y == 'top') y = 0;
		else if (point.y == 'middle') y = 0.5;
		else y = 1;

		return {
			x: x,
			y: y
		};
	}

	hasSpaceByAxis(
		before,
		eSize,
		pos,
		fSize,
		align,
		marginBefore,
		marginAfter,
		after
	) {
		const margin = marginBefore - marginAfter;
		const hasSpace =
			(align < 1
				? after + (pos < 1 ? eSize * (pos == 0.5 ? pos : 1) : 0) >=
				  fSize * (align == 0.5 ? align : 1) + margin
				: 1) &&
			(align > 0
				? before + (pos > 0 ? eSize * (pos == 0.5 ? pos : 1) : 0) >=
				  fSize * (align == 0.5 ? align : 1) - margin
				: 1) &&
			(align == 0 && margin < 0
				? before + eSize * pos >= Math.abs(margin)
				: 1) &&
			(align == 1 && margin > 0
				? after + eSize * Math.abs(pos - 1) >= margin
				: 1);

		return hasSpace ? true : false;
	}

	hasSpaceOnX(leftArgs) {
		return this.hasSpaceByAxis.apply(undefined, Object.values(leftArgs));
	}

	hasSpaceOnY(topArgs) {
		return this.hasSpaceByAxis.apply(undefined, Object.values(topArgs));
	}

	getPos(before, eSize, pos, fSize, align, marginBefore, marginAfter) {
		return (
			before + eSize * pos - fSize * align + marginBefore - marginAfter
		);
	}

	getPosWrapper(args) {
		return this.getPos.apply(undefined, Object.values(args));
	}

	consoleFeedbackAboutSpace(index, axis, isLast = false) {
		if (this.options.debug) {
			axis = axis ? 'Y' : 'X';
			let msg;
			if (index == 0) {
				msg = 'Floater component - ' + axis + ' Axis:';
				console.group(msg);
				msg =
					"Couldn place the floater on the specified anchor, it doesn't fit the screen on the " +
					axis +
					' axis. Trying without margins...';
				console.warn(msg);
			}

			if (index == 2)
				console.log(
					"...still doesn't fit without margins, trying on a different anchor point..."
				);

			for (let i = 3; (i <= index && !isLast) || i < index; i++) {
				console.warn(
					"...still doesn't fit on this anchor point, trying on a different one..."
				);
			}

			if (index > 0 && !isLast) console.log('...it worked!');

			if (isLast)
				console.error(
					"...couldn't place it successfully on any anchor point, placing it at the edge of the screen"
				);

			if (index != 0) console.groupEnd();
		}
	}

	checkSpaceAndGetPos() {
		let pos = {};

		// X Axis

		if (!this.hasSpaceOnX(this.leftArgs)) {
			this.consoleFeedbackAboutSpace(0, 0);
			if (
				this.hasSpaceOnX({
					...this.leftArgs,
					marginBefore: 0,
					marginAfter: 0
				})
			) {
				this.consoleFeedbackAboutSpace(1, 0);
				this.leftArgs = {
					...this.leftArgs,
					marginBefore: 0,
					marginAfter: 0
				};
				this.marginChangedX = true;
				pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
			} else if (
				this.hasSpaceOnX({
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1,
					align: this.leftArgs.align > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(4, 0);
				this.leftArgs = {
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1,
					align: this.leftArgs.align > 0 ? 0 : 1
				};
				this.alignChangedX = true;
				this.posChangedX = true;
				pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
			} else if (
				this.hasSpaceOnX({
					...this.leftArgs,
					align: this.leftArgs.align > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(2, 0);
				this.leftArgs = {
					...this.leftArgs,
					align: this.leftArgs.align > 0 ? 0 : 1
				};
				this.alignChangedX = true;
				pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
			} else if (
				this.hasSpaceOnX({
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(3, 0);
				this.leftArgs = {
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1
				};
				this.posChangedX = true;
				pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
			} else if (
				this.hasSpaceOnX({
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1,
					align: this.leftArgs.align > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(4, 0);
				this.leftArgs = {
					...this.leftArgs,
					pos: this.leftArgs.pos > 0 ? 0 : 1,
					align: this.leftArgs.align > 0 ? 0 : 1
				};
				this.alignChangedX = true;
				this.posChangedX = true;
				pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
			} else {
				this.consoleFeedbackAboutSpace(5, 0, true);
				pos = { ...pos, left: 0 };
			}
		} else {
			pos = { ...pos, left: this.getPosWrapper(this.leftArgs) };
		}

		// Y Axis

		if (!this.hasSpaceOnY(this.topArgs)) {
			this.consoleFeedbackAboutSpace(0, 1);
			if (
				this.hasSpaceOnY({
					...this.topArgs,
					marginBefore: 0,
					marginAfter: 0
				})
			) {
				this.consoleFeedbackAboutSpace(1, 1);
				this.topArgs = {
					...this.topArgs,
					marginBefore: 0,
					marginAfter: 0
				};
				this.marginChangedY = true;
				pos = { ...pos, top: this.getPosWrapper(this.topArgs) };
			} else if (
				this.hasSpaceOnY({
					...this.topArgs,
					pos: this.topArgs.pos > 0 ? 0 : 1,
					align: this.topArgs.align > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(2, 1);
				this.topArgs = {
					...this.topArgs,
					pos: this.topArgs.pos > 0 ? 0 : 1,
					align: this.topArgs.align > 0 ? 0 : 1
				};
				this.posChangedY = true;
				this.alignChangedY = true;
				pos = { ...pos, top: this.getPosWrapper(this.topArgs) };
			} else if (
				this.hasSpaceOnY({
					...this.topArgs,
					pos: this.topArgs.pos > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(3, 1);
				this.topArgs = {
					...this.topArgs,
					pos: this.topArgs.pos > 0 ? 0 : 1
				};
				this.posChangedY = true;
				pos = { ...pos, top: this.getPosWrapper(this.topArgs) };
			} else if (
				this.hasSpaceOnY({
					...this.topArgs,
					align: this.topArgs.align > 0 ? 0 : 1
				})
			) {
				this.consoleFeedbackAboutSpace(4, 1);
				this.topArgs = {
					...this.topArgs,
					align: this.topArgs.align > 0 ? 0 : 1
				};
				this.alignChangedY = true;
				pos = { ...pos, top: this.getPosWrapper(this.topArgs) };
			} else {
				this.consoleFeedbackAboutSpace(5, 1, true);
				pos = { ...pos, top: 0 };
			}
		} else {
			pos = { ...pos, top: this.getPosWrapper(this.topArgs) };
		}

		return pos;
	}

	showFloater() {
		if (!this.isVisible) {
			if (this.needsDraw) {
				// draw method will call showFloater again once it finished recalculating the position of the floater
				this.draw();
			} else {
				this.floater.classList.add(`${this.cssClass}--active`);
				this.floater.classList.remove(`${this.cssClass}--hidden`);
				this.element.classList.add(`${this.cssClass}__trigger--active`);
				this.isVisible = true;
				this.eventEmitter.emit('show');
			}
		}
	}

	hideFloater() {
		if (this.isVisible) {
			this.floater.classList.add(`${this.cssClass}--hidden`);
			this.floater.classList.remove(`${this.cssClass}--active`);
			this.element.classList.remove(`${this.cssClass}__trigger--active`);
			this.isVisible = false;
			this.eventEmitter.emit('hide');
		}
	}

	toggleVisible() {
		if (this.isVisible) {
			this.hideFloater();
		} else if (this.hasBeenDrawn) {
			this.showFloater();
		} else {
			this.draw();
		}
	}

	placeFloater() {
		const floaterPos = this.checkSpaceAndGetPos();
		this.floater.style.left = floaterPos.left + 'px';
		this.floater.style.top = floaterPos.top + 'px';
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		// TODO: remove all event listeners
		// this.eventEmitter.removeAllListeners();
		this.floater.remove();
		this.targetResizeObserver.disconnect();
		this.floaterResizeObserver.disconnect();
		this.intObservers.forEach(obs => {
			obs.disconnect();
		});
	}
}
