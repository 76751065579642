import { merge } from 'datatalks-utils';
import { InputNumber } from 'datatalks-ui';
import { getUnit } from 'datatalks-utils';

export default (obj, options) => {
	const defaults = {
		getValue: null,
		getUnit: getUnit
	};

	options = merge(defaults, options);

	if (typeof options.getValue === 'string')
		options.getValue = () => options.getValue;

	if (typeof options.getValue === 'function') {
		const spacingInput = new InputNumber({
			defaultValue: options.getValue(),
			unit: getUnit(options.getValue()),
			onChange: (value, unit, inputValue) => {
				obj.component.changeLinksGap.call(obj.component, inputValue);
			}
		}).getEl();

		return spacingInput;
	} else {
		console.error('No getValue function passed');
	}
};
