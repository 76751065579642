export default {
	color: 'transparent',
	backgroundColor: 'transparent',
	dimension: '16px',
	cssClass: 'gjs-comp-space',
	isVertical: true,
	name: 'space',
	tagName: 'tr',
	toHtmlOptions: {}
};
