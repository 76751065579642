/* eslint-disable require-jsdoc */
export default function OnChangePlugin(
	editor,
	{
		ignoreHistoryMergeTagChange = true,
		ignoreSelectionChange = false,
		onChange
	}
) {
	if (onChange) {
		return editor.registerUpdateListener(
			({
				editorState,
				dirtyElements,
				dirtyLeaves,
				prevEditorState,
				tags
			}) => {
				if (
					(ignoreSelectionChange &&
						dirtyElements.size === 0 &&
						dirtyLeaves.size === 0) ||
					(ignoreHistoryMergeTagChange &&
						tags.has('history-merge')) ||
					prevEditorState.isEmpty()
				) {
					return;
				}

				onChange(editorState, editor, tags);
			}
		);
	}

	return null;
}
