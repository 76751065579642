/* eslint-disable require-jsdoc */
import { merge } from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { Button, UrlEditor } from 'datatalks-ui';
import { TextEditorObjectTrait } from './_TextEditorObject';
import TextEditorPopup from './_TextEditorPopup';

const ToolbarObjectLocationTypes = {
	NEW_POPUP: 'newPopup',
	LINK_EDITOR: 'linkEditor',
	CUSTOM: HTMLElement
};

const ToolbarObjectTypes = {
	BUTTON: 'button'
};

export default class ToolbarObject {
	constructor(toolbar, options = {}) {
		const defaults = {
			type: ToolbarObjectTypes.BUTTON,
			contentType: 'text',
			location: null,
			locationType: ToolbarObjectLocationTypes.NEW_POPUP,
			urlEditorLocationType: ToolbarObjectLocationTypes.LINK_EDITOR,
			urlEditorLocation: null,
			popupTitle: 'Options',
			defaultValue: null,
			iconName: '',
			position: 'top',
			items: [],
			objectNodeOptions: {}
		};

		if (options.iconName) {
			defaults.icon = getIcon(options.iconName, {
				size: 'lg'
			});
		} else {
			console.warn('No icon name provided for the ToolbarObject.');
		}

		this.options = merge(defaults, options);
		this.isUrlEditor = toolbar.textEditor instanceof UrlEditor;

		if (this.options.contentType === 'link' && !this.isUrlEditor) {
			console.warn(
				'The toolbar is not an instance of UrlEditor. Skipping the creation of the ToolbarObject.'
			);
			this.invalid = true;
			return;
		}

		this.type = this.options.type;
		this.position = this.options.position;
		this.icon = this.options.icon;
		this.items = this.options.items;
		this.toolbar = toolbar;
		this.editor = toolbar.editor;
		this.objectNodeOptions = this.options.objectNodeOptions;
		this.instances = [];

		this.init();
	}

	init() {
		this.createElement();
		this.addListeners();
		this.draw();
	}

	createElement() {
		switch (this.type) {
			case ToolbarObjectTypes.BUTTON:
				this.element = new Button({
					content: this.icon,
					extendedClasses: `${this.toolbar.className}__button`
				}).getEl();
				break;

			default:
				console.warn(
					`The type ${this.type} is not supported yet for the creation of a ToolbarObject.`
				);
				break;
		}
	}

	getEl() {
		return this.element;
	}

	draw() {
		this.toolbar.addElement(this.element, this.position);
	}

	createInstance(object = null) {
		const toolbarObject = this;
		const instance = {};
		toolbarObject.instances.push(instance);
		if (!object) {
			toolbarObject.createObject(instance);
		} else {
			toolbarObject.linkObject(instance, object);
		}
		toolbarObject.createTrait(instance);
		if (toolbarObject.hasPopup()) {
			toolbarObject.createTraitPopup(instance);
		}
		if (!object) {
			// activate if it's a new object
			instance.objectNode.activate();
		}
	}

	hasPopup() {
		const toolbarObject = this;
		return (
			(toolbarObject.isUrlEditor &&
				toolbarObject.options.urlEditorLocationType ===
					ToolbarObjectLocationTypes.NEW_POPUP) ||
			(!toolbarObject.isUrlEditor &&
				toolbarObject.options.locationType ===
					ToolbarObjectLocationTypes.NEW_POPUP)
		);
	}

	createTrait(instance) {
		const toolbarObject = this;
		instance.trait = new TextEditorObjectTrait({
			type: 'dropdown',
			options: {
				...this.toolbar.options.dropdownsOptions,
				items: toolbarObject.items,
				onChange: (dropdown, item) => {
					toolbarObject.toolbar.editor.update(() => {
						instance.objectNode.setContent(item.value);
					});
				}
			}
		});
	}

	appendToUrlPopup(instance) {
		const toolbarObject = this;
		if (
			toolbarObject.isUrlEditor &&
			toolbarObject.toolbar.textEditor.parentEditor
		) {
			toolbarObject.toolbar.textEditor.parentEditor.toolbar.linkPopupAddContent(
				instance.trait.getEl()
			);
			toolbarObject.toolbar.textEditor.parentEditor.toolbar.linkPopup.popup.on(
				'close',
				() => {
					toolbarObject.removeFromUrlPopup(instance);
				}
			);
		} else if (toolbarObject.toolbar.linkPopup?.popupEl) {
			toolbarObject.toolbar.linkPopupAddContent(instance.trait.getEl());
		} else {
			console.warn('No link popup found to append the trait.');
		}
	}

	removeFromUrlPopup(instance) {
		const toolbarObject = this;
		if (
			toolbarObject.isUrlEditor &&
			toolbarObject.toolbar.textEditor.parentEditor &&
			toolbarObject.toolbar.textEditor.parentEditor.toolbar?.linkPopup
				?.popupEl
		) {
			instance.trait.getEl().remove();
			toolbarObject.toolbar.textEditor.parentEditor.toolbar.linkPopup.popup.off(
				'close',
				() => {
					toolbarObject.removeFromUrlPopup(instance);
				}
			);
		}
	}

	createTraitPopup(instance) {
		const toolbarObject = this;
		instance.traitPopup = new TextEditorPopup(toolbarObject.toolbar, {
			content: [instance.trait.getEl()],
			title: toolbarObject.options.popupTitle || null
		});
		instance.traitPopup.popup.on('close', () => {
			if (instance.objectNode.isActive())
				instance.objectNode.deactivate();
		});
	}

	createObject(instance) {
		const toolbarObject = this;
		instance.objectNode = toolbarObject.toolbar.editorAddObjectNode({
			content:
				toolbarObject.options.defaultValue ||
				toolbarObject.items[0].value,
			onClick: e => {
				e.stopPropagation();
			},
			onClone: clone => {
				instance.objectNode = clone;
				toolbarObject.setupNodeEvents(instance);
			}
		});
		toolbarObject.setupNodeEvents(instance);
	}

	linkObject(instance, object) {
		const toolbarObject = this;
		instance.objectNode = object;
		instance.objectNode.on('clone', clone => {
			instance.objectNode = clone;
			toolbarObject.setupNodeEvents(instance);
		});
		toolbarObject.setupNodeEvents(instance);
	}

	setupNodeEvents(instance) {
		const toolbarObject = this;
		instance.objectNode.on('deactivate', () => {
			toolbarObject.removeFromUrlPopup(instance);
		});
		instance.objectNode.on('activate', () => {
			toolbarObject.nodeActivateHandler(instance);
		});
	}

	nodeActivateHandler(instance) {
		const toolbarObject = this;
		if (toolbarObject.isUrlEditor) {
			if (
				toolbarObject.options.urlEditorLocationType ===
				ToolbarObjectLocationTypes.LINK_EDITOR
			) {
				toolbarObject.appendToUrlPopup(instance);
			}
		}
		if (toolbarObject.hasPopup()) {
			toolbarObject.toolbar.activePopups.forEach(popup => {
				popup.popup.close();
			});
			instance.traitPopup.popup.open();
		}

		// Deactivate other instances
		toolbarObject.instances.forEach(inst => {
			if (inst.objectNode !== instance.objectNode) {
				inst.objectNode.deactivate();
			}
		});
	}

	nodeDeactivateHandler(instance) {
		const toolbarObject = this;
		if (toolbarObject.isUrlEditor) {
			if (
				toolbarObject.options.urlEditorLocationType ===
				ToolbarObjectLocationTypes.LINK_EDITOR
			) {
				toolbarObject.removeFromUrlPopup(instance);
			}
		}
		if (toolbarObject.hasPopup()) {
			instance.traitPopup.popup.close();
		}
	}

	addListeners() {
		this.element.addEventListener('click', e => {
			this.editor.focus();
			this.createInstance();
			e.stopPropagation();
		});
	}
}
