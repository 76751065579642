import { merge } from 'datatalks-utils';
import slider from '../../../common/rangeSlider/_slider';
import { getUnit } from 'datatalks-utils';

export default options => {
	const defaults = {
		extendedClasses: '',
		sliderOptions: {},
		values: {
			current: 100,
			min: 0,
			max: 100,
			interval: 20
		},
		mode: 'positions',
		unit: '%',
		onChange: null
	};

	options = merge(defaults, options);

	const values = [];
	if (options.values.interval) {
		for (
			let i = options.values.min;
			i <= options.values.max;
			i += options.values.interval
		) {
			values.push(i);
		}
	} else {
		values.push(options.values.min, options.values.max);
	}

	const imageSliderDefaultOptions = {
		extendedClasses: options.extendedClasses,
		start: [parseInt(options.values.current)],
		range: {
			min: [options.values.min],
			max: [options.values.max]
		},
		pips: {
			mode: options.mode,
			values,
			density: options.values.interval || 3,
			format: {
				to: value => value + getUnit(options.values.current)
			}
		}
	};

	const imageSliderOptions = merge(
		imageSliderDefaultOptions,
		options.sliderOptions
	);

	const imageSlider = slider(imageSliderOptions);

	imageSlider.noUiSlider.on(
		'set',
		(values, handle, unencoded, tap, positions, noUiSlider) => {
			if (typeof options.onChange === 'function')
				options.onChange.call(null, '' + values[0] + options.unit);
		}
	);

	return imageSlider;
};
