import { merge } from 'datatalks-utils';
import getItemsList from './linksAccordion/_items';
import Sortable from 'sortablejs';
import { ToggleButton, Accordion } from 'datatalks-ui';
import { addClassesString, setContent } from 'datatalks-utils';

export default (obj, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		hasLinksToggler: true,
		extendedClasses: '',
		toggleBtnClickTogglesAccordion: false,
		accordion: {
			title: localize('components.names.linksList') || localize('Links'),
			color: 'grey',
			type: 'extend'
		},
		componentProperties: {
			display: 'displayLinks'
		},
		componentMethods: {
			addItem: 'addLink',
			changeItemsOrder: 'changeLinksOrder'
		},
		itemsListOptions: {
			className: 'items-list-wrapper',
			button: {
				display: true,
				onClick: null,
				content: `+ ${localize('Add new item')}`,
				appendTo: null,
				isOutline: true,
				extendedClasses: 'eb-w-full eb-mt-4',
				dataAction: 'create'
			},
			componentProperties: {
				itemsList: 'linksList'
			}
		}
	};

	options = merge(defaults, options);

	const content = document.createElement('div');
	const items = getItemsList.call(
		null,
		obj,
		options.itemsListOptions,
		customTrait
	);
	content.append(items);
	if (obj.trait.itemsListElement.childElementCount) {
		new Sortable(obj.trait.itemsListElement, {
			handle: '[eb-data-action="drag"]',
			onSort: evt => {
				obj.component[options.componentMethods.changeItemsOrder].call(
					obj.component,
					evt.oldIndex,
					evt.newIndex
				);
			}
		});
	}

	const title = options.hasLinksToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get(
							options.componentProperties.display
						),
						onChange: isActive => {
							obj.component.setAndRerender.call(
								obj.component,
								options.componentProperties.display,
								isActive
							);
						}
					}).getEl(),
					`<span>${options.accordion.title}</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: options.accordion.title;

	const contentTabLinksAccordion = new Accordion({
		title: title,
		content: content,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type,
		extendedClasses: options.extendedClasses || ''
	});

	return contentTabLinksAccordion;
};
