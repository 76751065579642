import { svgToBase64 } from 'datatalks-utils';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';

export default comp => {
	const imageStyle = validateStyleObj({
		'background-color': comp.get('backgroundColor'),
		'border-width': comp.get('borderWidth'),
		'border-style': comp.get('borderStyle'),
		'border-color': comp.get('borderColor'),
		'border-radius': comp.get('useBorderRadius')
			? Object.values(comp.get('borderRadius')).join(' ')
			: 0,
		width: comp.get('imgWidth'),
		'text-align': comp.get('alignment')
	});

	if (comp.get('hasLink')) {
		if (comp.get('tagName') !== 'a') comp.set('tagName', 'a');
		if (comp.get('href')) {
			comp.addAttributes({ href: comp.get('href') });
		}
		comp.setStyle({
			...comp.getStyle(),
			display: 'inline-block'
		});

		return [
			{
				type: 'image',
				src: comp.get('imgSrc'),
				style: imageStyle
			}
		];
	} else {
		if (comp.get('tagName') !== 'img') comp.set('tagName', 'img');
		comp.setStyle(imageStyle);

		if (!comp.get('imgSrc')) {
			comp.addAttributes({ src: svgToBase64(comp.get('fallback')) });
		} else {
			comp.addAttributes({ src: comp.get('imgSrc') });
		}

		return [];
	}
};
